import { MapSource } from '../../sources/source';
import { MapLayer } from '../layer';
import { TgmMapboxComponent } from '../../mapbox.component';
import { ObservableExpression } from '../../../../../types';
import { Layer } from 'mapbox-gl';

// FIXME: for now copied
export interface DefaultMapLayerOptions {
  layout?: any;
  paint?: any;
  sourceLayer?: string;
  'source-layer'?: string;
  type: string;
  minzoom?: number;
  maxzoom?: number;
  filter?: any;
}

/**
 * A general mapbox layer, can be used for ex showing some arbitrary geojson
 */
export class DefaultMapLayer extends MapLayer<MapSource<{}>> {
  private options: DefaultMapLayerOptions;

  constructor(
    protected map: TgmMapboxComponent,
    protected source: MapSource<{}>,
    optionsObservable: ObservableExpression<DefaultMapLayerOptions>
  ) {
    super(map, source);

    this.watch(optionsObservable, options => {
      this.options = options;
      this.update();
    });
  }

  get(): Partial<Layer> {
    if (this.options) {
      const layer: any = {
        type: this.options.type
      };

      if (this.options.sourceLayer) {
        layer['source-layer'] = this.options.sourceLayer;
      }

      for (let key in this.options) {
        if (key !== 'sourceLayer' && (<any>this.options)[key]) {
          layer[key] = (<any>this.options)[key];
        }
      }

      return layer;
    } else {
      return super.get();
    }
  }
}
