import { AbstractSubscriber } from './abstract-subscriber';
import { OnDestroy } from '@angular/core'

export abstract class SubscriberComponent extends AbstractSubscriber implements OnDestroy  {

  constructor() {
    super()
  }

  ngOnDestroy() {
    this.unsubscribe()
  }
}
