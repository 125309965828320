import { Component, Input } from '@angular/core';
import { Route } from '@targomo/core';
import { RouteSegmentToIconPipe } from '../../pipes/route-segment-to-icon.pipe';

@Component({
  selector: 'tgm-route-teaser',
  templateUrl: './route-teaser.component.html',
  styleUrls: ['./route-teaser.component.scss']
})
export class RouteTeaserComponent {

  @Input() route: Route;

  constructor(
    private routeSegmentToIconPipe: RouteSegmentToIconPipe
  ) { }

  get segments(): string[] {
    if (!!this.route) {
      return this.route.routeSegments
        .filter((s: any) => s.type !== 'TRANSFER')
        .map((segment: any) => {
          if (segment.type === 'TRANSIT') {
            return this.routeSegmentToIconPipe.transform(segment);
          } else {
            return segment.type;
          }
        });
    } else {
      return []
    }
  }
}
