import { MarkerMapLayer, SymbolMarkerMapLayer } from '../simple';
import { InteractionLayer } from '../interaction-layer';
import { LatLng, LatLngProperties } from '@targomo/core';
import { Observable ,  Subject } from 'rxjs';
import { MapInteractionEvent } from '../../mapbox.component.type';
import { PrimaryLayer } from '../abstract-layer';
import { DefaultMapLayer } from '../simple/default-layer';
import { GeojsonMapSource } from '../../sources';
import { EMPTY_GEOJSON_DATA } from '../../constants';
import { MarkerMapSource } from '../../sources/index';
import { ObservableExpression } from '../../../../../types';

export interface MarkerInteractionLayerOptions<T> {
  dragTimeoutValue?: number;
  showHover?: boolean;
  hoverTitle?: (marker: T) => string;
}

/**
 *  A layer providing hover and click events for a markers layer
 */
export class MarkerInteractionLayer<
  T extends LatLngProperties
> extends InteractionLayer {
  private hoverTitle: (marker: T) => string;
  private _visible: boolean; // FIXME::
  private layers: (MarkerMapLayer<T> | SymbolMarkerMapLayer<T>)[];

  private hoverSource: GeojsonMapSource;
  private hoverLayer: DefaultMapLayer;

  private dragTimeout: any;
  private dragTimeoutValue = 500;
  private showHover = true;

  readonly events: {
    readonly click: Observable<MapInteractionEvent<T>>;
    readonly context: Observable<MapInteractionEvent<T>>;
    readonly hover: Observable<MapInteractionEvent<T>>;
    readonly drag: Observable<MapInteractionEvent<T>>;
  } = {
    click: new Subject<MapInteractionEvent<T>>(),
    context: new Subject<MapInteractionEvent<T>>(),
    hover: new Subject<MapInteractionEvent<T>>(),
    drag: new Subject<MapInteractionEvent<T>>()
  };

  constructor(
    layers: PrimaryLayer<MarkerMapLayer<T> | SymbolMarkerMapLayer<T>>[],
    options?: ObservableExpression<MarkerInteractionLayerOptions<T>>
  ) {
    super((layers[0].getPrimaryLayer() as any).map);

    if (options) {
      this.watch(options, value => {
        this.dragTimeoutValue =
          value.dragTimeoutValue != null ? value.dragTimeoutValue : 500;
        this.showHover = value.showHover != null ? value.showHover : true;
        this.hoverTitle = value.hoverTitle;
      });
    }

    this.layers = layers.map(layer => layer.getPrimaryLayer());

    this.hoverSource = new GeojsonMapSource(EMPTY_GEOJSON_DATA);
    this.hoverLayer = new DefaultMapLayer(this.map, this.hoverSource, {
      type: 'circle',
      paint: {
        'circle-radius': 20,
        'circle-color': 'rgba(56, 135, 190, 0.7)'
      }
    });

    this.hoverLayer.setVisible(true);

    this.map.zone.runOutsideAngular(() => {
      this.initEvents();
    });
  }

  isActiveFeature(marker: LatLngProperties) {
    return (
      marker &&
      marker.properties &&
      marker.properties['marker-active'] !== false
    );
  }

  // isDraggableFeature(marker: LatLngProperties) {
  //   return marker && marker.properties && (marker.properties['marker-draggable'] !== false)
  // }

  async initEvents() {
    const map = await this.map.getMap();
    const layerIds = this.layers.map(layer => layer.id);
    const sources: { [index: string]: MarkerMapSource<T> } = {};

    this.layers.forEach(layer => {
      sources[layer.id] = layer.getSource();
    });

    function markerByFeature(feature: any) {
      if (feature && feature.properties) {
        const index = feature.properties.index;
        const source = sources[feature.layer && feature.layer.id];
        if (source) {
          return source.byIndex(index);
        }
      } else {
        return null;
      }
    }

    const canvas = await map.getCanvasContainer();

    let downPoint: any = null;
    let currentPoint: any = null;

    let hoverLocation: any = null;
    let hoverPoint: any = null;
    // let hoverFeature: any = null
    // let isMouseDown = false
    let isDragging = false;
    let isMoved = false;

    const onHoverMarker = (event: any, features: any[]) => {
      const marker = features[0] && markerByFeature(features[0]);
      if (marker == hoverLocation) {
        return;
      }

      hoverLocation = marker;
      const mapEvent = new MapInteractionEvent<LatLng>(
        event.lngLat,
        this.mousePoint(event),
        marker,
        event.originalEvent
      );
      this.map.angularZoneMouseMove(() => {
        this.fire(this.events.hover, mapEvent);
      });
    };

    map.on('click', (event: any) => {
      this.map.zone.run(() => {
        const features = this.queryMapRenderedFeatures(map, event.point, {
          layers: layerIds
        });

        if (features.length) {
          const marker = markerByFeature(features[0]);

          if (this.isActiveFeature(marker)) {
            const cellEvent = new MapInteractionEvent(
              event.lngLat,
              this.mousePoint(event),
              marker,
              event.originalEvent
            );
            this.fire(this.events.click, cellEvent);
          }
        }
      });
    });

    map.on('contextmenu', (event: any) => {
      this.map.zone.run(() => {
        const features = this.queryMapRenderedFeatures(map, event.point, {
          layers: layerIds
        });
        if (features.length) {
          const marker = markerByFeature(features[0]);

          if (this.isActiveFeature(marker)) {
            const cellEvent = new MapInteractionEvent(
              event.lngLat,
              this.mousePoint(event),
              marker,
              event.originalEvent
            );
            this.fire(this.events.context, cellEvent);
          }
        }
      });
    });

    map.on('mouseup', (event: any) => {
      clearTimeout(this.dragTimeout);
      isDragging = false;
      // isMouseDown = false
      map.dragPan.enable();
      canvas.style.cursor = '';

      this.hoverSource.updateValue(EMPTY_GEOJSON_DATA);

      if (event.originalEvent.which > 1) {
        return;
      }

      if (hoverPoint && isMoved) {
        const location = markerByFeature(hoverPoint);
        this.map.angularZoneMouseMove(() => {
          this.fire(
            this.events.drag,
            new MapInteractionEvent(
              event.lngLat,
              null,
              location,
              event.originalEvent
            )
          );
        });
      }

      hoverPoint = null;
      downPoint = null;
      currentPoint = null;
    });

    const mouseDown = (event: any) => {
      // isMouseDown = true
      isMoved = false;
      const features = this.queryMapRenderedFeatures(map, event.point, {
        layers: layerIds
      });

      if (features.length && features[0].properties['marker-draggable']) {
        hoverPoint = features[0];
        downPoint = this.mousePoint(event);

        this.dragTimeout = setTimeout(() => {
          if (currentPoint) {
            const diff = Math.sqrt(
              Math.pow(downPoint.x - currentPoint.x, 2) +
                Math.pow(downPoint.y - currentPoint.y, 2)
            );
            if (diff > 15) {
              // 15 pixel movement threshold for stating drag
              return;
            } else if (diff > 0) {
              isMoved = true;
            }
          }

          isDragging = true;
          this.hoverSource.updateValue(hoverPoint);
          canvas.style.cursor = 'move';
        }, this.dragTimeoutValue);
      }
    };

    map.on('mousemove', (event: any) => {
      if (event.originalEvent.which > 1) {
        return;
      }

      if (hoverPoint) {
        let coords = event.lngLat;
        hoverPoint.geometry.coordinates = [coords.lng, coords.lat];
        currentPoint = this.mousePoint(event);
      }

      if (isDragging) {
        isMoved = true;
        canvas.style.cursor = 'grabbing';
        // hoverPoint.geometry.coordinates = [coords.lng, coords.lat]
        this.hoverSource.updateValue(hoverPoint);
      } else {
        const features = this.queryMapRenderedFeatures(map, event.point, {
          layers: layerIds
        });

        if (features.length && features[0].properties['marker-draggable']) {
          const marker = markerByFeature(features[0]);
          map.dragPan.disable();

          if (!this.dragTimeoutValue) {
            canvas.style.cursor = 'move'; // TODO: better to add/remove class instead
          } else if (this.isActiveFeature(marker)) {
            canvas.style.cursor = 'pointer';
          }

          if (this.showHover) {
            canvas.title = this.hoverTitle
              ? this.hoverTitle(marker)
              : marker.toString();
          }
        } else if (!hoverPoint) {
          map.dragPan.enable();

          let cursor = ''; // TODO: better to add/remove class instead

          if (features.length) {
            const marker = markerByFeature(features[0]);

            if (this.showHover) {
              canvas.title = this.hoverTitle
                ? this.hoverTitle(marker)
                : marker.toString();
            }

            if (this.isActiveFeature(marker)) {
              cursor = 'pointer';
            }
          } else {
            canvas.title = '';
          }

          canvas.style.cursor = cursor;
        }

        onHoverMarker(event, features);
      }
    });

    map.on('mousedown', mouseDown);
  }

  setVisible(value: boolean) {
    this._visible = value;
    // NOTE: maybe have an interaction layer parent class ... or maybe not call it layer
    return this;
  }

  isVisible(): boolean {
    return this._visible;
  }
}
