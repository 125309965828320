import { SubscriberComponent } from '@targomo/client'
import { AppModel } from '../../../../model/appModel.service'

export class AbstractMiniReportComponent extends SubscriberComponent {
  allColumns: boolean
  viewModes: { [id: string]: string } = {}
  mobileMode: boolean

  constructor(readonly appModel: AppModel) {
    super()

    this.watch(this.appModel.places.sources.mobileSource$, (source) => {
      this.mobileMode = !!source
    })
  }

  getViewMode(id: string) {
    if (this.allColumns || this.mobileMode) {
      return 'table'
    } else {
      return this.viewModes[id] || 'graph'
    }
  }

  changeViewMode(id: string, mode: string) {
    this.viewModes[id] = mode
  }

  toggle(node: { visible: boolean }) {
    node.visible = !node.visible
  }

  showGraphs() {
    return !this.allColumns && !this.mobileMode
  }

  click(node: any) {}
}
