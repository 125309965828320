/**
 * Download generated content from browser as a file
 */
export function saveFile(data: string, dataType: string, fileName: string) {
  const anchor: any = document.createElement('a')
  const blob        = new Blob([data], {type: dataType})
  anchor.href       = URL.createObjectURL(blob)
  anchor.download   = fileName

  document.body.appendChild(anchor)
  try {
    anchor.click()
  } catch (e) {
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, anchor.download)
    }
  }
  document.body.removeChild(anchor)
}

/**
 * Read the contents of the given file
 */
export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event: any) => {
      resolve(event.target.result)
    }

    reader.onerror = () => reject()
    reader.readAsText(file)
  })
}

