
import { map } from 'rxjs/operators';
import { GeojsonMapSource } from '../../sources/geojson-source';
import { PolygonMapLayerOptions } from '../../layers';
import { PolygonMapLayer } from '../simple/polygon-layer';
import { TgmMapboxComponent } from '../../mapbox.component';
import { MapLayerPosition } from '../layer';

import * as invertPolygons from '../../geometry/invertPolygon';
import { CompositeLayer } from '../composite-layer';
import { ObservableExpression } from '../../../../../types';

/**
 *
 */
export class DefaultPolygonsLayer extends CompositeLayer {
  private _visible: boolean = true;
  private _inverse: boolean = false;
  private _outline: boolean = false;

  private layer: PolygonMapLayer;
  private outlineLayer: PolygonMapLayer;
  private inverseLayer: PolygonMapLayer;

  private source = new GeojsonMapSource(null, { buffer: 0 });
  private inverseSource = new GeojsonMapSource(null, {
    maxzoom: 11,
    buffer: 0
  });

  protected map: TgmMapboxComponent

  constructor(
    mapboxComponent: TgmMapboxComponent,
    source: ObservableExpression<any>,
    optionsExpression: ObservableExpression<PolygonMapLayerOptions>
  ) {
    super(mapboxComponent);
    this.map = mapboxComponent;

    const optionsObservable = this.toObservable(optionsExpression);

    this.layer = new PolygonMapLayer(mapboxComponent, this.source, optionsObservable);
    this.outlineLayer = new PolygonMapLayer(
      mapboxComponent,
      this.source,
      optionsObservable.pipe(
        map( (options: PolygonMapLayerOptions): PolygonMapLayerOptions => {
          const newOptions: PolygonMapLayerOptions = { ...options };
          newOptions.outline = true;
          return newOptions;
        }))
    );

    this.inverseLayer = new PolygonMapLayer(
      mapboxComponent,
      this.inverseSource,
      optionsObservable.pipe(map(options => {
        const newOptions = { ...options };
        newOptions.inverse = true;
        return newOptions;
      }))
    );

    this.layer.setPosition(MapLayerPosition.POLYGONS);
    this.outlineLayer.setPosition(MapLayerPosition.POLYGONS);
    this.inverseLayer.setPosition(MapLayerPosition.BELOW_MARKERS);

    this.setVisible(true);

    this.watch(source, polygons => {
      this.source.updateValue(polygons);
      this.inverseSource.updateValue(
        polygons && invertPolygons.invert(polygons)
      );
    });

    // let visibleOutline = false
    // this.subscriptions.push(model.options.subscribe(options => {
    //   let visibleOutline = false
    //   options.edgeWeights && options.edgeWeights.forEach(item => {
    //     visibleOutline = visibleOutline || !!item.outlineColor
    //   })

    //   outlineLayer.setVisible(visibleOutline)
    // }))

    // this.subscriptions.push(model.options.pluck<any, number>('travelTimesOpacity').subscribe(opacity => {
    //   polygonOptionsObservable.nextProperty('fillOpacity', opacity)
    //   polygonInverseOptionsObservable.nextProperty('fillOpacity', opacity)
    //   polygonOutlineOptionsObservable.nextProperty('outlineOpacity', Math.min(1, opacity * 1.5)) // TODO
    // }))

    // this.subscriptions.push(model.options.pluck<any, boolean>('inverseTravel').subscribe(inverse => {
    //   layer.setVisible(!inverse)
    //   outlineLayer.setVisible(!inverse && visibleOutline)
    //   inverseLayer.setVisible(inverse)
    // }))

    // this.subscriptions.push(this.model.polygons.subscribe(polygons => {
    //   if (!polygons) {
    //     inversePolygonDataObservable.next(null)
    //     return
    //   }

    //   const newPolygons = turf.multiPolygon(this.invertMultipolygon(this.findMaximumRing(polygons)))
    //   inversePolygonDataObservable.next(newPolygons)
    // }))
  }

  // private init() {
  // }

  // private initEvents() {
  // }

  setVisible(value: boolean) {
    this._visible = value;

    if (!value) {
      this.layer.setVisible(false);
      this.outlineLayer.setVisible(false);
      this.inverseLayer.setVisible(false);
    } else {
      this.layer.setVisible(!this._inverse);
      this.outlineLayer.setVisible(!this._inverse && this._outline);
      this.inverseLayer.setVisible(this._inverse);
    }

    return this;
  }

  isVisible(): boolean {
    return this._visible;
  }

  setInverse(value: boolean) {
    this._inverse = value;

    if (this._visible) {
      this.setVisible(true);
    }
  }

  setOutline(value: boolean) {
    this._outline = value;

    if (this._visible) {
      this.setVisible(true);
    }
  }

  setOpacity(opacity: number) { }
}
