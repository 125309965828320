import { CommonModule } from '@angular/common';
import { TgmLocationTableComponent } from './location-table.component';
import { MatIconModule } from '@angular/material';
import { NgModule } from '@angular/core'


@NgModule({
  declarations: [
    TgmLocationTableComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    TgmLocationTableComponent
  ]
})
export class TgmLocationTableModule { }
