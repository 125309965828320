import { MapSource } from './source'

/**
 * A general source for a mapbox layer, return the data give to its constructor as is
 */
export class DefaultMapSource extends MapSource<any> {
  get(data: any) {
    if (data) {
      return data
    } else {
      return super.get(data)
    }
  }
}
