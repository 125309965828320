import { NgModule } from '@angular/core'
import { TgmMapboxComponent } from './mapbox.component'
import { MatIconModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    TgmMapboxComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule
  ],
  exports: [
    TgmMapboxComponent
  ]
})
export class TgmMapboxModule { }
