import { CommonModule } from '@angular/common';
import { TgmFileUploaderComponent } from './file-uploader.component';
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { TgmFileSelectDirective } from './file-select.directive';


@NgModule({
  declarations: [
    TgmFileUploaderComponent,
    TgmFileSelectDirective
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    TgmFileUploaderComponent,
    TgmFileSelectDirective
  ]
})
export class TgmFileUploaderModule { }
