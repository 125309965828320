import d3scales from './scales'
import * as d3 from 'd3'

import {StatisticsKeyMeta} from '@targomo/core'

/**
 *
 * @param value
 * @param max
 * @param interpolator
 * @param invert
 */
export function getValueMaxColor(value: number, max: number, interpolator: string, invert: boolean = false) {
  value = value / max

  return scaleToRgb((<any>d3scales)[interpolator](value))
}

/**
 *
 * @param colorScale
 * @param opacity
 */
export function scaleToRgb(colorScale: any, opacity: number = 1) {
  const rgb = d3.rgb(colorScale)
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`
}

// TODO: this entire module should be reworked

/**
 * @hidden
 */

/**
 * @hidden
 */
function getColor(idx: number, stops: number, interpolator: string, invert: boolean = false) {
  const opacity = 1
  let colorScale: any

  if (invert) {
    colorScale = (<any>d3scales)[interpolator]((stops - idx + 1) / stops)
  } else {
    colorScale = (<any>d3scales)[interpolator]((idx + 1) / stops)
  }

  const rgb = d3.rgb(colorScale)

  return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity + ')'
}

/**
 * @hidden
 */
export function statisticsLayerStops(attribute: StatisticsKeyMeta,
                                     classifier: string,
                                     classes: number,
                                     interpolator: string = 'interpolateYlOrRd') {
  if (!attribute) {
    return 'rgba(0, 0, 0, 0)'
  }

  let breakpoints: any[]
  if (attribute.breakpoints[classifier] && attribute.breakpoints[classifier]['c' + classes]) {
    breakpoints = attribute.breakpoints[classifier]['c' + classes]
  } else {
    breakpoints = attribute.breakpoints.equal_interval.c9
  }

  return {
    property: '' + attribute.statistic_id,
    stops: breakpoints.map((breakpoint: any, i: number) => [breakpoint, getColor(i, breakpoints.length, interpolator)])
  }
}

// /**
//  *
//  */
// export const EMPTY_GEOJSON: any = {
//   "type": "geojson",
//   "data": {
//     "type": "FeatureCollection",
//     "features": []
//   }
// }

// export const EMPTY_GEOJSON_DATA: any = {
//   "type": "FeatureCollection",
//   "features": []
// }

/**
 * @hidden
 */
// export function endpointLayer(endpoint: StatisticsSetMeta, sourceLayer: string, metadata:
// StatisticsKeyMeta, classifier: string = 'equal_interval', classes: number = 9,
// interpolator: string = 'interpolateYlOrRd', opacity: number = 0.5, paint: any = null) {
//   if (endpoint && metadata) {
//     return {
//       'id': 'pop',
//       'type': 'fill',
//       'source': endpoint.table,
//       'source-layer': sourceLayer,
//       'paint': paint || {
//           'fill-color': getCensusStyle(metadata, 'kmeans', 9, interpolator),
//           'fill-opacity': opacity,
//           'fill-antialias': false
//       }
//     }
//   } else {
//     return {
//       'id': 'pop',
//       'type': 'fill',
//       'source': EMPTY_GEOJSON,
//       'paint': {
//         'fill-opacity': 0,
//       }
//     }
//   }
// }


// /**
//  *
//  */
// export class MapLayers {
//   private layers: any[] = []
//   // private timeLayers: any[] = []
//   // private inverseLayers: any[] = []

//   constructor(private map: any, private model: MapModel<LatLng>) {
//   }

//   add(layer: any, next?: string) {
//     this.map.addLayer(layer, next)
//     this.layers.push(layer)
//   }

//   addTimeLayers(layer: any) {
//     this.map.addLayer(layer, 'map-travel')
//     this.layers.push(layer)
//   }

//   clear() {
//     this.layers.forEach((layer) => {
//       if (this.map.getLayer(layer.id))
//         this.map.removeLayer(layer.id)
//     })

//     this.layers = []
//   }

//   init(referenceLayer: string) {
//     this.clear()

//     function createLayer(id: string) {
//       return {
//         'id': id,
//         'type': 'fill',
//         'source': 'map-dummy'
//       }
//     }

//     if (this.map.getSource("map-dummy"))
//       this.map.removeSource("map-dummy")

//     this.map.addSource("map-dummy", EMPTY_GEOJSON)

//     this.map.addLayer(createLayer('map-all-statistics'), referenceLayer || 'tunnel-service-track-casing')
//     this.map.addLayer(createLayer('map-statistics'), 'map-all-statistics')
//     this.map.addLayer(createLayer('map-travel'), 'map-all-statistics')
//     this.map.addLayer(createLayer('map-polygons'), 'map-all-statistics')
//     this.map.addLayer(createLayer('map-below-markers'))
//     this.map.addLayer(createLayer('map-markers'))
//     this.map.addLayer(createLayer('map-sources'))
//     this.map.addLayer(createLayer('map-hover'))
//     this.map.addLayer(createLayer('map-above-hover'))
//   }
// }
