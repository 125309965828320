import {Component, Input, OnInit, OnChanges} from '@angular/core'

/**
 * A component that shows a loading bar based on the model input state, useful as a saving status indicator for forms
 *
 * Inputs:
 * @model Can be a boolean or a promise. If it is a boolean the loading bar is shown when it's value is true. If it is a promise
 * the loading bar is shown when the promise is unresolved.
 *
 * TODO: also support Observable<Promise<{}>>
 */
@Component({
  selector: 'tgm-saveable',
  styleUrls: ['./saveable.component.less'],
  templateUrl: './saveable.component.html'
})
export class TgmSaveableComponent implements OnInit, OnChanges {
  @Input() private model: Promise<{}> | boolean
  loading: boolean = false
  error: string

  ngOnInit() {
    this.updateState()
  }

  ngOnChanges() {
    this.updateState()
  }

  private async updateState() {
    if (typeof this.model == 'boolean') {
      this.loading = this.model
    } else if (this.model && this.model.then) {
      this.loading = true
      this.model
        .then(() => this.loading = false)
        .catch(e => {
          this.loading = false
          this.error = e.message || (e.data && e.data.message) || 'There was an error'
        })
    } else {
      this.loading = false
    }
  }
}
