import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js'
import * as turf from '@turf/turf'
import { TgmMapboxComponent } from '../../index'
import { LatLng } from '@targomo/core';

/**
 *
 */
export class MapboxDrawLayer {
  private draw: any

  constructor(private map: TgmMapboxComponent, options?: any) {
    this.draw = new MapboxDraw(options)
    this.map.addControl(this.draw)
  }

  async removeControl() {
    const map = await this.map.getMap()
    map.removeControl(this.draw)
  }

  modeDraw() {
    this.draw.changeMode(this.draw.modes.DRAW_POLYGON)
  }

  modeSelect() {
    this.draw.changeMode(this.draw.modes.SIMPLE_SELECT)
  }

  modeNodeSelect() {
    const featureId = this.draw.getSelectedId()
    if (featureId) {
      this.draw.changeMode(this.draw.modes.DIRECT_SELECT, {
      featureId
      })
    }
  }

  modeInactive() {
    this.draw.changeMode(this.draw.modes.STATIC)
  }

  delete() {
    this.draw.trash()
  }

  deleteAll() {
    this.draw.deleteAll()
  }

  getFeatures() {
    return this.draw.getAll()
  }

  asFilter() {
    const features = this.getFeatures()
    if (!features) {
      return function() {return false}
    }

    const polygons = turf.multiPolygon(features.features.map((feature: any) => feature.geometry.coordinates))
    return function(point: LatLng) {
      return turf.inside([point.lng, point.lat], polygons)
    }
  }
}
