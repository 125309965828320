import {Component, Input, OnInit, OnChanges, HostBinding} from '@angular/core'

/**
 * A component that shows a loading bar above it's transcluded content based on the model input state
 *
 * Inputs:
 * model Can be a boolean or a promise. If it is a boolean the loading bar is shown when it's value is true. If it is a promise
 * the loading bar is shown when the promise is unresolved.
 *
 * TODO: also support Observable<Promise<{}>>
 */
@Component({
  selector: 'tgm-loadable',
  styleUrls: ['./loadable.component.less'],
  templateUrl: './loadable.component.html'
})
export class TgmLoadableComponent implements OnInit, OnChanges {

  /**
   * Content of laodable is hidden, as long as this promise is not resolved.
   */
  @Input() model: Promise<{}> | boolean // TODO also observable

  /**
   * Display a loading bar as long as thr promise is unresolved. Default is true.
   */
  @Input() showProgress: boolean = true

  /**
   * Type of loading indicator if `showProgress` is true
   */
  @Input() type: 'spinner' | 'bar' = 'spinner'

  /**
   * no colors!
   */
  @Input() monochrome = false;

  /**
   * @hidden
   */
  @HostBinding('class.loading') loading: boolean = false

  ngOnInit() {
    this.updateState()
  }

  ngOnChanges() {
    this.updateState()
  }

  private async updateState() {
    if (typeof this.model == 'boolean') {
      this.loading = this.model
    } else if (this.model && this.model.then) {
      this.loading = true
      const callback = () => this.loading = false
      this.model.then(callback, callback)
    } else {
      this.loading = false
    }
  }
}
