import * as mapbox from 'mapbox-gl'
import { SetBoundsOptions, LatLng, BoundingBox } from '@targomo/core'

/**
 * An event that is emited when something happens to the map (click, right click map)
 */
export class MapEvent {
  constructor(readonly latlng: LatLng, readonly position: {x: number, y: number}) {}
}

/**
 * An event that is emited when something happens involing a map cell (ex click/hover)
 */
export class MapCellEvent {
  constructor(readonly latlng: LatLng,
              readonly position: {x: number, y: number},
              readonly feature: any = null,
              readonly properties: any = null) {}
}

/**
 * An event that is emited when something happens to a marker (click, right click map)
 */
export class MapInteractionEvent<T extends LatLng> {
  constructor(
    readonly latlng: LatLng,
    readonly position: {x: number, y: number},
    readonly location: T = null,
    readonly originalEvent?: MouseEvent,
    readonly layer?: any
  ) {}
}

/**
 * An event that is emitted when the map is zoomed or moved
 * The associated event object includes the viewport bounding box and the zoom level
 */
export class MapMoveEvent {
  constructor(readonly latlng: LatLng, readonly bounds: BoundingBox, readonly zoom: number) {}
}

export interface MapJumpOptions {
  pitch?: number
  bearing?: number
  zoom?: number
}


// TODO: interface just for thinking now
export interface MapView {
  /**
   * Set the center point and zoom level of the map component
   */
  setView(center: LatLng, animate?: boolean): void

  /**
   * Sets the zoom level for this map
   */
  setZoom(zoom: number): void

  /**
   * Gets the zoom level for this map
   */
  getZoom(): number

  /**
   * Exposes the underlying mapbox component...use with care
   */
  getMap(): Promise<mapbox.Map>

  /**
   * Calls given function, eiher immedialely, or if the map is not done loading the style, after the style is loaded
   * The callback receives the underlying mapbox object as a parameter
   */
  whenSafe(callback: (map?: mapbox.Map) => void): void

  /**
   * Exposes then underlying HTML element of the map so that you can set classes
   */
  getNativeElement(): Promise<HTMLElement>

  /**
   * @hidden Exposes the underlying mapbox `jumpTo` method, which sets the map pitch and bearing
   */
  jumpTo(options: MapJumpOptions): void

  /**
   * Geolocates the users current position and moved the map view position to that position.
   * Requires the user to allow the usage of `navigator.geolocation`
   */
  jumpToCurrentPosition(): void

  /**
   * Get the current map view as an image as a data url
   */
  getImageData(type?: string): string


  /**
   * Exposes the underlying mapbox `addControl` method
   */
  addControl(control: any): void

  /**
   * get the current map view bounds
   */
  getBounds(): BoundingBox

  /**
   * set the map view bounds
   */
  setBounds(bounds: BoundingBox, options?: SetBoundsOptions): void

  /**
   * Wait until any background tiles of the map are finished loading
   */
  waitUntilTilesLoaded(): Promise<{}>
}

// TODO...work on types and ways of firing here
// TODO: think: observable per event or one observable for all events
// export class MapModelEventsImpl<T extends LatLng> implements MapModelEvents<T> {
//   readonly clickMap: Subject<MapInteractionEvent<LatLng>> = new Rx.Subject<MapInteractionEvent<LatLng>>()
//   readonly contextMap: Subject<MapInteractionEvent<LatLng>> = new Rx.Subject<MapInteractionEvent<LatLng>>()
//   readonly clickLocation: Subject<MapInteractionEvent<T>> = new Rx.Subject<MapInteractionEvent<T>>()
//   readonly contextLocation: Subject<MapInteractionEvent<T>> = new Rx.Subject<MapInteractionEvent<T>>()
//   readonly dragLocation: Subject<MapInteractionEvent<T>> = new Rx.Subject<MapInteractionEvent<T>>()
//   readonly hoverCell: Subject<MapCellEvent> = new Rx.Subject<MapCellEvent>()
//   readonly clickCell: Subject<MapCellEvent> = new Rx.Subject<MapCellEvent>()
//   readonly hoverLocation: Subject<MapInteractionEvent<T>> = new Rx.Subject<MapInteractionEvent<T>>()
//   readonly moveMap: Subject<MapMoveEvent> = new Rx.Subject<MapMoveEvent>()
// }
