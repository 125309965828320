import { Route, LatLng } from '@targomo/core';
import * as helpers from '@turf/helpers'
import { MapSource } from '../../../sources/source';

const IGNORE: {[index: string]: boolean} = {
  'walk': true,
  'bike': true,
  'car': true,
  'transit': true,
  'start': true,
  'end': true,
  'source': true,
  'target': true,
}

/**
 *
 * Only useful internally
 */
export class RoutePointsSource extends MapSource<Route[]> {
  get(routes: Route[]) {
    if (routes) {
      const pointsResult: helpers.Feature<any>[] = []

      routes.forEach((route, i) => {
        if (route.routeSegments) {
          route.routeSegments.forEach(segment => {
            if (segment.type !== 'TRANSFER') {
              // only transfer are used as points
              return
            }

            let segmentPoints: LatLng[] = segment.points
            const {points, ...properties} = segment as any
            properties.routeIndex = i
            properties.combinedType = '' + (properties.transitType || properties.routeType || properties.type)

            if (!IGNORE[segment.startName]) {
              const pointProperties = {...properties, name: segment.startName}
              const current = helpers.point([segmentPoints[0].lng, segmentPoints[0].lat], pointProperties)
              pointsResult.push(current)
            }

            if (!IGNORE[segment.endName]) {
              const pointProperties = {...properties, name: segment.endName}
              const current = helpers.point([segmentPoints[segmentPoints.length - 1].lng,
                                            segmentPoints[segmentPoints.length - 1].lat], pointProperties)

              pointsResult.push(current)
            }
          })
        }
      })

      const result = helpers.featureCollection(pointsResult)

      return {
        type: 'geojson',
        data: result
      }
    } else {
      return super.get(routes)
    }
  }

  byIndex(index: number) {
    return this.value[index]
  }
}
