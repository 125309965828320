import * as turf from '@turf/turf';

function findMaximumRing(featureCollection: any) {
  if (!featureCollection) {
    return null;
  }

  let biggestFeature: any = null;
  featureCollection.features.forEach((feature: any) => {
    if (!biggestFeature) {
      biggestFeature = feature;
    } else if (
      biggestFeature.properties != null &&
      feature.properties != null &&
      +biggestFeature.properties.time < +feature.properties.time
    ) {
      biggestFeature = feature;
    }
  });

  if (!biggestFeature) {
    return null;
  } else {
    return biggestFeature.geometry.coordinates;
  }
}

function invertMultipolygon(myltipolygon: any[][][]) {
  if (!myltipolygon) {
    return [];
  }

  const world = [[[180, 90], [-180, 90], [-180, -90], [180, -90], [180, 90]]];
  const holes: any = [];
  const rings: any = [];

  myltipolygon.forEach(outer => {
    outer.forEach((inner, i) => {
      if (i == 0) {
        holes.push(inner);
      } else {
        rings.push([inner]);
      }
    });
  });

  return [world.concat(holes)].concat(rings);
}

export function invert(polygons: any): any {
  return turf.multiPolygon(invertMultipolygon(findMaximumRing(polygons)));
}
