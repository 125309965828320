import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'locationFilter'
})
export class LocationFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items
    }

    searchText = searchText.toLowerCase();

    return items.filter((item) => {
      return (
        item.properties.name.toLowerCase().includes(searchText)
        || item.properties.address.toLowerCase().includes(searchText)
      );
    });
  }
}
