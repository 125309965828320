import { Component, Input, Output, EventEmitter, SimpleChanges, Inject } from '@angular/core'
import { NumberOrValueLabel, ValueLabelFunctions } from '../../../types/value-label'
import { OnChanges, OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { DOCUMENT } from '@angular/common';

/**
 *
 */
@Component({
  selector: 'tgm-travelfab',
  styleUrls: ['./travel-fab.component.less'],
  templateUrl: './travel-fab.component.html'
})
export class TgmTravelFabComponent implements OnChanges, OnDestroy {
  @Input() options: NumberOrValueLabel[] = []
  @Input() colors: string[]
  @Input() unitLabel: string
  @Input() model: any
  @Output() modelChange = new EventEmitter()

  open = false
  animate = false
  selected: any
  items: any[] = []

  constructor(@Inject(DOCUMENT) private document: any) {
    this.document.addEventListener('click', this.clickOutside)
  }

  ngOnChanges(changes: SimpleChanges) {
    const colors = this.colors || []

    if (this.options && this.options.length) {
      this.items = this.options.map((option, i) => ({
        label: ValueLabelFunctions.getLabel(option),
        icon:  ValueLabelFunctions.getIcon(option),
        value: ValueLabelFunctions.getValue(option),
        color: (ValueLabelFunctions.getColor(option) || colors[i] || undefined)}
      ))
    }

    this.selected = this.items.find(option => option.value == this.model)
  }

  ngOnDestroy() {
    this.document.removeEventListener('click', this.clickOutside)
  }

  toggle(event: any) {
    event.stopPropagation()

    this.setOpen(!this.open)
  }

  private readonly clickOutside = () => {
    this.setOpen(false)
  }

  select(value: any) {
    this.setOpen(false)
    this.model = value
    this.modelChange.next(this.model)
  }

  private setOpen(open: boolean) {
    this.open = open
    setTimeout(() => {
      this.animate = this.open
    }, 50)
  }
}
