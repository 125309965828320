import { Component, Input } from '@angular/core'
import {
  AllStatistics,
  CensusStatistics,
  LifestyleStatistics,
  ReportPerCapitaHousehold,
} from '../../../../../common/models/statistics/statistics'
import { AppModel } from '../../../../model/appModel.service'
import { HOUSE_PRICES_SEGMENTS } from '../../model/reportSegments'
import { AbstractMiniReportComponent } from '../abstractMiniReportComponent/abstractMiniReportComponent'

const INDICES = require('../../../../../common/models/statistics/indices_avg_households_prices.json')

@Component({
  selector: 'house-prices-mini-report',
  // templateUrl: '../../censusMiniReport.html',
  templateUrl: './housePricesMiniReport.component.html',
  styleUrls: ['./housePricesMiniReport.component.less'],
})
export class HousePricesMiniReportComponent extends AbstractMiniReportComponent {
  @Input() allColumns: boolean

  // The application wide model keeping track of selected and visible places
  loading: Promise<AllStatistics>
  statistics: CensusStatistics
  allStatistics: AllStatistics
  census: CensusStatistics
  isAvailabelForRegion: boolean
  isScotland: boolean

  currencySymbol = '£'
  perHouseholdCapitaTitle: string = ''

  groups = HOUSE_PRICES_SEGMENTS

  constructor(readonly appModel: AppModel) {
    super(appModel)
  }

  ngOnInit() {
    this.watch(this.appModel.statistics.census.promise, (promise) => (this.loading = promise))
    this.watch(this.appModel.statistics.census.value, (statistics) => {
      if (statistics) {
        this.groups = JSON.parse(JSON.stringify(HOUSE_PRICES_SEGMENTS)).map((item: any) => {
          return {
            ...item,
            totalLabel: item.totalLabel && item.totalLabel.replace(/£/g, statistics.currencySymbol()),
          }
        })

        const householsIncome: typeof statistics.census.householsIncome = JSON.parse(
          JSON.stringify(statistics.census.householsIncome)
        )

        // Average per household
        let region = statistics.indicesRegion
        const indicesRegion = INDICES[region] || {}

        ;['totalGross', 'totalNet', 'totalAfterHousingCosts'].forEach((key) => {
          if (householsIncome[key].total && statistics.census.population2011.households.total) {
            householsIncome[key].total = householsIncome[key].total / statistics.census.population2011.households.total
          } else {
            householsIncome[key].total = 0
          }

          if (indicesRegion[key]) {
            householsIncome[key].percentOverIndex = (householsIncome[key].total / indicesRegion[key]) * 100
          }
        })

        this.statistics = {
          ...(statistics.census.dwellingTypes() as any),
          householsIncome: householsIncome,
        }

        this.census = statistics.census
        this.allStatistics = statistics
        this.currencySymbol = this.allStatistics.currencySymbol()
        this.perHouseholdCapitaTitle =
          statistics.householdsOrPopulation === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Per Household'

        this.isAvailabelForRegion = statistics.country === 'GB'
        this.isScotland =
          (statistics.calculatedRegion || '').toUpperCase() === 'SCOTLAND' ||
          (this.appModel.places.sources.getValues() || []).find(
            (item) => (item.region || '').toUpperCase() === 'SCOTLAND'
          ) != null
      } else {
        this.statistics = null
        this.census = null
        this.allStatistics = null
        this.isScotland = false
      }
    })
  }
}
