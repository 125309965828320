// const Color = require('color')
import * as _Color from 'color'
const colorUtil = _Color // <-- this is a hack. see https://github.com/rollup/rollup/issues/670

// TODO: fix  this module

/**
 *
 */
export class MarkerLayerStyleOptions {
  sizeStops = 6
  sizeStep = 5
  sizeStart = 18

  selectedStrokeColor = 'white'
  selectedStrokeWidth = 2
}

// TODO: do this differently
export namespace markers {

  export function style(colorStops: string[][], options = new MarkerLayerStyleOptions()) {
    const colorStopsLighter = colorStops.map(stop => [stop[0], colorUtil(stop[1]).lighten(0.1).hex()])
    const colorStopsDarker = colorStops.map(stop => [stop[0], colorUtil(stop[1]).darken(0.5).hex()])

    const sizeStops: any = []
    for (let i = 0; i < options.sizeStops; i++) {
      sizeStops.push([{ zoom: 5, value: i }, 1])
    }

    for (let i = 0; i < options.sizeStops; i++) {
      sizeStops.push([{ zoom: 25, value: i }, options.sizeStart + (i * options.sizeStep)])
    }

    // Marker styling, per category
    const markerStyle = {
      'circle-radius': {
        property: 'marker-size',
        type: 'exponential',
        stops: sizeStops,
      },
      'circle-color': {
        property: 'marker-type',
        type: 'categorical',
        stops: colorStops
      },
      'circle-stroke-width': 1,
      'circle-stroke-color': {
        property: 'marker-type',
        type: 'categorical',
        stops: colorStopsDarker
      }
    }

    const sourceMarkerStyle = {
      ...markerStyle,
      'circle-color': {
        property: 'marker-type',
        type: 'categorical',
        stops: colorStopsLighter
      },
      'circle-stroke-width': options.selectedStrokeWidth,
      'circle-stroke-color': options.selectedStrokeColor
    }

    return { markerStyle, sourceMarkerStyle }
  }
}
