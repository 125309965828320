import { LatLngProperties } from '@targomo/core';
import { Layer, SymbolLayout } from 'mapbox-gl';

import { ObservableExpression } from '../../../../../types';
import { TgmMapboxComponent } from '../../mapbox.component';
import { MarkerMapSource } from '../../sources';
import { MapLayer } from '../layer';

/**
 * A layer representing markers on a map, implemented by a mapbox `symbol` layer
 */
export class SymbolMarkerMapLayer<T extends LatLngProperties> extends MapLayer<MarkerMapSource<T>> {

  private layout: SymbolLayout;

  /**
   * @param map The mapbox component this layer will be added to
   * @param source The source of markers data to be shown
   * @param layoutObservable A mapbox-gl layout object definition that can be used to style this layer
   */
  constructor(
    protected map: TgmMapboxComponent,
    source: ObservableExpression<T[]> | MarkerMapSource<T>,
    layoutObservable?: ObservableExpression<any>
  ) {
    super(map, source instanceof MarkerMapSource ? source : new MarkerMapSource(source))

    if (layoutObservable) {
      this.watch(layoutObservable, layout => {
        this.layout = layout
        this.update()
      })
    }
  }

  get(): Partial<Layer> {
    return {
      'interactive': true,
      'type': 'symbol',
      'layout': this.layout,
      'paint': {}
    }
  }
}
