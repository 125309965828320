import { NgModule } from '@angular/core'
import {
  MatFormFieldModule, MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule
} from '@angular/material'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { TgmMapPopupComponent } from './map-popup.component'
import { TgmMapboxModule } from '../mapbox/mapbox.module';

@NgModule({
  declarations: [
    TgmMapPopupComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TgmMapboxModule,
  ],
  exports: [
    TgmMapPopupComponent
  ]
})
export class TgmMapPopupModule { }
