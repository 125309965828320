import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { TargomoClient, ClientOptions } from '@targomo/core';
import { observable } from 'rxjs/symbol/observable';
import { Observable } from 'rxjs';

export const TGM_MAIN_CONFIG = new InjectionToken<TgmClientConfig | Observable<TgmClientConfig>>('tgmMainClientConf');
export const TGM_DEV_CONFIG = new InjectionToken<TgmClientConfig | Observable<TgmClientConfig>>('tgmDevClientConf');

export interface TgmClientConfig { region: string, key: string, options?: ClientOptions }


/**
 * Service to be used for accessing a global TargomoClient.
 * Please use `TgmClientModule.forRoot()` with your client config (region, key, options) in your imports.
 *
 * Usage:
 * ```
 * class Component {
 *  constructor(
 *    private tgm: TgmClient
 *  ) {
 *    this.tgm.mainClient   // main client
 *    this.tgm.devClient    // dev client (if configured)
 *  }
 * }
 * ```
 */
@Injectable()
export class TgmClient {

  mainClient: TargomoClient
  devClient?: TargomoClient

  constructor(
    @Inject(TGM_MAIN_CONFIG) mainClientConf: TgmClientConfig | Observable<TgmClientConfig>,
    @Optional() @Inject(TGM_DEV_CONFIG) devClientConf?: TgmClientConfig | Observable<TgmClientConfig>
  ) {

    if (mainClientConf instanceof Observable || typeof (<any>mainClientConf)[observable] === 'function') {
      (<Observable<TgmClientConfig>>mainClientConf).subscribe(conf => {
        this.mainClient = new TargomoClient(
          conf.region,
          conf.key,
          conf.options
        )
      })
    } else {
      this.mainClient = new TargomoClient(
        mainClientConf.region,
        mainClientConf.key,
        mainClientConf.options
      )
    }

    if (!!devClientConf) {
      if (devClientConf instanceof Observable || typeof (<any>devClientConf)[observable] === 'function') {
        (<Observable<TgmClientConfig>>devClientConf).subscribe(conf => {
          this.devClient = new TargomoClient(
            conf.region,
            conf.key,
            conf.options
          )
        })
      } else {
        this.devClient = new TargomoClient(
          devClientConf.region,
          devClientConf.key,
          devClientConf.options
        )
      }
    }
  }

}


