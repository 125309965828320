import { TgmGeosearchComponent } from './geosearch.component'
import { NgModule } from '@angular/core'
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule } from '@angular/material'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [
    TgmGeosearchComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    TgmGeosearchComponent
  ]
})
export class TgmGeosearchModule { }


