import { NgModule } from '@angular/core';
import { TgmReachabilityPanelComponent } from './reachability-panel.component';
import { TgmGenericPanelModule } from '../generic-panel/generic-panel.module';
import { MatSelectModule, MatProgressSpinnerModule, MatSlideToggleModule } from '@angular/material';
import { TgmTravelTypeSelectModule } from '../../map/traveltype-select/traveltype-select.module';
import { TgmMaxEdgeWeightSelectModule } from '../../map/maxedgeweight-select/maxedgeweight-select.module';
import { TgmLoadableModule } from '../../loadable/loadable.module';
import { TgmBargraphModule } from '../../bargraph/bargraph.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TgmPipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    TgmGenericPanelModule,
    TgmTravelTypeSelectModule,
    TgmMaxEdgeWeightSelectModule,
    TgmLoadableModule,
    TgmBargraphModule,
    TgmPipesModule,
    FlexLayoutModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule
  ],
  declarations: [TgmReachabilityPanelComponent],
  exports: [TgmReachabilityPanelComponent]
})
export class TgmReachabilityPanelModule {}
