<admin-header></admin-header>
<section>
  <div>
    <mat-list>
      <mat-list-item routerLink="/admin/users">
        <mat-icon mat-font-set="material-icons">supervisor_account</mat-icon>
        <p>User Management</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/companies">
        <mat-icon mat-font-set="material-icons">account_balance</mat-icon>
        <p>Company Management</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/profiles">
        <mat-icon mat-font-set="material-icons">assignment</mat-icon>
        <p>Data Profiles</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/datasets">
        <mat-icon mat-font-set="material-icons">equalizer</mat-icon>
        <p>Data Sets</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/planningApplications">
        <mat-icon mat-font-set="material-icons">traffic</mat-icon>
        <p>Planning Applications</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/matchpoint">
        <mat-icon mat-font-set="material-icons">compare_arrows</mat-icon>
        <p>Matchpoint</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/zones">
        <mat-icon mat-font-set="material-icons">edit_location</mat-icon>
        <p>Zone Layers</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/georegions">
        <mat-icon mat-font-set="material-icons">terrain</mat-icon>
        <p>Geo Regions</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/postcodes">
        <mat-icon mat-font-set="material-icons">markunread_mailbox</mat-icon>
        <p>Postcodes Analysis</p>
      </mat-list-item>

      <!-- <mat-list-item routerLink="/admin/mobilecatchments">
        <mat-icon mat-font-set="material-icons">app_shortcut</mat-icon>
        <p>Mobile Catchments</p>
      </mat-list-item> -->

      <mat-list-item routerLink="/admin/settings">
        <mat-icon mat-font-set="material-icons">settings</mat-icon>
        <p>Settings</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/carehome">
        <mat-icon mat-font-set="material-icons">elderly</mat-icon>
        <p>Care Home Report</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/reportTemplates">
        <mat-icon mat-font-set="material-icons">photo_filter</mat-icon>
        <p>PDF Report Templates</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/comparisonReportTemplates">
        <mat-icon mat-font-set="material-icons">photo_filter</mat-icon>
        <p>Comparison Report Templates</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/messages">
        <mat-icon mat-font-set="material-icons">message</mat-icon>
        <p>Messages</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/activitylog">
        <mat-icon mat-font-set="material-icons">assignment_late</mat-icon>
        <p>Activity Log</p>
      </mat-list-item>

      <mat-list-item routerLink="/admin/activitylogreport">
        <mat-icon mat-font-set="material-icons">assignment_turned_in</mat-icon>
        <p>User Activity Report</p>
      </mat-list-item>

      <mat-list-item (click)="logout()">
        <mat-icon mat-font-set="material-icons">exit_to_app</mat-icon>
        <p>Logout</p>
      </mat-list-item>
    </mat-list>
  </div>

  <div class="admin-content" [class.wide]="wide">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
