import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Observable } from 'rxjs';
import { SubscriberComponent } from '../../types/subscriber-component';
import { LocationCollection } from '../../types/location-collection';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tgm-location-collection-list',
  templateUrl: './location-collection-list.component.html',
  styleUrls: ['./location-collection-list.component.scss']
})
export class TgmLocationCollectionListComponent extends SubscriberComponent {

  collectionName: string;
  color: string;

  @Input() collections: LocationCollection[];
  @Input() showCollectionMenu = true;

  @Output() collectionClicked = new EventEmitter<LocationCollection>();
  @Output() collectionSelected = new EventEmitter<LocationCollection>();
  @Output() collectionRemoved = new EventEmitter<LocationCollection>();
  @Output() collectionZoomed = new EventEmitter<LocationCollection>();
  @Output() collectionCreated = new EventEmitter<any>();
  @Output() reordered: Observable<string[]>;

  constructor(
    private dragulaService: DragulaService
  ) {
    super();

    this.reordered = this.dragulaService.dropModel.pipe(map(() =>
      this.collections.map(collection => collection.id)
    ));

    this.resetNewCollectionMeta();
  }

  createNewCollection(): void {
    this.collectionCreated.emit({
      name: this.collectionName,
      color: this.color
    })

    this.resetNewCollectionMeta();
  }

  resetNewCollectionMeta(): void {
    this.color = '#92def7';
    this.collectionName = '';
  }

  clickCollection(collection: LocationCollection): void {
    this.collectionClicked.emit(collection);
  }

  selectCollection(collection: LocationCollection): void {
    this.collectionSelected.emit(collection);
  }

  removeCollection(collection: LocationCollection): void {
    this.collectionRemoved.emit(collection);
  }

  zoomToCollection(collection: LocationCollection) {
    this.collectionZoomed.emit(collection);
  }
}
