export function imageToDataUrl(url: string, outputFormat: string) {
  return new Promise<{data: string, width: number, height: number}>((resolve, reject) => {
    let img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = function() {
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      let dataURL
      canvas.height = (<any>this).height
      canvas.width = (<any>this).width
      ctx.drawImage((<any>this), 0, 0)
      dataURL = canvas.toDataURL(outputFormat)
      resolve({
        data: dataURL,
        width: (<any>this).width,
        height: canvas.height
      })
      canvas = null
    }

    img.onerror = function() {
      reject()
      // resolve({width: 0, height: 0, data: ''}) // don't stop the show just for missing client/logo
    }

    img.src = url
  })
}
