import { CommonModule } from '@angular/common';
import { MatIconModule, MatFormFieldModule, MatMenuModule, MatButtonModule, MatInputModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout';
import { TgmLocationCollectionListComponent } from './location-collection-list.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [
    TgmLocationCollectionListComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatMenuModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    MatInputModule,
    DragulaModule,
    ColorPickerModule
  ],
  exports: [
    TgmLocationCollectionListComponent
  ]
})
export class TgmLocationCollectionListModule { }
