import { EMPTY_GEOJSON } from '../constants';
import { AbstractSource } from './abstract-source'
import { ObservableExpression } from '../../../../types';


/**
 * A parent class for mapbox sources
 */
export class MapSource<T> extends AbstractSource {
  protected value: T

  constructor(private input: ObservableExpression<T>) {
    super()
    this.init()
  }

  private async init() {
    this.watch(this.input, (value) => this.updateValue(value))
  }

  updateValue(value?: T) {
    if (value !== undefined) {
      this.value = value
    }

    this.update()
    // this.events.next(new MapSourceUpdatedEvent(this))
  }

  getMapBoxObject(): any {
    return this.get(this.value)
  }

  get(input: T): any {
    return EMPTY_GEOJSON
  }
}
