import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout';
import { TgmActionBarComponent } from './action-bar.component';
import { TgmActionBarButtonComponent } from './action-bar-button/action-bar-button.component';

@NgModule({
  declarations: [
    TgmActionBarComponent,
    TgmActionBarButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule
  ],
  exports: [
    TgmActionBarComponent,
    TgmActionBarButtonComponent
  ]
})
export class TgmActionBarModule { }


