import { MapSource } from '../../sources/source';
import { MapLayer } from '../layer';
import { TgmMapboxComponent } from '../../mapbox.component';
import { ObservableExpression } from '../../../../../types';
import * as colors from '../../../../../types/colors'
import { Layer } from 'mapbox-gl';

export class PolygonMapLayerOptions {
  outline?: boolean = false;
  inverse?: boolean = false;
  outlineWidth?: number = 2;
  outlineOpacity?: number = 0.8;
  fillOpacity?: number = 0.5;
  edgeWeights: { value: number; color: string; outlineColor: string }[];
  maxEdgeWeight?: number;
}

/**
 * A layer that displays geojson polygons on a map
 */
export class PolygonMapLayer extends MapLayer<MapSource<{}>> {
  private layerOptions: PolygonMapLayerOptions;

  constructor(
    map: TgmMapboxComponent,
    source: MapSource<{}>,
    layerOptionsObservable: ObservableExpression<
      PolygonMapLayerOptions
    > = new PolygonMapLayerOptions()
  ) {
    super(map, source);

    this.watch(layerOptionsObservable, layerOptions => {
      this.layerOptions = { ...new PolygonMapLayerOptions(), ...layerOptions };
      this.update();
    });
  }

  get(): Partial<Layer> {
    // const mapOptions = this.mapOptions
    const height = 4; // (mapOptions && mapOptions.polygonSingleLayerHeight) || 2

    const travelTimes = (this.layerOptions.edgeWeights || [])
      .filter(
        times =>
          this.layerOptions.maxEdgeWeight == null ||
          times.value <= this.layerOptions.maxEdgeWeight
      )
      .map(times => times.value);

    function creatStops(
      result: any[],
      zoom: number,
      calculation: (reverse: number) => number
    ) {
      travelTimes.forEach(function(time: any, i: any) {
        const reverse = travelTimes.length - i - 1;
        result.push([{ zoom: zoom, value: time }, calculation(reverse)]);
      });
    }

    function getHeightStops(heightFactor: number) {
      const result: any = [];
      creatStops(result, 1, reverse => reverse * height * 60);
      creatStops(result, 4, reverse => reverse * height * 30);
      creatStops(result, 6, reverse => reverse * height * 20);
      creatStops(result, 8, reverse => reverse * height * 10);
      creatStops(result, 12, reverse => reverse * height * 4);
      creatStops(result, 14, reverse => reverse * height * 2);
      creatStops(result, 30, reverse => reverse * height);

      return result;
    }

    const layerOptions = this.layerOptions;
    const getColorStops = () => {
      return travelTimes.map((time: any, i: any) => {
        if (this.layerOptions.outline) {
          return [
            time,
            (layerOptions.edgeWeights &&
              layerOptions.edgeWeights[i].outlineColor) ||
              'rgba(0, 0, 0, 0)'
          ];
        } else {
          return [
            time,
            (layerOptions.edgeWeights && layerOptions.edgeWeights[i].color) ||
              colors.TRAVEL_COLORS[i]
          ];
        }
      });
    };

    if (this.layerOptions.outline) {
      const stops = getColorStops();

      return {
        type: 'line',
        layout: {},
        paint: {
          'line-color': {
            property: 'time',
            stops: stops
          },
          'line-width': this.layerOptions.outlineWidth,
          'line-opacity': this.layerOptions.outlineOpacity
        }
      };
    } else if (this.layerOptions.inverse) {
      // const stops = getColorStops()

      return {
        type: 'fill-extrusion',
        layout: {},
        paint: {
          'fill-extrusion-base': 0,
          'fill-extrusion-height': 0,
          'fill-extrusion-color': '#000000',
          'fill-extrusion-opacity': this.layerOptions.fillOpacity
        }
      };
    } else {
      return {
        type: 'fill-extrusion',
        layout: {},
        paint: {
          // 'fill-extrusion-base': {
          //   'property': 'time',
          //   'stops': getBaseStops(4),
          // },

          'fill-extrusion-height': {
            property: 'time',
            stops: getHeightStops(4)
          },
          'fill-extrusion-color': {
            property: 'time',
            stops: getColorStops()
          },
          'fill-extrusion-opacity': this.layerOptions.fillOpacity
        }
      };
    }
  }
}
