import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], predicate: any): any {
    return array.sort( (a, b) => {
      if (a[predicate] < b[predicate]) { return -1; }
      if (a[predicate] > b[predicate]) { return 1; }
      return 0;
    });
  }

}
