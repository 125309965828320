import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import {  MatButtonModule, MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { TgmConfirmDialogComponent } from './confirm-dialog.component';


@NgModule({
  declarations: [
    TgmConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
  ],
  exports: [
    TgmConfirmDialogComponent
  ],
  entryComponents: [
    TgmConfirmDialogComponent
  ]
})
export class TgmConfirmDialogModule { }
