import { Component, Input } from '@angular/core';
import { Route } from '@targomo/core';

@Component({
  selector: 'tgm-travel-plan',
  templateUrl: './travel-plan.component.html',
  styleUrls: ['./travel-plan.component.scss']
})
export class TravelPlanComponent {

  @Input() route: Route;
  @Input() destinationName = 'Destination';

}
