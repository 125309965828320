import { CookieService } from 'ngx-cookie-service';
import { Observable ,  Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http';

export enum RESTHelperNotifyCancellation {
  NONE,
  RESOLVE_UNDEFINED,
  REJECT_EXCEPTION
}

export class RequestCancelled {
}

export function getBasePath() {
  const element = document.querySelector('base')
  return element && element.href || '/'
}

/**
 * Wraps the angular `Http` service to provide some additional functionality. Promise based.
 *
 * **Usage:**
 * - import `TgmHttpModule`
 * - call `.createInstance(serviceUrl)` with your desired service url
 */
export class RESTHelper {
  private _serviceUrl: string = getBasePath() + 'api' // APP_BASE_PATH
  private exclusiveMode: boolean = false
  private currentRequest: Subscription
  private currentResolve: (result: any) => void
  private currentReject: (reason: any) => void
  private notifyCancellation: RESTHelperNotifyCancellation

  get serviceUrl() {
    return this._serviceUrl
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  exclusive(notifyCancellation: RESTHelperNotifyCancellation = RESTHelperNotifyCancellation.NONE): RESTHelper {
    const result = new RESTHelper(this.http, this.router, this.cookieService)
    result._serviceUrl = (this._serviceUrl).trim()
    result.exclusiveMode = true
    result.notifyCancellation = notifyCancellation
    return result
  }

  /**
   * @deprecated Use `createInstance`.
   * @param prefix String that is prepended to every request
   */
  prefix(prefix: string): RESTHelper {
    return this.createInstance(prefix)
  }

  createInstance(serviceUrl?: string): RESTHelper {
    const result = new RESTHelper(this.http, this.router, this.cookieService)
    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    if (r.test(serviceUrl)) {
      // Absolute URL
      result._serviceUrl = serviceUrl.trim()
    } else {
      // relative URL
      result._serviceUrl = (this._serviceUrl + serviceUrl).trim()
    }
    return result
  }

  get<T>(url: string): Promise<T> {
    return this.process(this.http.get<T>(this._serviceUrl + url, {withCredentials: true}))
  }

  delete<T>(url: string): Promise<T> {
    return this.process(this.http.delete<T>(this._serviceUrl + url, {withCredentials: true}))
  }

  head<T>(url: string): Promise<T> {
    return this.process(this.http.head<T>(this._serviceUrl + url, {withCredentials: true}))
  }

  post<T>(url: string, data: any): Promise<T> {
    return this.process(this.http.post<T>(this._serviceUrl + url, data, {withCredentials: true}))
  }

  put<T>(url: string, data: any): Promise<T> {
    return this.process(this.http.put<T>(this._serviceUrl + url, data, {withCredentials: true}))
  }

  patch<T>(url: string, data: any): Promise<T> {
    return this.process(this.http.patch<T>(this._serviceUrl + url, data, {withCredentials: true}))
  }

  options<T>(url: string): Promise<T> {
    return <any>this.process(this.http.options<T>(this._serviceUrl + url))
  }

  private process<T>(request: Observable<T>): Promise<T> {
    if (this.exclusiveMode && this.currentRequest) {
      this.currentRequest.unsubscribe()

      if (this.notifyCancellation === RESTHelperNotifyCancellation.RESOLVE_UNDEFINED) {
        if (this.currentResolve) {
          this.currentResolve(undefined)
        }

      } else if (this.notifyCancellation === RESTHelperNotifyCancellation.REJECT_EXCEPTION) {
        if (this.currentReject) {
          this.currentReject(new RequestCancelled())
        }
      }
    }

    return new Promise<T>((resolve, reject) => {
      this.currentResolve = resolve
      this.currentReject = reject

      let promiseResult: T
      this.currentRequest = request.subscribe(
        result => {
          this.currentRequest = null
          promiseResult = result
        },

        error => {
          this.currentRequest = null
          reject(error)
        },

        () => {
          resolve(promiseResult)
        }
      )
    })
  }

  mapToQuery(object: any): URLSearchParams {
    const search = new URLSearchParams()

    for (let key in object) {
      search.append(key, object[key])
    }

    return search
  }
}
