import { Component, Input } from '@angular/core';

@Component({
  selector: 'tgm-action-bar-button',
  templateUrl: './action-bar-button.component.html',
  styleUrls: ['./action-bar-button.component.less']
})
export class TgmActionBarButtonComponent {
  /**
   * @deprecated
   */
  @Input() badgeValue: number = null;
}
