import { NgModule } from '@angular/core'
import { TgmUserAvatarComponent } from './user-avatar.component'


@NgModule({
  declarations: [
    TgmUserAvatarComponent
  ],
  exports: [
    TgmUserAvatarComponent
  ]
})
export class TgmUserAvatarModule { }
