import { LatLng } from '@targomo/core';

/**
 * A search result.
 */
export class SearchResult implements LatLng {
  constructor(public lat: number, public lng: number, public title: string, public description: string) {
  }

  toString() {
    return this.title
  }
}

export class SuggestionResult  {
  constructor(public text: string, public magicKey: string, public query: string, public center: LatLng, public filterCountry: string) {
  }

  toString() {
    return this.text
  }
}
