import { MapSource } from './source'
import { LatLngProperties } from '@targomo/core'
import { EMPTY_GEOJSON_DATA } from '../constants';

/**
 * Transforms its input from LatLngProperties[] objects to geojson for a mapbox source
 */
export class MarkerMapSource<T extends LatLngProperties> extends MapSource<T[]> {
  get(locations: T[]) {
    if (locations) {
      const markers = locations.map((location, index) => {
        const properties = {...location.properties, index} || {index}

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [location.lng, location.lat]
          },
          properties
        }
      })

      return {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': markers
        }
      }
    } else {
      return super.get(locations)
    }
  }

  byIndex(index: number) {
    return this.value[index]
  }

  // TODO: move to common parent....like geojson source
  updateValue(value?: T[]) {
    if (value !== undefined) {
      this.value = value
    }

    this.update((map, sourceData) => {
      (map.getSource(this.id) as any).setData(sourceData.data || EMPTY_GEOJSON_DATA)
    })
  }
}
