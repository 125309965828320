import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToColor'
})
export class TimeToColorPipe implements PipeTransform {

  /**
   * Transforms a traveltime into a corresponding color (currently hardcoded green to red)
   *
   */
  transform(value: any, maxTime: number = 3600): any {
    if (value < 0) {
      return '#666';
    }
    const frac = value / maxTime;
    const hue = ((1 - frac) * 120).toString(10);
    return ['hsl(', hue, ',70%,50%)'].join('');
  }

}
