import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { DateTimePickerService } from '../date-time-picker.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'tgm-date-time-picker-dialog',
  templateUrl: './date-time-picker-dialog.component.html',
  styleUrls: ['./date-time-picker-dialog.component.scss']
})
export class DateTimePickerDialogComponent {

  date: Date;

  @ViewChild('datepicker') datepicker: ElementRef<HTMLInputElement>
  @ViewChild('timepicker') timepicker: ElementRef<HTMLInputElement>

  constructor(
    public dateService: DateTimePickerService,
    public dialogRef: MatDialogRef<DateTimePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.date = data.date
  }

  openNativeTimePicker() {
    this.timepicker.nativeElement.focus()
  }

  openNativeDatePicker() {
    this.datepicker.nativeElement.focus()
  }

  setToday() {
    this.date = new Date(this.date.setDate(this.dateService.today.getDate()))
  }

  setTomorrow() {
    this.date = new Date(this.date.setDate(this.dateService.tomorrow.getDate()))
  }

  setDayAfterTomorrow() {
    this.date = new Date(this.date.setDate(this.dateService.dayAfterTomorrow.getDate()))
  }

  decrementDay() {
    this.date = new Date(this.date.setDate(this.date.getDate() - 1))
  }

  incrementDay() {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1))
  }

  decrementHours() {
    this.date = new Date(this.date.setHours(this.date.getHours() - 1))
  }

  incrementHours() {
    this.date = new Date(this.date.setHours(this.date.getHours() + 1))
  }

  decrementFiveMinutes() {
    this.date = new Date(this.date.setMinutes(this.date.getMinutes() - 5))
  }

  incrementFiveMinutes() {
    this.date = new Date(this.date.setMinutes(this.date.getMinutes() + 5))
  }

}
