export type UndoActionType = 'create' | 'remove' | 'modify'

const REVERSE_ACTIONS = {
  'create' : 'remove',
  'remove' : 'create',
  'modify' : 'modify'
}

class UndoItem<T> {
  constructor(readonly type: UndoActionType,
              readonly place: T,
              readonly oldValues?: any,
              readonly newValues?: any) {
  }

  reverse() {
    return REVERSE_ACTIONS[this.type]
  }
}

export interface UndoActions<T, U> {
  create(place: T, extra?: U): void
  remove(place: T, extra?: U): void
  modify(place: T, oldValues: any, newValues: any): void
}

// TODO:....wide the scope beyond locations
export class UndoQueue<T, U> {
  private queue: UndoItem<T>[] = []
  private index = -1

  constructor(private undoActions: UndoActions<T, U>) {
  }

  pushCreate(place: T, extra?: U) {
    this.push(new UndoItem('create', place, extra, extra))
  }

  pushRemove(place: T, extra?: U) {
    this.push(new UndoItem('remove', place, extra, extra))
  }

  pushUpdate(place: T, oldValues: any, newValues: any) {
    this.push(new UndoItem('modify', place, oldValues, newValues))
  }

  undo() {
    if (this.hasUndo()) {
      this.apply(this.queue[this.index], true)
      this.index--
    }
  }

  redo() {
    if (this.hasRedo()) {
      this.index++
      this.apply(this.queue[this.index], false)
    }
  }

  undoTitle() {
    if (this.hasUndo()) {
      const item = this.queue[this.index]
      return `${item.type} ${item.place}`
    } else {
      return ''
    }
  }

  redoTitle() {
    if (this.hasRedo()) {
      const item = this.queue[this.index + 1]
      return `${item.type} ${item.place}`
    } else {
      return ''
    }
  }

  hasUndo() {
    return this.index >= 0
  }

  hasRedo() {
    return this.index < this.queue.length - 1
  }

  private apply(item: UndoItem<T>, undo: boolean) {
    if (undo) {
      (<any>this.undoActions)[item.reverse()](item.place, item.oldValues)
    } else {
      (<any>this.undoActions)[item.type](item.place, item.newValues)
    }
  }

  private push(action: UndoItem<T>) {
    this.index++

    if (this.index < this.queue.length) {
      this.queue[this.index] = action
      this.queue = this.queue.slice(0, this.index + 1)
    } else {
      this.queue.push(action)
    }
  }

  forEach(callback: (type: UndoActionType, value: T, oldValues?: U, newValues?: U) => void) {
    for (let i = 0; i <= this.index; i++) {
      const item = this.queue[i]
      callback(item.type, item.place, item.oldValues, item.newValues)
    }
  }

  /**
   * Remove all items from the queue and reset it to it's initial state
   */
  reset() {
    this.queue = []
    this.index = -1
  }
}
