import {
  TargomoClient,
  StatisticsSetMeta,
  StatisticsKey,
  StatisticsSet
} from '@targomo/core';
import { AbstractSource } from './abstract-source';
import { Subject } from 'rxjs';
import { ObservableExpression } from '../../../../types';

export class StatisticsMapSourceEvent {
  constructor(readonly group: StatisticsSet) {}
}

/**
 * A mapbox source for a statistics tiles layer, takes as input the StatisticsSet group and an array of
 * which statistics keys to request and creates a vector tiles source from them
 */
export class StatisticsMapSource extends AbstractSource {
  readonly events = new Subject<StatisticsMapSourceEvent>();

  metadata: StatisticsSetMeta;

  private statistics: StatisticsKey[] = [];
  private group: StatisticsSet;

  constructor(
    readonly client: TargomoClient,
    groupObservable: ObservableExpression<StatisticsSet>,
    statisticsObservable: ObservableExpression<StatisticsKey[]>
  ) {
    super();

    this.watch(groupObservable, group => {
      this.group = group;
      this.setGroup(group);
    });

    this.watch(statisticsObservable, statistics => {
      this.statistics = statistics;
      this.setGroup(this.group);
    });
  }

  getMapBoxObject(): any {
    if (this.metadata == null) {
      return super.getMapBoxObject();
    }

    const route = this.client.statistics.tileRoute(
      this.metadata,
      this.statistics
    );

    return {
      type: 'vector',
      tiles: [route]
    };
  }

  async setGroup(group: StatisticsSet) {
    if (this.group == null || this.statistics == null) {
      return;
    }

    this.metadata = await this.client.statistics.metadata(group);
    await this.update();
    this.events.next(new StatisticsMapSourceEvent(group));
  }
}
