import { Component, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { MobileCatchmentsEndpoint } from '../../../api/mobileCatchments'
import { DataSetLocation } from '../../../model'
import { APP_BASE_PATH } from '../../../util/basePath'
import { AdminVersionsComponent } from '../components/adminVersions/adminVersions.component'

export function waitTimeout(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

@Component({
  selector: 'admin-upload-mobile-catchments',
  templateUrl: './uploadMobileCatchments.component.html',
})
export class AdminUploadMobileCatchmentsComponent {
  @ViewChild('versions') versions: AdminVersionsComponent

  locations: DataSetLocation[]
  locationError: string = null
  locationSuccess: string = null
  locationCategorizeError: string = null

  basePath = APP_BASE_PATH
  saving: Promise<{}> | boolean
  form: FormGroup
  isSaving: boolean

  locationsFound = false
  locationsLength = 0

  submitted = false
  private filesHex: any
  private filesStats: any
  colors: { name: string; color: string }[]

  constructor(
    private formBuilder: FormBuilder,
    readonly mobileCatchmentEndpoint: MobileCatchmentsEndpoint,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.form = this.formBuilder.group({
      name: [null, []],
      categorize: [null, []],
    })

    this.init()
  }

  async init() {
    this.form = this.formBuilder.group({})
  }

  async save() {
    this.submitted = true
    this.locationError = null
    this.locationSuccess = null

    if (this.form.valid) {
      this.isSaving = true
      try {
        const result = await (this.saving = this.mobileCatchmentEndpoint.uploadMultipart(
          this.filesHex,
          this.filesStats
        ))
        this.locationSuccess = 'Upload completed'
        this.saving = null
        this.isSaving = false

        this.versions.reload()
      } catch (e) {
        this.saving = null
        this.isSaving = false

        if (e && e.error && e.error.message) {
          this.locationError = e.error.message
        } else {
          this.locationError = 'Upload unsucessful'
        }
      }
    }
  }

  async selectFileHex(files: File[]) {
    this.filesHex = files
  }

  async selectFileData(files: File[]) {
    this.filesStats = files
  }

  cancel() {
    this.router.navigate(['/admin/'])
  }

  updatedVersion() {}
}
