import { MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TgmGlobalIndicatorsComponent } from './global-indicators.component';
import {FlexLayoutModule} from '@angular/flex-layout'
import { Indicators } from './indicators.service';

@NgModule({
  declarations: [
    TgmGlobalIndicatorsComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FlexLayoutModule
  ],
  providers: [
    Indicators
  ],
  exports: [
    TgmGlobalIndicatorsComponent
  ]
})
export class TgmGlobalIndicatorsModule { }
