/**
 *
 */
export const DEFAULT_USER_ROLES = [
  'guest',
  'user',
  'admin'
]

/**
 *
 */
export interface User {
  id: any
  role: string
}
