import { Pipe, PipeTransform } from '@angular/core';
import { RouteSegment } from '@targomo/core';

@Pipe({
    name: 'routeSegmentToIcon'
})
export class RouteSegmentToIconPipe implements PipeTransform {
    transform(segment: RouteSegment): any {

        switch (segment.routeType) {

            case 1:
            case 2:
                if (segment.routeShortName) {
                    if (segment.routeShortName.includes('S') || segment.routeShortName.includes('s')) {
                        return 'SBAHN'
                    }
                    if (segment.routeShortName.includes('U') || segment.routeShortName.includes('u')) {
                        return 'UBAHN'
                    }
                    if (segment.routeShortName.includes('RE') || segment.routeShortName.includes('RB')) {
                        return 'BAHN'
                    }
                }

            case 700:
            case 3:
                return 'BUS';

            case 900:
            case 0:
                return 'TRAM';

            case 109:
                return 'SBAHN';

            case 100:
                return 'BAHN';

            case 400:
                return 'UBAHN';

            default:
                return 'UNKNOWN_ROUTETYPE';

        }

    }
}
