import { from as observableFrom, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, pluck, combineLatest } from 'rxjs/operators';
import { isPromise } from '@targomo/common'

/**
 * Either an observable, a promise or a value
 */
export type ObservableExpression<T> = Observable<T> | T | Promise<T>  // TODO move this to a more global level. It is used everywhere

// NOTE: instanceof had problems with npm link
function isObservable<T>(value: any): value is Observable<T> {
  return value && !!value.subscribe
}

/**
 * A parent class for classes that deal with subscribing to observables. Provides methods that keep track of subscriptions,
 * unsubscribe on destroy and other utility methods
 */
export class AbstractSubscriber {
  private _subscriptions: Subscription[] = []

  /**
   * Remember a subscription
   * @param subscription
   */
  protected remember(subscription: Subscription) {
    this._subscriptions.push(subscription)
  }

  /**
   * Remember a subscription
   * @deprecated
   * @param subscription
   */
  protected with(subscription: Subscription) {
    console.warn('`this.with()` is deprecated. Use `this.remember()` instead')
    this.remember(subscription)
  }

  /**
   * Unsubscribe from all remembered subscriptions
   */
  protected unsubscribe() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions = []
  }

  /**
   * If input is an observable then it subscribes to it with `callback` receiving the values
   * If input is a promise it received that value after the promise it resolved
   * Otherwise callback is called with the value directly
   *
   * @param input
   * @param callback
   */
  protected async watch<T>(input: ObservableExpression<T>, callback: (value: T) => void) {
    if (isObservable(input)) {
      this.remember(input.subscribe(callback))
    } else if (isPromise(input)) {
      callback(await input)
    } else {
      callback(<T>input)
    }
  }

  protected async watchProperties<U>(input: ObservableExpression<any>, properties: string | string[], callback: (value: U) => void) {
    return this.watch(this.pluckDistinctProperties(input, properties), callback)
  }

  protected pluckDistinctProperties<U>(input: ObservableExpression<any>, properties: string | string[]): Observable<U> {
    if (!(properties instanceof Array)) {
      properties = [properties]
    }

    if (!properties || properties.length === 0) {
      return observableFrom(null)
    }

    const observableSource = this.toObservable(input)
    const observables = properties.map(property =>
      observableSource.pipe(
        pluck(property),
        distinctUntilChanged()
      ))

    return observables[0].pipe(combineLatest.apply(observables[0], observables.slice(1)))
  }

  /**
   * Converts the parameter to an observable, if it is not one already
   *
   * @param input
   */
  protected toObservable<T>(input: ObservableExpression<T>): Observable<T> {
    if (isObservable(input)) {
      return input
    } else if (isPromise(input)) {
      return observableFrom(<Promise<T>>input)
    } else {
      return observableFrom([<T>input])
    }
  }
}
