/**
 * Constants for default travel colors
 */
export const TRAVEL_COLORS = ['#006837', '#39B54A', '#8CC63F', '#F7931E', '#F15A24', '#C1272D']


/**
 * A list of random colors that can be used for example for markers
 */
export const RANDOM_COLORS = ['#FF9800', '#2E7D32', '#0277BD', '#7B1FA2', '#B71C1C', '#9E9D24', '#7C4DFF', '#CDDC39', '#795548', '#424242']

/**
 * Color ramps that can be used for rendering cells on a map
 */
export const COLOR_RAMPS = [
  { name: 'Yellow-Orange-Red', key: 'interpolateYlOrRd' },
  { name: 'Red-Yellow-Blue', key: 'interpolateRdYlBu' },
  { name: 'Red-Yellow-Green', key: 'interpolateRdYlGn' },
  { name: 'Spectral', key: 'interpolateSpectral' },
  { name: 'Yellow-Green-Blue', key: 'interpolateYlGnBu' },
  { name: 'Red-Purple', key: 'interpolateRdPu' },
  { name: 'Blues', key: 'interpolateBlues' },
  { name: 'Greens', key: 'interpolateGreens' },
  { name: 'Purples', key: 'interpolatePurples' },
  { name: 'Warm', key: 'interpolateWarm' },
  { name: 'Cool', key: 'interpolateCool' },
  { name: 'Plasma', key: 'interpolatePlasma' },
]

export const COLOR_RAMP_GRAY = { name: 'GrayScale', key: 'interpolateGreys' }

export const COLOR_RAMPS_ALLOWED = [
  COLOR_RAMP_GRAY,
].concat(COLOR_RAMPS)
