import * as mapbox from 'mapbox-gl'
import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core'
import { HoldQueue } from '@targomo/common'
import { LatLng, BoundingBox, SetBoundsOptions } from '@targomo/core'
import { MAP_STYLES, EMPTY_GEOJSON, MAP_DEFAULT_REFERENCE_LAYER,
  DEFAULT_MAP_TILER_KEY, BASE_MAP_TILER_URL, MAP_DEFAULT_STYLE } from './constants';
import { MapView, MapJumpOptions, MapMoveEvent, MapInteractionEvent } from './mapbox.component.type'
import { Subject ,  Observable } from 'rxjs'
import * as interaction from './util/interaction'
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

// TODO: thinking to have map component itself have no layers at all
// instead you add any functionality you want  ex new DefaultMarkersLayer(map), new DefaultPolygonLayer(map)
// so there will not be any map model either
// for compatibility maybe will have one WhateverDEfaultLayer simulating the aggregae of functionality we had before
// ...events will also go on these individual layers perfahs
// ex markersLayer.events.clicked()
//

export function buildStyleUrl(options: MapBoxComponentOptions, mapStyleKey: string) {
  const mapTilerKey = !!(options && options.mapTilerKey) ? options.mapTilerKey : DEFAULT_MAP_TILER_KEY
  let styleUrl = mapStyleKey
  const styleExists = !!MAP_STYLES.find(item => item.key === mapStyleKey)

  if (!!styleExists) {
    styleUrl = `${BASE_MAP_TILER_URL}/maps/${mapStyleKey}/style.json`
  } else if (!mapStyleKey || mapStyleKey.split('-').length > 1) {
    styleUrl = `${BASE_MAP_TILER_URL}/maps/${MAP_DEFAULT_STYLE}/style.json`
  } else {
    return styleUrl
  }

  return styleUrl + '?key=' + mapTilerKey
}

export interface MapBoxComponentOptions {
  serviceKey?: string
  mapTilesUrl?: string
  initialLocation?: LatLng
  initialZoomLevel?: number
  initialStyle?: string
  performanceMouseMoveOutsideAngular?: boolean
  attributionExtra?: string
  mapTilerKey?: string
}

@Component({
  selector: 'tgm-mapbox',
  styleUrls: ['./mapbox.component.less'],
  templateUrl: './mapbox.component.html',
})
export class TgmMapboxComponent implements AfterViewInit, MapView, OnDestroy {
  @ViewChild('mapElement') private element: ElementRef

  @Input() options: MapBoxComponentOptions = {}

  map: mapbox.Map
  // THIS?
  // readonly events: MapModelEvents<T> = new MapModelEventsImpl<T>()
  // or THIS?
  // readonly events: Observable<MapEvent<T>> = ...
  // NOTE: we can use angular @Output() but can that bypass change detection? must examine

  readonly viewCreated = new HoldQueue()
  readonly viewReady = new HoldQueue()
  readonly styleReady = new HoldQueue()

  // TODO: thinking
  readonly events: {
    readonly click: Observable<MapInteractionEvent<LatLng>>
    readonly context: Observable<MapInteractionEvent<LatLng>>
    readonly move: Observable<MapMoveEvent>
  } = {
    click: new Subject<MapInteractionEvent<LatLng>>(),
    context: new Subject<MapInteractionEvent<LatLng>>(),
    move: new Subject<MapMoveEvent>(),
  }

  readonly onLayersReload = new Subject<{}>()
  readonly onLayersDestroy = new Subject<{}>()

  private styleUrl: string
  private streetsLayerName: string
  _bearing: number
  _showAttribution: boolean = false

  constructor(readonly zone: NgZone, private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('targomo', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/targomo.svg'))
    this.matIconRegistry.addSvgIcon('compass', this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/compass.svg'))
  }

  ngOnDestroy() {
    this.onLayersDestroy.next({})
  }

  ngAfterViewInit() {
    if (!this.options) {
      throw new Error('no options parameter supplied to TgmMapboxComponent')
    }

    this.zone.runOutsideAngular(() => {
      this.initMap()
    })
  }

  private initMap() {
    let initialLocation =
      this.options.initialLocation ? [this.options.initialLocation.lng,  this.options.initialLocation.lat] : [13.4050, 52.5200]
    let initialZoomLevel = this.options.initialZoomLevel ? this.options.initialZoomLevel : 10

    this.map = new mapbox.Map({
      container: this.element.nativeElement,
      style: this.styleUrl = this.getStyleUrl(this.options.initialStyle),
      zoom: initialZoomLevel,
      center: initialLocation,
      attributionControl: false,
      preserveDrawingBuffer: true
    })

    this.map.once('load', async () => {

      this.initLayers(this.streetsLayerName)
      this.styleReady.ready()

      this.initEvents()
    })

    // View is ready, it is safe to call methods on map component
    this.viewReady.ready()
    this.viewCreated.ready()
  }

  private initEvents() {
    this.map.on('zoom', (event: any) => {
      this.angularZoneMouseMove(() => {
        this.updateMoveEvent()
      })
    })

    this.map.on('moveend', (event: any) => {
      this.angularZoneMouseMove(() => {
        this.updateMoveEvent()
      })
    })

    // TODO: events from artitrat marker layers to take precedence
    // how to do? maybe a queuw with a slight delay?
    this.map.on('click', (event: any) => {
      this.zone.run(() => {
        const cellEvent = new MapInteractionEvent(event.lngLat, interaction.mousePoint(event), null, event.originalEvent)
        interaction.fire(this.events.click, cellEvent)
      })
    })

    this.map.on('contextmenu', (event: any) => {
      this.zone.run(() => {
        const cellEvent = new MapInteractionEvent(event.lngLat, interaction.mousePoint(event), null, event.originalEvent)
        interaction.fire(this.events.context, cellEvent)
      })
    })

    this.map.on('rotate', (event: any) => {
      this.zone.run(() => {
        this._bearing = this.map.getBearing()
      })
    })

    this.initTouchEvents()
  }

  private initTouchEvents() {
    let timeout: any = null
    let startTime = 0

    this.map.on('touchstart', (event: any) => {
      const action = () => {
        this.map.fire('contextmenu', event)
      }

      startTime = new Date().getTime()
      clearTimeout(timeout)
      timeout = setTimeout(action, 2000)
    })

    this.map.on('touchend', (event: any) => {
      clearTimeout(timeout)
      if (new Date().getTime() - startTime < 1000) {
        this.map.fire('click', event)
      }
    })

    this.map.on('touchmove', () => {
      if (new Date().getTime() - startTime > 200) {
        startTime = 0
        clearTimeout(timeout)
      }
    })
  }

  getNativeElement(): Promise<HTMLElement> {
    return new Promise(resolve => {
      this.viewCreated.add(() => {
        resolve(this.element.nativeElement)
      })
    })
  }

  getMap(): Promise<mapbox.Map> {
    return new Promise(resolve => {
      this.viewCreated.add(() => {
        resolve(this.map)
      })
    })
  }


  whenSafe(callback: (map?: mapbox.Map) => void) {
    this.styleReady.add(() => {
      callback(this.map)
    })
  }


  addControl(control: any) {
    this.viewReady.add(() => {
      this.map.addControl(control)
    })
  }

  getBounds(): BoundingBox {
    const boundingBox = this.map.getBounds()
    const bounds: BoundingBox = {northEast: {lat: boundingBox.getNorth(), lng: boundingBox.getEast()},
                                southWest: {lat: boundingBox.getSouth(), lng: boundingBox.getWest()}}
    return bounds
  }

  setView(center: LatLng, animate?: boolean): void {
    this.viewReady.add(() => {
      if (animate) {
        this.map.flyTo(<any>{center: {lat: center.lat, lng: center.lng}})
      } else {
        this.map.setCenter(<any>{lat: center.lat, lng: center.lng})
        this.updateMoveEvent()
      }
    })
  }

  setZoom(zoom: number): void {
    this.viewReady.add(() => {
      this.map.setZoom(zoom)
      this.updateMoveEvent(zoom)
    })
  }

  getZoom(): number {
    if (this.map) {
      return this.map.getZoom()
    } else {
      return 0
    }
  }

  jumpTo(options: MapJumpOptions): void {
    this.viewReady.add(() => {
      this.map.jumpTo(options)
      this.updateMoveEvent()
    })
  }

  jumpToCurrentPosition(): void {
    this.viewReady.add(() => {
      const onSuccess = (position: any) => {
        this.map.setCenter(<any>{
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
        this.updateMoveEvent()
      }

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(onSuccess)
      }
    })
  }

  getImageData(type?: string): string {
    const canvas = <HTMLCanvasElement>this.element.nativeElement.querySelector('canvas')
    if (canvas) {
      return canvas.toDataURL(type)
    } else {
      return ''
    }
  }

  snapshot(width: number, height: number, type?: string): Promise<string> {
    throw new Error('Method not implemented.')
  }

  setBounds(bounds: BoundingBox, options?: SetBoundsOptions): void {
    this.viewReady.add(() => {
      if (!isFinite(bounds.northEast.lng) || !isFinite(bounds.northEast.lat)
          || !isFinite(bounds.southWest.lng) || !isFinite(bounds.southWest.lat)) {
        return
      }

      const paramBounds = [[bounds.southWest.lng, bounds.southWest.lat], [bounds.northEast.lng, bounds.northEast.lat]]
      this.map.fitBounds(paramBounds, <any>options) // FIXME: <any>
    })
  }


  waitUntilTilesLoaded(): Promise<{}> {
    return new Promise(resolve => {
      this.viewCreated.add(() => {
        const callback = () => {
          if (this.map.areTilesLoaded()) {
            resolve({})
          } else {
            setTimeout(callback, 25)
          }
        }

        callback()
      })
    })
  }

  // TODO: temporary for testing
  private getStyleUrl(input?: string) {
    return buildStyleUrl(this.options, input)
  }

  // TODO
  switchLanguage(language: string) {
    const field = (!language) ? 'name' : 'name_' + language

    // const LANGUAGE_LAYERS: string[] = ['place_other', 'place_suburb', 'place_village', 'place_town', 'place_city',
    // 'place_capital', 'place_city_large', 'place_state', 'place_country_other', 'place_country_major']
    const LANGUAGE_LAYERS: string[] = [
              'place_other', 'place_suburb', 'place_village', 'place_town',
              'place_city', 'place_state', 'place_country_other', 'place_country_major']

    LANGUAGE_LAYERS.forEach(layerId => {
      if (this.map.getLayer(layerId)) {
        this.map.setLayoutProperty(layerId, 'text-field', '{' + field + '}')
      }
    })
  }

  private getStyleReferenceLayer(input: string) {
    const style = MAP_STYLES.find((item) => item.key === input)
    return style ? style.referenceLayer : MAP_DEFAULT_REFERENCE_LAYER
  }

  /**
   * Set the bae style of the map, can be either one of the predefined targomo styles
   * or an arbitrary url
   *
   * @param style
   * @param streetsLayerName
   */
  setStyle(style: string, streetsLayerName?: string) {
    this.streetsLayerName = streetsLayerName ? streetsLayerName : this.getStyleReferenceLayer(style)
    const styleUrl = this.getStyleUrl(style)

    if (this.styleUrl !== styleUrl) {
      this.styleReady.reset()
      this.styleUrl = styleUrl
      this.map.setStyle(styleUrl)

      this.map.once('styledata', this.styleDataHandler)
    }
  }

  private styleDataTimeout: any = null

  private readonly styleDataHandler = () => {
    clearTimeout(this.styleDataTimeout)

    if (!this.map.isStyleLoaded()) {
      this.styleDataTimeout = setTimeout(this.styleDataHandler, 50)
      return
    }

    this.styleReady.ready()
    this.initLayers(this.streetsLayerName)
    this.onLayersReload.next({})
  }

  angularZoneMouseMove(callback: () => void) {
    if (this.options.performanceMouseMoveOutsideAngular) {
      callback()
    } else {
      this.zone.run(callback)
    }
  }

  private initLayers(referenceLayer?: string) {
    function createLayer(id: string): mapbox.Layer {
      return {
        id: id,
        type: 'fill',
        source: 'map-dummy',
      }
    }

    let actualReferenceLayer = referenceLayer || MAP_DEFAULT_REFERENCE_LAYER

    const initLabelsPositionLayer = () => {
      if (this.map.getLayer('map-below-labels')) {
        this.map.removeLayer('map-below-labels')
      }

      const layers = this.map.getStyle().layers

      let foundReference = false
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].id === actualReferenceLayer) {
          foundReference = true
        } else if (foundReference && layers[i].type === 'symbol') {
          this.map.addLayer(createLayer('map-below-labels'), layers[i].id)
          return
        }
      }

      this.map.addLayer(createLayer('map-below-labels'))
    }

    if (!this.map.getSource('map-dummy')) {
      // this.map.removeSource('map-dummy')
      this.map.addSource('map-dummy', EMPTY_GEOJSON)
    }

    initLabelsPositionLayer()

    const addLayer = (layer: mapbox.Layer, before?: string) => {
      if (!this.map.getLayer(layer.id)) {
        this.map.addLayer(layer, before)
      }
    }

    if (this.map.getLayer(actualReferenceLayer)) {
      if (this.map.getLayer('map-all-statistics')) {
        this.map.removeLayer('map-all-statistics')
      }
      this.map.addLayer(createLayer('map-all-statistics'), actualReferenceLayer)
    } else {
      addLayer(createLayer('map-all-statistics'))
    }

    addLayer(createLayer('map-statistics'), 'map-all-statistics')
    addLayer(createLayer('map-travel'), 'map-all-statistics')
    addLayer(createLayer('map-polygons'), 'map-all-statistics')
    addLayer(createLayer('map-below-markers'))
    addLayer(createLayer('map-markers-shadow'))
    addLayer(createLayer('map-markers'))
    addLayer(createLayer('map-sources'))
    addLayer(createLayer('map-hover'))
    addLayer(createLayer('map-markers-glow'))
    addLayer(createLayer('map-above-hover'))
  }

  // private initAttribution() {
  //   const element = document.querySelector('.mapboxgl-ctrl-attrib.mapboxgl-compact')
  //   if (element) {
  //     element.addEventListener('mouseenter', event => {
  //       if (!element.querySelector('.mapboxgl-ctrl-attrib.mapboxgl-compact .mi-attribution')) {
  //         const node = document.createElement('span')
  //         node.innerHTML = `<span class="mi-attribution">developed by <a href='http://www.targomo.com/'
  //         target='_blank'>Targomo</a>, </span>` + (this.options.attributionExtra || '')
  //         element.insertBefore(node, element.children[0])
  //       }
  //     })
  //   }
  // }

  private updateMoveEvent(zoom?: number) {
    const boundingBox = this.map.getBounds()
    const bounds: BoundingBox = {
      northEast: {lat: boundingBox.getNorth(), lng: boundingBox.getEast()},
      southWest:  {lat: boundingBox.getSouth(), lng: boundingBox.getWest()}
    }

    this.angularZoneMouseMove(() => {
      (this.events.move as Subject<MapMoveEvent>).next(new MapMoveEvent(this.map.getCenter(), bounds, zoom || this.map.getZoom()))
    })
  }

  private _attributionTimeout: any
  showAttribution(value: boolean) {
    if (value) {
      clearTimeout(this._attributionTimeout)
      this._attributionTimeout = null
      this.zone.run(() => {
        this._showAttribution = value
      })
    } else if (!this._attributionTimeout) {
      this._attributionTimeout = setTimeout(() => {
        this.zone.run(() => {
          this._showAttribution = false
        })
      }, 300)
    }
  }
}
