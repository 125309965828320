import { CookieService } from 'ngx-cookie-service';
import { Auth, TGM_AUTH_API_CONF, TgmAuthApiConf } from './auth.service'
import { NgModule, InjectionToken, Optional, SkipSelf } from '@angular/core'
import {TgmHttpModule } from '../http/index'
import { AuthGuard } from './authGuard';
import { AdminAuthGuard } from './adminAuthGuard';
import { GuestAuthGuard } from './guestAuthGuard';
import { HasAuthorityDirective } from './hasAuthority.directive'
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    TgmHttpModule
  ],
  declarations: [
    HasAuthorityDirective
  ],
  exports: [
    HasAuthorityDirective
  ],
  providers: [
    CookieService,
    Auth,
    AuthGuard,
    AdminAuthGuard,
    GuestAuthGuard,
  ]
})
export class TgmAuthModule  {
  static forRoot(
    apiPath?: TgmAuthApiConf,
  ) {
    return {
      ngModule: TgmAuthModule,
      providers: [
        { provide: <InjectionToken<TgmAuthApiConf>>TGM_AUTH_API_CONF, useValue: apiPath },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: TgmAuthModule) {
    if (parentModule) {
      throw new Error('TgmClientModule has already been imported.');
    }
  }
}
