import * as indices from './statisticsIndices'
import * as api from '@targomo/client'
import { Statistics, StatisticsGroup } from '@targomo/core'
import { STATISTICS_COMBINED_ID_TO_NAME } from '../../constants/statisticsConstants'

export const POPULATION_FORECAST_FROM_YEAR = 2022
export const POPULATION_FORECAST_TO_YEAR = 2033
const INDICES = require('../../../common/models/statistics/indices_avg_households_prices.json')

export enum CatchmentMode {
  NORMAL,
  MOBILE,
}

export enum ReportPerCapitaHousehold {
  PER_HOUSEHOLD = 0,
  PER_CAPITA = 1,
}

export const EXCHANGE_RATE_GBP_TO_EURO = 1 // 1.1126

export interface StatisticsValue extends Statistics {
  // [time: number]: number
  // total: number
  percent?: number
  index?: number
  indexHouseholdsOrPopulation?: number
  percentOverIndex?: number
}

export interface LabelledStatisticsValue extends StatisticsValue {
  name?: string
  changeOverBase?: number
}

export function sumStatisticsValues(values: StatisticsValue[]): StatisticsValue {
  const resultValues: any = {}
  let index = 0
  let percent = 0

  for (var value of values) {
    if (value && value.values) {
      for (var key in value.values) {
        resultValues[key] = (resultValues[key] || 0) + Number(value.values[key])
      }
    }
  }

  for (var value of values) {
    if (value && value.index) {
      index += value.index
    }

    if (value && value.percent) {
      percent += value.percent
    }
  }

  const result = new Statistics(resultValues)
  ;(<any>result).percent = percent
  ;(<any>result).index = index
  ;(<any>result).totalOriginal = (<any>result).total
  return result
}

export class CensusStatisticsGroup {
  public total: number
  public totalOverride: number
  public totalOriginal: number;

  [index: string]: any

  seal() {
    let total = 0

    for (let key in this) {
      if (
        key != 'total' &&
        key != 'percent' &&
        key != 'index' &&
        key != 'indexHouseholdsOrPopulation' &&
        key != 'percentOverIndex' &&
        key != 'totalOverride' &&
        key != 'totalOriginal' &&
        key != 'horizontal' &&
        key != 'vertical' &&
        key != 'horizontalIndices' &&
        key != 'verticalIndices' &&
        key != 'indicesRegion' &&
        key != 'populationOrHouseholds'
      ) {
        try {
          const current: any = this[key]
          if (
            current instanceof CensusStatisticsGroup ||
            current instanceof PersonicxStatisticsSummary ||
            current instanceof LifestyleSocial
          ) {
            current.seal()
          } else if (current instanceof Array) {
            const array = <Statistics[]>current
            for (let value of array) {
              total += value ? value.total : 0
            }
          } else if (current && (<any>current).total) {
            if (!this.excludeKey(key)) {
              total += (<Statistics>current).total
            }
          }
        } catch (e) {
          console.log('Error: ', key, e, e.stack)
        }
      }
    }

    if (this.totalOverride != null) {
      this.total = this.totalOverride
    } else {
      this.total = total
    }

    this.totalOriginal = this.total

    for (let key in this) {
      if (this[key] && this[key]['total']) {
        const value = this[key]['total']
        const percent = (value / this.total) * 100
        this[key]['percent'] = percent
      }
    }
  }

  convertCurrency(convert: boolean, rate: number) {
    for (let key in this) {
      if (
        key != 'total' &&
        key != 'percent' &&
        key != 'index' &&
        key != 'totalOverride' &&
        key != 'indexHouseholdsOrPopulation' &&
        key != 'percentOverIndex' &&
        key != 'totalOriginal' &&
        key != 'indicesRegion' &&
        key != 'populationOrHouseholds'
      ) {
        const current: any = this[key]
        if (
          current instanceof CensusStatisticsGroup ||
          current instanceof PersonicxStatisticsSummary ||
          current instanceof LifestyleSocial
        ) {
          current.convertCurrency(convert, rate)
        } else if (current instanceof Array && convert) {
          const array = <Statistics[]>current
          for (var value of array) {
            if ((<any>value).totalOriginal) {
              ;(<any>value).total = (<any>value).totalOriginal * rate
            }
          }
        } else if (current && (<any>current).total) {
          if ((<any>current).totalOriginal && convert) {
            ;(<any>current).total = (<any>current).totalOriginal * rate
          }
        }
      }
    }

    if (convert) {
      this.total = this.totalOriginal * rate
    }
  }

  excludeKey(key: string) {
    return false
  }
}

export class CensusStatisticsAges extends CensusStatisticsGroup {
  aged0to4: StatisticsValue
  aged5to7: StatisticsValue
  aged8to9: StatisticsValue
  aged10to14: StatisticsValue
  aged15: StatisticsValue
  aged16to17: StatisticsValue
  aged18to19: StatisticsValue
  aged20to24: StatisticsValue
  aged25to29: StatisticsValue
  aged30to44: StatisticsValue
  aged45to59: StatisticsValue
  aged60to64: StatisticsValue
  aged65to74: StatisticsValue
  aged75to84: StatisticsValue
  aged85to89: StatisticsValue
  aged90AndOver: StatisticsValue
}

export class CensusHouseholsIncome extends CensusStatisticsGroup {
  totalGross: StatisticsValue
  totalNet: StatisticsValue
  totalAfterHousingCosts: StatisticsValue
}

// NEW
export class CensusReligion extends CensusStatisticsGroup {
  christian: StatisticsValue
  buddhist: StatisticsValue
  hindu: StatisticsValue
  jewish: StatisticsValue
  muslim: StatisticsValue
  sikh: StatisticsValue
  otherReligion: StatisticsValue
  none: StatisticsValue
  notStated: StatisticsValue
}

export class CensusEducation extends CensusStatisticsGroup {
  education1_4_O_Levels_CSE_GCSE: StatisticsValue
  educationNVQ_Lvl_1_Foundation_GNVQ_basic_skills: StatisticsValue
  education1_A_Level_2_3_AS_Levels_Welsh_Bacc_Interm_Diploma: StatisticsValue
  educationNVQ_Lvl_2_Interm_GNVQ_etc: StatisticsValue
  educationApprenticeship: StatisticsValue
  education2_A_Levels_4_AS_Levels_etc: StatisticsValue
  educationNVQ_Lvl_3_Adv_GNVQ_etc: StatisticsValue
  educationDegree_BA_BSc_MA_PhD_PGCE: StatisticsValue
  educationNVQ_Lvl_4_5_HNC_HND_etc: StatisticsValue
  educationProfessional_qualifications: StatisticsValue
  educationVocational_work_related_quals: StatisticsValue
  educationNo_qualifications: StatisticsValue
  educationForeign_quals: StatisticsValue
}

export class CensusHouseholdType extends CensusStatisticsGroup {
  househole1_Person: StatisticsValue
  householeMarried_dep_children: StatisticsValue
  householeMarried_no_dep_children: StatisticsValue
  householeCivil_partner_dep_children: StatisticsValue
  householeCivil_partner_no_dep_children: StatisticsValue
  householeCohabiting_dep_children: StatisticsValue
  householeCohabiting_no_dep_children: StatisticsValue
  householeLone_parent_dep_children: StatisticsValue
  householeLone_parent_no_dep_children: StatisticsValue
  householeMulti_family_students: StatisticsValue
  householeMulti_family_other: StatisticsValue
}
// NEW
export class CensusEconomicActivity extends CensusStatisticsGroup {
  economicallyActiveEmployedPartTime: StatisticsValue
  economicallyActiveEmployedFullTime: StatisticsValue
  economicallyActiveSelfEmployedPartTimeWithEmployees: StatisticsValue
  economicallyActiveSelfEmployedFullTimeWithEmployees: StatisticsValue
  economicallyActiveSelfEmployedPartTimeNoEmployees: StatisticsValue
  economicallyActiveSelfEmployedFullTimeNoEmployees: StatisticsValue
  economicallyActiveUnemployed: StatisticsValue
  economicallyActiveFullTimeStudent: StatisticsValue
  economicallyInactiveRetired: StatisticsValue
  economicallyInactiveStudent: StatisticsValue
  economicallyInactiveCaringForHomeOrFamily: StatisticsValue
  economicallyInactiveLongTermSick: StatisticsValue
  economicallyInactiveOther: StatisticsValue
}

// NEW
export class CensusHouseholdsByIncomeBand extends CensusStatisticsGroup {
  householdsWithIncome_100k: StatisticsValue
  householdsWithIncome_90_100k: StatisticsValue
  householdsWithIncome_80_90k: StatisticsValue
  householdsWithIncome_70_80k: StatisticsValue
  householdsWithIncome_60_70k: StatisticsValue
  householdsWithIncome_50_60k: StatisticsValue
  householdsWithIncome_40_50k: StatisticsValue
  householdsWithIncome_30_40k: StatisticsValue
  householdsWithIncome_20_30k: StatisticsValue
  householdsWithIncome_20k: StatisticsValue
}

// NEW
export class CensusHouseholdSize extends CensusStatisticsGroup {
  householdSize_1Person: StatisticsValue
  householdSize_2Persons: StatisticsValue
  householdSize_3Persons: StatisticsValue
  householdSize_4Persons: StatisticsValue
  householdSize_5Persons: StatisticsValue
  householdSize_6Persons: StatisticsValue
  householdSize_7Persons: StatisticsValue
  householdSize_8OrMorePersons: StatisticsValue
}

// NEW
export class CensusOutputClassification extends CensusStatisticsGroup {
  farmingCommunities_1a: StatisticsValue
  ruralTenants_1b: StatisticsValue
  ageingRuralDwellers_1c: StatisticsValue
  studentsAroundCampus_2a: StatisticsValue
  innerCityStudents_2b: StatisticsValue
  comfortableCosmopolitan_2c: StatisticsValue
  aspiringAndAffluent_2d: StatisticsValue
  ethnicFamilyLife_3a: StatisticsValue
  endeavouringEthnicMix_3b: StatisticsValue
  ethnicDynamics_3c: StatisticsValue
  aspirationalTechies_3d: StatisticsValue
  rentedFamilyLiving_4a: StatisticsValue
  challengedAsianTerraces_4b: StatisticsValue
  asianTraits_4c: StatisticsValue
  urbanProfessionalsAndFamilies_5a: StatisticsValue
  ageingUrbanLiving_5b: StatisticsValue
  suburbanAchievers_6a: StatisticsValue
  semiDetachedSuburbia_6b: StatisticsValue
  challengedDiversity_7a: StatisticsValue
  constrainedFlatDwellers_7b: StatisticsValue
  whiteCommunities_7c: StatisticsValue
  ageingCityDwellers_7d: StatisticsValue
  industriousCommunities_8a: StatisticsValue
  challengedTerracedWorkers_8b: StatisticsValue
  hardPressedAgeingWorkers_8c: StatisticsValue
  migrationAndChurn_8d: StatisticsValue
}

export class CensusHealthDayToDay extends CensusStatisticsGroup {
  dayToDayLimitedALot: StatisticsValue
  dayToDayLimitedALittle: StatisticsValue
  dayToDayNotLimited: StatisticsValue
}
export class CensusHealth extends CensusStatisticsGroup {
  veryGood: StatisticsValue
  good: StatisticsValue
  fair: StatisticsValue
  bad: StatisticsValue
  veryBad: StatisticsValue
}

export class CensusStatisticsPopulation extends CensusStatisticsGroup {
  households: StatisticsValue
  population: StatisticsValue
}

export class CensusStatisticsGender extends CensusStatisticsGroup {
  males: StatisticsValue
  females: StatisticsValue
}

export class CensusStatisticsEconomicallyActive extends CensusStatisticsGroup {
  economicallyActive: StatisticsValue
  economicallyInactive: StatisticsValue
}

export class CensusStatisticsNationalSocioEconomicClassification extends CensusStatisticsGroup {
  higherManagerialProfessionalOccupations: StatisticsValue
  lowerManagerialProfessionalOccupations: StatisticsValue
  intermediateOccupations: StatisticsValue
  smallEmployersOwnAccountWorkers: StatisticsValue
  lowerSupervisoryTechnicalOccupations: StatisticsValue
  semiRoutineOccupations: StatisticsValue
  routineOccupations: StatisticsValue
  neverWorkedLongTermUnemployed: StatisticsValue
  notClassified: StatisticsValue
}

export class CensusStatisticsTravelToWork extends CensusStatisticsGroup {
  worksMainlyHome: StatisticsValue
  undergroundMetroLightRailTram: StatisticsValue
  train: StatisticsValue
  busMinibusCoach: StatisticsValue
  taxiMinicab: StatisticsValue
  motorcycleScooterMoped: StatisticsValue
  drivingCarVan: StatisticsValue
  passengerCarVan: StatisticsValue
  bicycle: StatisticsValue
  onFoot: StatisticsValue
  otherTravelSituation: StatisticsValue
  notCurrentlyWorking: StatisticsValue
}

export class CensusStatisticsEthnicity extends CensusStatisticsGroup {
  bangladeshi: StatisticsValue
  indian: StatisticsValue
  pakistani: StatisticsValue
  black: StatisticsValue
  african: StatisticsValue
  caribbean: StatisticsValue
  otherBlack: StatisticsValue
  chinese: StatisticsValue
  mixed: StatisticsValue
  otherEthnicGroup: StatisticsValue
  white: StatisticsValue
  asianOther: StatisticsValue
  arab: StatisticsValue

  excludeKey(key: string) {
    return key === 'african' || key === 'caribbean' || key === 'otherBlack'
  }
}

export class CensusStatisticsAccomodation extends CensusStatisticsGroup {
  detatched: StatisticsValue
  flat: StatisticsValue
  semiDetached: StatisticsValue
  temporaryStructure: StatisticsValue
  terrace: StatisticsValue
}

export class CensusStatisticsCars extends CensusStatisticsGroup {
  noCar: StatisticsValue
  with1car: StatisticsValue
  with2car: StatisticsValue
  with3orMore: StatisticsValue
}

export class CensusStatisticsTenure extends CensusStatisticsGroup {
  employerHouseholdMember: StatisticsValue
  relativeFriendHouseholdMember: StatisticsValue
  livingRentFree: StatisticsValue
  privateLandlordLettingAgency: StatisticsValue
  ownsMortgageLoan: StatisticsValue
  sharedOwnership: StatisticsValue
  owned: StatisticsValue
  ownsOutright: StatisticsValue
  otherPrivateRented: StatisticsValue
  privateRented: StatisticsValue
  rentedCouncilLocalAuthority: StatisticsValue
  otherSocialRented: StatisticsValue
  socialRented: StatisticsValue
}

export class CensusStatisticsArrival extends CensusStatisticsGroup {
  bornUk: StatisticsValue
  pre41: StatisticsValue
  from41to50: StatisticsValue
  from51to60: StatisticsValue
  from61to70: StatisticsValue
  from71to80: StatisticsValue
  from81to90: StatisticsValue
  from91to00: StatisticsValue
  from01to03: StatisticsValue
  from04to06: StatisticsValue
  from07to09: StatisticsValue
  from10to11: StatisticsValue
}

export class CensusStatisticsSocialClass extends CensusStatisticsGroup {
  ab: StatisticsValue
  c1: StatisticsValue
  c2: StatisticsValue
  de: StatisticsValue
}

export class CensusStatisticsIndustries extends CensusStatisticsGroup {
  agricultureForestryFishing: StatisticsValue
  miningQuarrying: StatisticsValue
  manufacturing: StatisticsValue
  electricityGasSteam: StatisticsValue
  waterSewerageWasteManagement: StatisticsValue
  construction: StatisticsValue
  wholesaleRetailTradeRepairMotorVehicles: StatisticsValue
  transportStorage: StatisticsValue
  accomodationFoodServices: StatisticsValue
  informationCommunication: StatisticsValue
  financialInsuranceActivities: StatisticsValue
  realEstateActivities: StatisticsValue
  professionalScientificTechnicalActivities: StatisticsValue
  administrativeSupportServiceActivities: StatisticsValue
  publicAdministrationDefenceCompulsorySocialSecurity: StatisticsValue
  education: StatisticsValue
  humanHealthSocialWorkActivities: StatisticsValue
  artsEntertainmentRecreationOtherServiceActivities: StatisticsValue
  other1: StatisticsValue
  other2: StatisticsValue
}

export class WorkforceStatisticsOccupations extends CensusStatisticsGroup {
  managersDirectorsSeniorOfficials: StatisticsValue
  professional: StatisticsValue
  associateProfessional: StatisticsValue
  adminSecretarial: StatisticsValue
  skilledTrades: StatisticsValue
  caringLeisureOtherService: StatisticsValue
  salesCustomerService: StatisticsValue
  processPlantMachineOperatives: StatisticsValue
  elementaryOccupations: StatisticsValue
}

export class CensusStatisticsOccupations extends WorkforceStatisticsOccupations {
  students: StatisticsValue
}

export class CensusStatisticsWorkerAge extends CensusStatisticsGroup {
  aged16to24: StatisticsValue
  aged25to34: StatisticsValue
  aged35to44: StatisticsValue
  aged45to54: StatisticsValue
  aged55to64: StatisticsValue
  aged65to74: StatisticsValue

  // aged00to15: StatisticsValue
  // aged16to24: StatisticsValue
  // aged25to44: StatisticsValue
  // aged45to64: StatisticsValue
  // aged65plus: StatisticsValue
}

const DWELLING_PRICE_BAND_KEYS: (keyof DwellingPerPrice)[] = [
  'lessThan100k',
  'from100kto200k',
  'from200kto300k',
  'from300kto400k',
  'from400kto500k',
  'from500kto600k',
  'from600kto700k',
  'from700kto800k',
  'from800kto900k',
  'from900kto1m',
  'from1mto1_1m',
  'from1_1mto1_2m',
  'from1_2mto1_3m',
  'from1_3mto1_4m',
  'from1_4mto1_5m',
  'above1_5m',
]
export class DwellingPerPrice extends CensusStatisticsGroup {
  lessThan100k: StatisticsValue
  from100kto200k: StatisticsValue
  from200kto300k: StatisticsValue
  from300kto400k: StatisticsValue
  from400kto500k: StatisticsValue
  from500kto600k: StatisticsValue
  from600kto700k: StatisticsValue
  from700kto800k: StatisticsValue
  from800kto900k: StatisticsValue
  from900kto1m: StatisticsValue
  from1mto1_1m: StatisticsValue
  from1_1mto1_2m: StatisticsValue
  from1_2mto1_3m: StatisticsValue
  from1_3mto1_4m: StatisticsValue
  from1_4mto1_5m: StatisticsValue
  above1_5m: StatisticsValue
}

export class DwellingTypesPerPrice extends CensusStatisticsGroup {
  detached: DwellingPerPrice
  semiDetached: DwellingPerPrice
  terraced: DwellingPerPrice
  flat: DwellingPerPrice
  all: DwellingPerPrice
}

export class DwellingTypesAreaValue {
  area: StatisticsValue
  value: StatisticsValue
}
export class DwellingTypesValues {
  detached: DwellingTypesAreaValue
  semiDetached: DwellingTypesAreaValue
  terraced: DwellingTypesAreaValue
  flat: DwellingTypesAreaValue
  all: DwellingTypesAreaValue
}

export class AgesPerYear extends CensusStatisticsGroup {
  ages: StatisticsValue[] = []
}

export class CensusStatistics extends CensusStatisticsGroup {
  agesPerYear: AgesPerYear

  ages = new CensusStatisticsAges()
  workerAges = new CensusStatisticsWorkerAge()
  malesAges = new CensusStatisticsWorkerAge()
  femalesAges = new CensusStatisticsWorkerAge()
  occupationsCensus = new CensusStatisticsOccupations()
  occupations = new WorkforceStatisticsOccupations()
  industries = new CensusStatisticsIndustries()
  socialClass = new CensusStatisticsSocialClass()
  socialClassWorkforce = new CensusStatisticsSocialClass()
  arrival = new CensusStatisticsArrival()
  tenure = new CensusStatisticsTenure()
  cars = new CensusStatisticsCars()
  accomodation = new CensusStatisticsAccomodation()
  ethnicity = new CensusStatisticsEthnicity()
  travelWork = new CensusStatisticsTravelToWork()
  economicallyActive = new CensusStatisticsEconomicallyActive()
  nationalStatisticsSocialClass = new CensusStatisticsNationalSocioEconomicClassification()
  population2011 = new CensusStatisticsPopulation()
  gender = new CensusStatisticsGender()
  allWorkforce: StatisticsValue
  dwellingPerType = new DwellingTypesPerPrice()
  dwellingAreaValuePerType = new DwellingTypesValues()
  householsIncome = new CensusHouseholsIncome()

  religion = new CensusReligion()
  economicActivity = new CensusEconomicActivity()
  householdsByIncomeBand = new CensusHouseholdsByIncomeBand()
  householdSize = new CensusHouseholdSize()
  outputClassification = new CensusOutputClassification()

  healthDayToDay = new CensusHealthDayToDay()
  health = new CensusHealth()
  education = new CensusEducation()
  householdType = new CensusHouseholdType()

  summaryDwellingTypes() {
    const KEYS_COUNT = ['semiDetached', 'detatched', 'terrace', 'flat']
    const KEYS = ['semiDetached', 'detached', 'terraced', 'flat', 'all']

    return KEYS.reduce(
      (acc, key, i) => {
        const units: number =
          key === 'all'
            ? sumStatisticsValues([
                this.accomodation.semiDetached,
                this.accomodation.terrace,
                this.accomodation.detatched,
                this.accomodation.flat,
              ]).total
            : this.accomodation[KEYS_COUNT[i]].total
        const areaValue: DwellingTypesAreaValue = (this.dwellingAreaValuePerType as any)[key]

        const avgValue = areaValue.value.total / units
        const sqmPrice = areaValue.value.total / areaValue.area.total

        acc[key] = {
          avgValue,
          avgValueIndexVsAvg: (avgValue / areaValue.value.index) * 100,
          sqmPrice,
          sqmPriceIndexVsAvg: (sqmPrice / (areaValue.value.index / areaValue.area.index)) * 100,
        }

        return acc
      },
      {} as Record<
        string,
        {
          avgValue: number
          avgValueIndexVsAvg: number
          sqmPrice: number
          sqmPriceIndexVsAvg: number
        }
      >
    )
  }

  dwellingTypes() {
    const data: any = this.summaryDwellingTypes()

    const KEYS = ['semiDetached', 'detached', 'terraced', 'flat', 'all']

    function makeStatistic(key: string, indexKey: string) {
      return KEYS.reduce((acc, cur) => {
        acc[cur] = {
          values: [],
          total: isNaN(data[cur][key]) ? 0 : data[cur][key],
          percentOverIndex: isNaN(data[cur][indexKey]) ? 0 : data[cur][indexKey],
          index: data[cur][indexKey],
        }
        return acc
      }, {} as any)
    }

    return {
      dwellingTypesAveragePrice: makeStatistic('avgValue', 'avgValueIndexVsAvg'),
      dwellingTypesAveragePricePerSqFt: makeStatistic('sqmPrice', 'sqmPriceIndexVsAvg'),
    }
  }
}

export class LifestyleStatisticsSpend extends CensusStatisticsGroup {
  bakedGoods: StatisticsValue
  confectionery: StatisticsValue
  otherFoodStuffs: StatisticsValue
  fruitAndVegetables: StatisticsValue
  meatFishAndPoultry: StatisticsValue
  alcoholicDrinks: StatisticsValue
  cleaningAndPaperProducts: StatisticsValue
  nonAlcoholicDrinks: StatisticsValue
  petsAndPetfood: StatisticsValue
  babyEquipment: StatisticsValue
  books: StatisticsValue
  cards: StatisticsValue
  stationery: StatisticsValue
  childrenswear: StatisticsValue
  electricalGasAppliances: StatisticsValue
  electricalGasAccessories: StatisticsValue
  fashionAccessories: StatisticsValue
  floorCoverings: StatisticsValue
  footwear: StatisticsValue
  furniture: StatisticsValue
  haberdashery: StatisticsValue
  healthBeauty: StatisticsValue
  homeFurnishingsGifts: StatisticsValue
  homeImprovementsAndGardening: StatisticsValue
  jewellery: StatisticsValue
  menswear: StatisticsValue
  mobilePhones: StatisticsValue
  motorAccessories: StatisticsValue
  musicAndVideo: StatisticsValue
  newspapersAndMagazines: StatisticsValue
  oTCPharmaceuticals: StatisticsValue
  photography: StatisticsValue
  softFurnishingsLinens: StatisticsValue
  spectaclesAndLenses: StatisticsValue
  sportsAndCampingEquipment: StatisticsValue
  tobacco: StatisticsValue
  toys: StatisticsValue
  eatingOut: StatisticsValue
  travelGoods: StatisticsValue
  womenswear: StatisticsValue
  // comparisonTotal: StatisticsValue
  // foodTotal: StatisticsValue
  cinemas: StatisticsValue
  leisureCentres: StatisticsValue
  clubsDancesdiscoBingo: StatisticsValue
  liveEntertainment: StatisticsValue
  museumZooThemeParkHouse: StatisticsValue
  alcoholOutofHome: StatisticsValue
  restaurantandCafemeals: StatisticsValue
}

export class LifestyleStatisticsSummarySpend extends CensusStatisticsGroup {
  leisure: StatisticsValue
  foodAndBeverage: StatisticsValue
  comparisonTotal: StatisticsValue
  convenience: StatisticsValue

  // put them back for match point
  booksCardsStationery: StatisticsValue
  fashionFootwearJewellery: StatisticsValue
  electricalGoodsMobilePhonesPhotography: StatisticsValue
  homeImprovementsGardening: StatisticsValue
  healthBeautyIncOtcPharmacy: StatisticsValue
  musicDvDsGamesToys: StatisticsValue
  otherSpectaclesLensesTravelGoodsMotorAccessories: StatisticsValue
  eatingOutRestaurantsCafes: StatisticsValue
  sportsAndCampingEquipment: StatisticsValue
  floorCoverings: StatisticsValue
  homeFurnishingsGifts: StatisticsValue
  softFurnishingsLinens: StatisticsValue
  leisureCenters: StatisticsValue
  // was removed until here
}

export class PersonicxStatisticsSummary extends CensusStatisticsGroup {
  a: StatisticsValue[] = new Array(5)
  b: StatisticsValue[] = new Array(5)
  c: StatisticsValue[] = new Array(5)
  d: StatisticsValue[] = new Array(5)

  horizontal: number[]
  vertical: number[]

  horizontalIndices: number[]
  verticalIndices: number[]

  total: number

  constructor(input: StatisticsGroup, property: string) {
    super()

    const classes = ['a', 'b', 'c', 'd']
    for (var x = 0; x < 5; x++) {
      for (var y = 0; y < 4; y++) {
        const key = classes[y] + (x + 1)
        const variation1 = `${key.toUpperCase()}_${property}`
        const variation2 = `${property}_${key}`

        this[classes[y]][x] = input[variation1] === undefined ? input[variation2] : input[variation1]
      }
    }
  }

  seal() {
    super.seal()

    const array = [this.a, this.b, this.c, this.d]

    this.horizontal = [0, 0, 0, 0, 0]
    this.vertical = [0, 0, 0, 0]
    this.horizontalIndices = [0, 0, 0, 0, 0]
    this.verticalIndices = [0, 0, 0, 0]

    this.total = 0

    for (var x = 0; x < 5; x++) {
      for (var y = 0; y < 4; y++) {
        this.horizontal[x] += array[y][x] ? array[y][x].total : 0
        this.vertical[y] += array[y][x] ? array[y][x].total : 0

        this.horizontalIndices[x] += array[y][x] ? array[y][x].index : 0
        this.verticalIndices[y] += array[y][x] ? array[y][x].index : 0
      }
    }

    for (var x = 0; x < 5; x++) {
      this.total += this.horizontal[x]
    }
  }
}

export class PersonicxStatistics extends CensusStatisticsGroup {
  gx301: StatisticsValue
  gx203: StatisticsValue
  gx304: StatisticsValue
  gx105: StatisticsValue
  gx506: StatisticsValue
  gx210: StatisticsValue
  gx315: StatisticsValue
  gx418: StatisticsValue
  gx520: StatisticsValue
  gx324: StatisticsValue
  gx127: StatisticsValue
  gx229: StatisticsValue
  gx337: StatisticsValue
  gf202: StatisticsValue
  gf307: StatisticsValue
  gf408: StatisticsValue
  gf109: StatisticsValue
  gf411: StatisticsValue
  gf512: StatisticsValue
  gf314: StatisticsValue
  gf216: StatisticsValue
  gf319: StatisticsValue
  gf221: StatisticsValue
  gf422: StatisticsValue
  gf326: StatisticsValue
  gf128: StatisticsValue
  gf430: StatisticsValue
  gr345: StatisticsValue
  gr346: StatisticsValue
  gr547: StatisticsValue
  gr448: StatisticsValue
  gr549: StatisticsValue
  gr250: StatisticsValue
  gr551: StatisticsValue
  gr152: StatisticsValue
  gr153: StatisticsValue
  gr354: StatisticsValue
  gr355: StatisticsValue
  gr256: StatisticsValue
  gr557: StatisticsValue
  gr458: StatisticsValue
  gr559: StatisticsValue
  gr560: StatisticsValue
  gm313: StatisticsValue
  gm517: StatisticsValue
  gm223: StatisticsValue
  gm225: StatisticsValue
  gm531: StatisticsValue
  gm232: StatisticsValue
  gm433: StatisticsValue
  gm234: StatisticsValue
  gm235: StatisticsValue
  gm336: StatisticsValue
  gm238: StatisticsValue
  gm539: StatisticsValue
  gm440: StatisticsValue
  gm541: StatisticsValue
  gm342: StatisticsValue
  gm143: StatisticsValue
  gm244: StatisticsValue
}

export class LifestyleSocial {
  public readonly households: PersonicxStatisticsSummary
  public readonly comparisonTotal: PersonicxStatisticsSummary
  public readonly foodTotal: PersonicxStatisticsSummary
  public readonly eatingOut: PersonicxStatisticsSummary
  public readonly restaurantsCafeAlcohol: PersonicxStatisticsSummary

  constructor(input: StatisticsGroup) {
    this.households = new PersonicxStatisticsSummary(input, 'Households')
    this.comparisonTotal = new PersonicxStatisticsSummary(input, 'ComparisonTotal')
    this.foodTotal = new PersonicxStatisticsSummary(input, 'FoodTotal')
    this.eatingOut = new PersonicxStatisticsSummary(input, 'EatingOut')
    this.restaurantsCafeAlcohol = new PersonicxStatisticsSummary(input, 'RestaurantsCafeAlcohol')
  }

  seal() {
    this.households.seal()
    this.comparisonTotal.seal()
    this.foodTotal.seal()
    this.eatingOut.seal()
    this.restaurantsCafeAlcohol.seal()
  }

  convertCurrency(convert: boolean, rate: number) {
    this.comparisonTotal.convertCurrency(convert, rate)
    this.foodTotal.convertCurrency(convert, rate)
    this.eatingOut.convertCurrency(convert, rate)
    this.restaurantsCafeAlcohol.convertCurrency(convert, rate)
  }
}

export class LifestyleStatistics extends CensusStatisticsGroup {
  spend = new LifestyleStatisticsSpend()
  spendSummary = new LifestyleStatisticsSummarySpend()
  social: LifestyleSocial

  personicxSummary: PersonicxStatisticsSummary // alias
  // personicx = new PersonicxStatistics()

  constructor(input: StatisticsGroup) {
    super()

    this.social = new LifestyleSocial(input)
    this.personicxSummary = this.social.households
  }

  convertCurrency(convert: boolean, rate: number) {
    this.spend.convertCurrency(true, rate)
    this.spendSummary.convertCurrency(true, rate)
    this.social.convertCurrency(true, rate)
  }
}

export class ProjectedAges extends CensusStatisticsGroup {
  age0to4: StatisticsValue
  age5to9: StatisticsValue
  age10to14: StatisticsValue
  age15to19: StatisticsValue
  age20to24: StatisticsValue
  age25to29: StatisticsValue
  age30to34: StatisticsValue
  age35to39: StatisticsValue
  age40to44: StatisticsValue
  age45to49: StatisticsValue
  age50to54: StatisticsValue
  age55to59: StatisticsValue
  age60to64: StatisticsValue
  age65to69: StatisticsValue
  age70to74: StatisticsValue
  age75to79: StatisticsValue
  age80to84: StatisticsValue
  age85to89: StatisticsValue
  age90Plus: StatisticsValue
}

export class AgesByYear extends CensusStatisticsGroup implements Record<string, StatisticsValue> {
  constructor(input: StatisticsGroup) {
    super()

    for (let i = 0; i <= 100; i++) {
      const key = `year${i}`
      const inputKey = i === 0 ? 'id_538_below_1_years' : i === 1 ? 'id_539_1_year' : `id_${538 + i}_${i}_years`
      this[key] = input[inputKey]
    }
  }

  static keysForRange(from: number, to: number) {
    from = Math.min(100, Math.max(0, from))
    to = Math.min(100, Math.max(0, to))

    const result: string[] = []

    for (let i = from; i <= to; i++) {
      result.push(`year${i}`)
    }

    return result
  }

  rangeByAges(from: number, to: number) {
    return sumStatisticsValues(AgesByYear.keysForRange(Math.min(from, to), Math.max(from, to)).map((key) => this[key]))
  }

  rangeByYears(from: number, to: number) {
    const refYear = 2023
    return this.rangeByAges(refYear - from, refYear - to)
  }
}

export class ProjectedAgesGroups extends CensusStatisticsGroup {
  year: { [year: number]: ProjectedAges } = {}

  constructor(input: StatisticsGroup) {
    super()

    for (let i = POPULATION_FORECAST_FROM_YEAR; i <= POPULATION_FORECAST_TO_YEAR; i++) {
      const current = (this.year[i] = new ProjectedAges())

      if (i === 2021) {
        // Note will changes back to from year later after data update
        current.age0to4 = input['Age_0_4_' + i]
        current.age5to9 = input['Age_5_9_' + i]
        current.age10to14 = input['Age_10_14_' + i]
        current.age15to19 = input['Age_15_19_' + i]
        current.age20to24 = input['Age_20_24_' + i]
        current.age25to29 = input['Age_25_29_' + i]
        current.age30to34 = input['Age_30_34_' + i]
        current.age35to39 = input['Age_35_39_' + i]
        current.age40to44 = input['Age_40_44_' + i]
        current.age45to49 = input['Age_45_49_' + i]
        current.age50to54 = input['Age_50_54_' + i]
        current.age55to59 = input['Age_55_59_' + i]
        current.age60to64 = input['Age_60_64_' + i]
        current.age65to69 = input['Age_65_69_' + i]
        current.age70to74 = input['Age_70_74_' + i]
        current.age75to79 = input['Age_75_79_' + i]
        current.age80to84 = input['Age_80_84_' + i]
        current.age85to89 = input['Age_85_89_' + i]
        current.age90Plus = input['Age_90_Plus_' + i]
      } else {
        current.age0to4 = input['Age_0_4_uplift_' + i]
        current.age5to9 = input['Age_5_9_uplift_' + i]
        current.age10to14 = input['Age_10_14_uplift_' + i]
        current.age15to19 = input['Age_15_19_uplift_' + i]
        current.age20to24 = input['Age_20_24_uplift_' + i]
        current.age25to29 = input['Age_25_29_uplift_' + i]
        current.age30to34 = input['Age_30_34_uplift_' + i]
        current.age35to39 = input['Age_35_39_uplift_' + i]
        current.age40to44 = input['Age_40_44_uplift_' + i]
        current.age45to49 = input['Age_45_49_uplift_' + i]
        current.age50to54 = input['Age_50_54_uplift_' + i]
        current.age55to59 = input['Age_55_59_uplift_' + i]
        current.age60to64 = input['Age_60_64_uplift_' + i]
        current.age65to69 = input['Age_65_69_uplift_' + i]
        current.age70to74 = input['Age_70_74_uplift_' + i]
        current.age75to79 = input['Age_75_79_uplift_' + i]
        current.age80to84 = input['Age_80_84_uplift_' + i]
        current.age85to89 = input['Age_85_89_uplift_' + i]
        current.age90Plus = input['Age_90_Plus_uplift_' + i]
      }
    }
  }
}

export class AllStatistics extends CensusStatisticsGroup {
  public readonly census = new CensusStatistics()
  public readonly lifestyle: LifestyleStatistics
  public readonly projectedPopulation: ProjectedAgesGroups
  public readonly populationByYear: AgesByYear

  public hasWorkforce = false
  public hasForecast = false

  private input: StatisticsGroup

  private deepCopy(input: StatisticsGroup) {
    const result: StatisticsGroup = {}

    for (let key in input) {
      result[key] = new Statistics({ ...input[key].values })
    }

    return result
  }

  setCatchmentMode(catchmentMode: CatchmentMode) {
    this.catchmentMode = catchmentMode
    return this
  }

  isMobileCatchmentMode() {
    return this.catchmentMode === CatchmentMode.MOBILE
  }

  constructor(
    public inputOriginal: StatisticsGroup,
    public gbpToEuroRate: number,
    public region: string = 'GB',
    public calculatedRegion: string = 'GB',
    public country: string = 'GB',
    public householdsOrPopulation = ReportPerCapitaHousehold.PER_HOUSEHOLD,
    private catchmentMode = CatchmentMode.NORMAL
  ) {
    super()

    this.input = this.deepCopy(inputOriginal)
    const input = this.input

    if (this.gbpToEuroRate == null) {
      this.gbpToEuroRate = EXCHANGE_RATE_GBP_TO_EURO
    }

    for (let key in input) {
      if (input[key].total && !(<any>input[key]).totalOriginal) {
        ;(<any>input[key]).totalOriginal = input[key].total
      }
    }

    this.lifestyle = new LifestyleStatistics(input)
    this.projectedPopulation = new ProjectedAgesGroups(input)
    this.populationByYear = new AgesByYear(input)

    this.updateIndices(this.region, this.calculatedRegion, this.country, this.householdsOrPopulation)
    this.updateExistsData()
    this.updatePercentOverIndex()
  }

  get indicesRegion() {
    let region = String(this.region).trim().toUpperCase()
    if (region === 'YORKS & HUMBER') {
      region = 'YORKS & the HUMBER'
    }
    return region
  }

  get populationOrHouseholds() {
    if (this.census && this.census.population2011 && this.census.population2011.population) {
      return this.householdsOrPopulation === ReportPerCapitaHousehold.PER_CAPITA
        ? this.census.population2011.population.total
        : this.census.population2011.households.total
    } else {
      return 0
    }
  }

  copy() {
    return new AllStatistics(this.inputOriginal, this.gbpToEuroRate, this.region, this.calculatedRegion, this.country)
  }

  currencySymbol() {
    // NOTE simple for now
    if (this.country === 'ROI') {
      return '€'
    } else {
      return '£'
    }
  }

  private updatePercentOverIndex() {
    const populationOrHouseholds = this.populationOrHouseholds

    function update(value: any, simpleCalculation = true) {
      Object.keys(value).forEach((key) => {
        const current = value[key]

        if (current) {
          if (current.total != null && current.values != null && current.index != null) {
            if (simpleCalculation) {
              current.percentOverIndex = (current.percent * 100) / current.index
            } else {
              current.percentOverIndex = (current.total / populationOrHouseholds / current.index) * 100
            }
          }

          update(current, simpleCalculation)
        }
      })
    }

    update(this.census, true)
    update(this.lifestyle, false)
  }

  private updateExistsData() {
    let hasForecast = false
    if (this.projectedPopulation && this.projectedPopulation.year) {
      for (let year in this.projectedPopulation.year) {
        if (this.projectedPopulation.year[year]) {
          for (let key in this.projectedPopulation.year[year]) {
            hasForecast =
              hasForecast ||
              (this.projectedPopulation.year[year][key] && this.projectedPopulation.year[year][key].total > 0)
          }
        }
      }
    }
    this.hasForecast = hasForecast

    this.hasWorkforce =
      (this.census.allWorkforce && this.census.allWorkforce.total > 0) ||
      (this.census.occupations && this.census.occupations.total > 0)
  }

  regionLabel() {
    if (!this.region || this.region.toUpperCase() === 'GB') {
      return 'GB'
    } else if (this.region.toUpperCase() === 'ROI') {
      return 'ROI'
    } else {
      return 'Region'
    }
  }

  updateIndices(
    region: string,
    calculatedRegion: string,
    country: string,
    householdsOrPopulation: ReportPerCapitaHousehold
  ) {
    this.region = region
    this.calculatedRegion = calculatedRegion
    this.country = country

    this.householdsOrPopulation =
      householdsOrPopulation == null ? ReportPerCapitaHousehold.PER_HOUSEHOLD : householdsOrPopulation

    this.init()
    this.seal()

    if (country === 'ROI') {
      this.convertCurrency(false, this.gbpToEuroRate)
    } else {
      this.convertCurrency(false, 1)
    }
    this.seal()
  }

  dwellingTypesAndIncomeAverages() {
    let region = this.indicesRegion
    const householsIncome: CensusHouseholsIncome = JSON.parse(JSON.stringify(this.census.householsIncome))
    const indicesRegion = INDICES[region] || {}

    ;['totalGross', 'totalNet', 'totalAfterHousingCosts'].forEach((key) => {
      if (householsIncome[key].total && this.census.population2011.households.total) {
        householsIncome[key].total = householsIncome[key].total / this.census.population2011.households.total
      } else {
        householsIncome[key].total = 0
      }

      if (indicesRegion[key]) {
        householsIncome[key].percentOverIndex = (householsIncome[key].total / indicesRegion[key]) * 100
        householsIncome[key].index = indicesRegion[key]
      }
    })

    return {
      ...(this.census.dwellingTypes() as any),
      householsIncome: householsIncome,
    }
  }

  init() {
    const input = this.input

    indices.decorate(input, this.region.toUpperCase(), this.householdsOrPopulation)

    // const result = new AllStatistics(input)
    const census = this.census
    const lifestyle = this.lifestyle

    census.ages.aged0to4 = input.AGE_0_to_4 // census_age_0_to_4
    census.ages.aged5to7 = input.AGE_5_to_7 // census_age_5_to_7
    census.ages.aged8to9 = input.AGE_8_to_9 // census_age_8_to_9
    census.ages.aged10to14 = input.AGE_10_to_14 // census_age_10_to_14
    census.ages.aged15 = input.AGE_15 // census_age_15
    census.ages.aged16to17 = input.AGE_16_to_17 // census_age_16_to_17
    census.ages.aged18to19 = input.AGE_18_to_19 // census_age_18_to_19
    census.ages.aged20to24 = input.AGE_20_to_24 // census_age_20_to_24
    census.ages.aged25to29 = input.AGE_25_to_29 // census_age_25_to_29
    census.ages.aged30to44 = input.AGE_30_to_44 // census_age_30_to_44
    census.ages.aged45to59 = input.AGE_45_to_59 // census_age_45_to_59
    census.ages.aged60to64 = input.AGE_60_to_64 // census_age_60_to_64
    census.ages.aged65to74 = input.AGE_65_to_74 // census_age_65_to_74
    census.ages.aged75to84 = input.AGE_75_to_84 // census_age_75_to_84
    census.ages.aged85to89 = input.AGE_85_to_89 // census_age_85_to_89
    census.ages.aged90AndOver = input.AGE_90_and_over // census_age_90_and_over

    census.workerAges.aged16to24 = input.all_16_24 // workforce_all_16_24
    census.workerAges.aged25to34 = input.all_25_34 // workforce_all_25_34
    census.workerAges.aged35to44 = input.all_35_44 // workforce_all_35_44
    census.workerAges.aged45to54 = input.all_45_54 // workforce_all_45_54
    census.workerAges.aged55to64 = input.all_55_64 // workforce_all_55_64
    census.workerAges.aged65to74 = input.all_65_74 // workforce_all_65_74

    census.malesAges.aged16to24 = input.m_16_24 // workforce_m_16_24
    census.malesAges.aged25to34 = input.m_25_34 // workforce_m_25_34
    census.malesAges.aged35to44 = input.m_35_44 // workforce_m_35_44
    census.malesAges.aged45to54 = input.m_45_54 // workforce_m_45_54
    census.malesAges.aged55to64 = input.m_55_64 // workforce_m_55_64
    census.malesAges.aged65to74 = input.m_65_74 // workforce_m_65_74

    census.femalesAges.aged16to24 = input.f_16_24 // workforce_f_16_24
    census.femalesAges.aged25to34 = input.f_25_34 // workforce_f_25_34
    census.femalesAges.aged35to44 = input.f_35_44 // workforce_f_35_44
    census.femalesAges.aged45to54 = input.f_45_54 // workforce_f_45_54
    census.femalesAges.aged55to64 = input.f_55_64 // workforce_f_55_64
    census.femalesAges.aged65to74 = input.f_65_74 // workforce_f_65_74

    // NOTE: unsure if these are truly workforce
    // census.workerAges.aged00to15 = input.AGED001501
    // census.workerAges.aged16to24 = input.AGED162401
    // census.workerAges.aged25to44 = input.AGED254401
    // census.workerAges.aged45to64 = input.AGED456401
    // census.workerAges.aged65plus = input.Aged65PLS01

    census.allWorkforce = input.all_workforce // workforce_all_workfo

    census.occupations.managersDirectorsSeniorOfficials = input.occ_1 // workforce_occ_1
    census.occupations.professional = input.occ_2 //  workforce_occ_2
    census.occupations.associateProfessional = input.occ_3 //  workforce_occ_3
    census.occupations.adminSecretarial = input.occ_4 //  workforce_occ_4
    census.occupations.skilledTrades = input.occ_5 //  workforce_occ_5
    census.occupations.caringLeisureOtherService = input.occ_6 //  workforce_occ_6
    census.occupations.salesCustomerService = input.occ_7 //  workforce_occ_7
    census.occupations.processPlantMachineOperatives = input.occ_8 // workforce_occ_8
    census.occupations.elementaryOccupations = input.occ_9 //  workforce_occ_9

    census.occupationsCensus.managersDirectorsSeniorOfficials = input.OCCU_1 // census_occu_1
    census.occupationsCensus.professional = input.OCCU_2 //  census_occu_2
    census.occupationsCensus.associateProfessional = input.OCCU_3 //  census_occu_3
    census.occupationsCensus.adminSecretarial = input.OCCU_4 //  census_occu_4
    census.occupationsCensus.skilledTrades = input.OCCU_5 //  census_occu_5
    census.occupationsCensus.caringLeisureOtherService = input.OCCU_6 //  census_occu_6
    census.occupationsCensus.salesCustomerService = input.OCCU_7 //  census_occu_7
    census.occupationsCensus.processPlantMachineOperatives = input.OCCU_8 // census_occu_8
    census.occupationsCensus.elementaryOccupations = input.OCCU_9 //  census_occu_9
    census.occupationsCensus.students = input.Students //  census_occu_9

    census.industries.agricultureForestryFishing = input.ind_a
    census.industries.miningQuarrying = input.ind_b
    census.industries.manufacturing = input.ind_c
    census.industries.electricityGasSteam = input.ind_d
    census.industries.waterSewerageWasteManagement = input.ind_e
    census.industries.construction = input.ind_f
    census.industries.wholesaleRetailTradeRepairMotorVehicles = input.ind_g
    census.industries.transportStorage = input.ind_h
    census.industries.accomodationFoodServices = input.ind_i
    census.industries.informationCommunication = input.ind_j
    census.industries.financialInsuranceActivities = input.ind_k
    census.industries.realEstateActivities = input.ind_l
    census.industries.professionalScientificTechnicalActivities = input.ind_m
    census.industries.administrativeSupportServiceActivities = input.ind_n
    census.industries.publicAdministrationDefenceCompulsorySocialSecurity = input.ind_o
    census.industries.education = input.ind_p
    census.industries.humanHealthSocialWorkActivities = input.ind_q
    census.industries.artsEntertainmentRecreationOtherServiceActivities = input.ind_rs
    census.industries.other1 = input.ind_t
    census.industries.other2 = input.ind_u

    census.socialClass.ab = input.Social_AB // census_social_ab
    census.socialClass.c1 = input.Social_C1 // census_social_c1
    census.socialClass.c2 = input.Social_C2 // census_social_c2
    census.socialClass.de = input.Social_DE // census_social_de

    census.socialClassWorkforce.ab = input.ab
    census.socialClassWorkforce.c1 = input.c1
    census.socialClassWorkforce.c2 = input.c2
    census.socialClassWorkforce.de = input.de

    census.arrival.bornUk = input.ARR_BRNUK // census_arr_brnuk
    census.arrival.pre41 = input.ARR_PRE41 // census_arr_pre41
    census.arrival.from41to50 = input.ARR_41_50 // census_arr_41_50
    census.arrival.from51to60 = input.ARR_51_60 // census_arr_51_60
    census.arrival.from61to70 = input.ARR_61_70 // census_arr_61_70
    census.arrival.from71to80 = input.ARR_71_80 // census_arr_71_80
    census.arrival.from81to90 = input.ARR_81_90 // census_arr_81_90
    census.arrival.from91to00 = input.ARR_91_00 // census_arr_91_00
    census.arrival.from01to03 = input.ARR_01_03 // census_arr_01_03
    census.arrival.from04to06 = input.ARR_04_06 // census_arr_04_06
    census.arrival.from07to09 = input.ARR_07_09 // census_arr_07_09
    census.arrival.from10to11 = input.ARR_10_11 // census_arr_10_11
    census.tenure.employerHouseholdMember = input.TENHEMP01 // census_tenhemp01
    census.tenure.relativeFriendHouseholdMember = input.TENHFAM01 // census_tenhfam01
    census.tenure.livingRentFree = input.TENHFREE01 // census_tenhfree01
    census.tenure.privateLandlordLettingAgency = input.TENHLLLA01 // census_tenhllla01
    census.tenure.ownsMortgageLoan = input.TENHMTG01 // census_tenhmtg01
    census.tenure.sharedOwnership = input.TENHOSH01 // census_tenhosh01
    census.tenure.owned = input.TENHOWN01 // census_tenhown01
    census.tenure.ownsOutright = input.TENHOWO01 // census_tenhowo01
    census.tenure.otherPrivateRented = input.TENHPOTH01 // census_tenhpoth01
    census.tenure.privateRented = input.TENHPRRT01 // census_tenhprrt01
    census.tenure.rentedCouncilLocalAuthority = input.TENHRLA01 // census_tenhrla01
    census.tenure.otherSocialRented = input.TENHROS01 // census_tenhros01
    census.tenure.socialRented = input.TENHSCR01 // census_tenhscr01

    // issue 335
    census.tenure.totalOverride = Math.round(
      sumStatisticsValues([input.TENHFREE01, input.TENHOSH01, input.TENHOWN01, input.TENHPRRT01, input.TENHSCR01]).total
    )

    if (input.OCCU_All) {
      census.occupationsCensus.totalOverride = input.OCCU_All.total
    }

    census.cars.noCar = input.CAR0_01 // census_car0_01
    census.cars.with1car = input.CAR1_01 // census_car1_01
    census.cars.with2car = input.CAR2_01 // census_car2_01
    census.cars.with3orMore = input.CAR3PLS_01 // census_car3pls_01
    census.accomodation.detatched = input.ACCHDET01 // census_acchdet01
    census.accomodation.flat = input.ACCHFLAT01 // census_acchflat01
    census.accomodation.semiDetached = input.ACCHSEMI01 // census_acchsemi01
    census.accomodation.temporaryStructure = input.ACCHTEMP01 // census_acchtemp01
    census.accomodation.terrace = input.ACCHTER01 // census_acchter01
    census.ethnicity.bangladeshi = input.ETHAB_UK01 // census_ethab_uk01
    census.ethnicity.indian = input.ETHAI_UK01 // census_ethai_uk01
    census.ethnicity.pakistani = input.ETHAP_UK01 // census_ethap_uk01
    census.ethnicity.black = input.ETHB_UK01 // census_ethb_uk01
    census.ethnicity.african = input.ETHBA_UK01 // census_ethba_uk01
    census.ethnicity.caribbean = input.ETHBC_UK01 // census_ethbc_uk01
    census.ethnicity.otherBlack = input.ETHBO_UK01 // census_ethbo_uk01
    census.ethnicity.chinese = input.ETHCH_UK01 // census_ethch_uk01
    census.ethnicity.mixed = input.ETHM_UK01 // census_ethm_uk01
    census.ethnicity.otherEthnicGroup = input.ETHOTHUK01 // census_ethothuk01
    census.ethnicity.white = input.ETHW_UK01 // census_ethw_uk01
    census.ethnicity.asianOther = input.ETHAOTH_UK01 // census_ethaoth_uk01
    census.ethnicity.arab = input.ETHARA_UK01 // census_ethara_uk01
    census.travelWork.worksMainlyHome = input.MTHOMR7401 // census_mthomr7401
    census.travelWork.undergroundMetroLightRailTram = input.MTLTRR7401 // census_mtltrr7401
    census.travelWork.train = input.MTTRNR7401 // census_mttrnr7401
    census.travelWork.busMinibusCoach = input.MTBUSR7401 // census_mtbusr7401
    census.travelWork.taxiMinicab = input.MTCABR7401 // census_mtcabr7401
    census.travelWork.motorcycleScooterMoped = input.MTMBKR7401 // census_mtmbkr7401
    census.travelWork.drivingCarVan = input.MTDRVR7401 // census_mtdrvr7401
    census.travelWork.passengerCarVan = input.MTPASR7401 // census_mtpasr7401
    census.travelWork.bicycle = input.MTBICR7401 // census_mtbicr7401
    census.travelWork.onFoot = input.MTFTR7401 // census_mtftr7401
    census.travelWork.otherTravelSituation = input.MTOTHR7401 // census_mtothr7401
    census.travelWork.notCurrentlyWorking = input.MTNOTR7401 // census_mtnotr7401
    census.economicallyActive.economicallyActive = input.EMPACTRT01 // census_empactrt01
    census.economicallyActive.economicallyInactive = input.EMPINART01 // census_empinart01
    census.nationalStatisticsSocialClass.higherManagerialProfessionalOccupations = input.SEC1_7401 // census_sec1_7401
    census.nationalStatisticsSocialClass.lowerManagerialProfessionalOccupations = input.SEC2_7401 // census_sec2_7401
    census.nationalStatisticsSocialClass.intermediateOccupations = input.SEC3_7401 // census_sec3_7401
    census.nationalStatisticsSocialClass.smallEmployersOwnAccountWorkers = input.SEC4_7401 // census_sec4_7401
    census.nationalStatisticsSocialClass.lowerSupervisoryTechnicalOccupations = input.SEC5_7401 // census_sec5_7401
    census.nationalStatisticsSocialClass.semiRoutineOccupations = input.SEC6_7401 // census_sec6_7401
    census.nationalStatisticsSocialClass.routineOccupations = input.SEC7_7401 // census_sec7_7401
    census.nationalStatisticsSocialClass.neverWorkedLongTermUnemployed = input.SEC8_7401 // census_sec8_7401
    census.nationalStatisticsSocialClass.notClassified = input.SECNC_7401 // census_secnc_7401
    census.population2011.population = input.Population // census_census2011popn
    census.population2011.households = input.Households // census_census2011households
    census.gender.males = input.Males // census_males
    census.gender.females = input.Females // census_females

    census.householsIncome.totalGross = input.id_525_Total_Gross_Income
    census.householsIncome.totalNet = input.id_526_Total_Net_Income
    census.householsIncome.totalAfterHousingCosts = input.id_527_Total_after_housing_costs

    if (input.id_538_below_1_years) {
      census.agesPerYear = decodeAgesPerYear(input)
    }

    if (input.id_931_D_100k) {
      census.dwellingPerType = decodeDwellingPerType(input)
    }

    if (input.id_995_Detached_total_area) {
      census.dwellingAreaValuePerType = decodeDwellingAreaValuePerType(input)
    }

    lifestyle.spend.bakedGoods = input.BakedGoods // spend_bakedgoods
    lifestyle.spend.confectionery = input.Confectionery // spend_confection
    lifestyle.spend.otherFoodStuffs = input.OtherFoodStuffs // spend_otherfoods
    lifestyle.spend.fruitAndVegetables = input.FruitAndVegetables // spend_fruitandve
    lifestyle.spend.meatFishAndPoultry = input.MeatFishAndPoultry // spend_meatfishan
    lifestyle.spend.alcoholicDrinks = input.AlcoholicDrinks // spend_alcoholicd
    lifestyle.spend.cleaningAndPaperProducts = input.CleaningAndPaperProducts // spend_cleaningan
    lifestyle.spend.nonAlcoholicDrinks = input.NonAlcoholicDrinks // spend_nonalcohol
    lifestyle.spend.petsAndPetfood = input.PetsAndPetfood // spend_petsandpet
    lifestyle.spend.babyEquipment = input.BabyEquipment // spend_babyequipm
    lifestyle.spend.books = input.Books // spend_booksfv
    lifestyle.spend.cards = input.Cards // spend_cardsfv
    lifestyle.spend.stationery = input.Stationery // spend_stationery
    lifestyle.spend.childrenswear = input.Childrenswear // spend_childrensw
    lifestyle.spend.electricalGasAppliances = input.ElectricalGasAppliances // spend_electrical
    lifestyle.spend.electricalGasAccessories = input.ElectricalGasAccessories // spend_electric0
    lifestyle.spend.fashionAccessories = input.FashionAccessories // spend_fashionacc
    lifestyle.spend.floorCoverings = input.FloorCoverings // spend_floorcover
    lifestyle.spend.footwear = input.Footwear // spend_footwearfv
    lifestyle.spend.furniture = input.Furniture // spend_furnituref
    lifestyle.spend.haberdashery = input.Haberdashery // spend_haberdashe
    lifestyle.spend.healthBeauty = input.HealthBeauty // spend_healthbeau
    lifestyle.spend.homeFurnishingsGifts = input.HomeFurnishingsGifts // spend_homefurnis
    lifestyle.spend.homeImprovementsAndGardening = input.HomeImprovementsAndGardening // spend_homeimprov
    lifestyle.spend.jewellery = input.Jewellery // spend_jewelleryf
    lifestyle.spend.menswear = input.Menswear // spend_menswearfv
    lifestyle.spend.mobilePhones = input.MobilePhones // spend_mobilephon
    lifestyle.spend.motorAccessories = input.MotorAccessories // spend_motoracces
    lifestyle.spend.musicAndVideo = input.MusicAndVideo // spend_musicandvi
    lifestyle.spend.newspapersAndMagazines = input.NewspapersAndMagazines // spend_newspapers
    lifestyle.spend.oTCPharmaceuticals = input.OTCPharmaceuticals // spend_otcpharmac
    lifestyle.spend.photography = input.Photography // spend_photograph
    lifestyle.spend.softFurnishingsLinens = input.SoftFurnishingsLinens // spend_softfurnis
    lifestyle.spend.spectaclesAndLenses = input.SpectaclesAndLenses // spend_spectacles
    lifestyle.spend.sportsAndCampingEquipment = input.SportsAndCampingEquipment // spend_sportsandc
    lifestyle.spend.tobacco = input.Tobacco // spend_tobaccofv
    lifestyle.spend.toys = input.Toys // spend_toysfv
    lifestyle.spend.eatingOut = input.EatingOut // spend_eatingoutf
    lifestyle.spend.travelGoods = input.TravelGoods // spend_travelgood
    lifestyle.spend.womenswear = input.Womenswear // spend_womenswear
    lifestyle.spend.cinemas = input.Cinemas // spend_cinemas
    lifestyle.spend.leisureCentres = input.LeisureCentres // spend_leisurecen
    lifestyle.spend.clubsDancesdiscoBingo = input.ClubsDancesdiscoBingo // spend_clubsdance
    lifestyle.spend.liveEntertainment = input.LiveEntertainment // spend_liveentert
    lifestyle.spend.museumZooThemeParkHouse = input.MuseumZooThemeParkHouse // spend_museumzoot
    lifestyle.spend.alcoholOutofHome = input.AlcoholOutOfHome // spend_alcoholout
    lifestyle.spend.restaurantandCafemeals = input.Restaurantandcafemeals // spend_restaurant
    lifestyle.spendSummary.comparisonTotal = input.ComparisonTotal // spend_comparison
    lifestyle.spendSummary.convenience = input.FoodTotal // spend_foodtotal

    /// new report
    // NEW
    census.religion.christian = input.id_748_Christian
    census.religion.buddhist = input.id_749_Buddhist
    census.religion.hindu = input.id_750_Hindu
    census.religion.jewish = input.id_751_Jewish
    census.religion.muslim = input.id_752_Muslim
    census.religion.sikh = input.id_753_Sikh
    census.religion.otherReligion = input.id_754_Other_religion
    census.religion.none = input.id_755_None
    census.religion.notStated = input.id_756_Not_stated

    // NEW
    census.economicActivity.economicallyActiveEmployedPartTime = input.id_705_Employed_part_time
    census.economicActivity.economicallyActiveEmployedFullTime = input.id_706_Employed_full_time
    census.economicActivity.economicallyActiveSelfEmployedPartTimeWithEmployees =
      input.id_707_Self_employed_part_time_with_employees
    census.economicActivity.economicallyActiveSelfEmployedFullTimeWithEmployees =
      input.id_708_Self_employed_full_time_with_employees
    census.economicActivity.economicallyActiveSelfEmployedPartTimeNoEmployees =
      input.id_709_Self_employed_part_time_no_employees
    census.economicActivity.economicallyActiveSelfEmployedFullTimeNoEmployees =
      input.id_710_Self_employed_full_time_no_employees
    census.economicActivity.economicallyActiveUnemployed = input.id_711_Unemployed
    census.economicActivity.economicallyActiveFullTimeStudent = input.id_712_Student_full_time
    census.economicActivity.economicallyInactiveRetired = input.id_713_Retired
    census.economicActivity.economicallyInactiveStudent = input.id_714_Student
    census.economicActivity.economicallyInactiveCaringForHomeOrFamily = input.id_715_Caring_for_home_or_family
    census.economicActivity.economicallyInactiveLongTermSick = input.id_716_Long_term_sick
    census.economicActivity.economicallyInactiveOther = input.id_717_Other_inactive

    // NEW
    census.householdsByIncomeBand.householdsWithIncome_100k = input.id_528_100k_
    census.householdsByIncomeBand.householdsWithIncome_90_100k = input.id_529_90_100k
    census.householdsByIncomeBand.householdsWithIncome_80_90k = input.id_530_80_90k
    census.householdsByIncomeBand.householdsWithIncome_70_80k = input.id_531_70_80k
    census.householdsByIncomeBand.householdsWithIncome_60_70k = input.id_532_60_70k
    census.householdsByIncomeBand.householdsWithIncome_50_60k = input.id_533_50_60k
    census.householdsByIncomeBand.householdsWithIncome_40_50k = input.id_534_40_50k
    census.householdsByIncomeBand.householdsWithIncome_30_40k = input.id_535_30_40k
    census.householdsByIncomeBand.householdsWithIncome_20_30k = input.id_536_20_30k
    census.householdsByIncomeBand.householdsWithIncome_20k = input.id_537__20k

    // NEW
    census.householdSize.householdSize_1Person = input.id_718_1_person
    census.householdSize.householdSize_2Persons = input.id_719_2_persons
    census.householdSize.householdSize_3Persons = input.id_720_3_persons
    census.householdSize.householdSize_4Persons = input.id_721_4_persons
    census.householdSize.householdSize_5Persons = input.id_722_5_persons
    census.householdSize.householdSize_6Persons = input.id_723_6_persons
    census.householdSize.householdSize_7Persons = input.id_724_7_persons
    census.householdSize.householdSize_8OrMorePersons = input.id_725_8_persons

    census.householdType.househole1_Person = input.id_821_1_Person
    census.householdType.householeMarried_dep_children = input.id_822_Married_dep_children
    census.householdType.householeMarried_no_dep_children = input.id_823_Married_no_dep_children
    census.householdType.householeCivil_partner_dep_children = input.id_824_Civil_partner_dep_children
    census.householdType.householeCivil_partner_no_dep_children = input.id_825_Civil_partner_no_dep_children
    census.householdType.householeCohabiting_dep_children = input.id_826_Cohabiting_dep_children
    census.householdType.householeCohabiting_no_dep_children = input.id_827_Cohabiting_no_dep_children
    census.householdType.householeLone_parent_dep_children = input.id_828_Lone_parent_dep_children
    census.householdType.householeLone_parent_no_dep_children = input.id_829_Lone_parent_no_dep_children
    census.householdType.householeMulti_family_students = input.id_830_Multi_family_students
    census.householdType.householeMulti_family_other = input.id_831_Multi_family_other

    // NEW
    census.outputClassification.farmingCommunities_1a = input.id_639_OAC_1a
    census.outputClassification.ruralTenants_1b = input.id_640_OAC_1b
    census.outputClassification.ageingRuralDwellers_1c = input.id_641_OAC_1c
    census.outputClassification.studentsAroundCampus_2a = input.id_642_OAC_2a
    census.outputClassification.innerCityStudents_2b = input.id_643_OAC_2b
    census.outputClassification.comfortableCosmopolitan_2c = input.id_644_OAC_2c
    census.outputClassification.aspiringAndAffluent_2d = input.id_645_OAC_2d
    census.outputClassification.ethnicFamilyLife_3a = input.id_646_OAC_3a
    census.outputClassification.endeavouringEthnicMix_3b = input.id_647_OAC_3b
    census.outputClassification.ethnicDynamics_3c = input.id_648_OAC_3c
    census.outputClassification.aspirationalTechies_3d = input.id_649_OAC_3d
    census.outputClassification.rentedFamilyLiving_4a = input.id_650_OAC_4a
    census.outputClassification.challengedAsianTerraces_4b = input.id_651_OAC_4b
    census.outputClassification.asianTraits_4c = input.id_652_OAC_4c
    census.outputClassification.urbanProfessionalsAndFamilies_5a = input.id_653_OAC_5a
    census.outputClassification.ageingUrbanLiving_5b = input.id_654_OAC_5b
    census.outputClassification.suburbanAchievers_6a = input.id_655_OAC_6a
    census.outputClassification.semiDetachedSuburbia_6b = input.id_656_OAC_6b
    census.outputClassification.challengedDiversity_7a = input.id_657_OAC_7a
    census.outputClassification.constrainedFlatDwellers_7b = input.id_658_OAC_7b
    census.outputClassification.whiteCommunities_7c = input.id_659_OAC_7c
    census.outputClassification.ageingCityDwellers_7d = input.id_660_OAC_7d
    census.outputClassification.industriousCommunities_8a = input.id_661_OAC_8a
    census.outputClassification.challengedTerracedWorkers_8b = input.id_662_OAC_8b
    census.outputClassification.hardPressedAgeingWorkers_8c = input.id_663_OAC_8c
    census.outputClassification.migrationAndChurn_8d = input.id_664_OAC_8d

    census.healthDayToDay.dayToDayLimitedALot = input.id_740_Day_to_day_limited_a_lot
    census.healthDayToDay.dayToDayLimitedALittle = input.id_741_Day_to_day_limited_a_little
    census.healthDayToDay.dayToDayNotLimited = input.id_742_Day_to_day_not_limited
    census.health.veryGood = input.id_743_Very_good
    census.health.good = input.id_744_Good
    census.health.fair = input.id_745_Fair
    census.health.bad = input.id_746_Bad
    census.health.veryBad = input.id_747_Very_bad

    census.education.education1_4_O_Levels_CSE_GCSE = input.id_693_1_4_O_Levels_CSE_GCSE
    census.education.educationNVQ_Lvl_1_Foundation_GNVQ_basic_skills =
      input.id_694_NVQ_Lvl_1_Foundation_GNVQ_basic_skills
    census.education.education1_A_Level_2_3_AS_Levels_Welsh_Bacc_Interm_Diploma =
      input.id_695_1_A_Level_2_3_AS_Levels_Welsh_Bacc_Interm_Diploma
    census.education.educationNVQ_Lvl_2_Interm_GNVQ_etc = input.id_696_NVQ_Lvl_2_Interm_GNVQ_etc
    census.education.educationApprenticeship = input.id_697_Apprenticeship
    census.education.education2_A_Levels_4_AS_Levels_etc = input.id_698_2_A_Levels_4_AS_Levels_etc
    census.education.educationNVQ_Lvl_3_Adv_GNVQ_etc = input.id_699_NVQ_Lvl_3_Adv_GNVQ_etc
    census.education.educationDegree_BA_BSc_MA_PhD_PGCE = input.id_700_Degree_BA_BSc_MA_PhD_PGCE
    census.education.educationNVQ_Lvl_4_5_HNC_HND_etc = input.id_701_NVQ_Lvl_4_5_HNC_HND_etc
    census.education.educationProfessional_qualifications = input.id_702_Professional_qualifications
    census.education.educationVocational_work_related_quals = input.id_703_Vocational_work_related_quals
    census.education.educationNo_qualifications = input.id_692_No_qualifications
    census.education.educationForeign_quals = input.id_704_Foreign_quals

    // put them back for matchpoint
    // what was wrong? I think it was percent and index made no sense?
    lifestyle.spendSummary.booksCardsStationery = input.Books_Cards_Stationery
    lifestyle.spendSummary.fashionFootwearJewellery = input.Fashion_Footwear_Jewellery
    lifestyle.spendSummary.electricalGoodsMobilePhonesPhotography = input.Electrical_Goods_Mobile_Phones_Photography
    lifestyle.spendSummary.homeImprovementsGardening = input.Home_Improvements_Gardening
    lifestyle.spendSummary.healthBeautyIncOtcPharmacy = input.Floor_Coverings
    lifestyle.spendSummary.musicDvDsGamesToys = input.Home_Furnishings_Gifts
    lifestyle.spendSummary.otherSpectaclesLensesTravelGoodsMotorAccessories = input.Soft_Furnishings_Linens
    lifestyle.spendSummary.eatingOutRestaurantsCafes = input.Health_Beauty_inc_OTC_Pharmacy
    lifestyle.spendSummary.sportsAndCampingEquipment = input.Music_DVDs_Games_Toys
    lifestyle.spendSummary.floorCoverings = input.Other_Spectacles_Lenses_Travel_Goods_Motor_Accessories
    lifestyle.spendSummary.homeFurnishingsGifts = input.Sports_And_Camping_Equipment
    lifestyle.spendSummary.softFurnishingsLinens = input.Eating_Out_Restaurants_Cafes
    lifestyle.spendSummary.leisureCenters = input.Leisure_Centres_Attractions_live_Entertainment_etc
    // was removed until here

    lifestyle.spendSummary.leisure = sumStatisticsValues([
      lifestyle.spend.cinemas,
      lifestyle.spend.leisureCentres,
      lifestyle.spend.clubsDancesdiscoBingo,
      lifestyle.spend.liveEntertainment,
      lifestyle.spend.museumZooThemeParkHouse,
    ])
    lifestyle.spendSummary.foodAndBeverage = sumStatisticsValues([
      lifestyle.spend.eatingOut,
      lifestyle.spend.alcoholOutofHome,
      lifestyle.spend.restaurantandCafemeals,
    ])
  }
}

/* UNASSIGNEd

TENHHTOT01
ETHALLUK01

EMPPOPRT01
SECPOP7401


AGED001501
AGED162401
AGED254401
AGED456401
Aged65PLS01

*/

function decodeAgesPerYear(input: StatisticsGroup): AgesPerYear {
  const result = new AgesPerYear()

  for (let i = 538; i <= 638; i++) {
    const name = STATISTICS_COMBINED_ID_TO_NAME[i]
    result.ages.push(input[name])
  }

  return result
}

function decodeDwellingPerType(input: StatisticsGroup): DwellingTypesPerPrice {
  const result = new DwellingTypesPerPrice()

  function decodePriceBand(startId: number) {
    const bandResult = new DwellingPerPrice()
    DWELLING_PRICE_BAND_KEYS.forEach((key, i) => {
      const name = STATISTICS_COMBINED_ID_TO_NAME[startId + i]
      bandResult[key] = input[name]
    })

    return bandResult
  }

  result.detached = decodePriceBand(931)
  result.semiDetached = decodePriceBand(947)
  result.terraced = decodePriceBand(963)
  result.flat = decodePriceBand(979)

  result.all = new DwellingPerPrice()
  DWELLING_PRICE_BAND_KEYS.forEach((key: keyof DwellingPerPrice) => {
    result.all[key] = sumStatisticsValues([
      result.detached[key],
      result.semiDetached[key],
      result.terraced[key],
      result.flat[key],
    ])
    result.all[key].index /= 4
  })

  return result
}

function decodeDwellingAreaValuePerType(input: StatisticsGroup): DwellingTypesValues {
  const result = new DwellingTypesValues()

  function decodeSingle(idArea: string, idValue: string) {
    const areaValueResult = new DwellingTypesAreaValue()
    areaValueResult.area = input[idArea]
    areaValueResult.value = input[idValue]

    return areaValueResult
  }

  result.detached = decodeSingle('id_995_Detached_total_area', 'id_996_Detached_total_value')
  result.semiDetached = decodeSingle('id_997_Semi_detached_total_area', 'id_998_Semi_detached_total_value')
  result.terraced = decodeSingle('id_999_Terraced_total_area', 'id_1000_Terraced_total_value')
  result.flat = decodeSingle('id_1001_Flat_total_area', 'id_1002_Flat_total_value')
  result.all = decodeSingle('id_1003_Total_area', 'id_1004_Total_value')

  return result
}
