import { Observable ,  Subject } from 'rxjs';
import { MapCellEvent } from '../../mapbox.component.type';
import { PrimaryLayer } from '../abstract-layer';
import { MapLayer } from '../layer';
import { InteractionLayer } from '../interaction-layer';

/**
 * Options for a FeatureInteractionLayer
 */
export class FeatureInteractionLayerOptions {
  /**
   * How to decide if two features are the same (for example when hovering over). If a string is passed
   * the property named as that string is compared. A function can also be passed for custom comparison.
   */
  compareFeature?: string | ((feature1: any, feature2: any) => boolean);
}

function propertyComparison(property: string = 'id') {
  return function isSameFeature(feature1: any, feature2: any) {
    if (feature1 == feature2) {
      return true;
    }

    if (
      feature1 &&
      feature1.properties &&
      feature2 &&
      feature2.properties &&
      feature1.properties[property] == feature2.properties[property]
    ) {
      return true;
    }

    return false;
  };
}

function asArray<T>(value: T | T[]) {
  return  (value instanceof Array) ? value : [value]
}

/**
 * General interactions for hovering over and clicking features of an arbitrary layer
 */
export class FeatureInteractionLayer extends InteractionLayer {
  private _visible: boolean; // FIXME::
  // private hoverLayer: FilterMapLayer

  readonly events: {
    readonly click: Observable<MapCellEvent>;
    readonly hover: Observable<MapCellEvent>;
    // readonly drag: Observable<MapCellEvent>
  } = {
    click: new Subject<MapCellEvent>(),
    hover: new Subject<MapCellEvent>()
    // drag: new Subject<MapCellEvent>(),
  };

  private layers: MapLayer<any>[];
  private options: FeatureInteractionLayerOptions;

  constructor(
    layers: PrimaryLayer<MapLayer<any>> | PrimaryLayer<MapLayer<any>>[],
    options?: FeatureInteractionLayerOptions
  ) {
    super((asArray(layers)[0].getPrimaryLayer() as any).map);

    this.layers = asArray(layers).map(layer => layer.getPrimaryLayer())
    this.options = options || new FeatureInteractionLayerOptions();

    // this.hoverLayer = new FilterMapLayer(this.map, null, null)
    this.map.zone.runOutsideAngular(() => {
      this.initEvents();
    });
  }

  // private fire<U>(target: Observable<U>, event: U) {
  //   (target as Subject<U>).next(event)
  // }

  async initEvents() {
    let hoverFeature: any = null;
    const map = await this.map.getMap();

    let isSameFeature =
      typeof this.options.compareFeature === 'function'
        ? this.options.compareFeature
        : propertyComparison(this.options.compareFeature);

    // TODO: ability to remove events
    map.on('mousemove', (event: any) => {
      if (event.originalEvent.which > 1) {
        return;
      }

      const features = this.queryMapRenderedFeatures(map, event.point, {
        layers: this.layers.map(layer => layer.id)
      });
      if (!isSameFeature(hoverFeature, features[0])) {
        hoverFeature = features[0];

        this.map.angularZoneMouseMove(() => {
          const cellEvent = new MapCellEvent(
            event.lngLat,
            event.point,
            features[0],
            features[0] && features[0].properties
          );
          this.fire(this.events.hover, cellEvent);
        });
      }

      // if (this.model.options.getValue().showCellHover) {
      //   if (features[0]) {
      //     this.map.getSource('stats-hover').setData(features[0])
      //   } else {
      //     this.map.getSource('stats-hover').setData(layers.EMPTY_GEOJSON_DATA)
      //   }
      // }
    });

    map.on('click', (event: any) => {
      const features = this.queryMapRenderedFeatures(map, event.point, {
        layers: this.layers.map(layer => layer.id)
      });
      if (features.length) {
        const cellEvent = new MapCellEvent(
          event.lngLat,
          event.point,
          features[0],
          features[0] && features[0].properties
        );
        this.fire(this.events.click, cellEvent);
      }
    });

    // this.subscriptions.push(this.model.options.pluck('showCellHover').distinctUntilChanged().subscribe(value => {
    //   if (!value) {
    //     this.map.getSource('stats-hover').setData(layers.EMPTY_GEOJSON_DATA)
    //   }
    // }))
  }

  setVisible(value: boolean) {
    this._visible = value;
    // NOTE: maybe have an interaction layer parent class ... or maybe not call it layer
    return this;
  }

  isVisible(): boolean {
    return this._visible;
  }
}
