import { Injectable } from '@angular/core'
import { AppModel } from './appModel.service'
import { Observable } from 'rxjs/Observable'
import { TravelDisplayMode, SettingsModel } from './settingsModel'
import { ZoneLayersModel } from './zoneLayersModel'
import { CatchmentMode } from './matchpoint/combinedSources'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class FeatureModel {
  readonly catchmentMode: Observable<CatchmentMode> = new BehaviorSubject<CatchmentMode>(CatchmentMode.NORMAL)

  readonly travelDisplayModeUpdates: Observable<TravelDisplayMode>
  readonly travelTimesVisibleUpdates: Observable<boolean>
  readonly statisticsTravelVisibleUpdates: Observable<boolean>
  readonly polygonsVisibleUpdates: Observable<boolean>
  readonly zonesLayerVisibleUpdates: Observable<boolean>

  constructor(private settings: SettingsModel, private zoneLayersModel: ZoneLayersModel) {
    this.travelDisplayModeUpdates = this.initTravelDisplayMode()
    this.travelTimesVisibleUpdates = this.initTravelTimesVisibleUpdates()

    this.statisticsTravelVisibleUpdates = this.initStatisticsTravelVisibleUpdates()
    this.polygonsVisibleUpdates = this.initPolygonsVisibleUpdates()

    this.zonesLayerVisibleUpdates = this.initZonesLayerVisibleUpdates()
  }

  private initTravelDisplayMode() {
    return Observable.combineLatest(
      this.settings.travelDisplayModeUpdates,
      this.settings.exclusiveTravelUpdates,
      this.catchmentMode
    )
      .map(([travelDisplayMode, exclusiveTravel, catchmentMode]) => {
        if (catchmentMode) {
          return TravelDisplayMode.MobileCatchment
        } else if (exclusiveTravel) {
          return TravelDisplayMode.ThematicNoPolygons
        } else {
          return travelDisplayMode
        }
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }

  private initTravelTimesVisibleUpdates() {
    return this.zoneLayersModel.selectionExistsUpdates
      .map((value) => !value)
      .distinctUntilChanged()
      .shareReplay(1)
    // return this.settings.showSectorsUpdates.map(value => !value).distinctUntilChanged().shareReplay(1)
  }

  private initPolygonsVisibleUpdates() {
    return Observable.combineLatest(this.travelDisplayModeUpdates, this.travelTimesVisibleUpdates)
      .map(([mode, visible]) => {
        return visible && mode !== TravelDisplayMode.ThematicNoPolygons && mode !== TravelDisplayMode.MobileCatchment
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }

  private initStatisticsTravelVisibleUpdates() {
    return Observable.combineLatest(this.travelDisplayModeUpdates, this.travelTimesVisibleUpdates)
      .map(([mode, visible]) => {
        return visible && mode === TravelDisplayMode.ThematicNoPolygons
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }

  private initZonesLayerVisibleUpdates() {
    return Observable.combineLatest(this.settings.showOnlyUserZonesUpdates, this.zoneLayersModel.editingUpdates)
      .map(([hidden, editing]) => {
        return !hidden || editing
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }
}
