import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http';

function getBasePath() {
  const element = document.querySelector('base')
  return element && element.href || '/'
}

/**
 * Wraps the angular `Http` service to provide some additional functionality.
 *
 * **Usage:**
 * - import `TgmHttpModule`
 * - call `.createInstance(serviceUrl)` with your desired service url
 */
export class ReactiveRESTHelper {
  private _serviceUrl: string = getBasePath() + 'api' // APP_BASE_PATH

  get serviceUrl() {
    return this._serviceUrl
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
  ) {}


  createInstance(serviceUrl?: string): ReactiveRESTHelper {
    const result = new ReactiveRESTHelper(this.http, this.router, this.cookieService)
    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    if (r.test(serviceUrl)) {
      // Absolute URL
      result._serviceUrl = serviceUrl.trim()
    } else {
      // relative URL
      result._serviceUrl = (this._serviceUrl + serviceUrl).trim()
    }
    return result
  }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this._serviceUrl + url, {withCredentials: true})
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this._serviceUrl + url, {withCredentials: true})
  }

  head<T>(url: string): Observable<T> {
    return this.http.head<T>(this._serviceUrl + url, {withCredentials: true})
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(this._serviceUrl + url, data, {withCredentials: true})
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(this._serviceUrl + url, data, {withCredentials: true})
  }

  patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(this._serviceUrl + url, data, {withCredentials: true})
  }

  options<T>(url: string): Observable<T> {
    return this.http.options<T>(this._serviceUrl + url)
  }

}
