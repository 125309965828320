import { Component } from '@angular/core';

/**
 * An actionbar to be displayed vertically on the side. Should contain instances of `tgm-action-bar-button`.
 *
 * `TgmActionBarButton` Should have a `[tgm-icon]` and *can* have a `[tgm-label]` attributed child.
 *
 * Example:
 * ``` html
 * <tgm-action-bar>
 *   <tgm-action-bar-button>
 *     <mat-icon tgm-icon>save</mat-icon>
 *     <span tgm-label>Save</span>
 *   </tgm-action-bar-button>
 *   <tgm-action-bar-button>
 *     <mat-icon tgm-icon>cloud_download</mat-icon>
 *     <span tgm-label>Load</span>
 *   </tgm-action-bar-button>
 * </tgm-action-bar>
 * ```
 *
 * The Bar is currently only available as vertical action bar.
 *
 * It is `60px` wide, and has default coloring, which can be changed with css.
 */
@Component({
  selector: 'tgm-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.less']
})
export class TgmActionBarComponent {
}
