import { StatisticsMapLayer } from '../simple'
import { PrimaryLayer } from '../abstract-layer'
import { FeatureInteractionLayer } from './feature-interaction-layer'

/**
 * Hovering over and clicking features on a statistics layer
 */
// Has been generalizerd to FeatureInteractionLayer,
// but still thinking if there statistics specific features...otherwise will delete this class
export class StatisticsHoverLayer extends FeatureInteractionLayer {
  constructor(layer: PrimaryLayer<StatisticsMapLayer>) {
    super(layer)
  }
}
