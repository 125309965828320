import { Component, ViewChild, OnInit } from '@angular/core'
import { MatMenuTrigger } from '@angular/material'
import { MatDialog } from '@angular/material'
import { UserEndpoint } from '../../api/user'
import { TgmQuickDialogs } from '@targomo/client'
import { User } from '../../api/index'
import { APP_BASE_PATH } from '../../util/basePath'

@Component({
  selector: 'admin-users',
  templateUrl: './adminUsers.html',
  styleUrls: ['./adminUsers.less'],
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('menu') menu: MatMenuTrigger

  basePath = APP_BASE_PATH
  users: any[] = []
  loading: Promise<any[]>

  constructor(private usersEndpoint: UserEndpoint, private dialog: MatDialog, private quickDialogs: TgmQuickDialogs) {}

  ngOnInit() {
    this.loadUsers()
  }

  private async loadUsers() {
    this.loading = this.usersEndpoint.findAll()
    this.users = await this.loading
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async deleteUser(user: User) {
    if (
      await this.quickDialogs.confirm({
        title: 'Delete User',
        message: `Would you like to delete user ${user.name} (${user.username})?`,
        confirm: 'Delete',
        cancel: 'Cancel',
      })
    ) {
      console.log('DELETE', user)
      const result = await this.usersEndpoint.delete(user)
      this.loadUsers()
    }

    // this.dialog.open()
    // var confirm = this.$mdDialog.confirm()
    //               .title(`Would you like to delete user ${user.name} (${user.username})?`)
    //               .ariaLabel('Delete User')
    //               .ok('Delete')
    //               .cancel('Cancel')

    // this.$mdDialog.show(confirm).then(() => {
    //   this.User.delete(user).then(() => this.updateList())
    // }, () => {
    // })
  }
}
