import { CompositeLayer } from '../composite-layer';
import { TgmMapboxComponent } from '../../mapbox.component';
import { MarkerMapLayer } from '../../layers/simple/';
import { LatLngProperties } from '@targomo/core';
import { MarkerMapSource } from '../../sources';
import { PrimaryLayer } from '../abstract-layer';
import { MapLayerPosition } from '../layer';
import { ObservableExpression } from '../../../../../types';

export class ShadowMarkersLayer<T extends LatLngProperties>
  extends CompositeLayer
  implements PrimaryLayer<MarkerMapLayer<T>> {
  private _visible: boolean;
  private shadowLayer: MarkerMapLayer<T>;
  private glowLayer: MarkerMapLayer<T>;

  private markersLayer: MarkerMapLayer<T>;

  constructor(
    map: TgmMapboxComponent,
    source: MarkerMapSource<T>,
    styleObservable?: ObservableExpression<any>
  ) {
    super(map);

    // TODO: fix style stuff
    this.shadowLayer = new MarkerMapLayer<T>(map, source, this.shadowStyle());
    this.markersLayer = new MarkerMapLayer<T>(map, source);
    this.glowLayer = new MarkerMapLayer<T>(map, source, this.glowStyle());

    this.shadowLayer.setPosition(MapLayerPosition.MARKERS_SHADOW);
    this.markersLayer.setPosition(MapLayerPosition.MARKERS);
    this.glowLayer.setPosition(MapLayerPosition.MARKERS_GLOW);
  }

  setVisible(value: boolean) {
    this._visible = value;

    this.shadowLayer.setVisible(value);
    this.glowLayer.setVisible(value);
    this.markersLayer.setVisible(value);

    return this;
  }

  isVisible() {
    return this._visible;
  }

  // TODO: style stuff for now temporary
  private shadowStyle() {
    return {
      'circle-radius': {
        stops: [[5, 1], [25, 28 * 1.5]]
      },
      'circle-blur': 0.5,
      'circle-color': '#000',
      'circle-opacity': {
        stops: [[8, 0], [10, 0.3]]
      },

      'circle-translate': [1, 1]
    };
  }

  private glowStyle() {
    return {
      'circle-radius': {
        stops: [[5, 1], [25, 28 / 2]]
      },
      'circle-translate': {
        stops: [[5, [-1, -1]], [20, [-3, -3]]]
      },
      'circle-blur': 1,
      'circle-stroke-width': 0,
      'circle-color': '#fff',
      'circle-opacity': {
        stops: [[8, 0], [10, 0.2]]
      }
    };
  }

  getPrimaryLayer() {
    return this.markersLayer;
  }
}
