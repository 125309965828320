import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transitTypeToColor'
})
export class TransitTypeToColorPipe implements PipeTransform {
    transform(routeType: number | string): any {

        if (routeType === 700 || routeType === 'BUS') {
            return '#A3007C';
        }

        if (routeType === 900 || routeType === 0 || routeType === 'TRAM') {
            return 'red';
        }

        if (routeType === 109 || routeType === 'SBAHN') {
            return '#006F35';
        }

        if (routeType === 100 || routeType === 'BAHN') {
            return 'black';
        }

        if (routeType === 700 || routeType === 3) {
            return '#A3007C';
        }

        if (routeType === 400 || routeType === 'UBAHN') {
            return '#156ab8';
        }

        if (routeType === 3) {
            return '#A3007C';
        }

        if (routeType === 1) {
            return '#156ab8';
        }

        if (routeType === 4) {
            return '#0089c8';
        }

        if (routeType === 1000) {
            return 'blue';
        }
    }
}
