import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { TgmLoadableComponent } from './loadable.component';
import { NgModule } from '@angular/core'


@NgModule({
  declarations: [
    TgmLoadableComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FlexLayoutModule
  ],
  exports: [
    TgmLoadableComponent
  ]
})
export class TgmLoadableModule { }
