import { CompositeLayer, DefaultMapLayer, MapLayerPosition, TileMapSource } from '@targomo/client'
import { interpolateRdYlGn } from 'd3-scale-chromatic'
import { map } from 'rxjs/operators'
import { MobileCatchmentsEndpoint } from '../../api/mobileCatchments'
import { GREAT_BRITAIN_WORKFORCE } from '../../model/constants'
import { CombinedSources } from '../../model/matchpoint/combinedSources'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

export const GREAT_BRITAIN_WORKFORCE_VISUAL = GREAT_BRITAIN_WORKFORCE + 3

export class MobileCatchmentsComposite extends CompositeLayer {
  constructor(
    map: CustomMapboxComponent,
    private sources: CombinedSources,
    private activeField: string,
    private meta: { min: number; max: number },
    private mobileCatchmentsEndpoint: MobileCatchmentsEndpoint
  ) {
    super(map as any)

    this.init()
  }

  private init() {
    const sourceMapUrl = this.sources.mobileSource$.pipe(
      map((source) => {
        return source && this.mobileCatchmentsEndpoint.tiles(source, this.activeField)
      })
    )

    const source = new TileMapSource(sourceMapUrl)
    const colorSteps = [this.meta.min || 0, interpolateRdYlGn(1), this.meta.max || 1, interpolateRdYlGn(0)]
    const options = this.sources.mobileSource$.pipe(
      map((source) => {
        return {
          type: 'fill',
          ...(source ? { sourceLayer: '0' } : {}),
          paint: {
            'fill-color':
              this.meta.min === this.meta.max
                ? interpolateRdYlGn(0)
                : ['interpolate', ['linear'], ['get', 'visits'], ...colorSteps],

            'fill-opacity': 0.8,
          },
        }
      })
    )

    const layer = new DefaultMapLayer(this.map, source, options).setPosition(MapLayerPosition.BELOW_MARKERS)
    this.remember(
      this.sources.mobileSource$.subscribe((source) => {
        layer.setVisible(!!source)
      })
    )

    return layer
  }

  setVisible(value: boolean): this {
    throw new Error('Method not implemented.')
  }
  isVisible(): boolean {
    throw new Error('Method not implemented.')
  }
}
