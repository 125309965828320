import { ObservableList } from '@targomo/client'
import { AbstractLocation } from '..'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map, debounceTime, shareReplay } from 'rxjs/operators'
import { CatchmentMode } from '../../../common/models/statistics/statistics'
export { CatchmentMode } from '../../../common/models/statistics/statistics'

export class CombinedSources extends ObservableList<AbstractLocation> {
  readonly mobileSource$: Observable<AbstractLocation>
  readonly observableCombined: Observable<AbstractLocation[]>

  constructor(private catchmentMode: Observable<CatchmentMode>) {
    super()

    this.observableCombined = this.observable

    this.mobileSource$ = combineLatest(this.observable, this.catchmentMode).pipe(
      debounceTime(0),
      map(([sources, mode]) => {
        if (mode !== CatchmentMode.MOBILE) {
          return null
        } else {
          return sources && sources[0]
        }
      }),
      shareReplay(1)
    )
    ;(this as any).observable = combineLatest(this.observable, this.catchmentMode).pipe(
      debounceTime(0),
      map(([sources, mode]) => {
        if (mode !== CatchmentMode.MOBILE) {
          return sources
        } else {
          return []
        }
      }),
      shareReplay(1)
    )
  }

  updatePointAndClick(source: AbstractLocation) {
    this.remove(this.find((item) => item.isTemporaryCustom()))
    if (source) {
      this.add(source)
    }
  }

  updateMobileCatchment(source: AbstractLocation) {
    this.clear()

    if (source) {
      super.add(source)
      this.setCatchmentMode(CatchmentMode.MOBILE)
    }
  }

  private setCatchmentMode(mode: CatchmentMode = CatchmentMode.NORMAL) {
    ;(this.catchmentMode as BehaviorSubject<CatchmentMode>).next(mode)
  }

  add(value: AbstractLocation) {
    this.setCatchmentMode()
    return super.add(value)
  }

  addAll(values: AbstractLocation[]) {
    this.setCatchmentMode()
    return super.addAll(values)
  }

  remove(value: AbstractLocation) {
    this.setCatchmentMode()
    return super.remove(value)
  }

  removeAll(values: AbstractLocation[]) {
    this.setCatchmentMode()
    return super.removeAll(values)
  }

  merge(value: AbstractLocation, status: boolean) {
    this.setCatchmentMode()
    return super.merge(value, status)
  }

  toggle(value: AbstractLocation) {
    this.setCatchmentMode()
    return super.toggle(value)
  }

  update(values: AbstractLocation[]) {
    this.setCatchmentMode()
    return super.update(values)
  }

  clear() {
    this.setCatchmentMode()
    return super.clear()
  }
}
