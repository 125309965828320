import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { LatLngProperties } from '@targomo/core';


@Component({
  selector: 'tgm-location-details-panel',
  templateUrl: './location-details-panel.component.html',
  styleUrls: ['./location-details-panel.component.scss']
})
export class TgmLocationDetailsPanelComponent implements OnChanges {

  activeItemDetails: { label: any; value: any; }[];
  activeItem: LatLngProperties;
  showDetail: boolean;
  details: { label: string; value: any; }[];
  location: LatLngProperties;
  filterKey: string;

  @Input() locations: LatLngProperties[];

  @Output() showListDialog = new EventEmitter()

  ngOnChanges(): void {
    if (this.locations.length === 1) {
      this.location = this.locations[0];
      this.details = this.getDetails(this.location);
    }
  }

  getDetails(location: LatLngProperties) {

    if (!location.properties || !location.properties.details) {
      return [];
    }

    const detailKeys = Object.keys(location.properties.details);

    return detailKeys.map((key: any) => {
      return {
        label: key,
        value: location.properties.details[key]
      }
    })
  }

  showDetailOfLocation(location: LatLngProperties): void {
    this.showDetail = true;
    this.activeItem = location;
    this.activeItemDetails = this.getDetails(this.activeItem);
  }

  closeDetail(): void {
    this.showDetail = false;
  }

  showMoreLocations(): void {
    this.showListDialog.emit();
  }
}
