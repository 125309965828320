import { TgmActionItemsComponent } from './action-items.component';
import { TgmActionItemComponent } from './action-item/action-item.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    TgmActionItemsComponent,
    TgmActionItemComponent
  ],
  exports: [
    TgmActionItemsComponent,
    TgmActionItemComponent
  ]
})
export class TgmActionItemsModule { }
