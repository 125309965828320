import { Component, Input } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'

/**
 * A coomponent that includes the angular2 router and also hides the initial 'loading' screen once the app is loaded
 */
@Component({
  selector: 'tgm-main-router',
  styleUrls: ['./main-router.component.less'],
  templateUrl: './main-router.component.html'
})
export class TgmMainRouterComponent {
  @Input() type: 'bar' | 'spinner' = 'bar'

  constructor(router: Router) {
    router.events.subscribe((value) => {
      function removeLoading(): void {
        const loadingElement = document.querySelector('.app-loading')
        if (loadingElement) {
          loadingElement.classList.add('app-loading-done')
          setTimeout(() => {
            if (loadingElement.parentNode !== null) {
              loadingElement.parentNode.removeChild(loadingElement)
            }
          }, 1100)
        }
      }

      if (value instanceof NavigationEnd) {
        setTimeout(removeLoading, 100)
      }
    })
  }
}
