import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core'
import { LatLngProperties, StatisticsKey, LabelStatisticsKey } from '@targomo/core'
import { Subject ,  Subscription } from 'rxjs';

/**
 * Display a list of locations statistics as a table
 */
@Component({
  selector: 'tgm-locationtable',
  styleUrls: ['./location-table.component.less'],
  templateUrl: './location-table.component.html'
})
export class TgmLocationTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: LatLngProperties[]
  @Input() columns: LabelStatisticsKey[]
  @Input() currentSorting: StatisticsKey
  @Input() inverseSorting = false
  @Input() changes: Subject<{}>
  @Input() format: (key: string, value: any) => string

  sources: { value: any, display: string }[][]
  private currentIndex = 0
  private subscription: Subscription

  constructor() {
  }

  private init() {
    this.sources = this.model.map(location => {
      return this.columns.map(statistic => {
        const value = location.properties[statistic.name]

        if (this.format) {
          return { value, display: this.format(statistic.name, value) }
        } if (typeof value == 'number') {
          return { value, display: value.toFixed(0) }
        } else {
          return { value, display: value }
        }
      })
    })

    if (this.currentIndex >= this.columns.length) {
      this.currentIndex = 0
    }

    this.runSort()
  }

  ngOnInit() {
    this.init()
    this.subscription = this.changes && this.changes.subscribe(() => this.init())

    if (!this.currentSorting) {
      this.currentSorting = this.columns[0]
    }
  }

  ngOnChanges(changes: any) {
    if (changes.columns) {
      this.init()
    }

    if (this.currentSorting) {
      this.sortByColumn(this.currentSorting)
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
     this.subscription.unsubscribe()
    }
  }

  private runSort() {
    if (!this.sources) {
      return
    }

    this.sources.sort((a, b) => {
      const av = a[this.currentIndex].value
      const bv = b[this.currentIndex].value

      let comparison = 0
      if (av < bv) {
        comparison = -1
      } else if (av > bv) {
        comparison = 1
           }

      return this.inverseSorting ? -comparison : comparison
    })
  }

  sortByColumn(statisticKey: StatisticsKey) {
    if (this.currentSorting.name == statisticKey.name) {
      this.inverseSorting = !this.inverseSorting
    }

    this.currentSorting = statisticKey
    this.columns.forEach((statistic, index) => {
      if (statistic.name == this.currentSorting.name) {
        this.currentIndex = index
      }
    })

    this.runSort()
  }
}
