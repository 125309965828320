import {Observable} from 'rxjs'

// Custom rxjs operators
export namespace rxjsOperators {
  export const latestValue = () => <T>(source: Observable<Promise<T>>) =>
    new Observable<T>(observer => {
      let lastPromise: Promise<T> = null
      return source.subscribe({
        async next(promise) {
          lastPromise = promise
          const result = await promise
          if (lastPromise === promise) { // no update in the meatime
            observer.next(result)
          }
        },
        error(err) {observer.error(err)},
        complete() {observer.complete()}
      })
    })
}
