import { Directive, ElementRef, Input, AfterViewInit, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core'

/**
 * A directive that adds a file open dialog to anything
 */
@Directive({ selector: '[tgmFileSelect], [tgm-file-select]' })
export class TgmFileSelectDirective implements AfterViewInit, OnDestroy {

  elementHash: number
  @Input() filter: string = '.csv,.json,.geojson'
  @Output() selected = new EventEmitter<any>()

  constructor(private elementRef: ElementRef) {
    this.elementHash = Math.floor(Math.random() * 1000000)
  }

  ngAfterViewInit() {
    const element: Element = this.elementRef.nativeElement

    this.elementRef.nativeElement.innerHTML += `<form>
      <input name="file${this.elementHash}" style="display:none" type="file" accept="${this.filter}" />
    </form>`;

    const fileElement: HTMLInputElement = element.querySelector(`[name=file${this.elementHash}]`)
    fileElement.addEventListener<'change'>('change', (event) => {
      this.selectFiles((<HTMLInputElement>event.target).files)
    })

    const bodyElement = document.querySelector('body')
    // Prevent accidental drops outside of box from causing unwanted navigation
    bodyElement.addEventListener('drop', this.cancel, false)
    bodyElement.addEventListener('dragover', this.cancel, false)
  }

  @HostListener('click') onClick() {
    this.openFileDialog()
  }

  @HostListener('dragover') ondragover() {
    return false
  }

  @HostListener('dragenter') onDragenter() {
    this.elementRef.nativeElement.classList.add('drag-hover')
    return false
  }

  @HostListener('dragleave') onDragleave() {
    this.elementRef.nativeElement.classList.remove('drag-hover')
    return false
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    this.elementRef.nativeElement.classList.remove('drag-hover')
    this.selectFiles(event.dataTransfer.files)
    return false
  }

  selectFiles(fileList: FileList) {
    const files: File[] = []
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i])
    }
    this.selected.emit(files)
  }

  openFileDialog() {
    this.elementRef.nativeElement
      .querySelector(`[name=file${this.elementHash}]`)
      .click()
  }

  clearSelection(event?: any) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.selected.emit(null)
  }

  ngOnDestroy() {
    const bodyElement = document.querySelector('body')
    bodyElement.removeEventListener('drop', this.cancel, false)
    bodyElement.removeEventListener('dragover', this.cancel, false)
  }

  private cancel(event: any) {
    event.stopPropagation()
    event.preventDefault()
    return false
  }


}
