import { AbstractSource } from '../sources/abstract-source'
import { TgmMapboxComponent } from '../mapbox.component'
import { AbstractLayer, PrimaryLayer } from './abstract-layer'
import { Layer } from 'mapbox-gl';

export enum MapLayerPosition {
  BASE = 'map-all-statistics',
  STATISTICS = 'map-statistics',
  TRAVEL = 'map-travel',
  POLYGONS = 'map-polygons',
  MARKERS_SHADOW = 'map-markers-shadow',
  MARKERS = 'map-markers',
  MARKERS_GLOW = 'map-markers-glow',
  SOURCES = 'map-sources',
  HOVER = 'map-hover',
  BELOW_MARKERS = 'map-below-markers',
  ABOVE_HOVER = 'above-map-hover',
  BELOW_LABELS = 'map-below-labels',
}

let counterId = 0

function isPrimaryLayer<T>(value: any): value is PrimaryLayer<T> {
  return value && !!value.getPrimaryLayer
}

/**
 * A custom user layer that can be added to the map. There are a number of predefined MapLayer subclasse but
 * to crate your own you subclass MapLayer and overriding the `get` method, which should return a mapbox-gl layer object.
 * The `id` and `source` will be added automatically (the source based on the MapSource you provide)
 */
export class MapLayer<U extends AbstractSource> extends AbstractLayer implements PrimaryLayer<MapLayer<U>> {
  private _id: string = null
  private layerPosition: string = undefined
  private _visible: boolean = true
  private _removed: boolean = false

  // readonly events: Subject<MapLayerEvent> = new Rx.Subject<MapLayerEvent>()
  readonly invalid: boolean = true

  /**
   *
   * @param map The map component to which this layer should be added
   * @param source The source for this layer
   */
  constructor(protected map: TgmMapboxComponent, protected source?: U) {
    super(map)

    if (source) {
      (source as any).addToMap(map)
    }

    this.remember(map.onLayersReload.subscribe(() => this.update()))
    this.update()
  }

  /**
   * Sets the z-index position of this layer relative to other layers, the parameter is either one of a number of predefined constants
   * or another layer. The new position of this layer will be just below the given parameter
   *
   * @param position
   */
  setPosition(position: MapLayerPosition | PrimaryLayer<MapLayer<any>>): this {
    if (isPrimaryLayer(position)) {
      this.layerPosition = position.getPrimaryLayer().id
    } else {
      this.layerPosition = position
    }

    this.update(map => {
      map.moveLayer(this.id, this.layerPosition)
    })

    return this
  }

  /**
   * Refreshes the layer. This methid is called both internally in response to data changes, but can also be called manually.
   * Concretely what this method does is the following:
   *
   * If the layer does not exist yet in the mapbox component if will add the layer to mapbox (based on the definition return by
   * the `getMapBoxObject()` method)
   *
   * If the layer exists in the mapbox component then if not `callback` parameter is given the layer is removend and re-added,
   * otherwise if a `callback` parameter was supplied it is called and expected to handle any concrete steps to update the layer
   *
   * Basically the callback parameter allows for fine-grained control of actual updates to the layer for performance optimization
   *
   * @param callback
   */
  update(callback?: (map: mapboxgl.Map, layerObject?: any) => void) {
    return new Promise<void>(resolve => {
      if (!this.map) {
        resolve()
        return
      }

      this.map.styleReady.add(async () => {
        const map = await this.map.getMap()
        await this.getSource().waitReady()

        if (this._removed) {
          return
        }

        const layerId = this.id
        let mapLayer = map.getLayer(layerId)
        const layerObject = this.getMapBoxObject()

        if (!layerObject) {
          if (mapLayer) {
            map.removeLayer(this.id)
          }
        } else {
          if (mapLayer && callback) {
            callback(map, layerObject)
            resolve()
            return
          } else if (!callback && mapLayer) {
            map.removeLayer(this.id)
          }

          map.addLayer(layerObject, this.getPosition())
        }
      })
    })
  }

  /**
   * This is the most significant method of this class, it return a mapbox-gl layer definition object
   * When you subclass this class to create your own layer you must implement this method and it should
   * return a mapbox layer definition object (see mapbox documentation). Some properties will be generated by default and
   * overriden if you privide them, such as `id` , `source` and `layout.visibility`
   */
  get(): Partial<Layer> {
    return {}
  }

  /**
   * Returns ths MapSource associated with this MapLayer
   */
  getSource() {
    return this.source
  }

  /**
   * Set if the layer should be visible or invisible
   *
   */
  setVisible(visible: boolean) {
    this._visible = visible

    this.update(map => {
      map.setLayoutProperty(this.id, 'visibility', visible ? 'visible' : 'none')
    })

    return this
  }

  /**
   * Returns where the layer is currently visible or invisible
   */
  isVisible() {
    return this._visible
  }


  /**
   * Returns the mapbox id of this layer, the id is autogenerated
   */
  get id() {
    if (!this._id) {
      this._id = `${this.constructor.name}_${counterId++}`
    }

    return this._id
  }

  /**
   * @hidden
   */
  getMapBoxObject() {
    const result: any = this.get()

    if (result) {
      result.id = this.id

      result.source = this.source && this.source.id

      result.layout = result.layout || {}
      result.layout.visibility = this._visible ? 'visible' : 'none'
    }

    return result
  }

  getPosition() {
    return this.layerPosition
  }

  /**
   * Implements the PrimaryLayer getPrimaryLayer() method, for details see the PrimaryLayer interface
   */
  getPrimaryLayer(): this {
    return this
  }

  remove(removeSource: boolean = false) {
    if (this._removed) {
      return
    }

    this._removed = true
    this.map.styleReady.add(async () => {
      const map = await this.map.getMap()

      if (map.getLayer(this.id)) {
        map.removeLayer(this.id)
      }

      // TODO: count one down for source too

      this.onRemove()

      if (removeSource && this.source) {
        this.source.removeIfUnused()
      }
    })
  }
}

