import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material';
import { TgmSaveableComponent } from './saveable.component';
import { NgModule } from '@angular/core'


@NgModule({
  declarations: [
    TgmSaveableComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  exports: [
    TgmSaveableComponent
  ]
})
export class TgmSaveableModule { }
