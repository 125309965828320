
import {MapLayer} from '../layer'
import { LatLngProperties } from '@targomo/core'
import { TgmMapboxComponent } from '../../mapbox.component'
import { MarkerMapSource } from '../../sources';
import { ObservableExpression } from '../../../../../types';
import { Layer } from 'mapbox-gl';

export interface MarkerLabelsLayerOptions {
  paint?: any
  layout?: any // TODO:have moer specific params
  minzoom?: number,
  maxzoom?: number,
  filter?: string[]
  property?: string
}

/**
 * A layer representing text labels that correspond to markers. The text to display is taked by default from the `name` property of the
 * marker LatLngProprties but can be configured
 */
export class MarkerLabelsLayer<T extends LatLngProperties> extends MapLayer<MarkerMapSource<T>> {
  private options: MarkerLabelsLayerOptions = {}

  constructor(
    protected map: TgmMapboxComponent,
    source: ObservableExpression<T[]> | MarkerMapSource<T>,
    optionsObservable?: ObservableExpression<MarkerLabelsLayerOptions>
  ) {
    super(map, source instanceof MarkerMapSource ? source : new MarkerMapSource(source))

    if (optionsObservable) {
      this.watch(optionsObservable, style => {
        this.options = style || {}
        this.update()
      })
    }
  }

  get(): Partial<Layer> {
    return {
      type: 'symbol',
      minzoom: this.options.minzoom != null ? this.options.minzoom : 7,
      maxzoom: this.options.maxzoom != null ? this.options.maxzoom : 24,
      filter: this.options.filter || ['all'],
      layout: this.options.layout || {
        'symbol-placement': 'point',
        'text-field': this.options.property ? `{${this.options.property}}` : '{name}',
        'text-allow-overlap': false,
        'symbol-avoid-edges': false,
        'text-size': 12,
        'text-anchor' : 'left',
        'text-offset' : [1, 0]
      },
      paint: this.options.paint || {
        'text-color': '#ffffff',
        'text-halo-color': '#555555',
        'text-halo-width': 1,
      }
    }
  }
}
