
import {from as observableFrom, combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {debounceTime, pluck, distinctUntilChanged, map, merge} from 'rxjs/operators';
import { CompositeLayer } from '../composite-layer';
import { StatisticsMapLayer, StatisticsMapLayerOptions } from '../simple/statistics-layer';
import { StatisticsHoverLayer } from '../interaction/statistics-hover-layer';
import { TgmMapboxComponent } from '../../mapbox.component';
import { GeojsonMapSource } from '../../sources/geojson-source';
import { DefaultMapLayer } from '../simple/default-layer';
import { StatisticsMapSource } from '../../sources/statistics-source';

import { EMPTY_GEOJSON_DATA } from '../../constants';
import { TargomoClient, StatisticsKey, StatisticsSet } from '@targomo/core';
import { PrimaryLayer } from '../abstract-layer';
import { ObservableExpression, MapboxLayerType } from '../../../../../types';
import { MapLayerPosition, MapLayer } from '../../../../../lib/map/mapbox';

export interface DefaultStatisticsLayerOptions {
  statistic: StatisticsKey;
  statistics: StatisticsKey[];
  cellHover?: boolean;
  interpolator: string;
  grayscale?: boolean;

  style?: any;
  opacity?: number;
  layerType?: MapboxLayerType;

  getGroupForZoom(zoom: number): StatisticsSet;
}

/**
 * An extended StatisticsMapLayer that adds commonly used features such as:
 * - changing the statistics based on zoom level changes
 * - a grayscale inverted mode
 * - hovering events and display of hovered cell
 */
export class DefaultStatisticsLayer extends CompositeLayer {
  private _visible: boolean;
  // private ready: Promise<any>

  layer: StatisticsMapLayer;
  interaction: StatisticsHoverLayer;
  group: Observable<StatisticsSet>;

  private statistics: Observable<StatisticsKey[]>;
  private showCellHover: Observable<boolean>;

  private options: Observable<DefaultStatisticsLayerOptions>;

  constructor(
    mapboxComponent: TgmMapboxComponent,
    private client: TargomoClient,
    options: ObservableExpression<DefaultStatisticsLayerOptions>
  ) {
    super(mapboxComponent);

    this.options = this.toObservable(options);

    this.statistics = this.options.pipe(
      pluck<any, StatisticsKey[]>('statistics'),
      distinctUntilChanged() );
    this.showCellHover = this.options.pipe(
      pluck<any, boolean>('cellHover'),
      distinctUntilChanged() );

    const { layer, interaction } = this.init();

    this.layer = layer;
    this.interaction = interaction;
  }

  init() {
    // Change statistics group on map zoom, other layers that depend on this such as travel layer
    // will pick up the change automatically from here
    const groupObservable = (this.group = observableCombineLatest(
      observableFrom([this.map.getZoom()]).pipe(merge(
        this.map.events.move.pipe(map(event => event.zoom))
      )),
      this.options
    ).pipe(
      map(([zoom, options]) => {
        if (options && options.getGroupForZoom) {
          return options.getGroupForZoom(zoom);
        } else {
          return null;
        }
      }),
      distinctUntilChanged() ));

    // List of all statistics to fetch for this source
    const source = new StatisticsMapSource(
      this.client,
      groupObservable,
      this.statistics
    );

    // Basically updates visible map statistic
    const layerOptions: Observable<StatisticsMapLayerOptions> = observableCombineLatest(
      this.options.pipe(
        pluck<any, StatisticsKey>('statistic'),
        distinctUntilChanged() ),
      this.options.pipe(pluck<any, string>('interpolator'), distinctUntilChanged() ),
      this.options.pipe(pluck<any, boolean>('grayscale'), distinctUntilChanged() ),
      this.options.pipe(pluck<any, string>('style'), distinctUntilChanged() ),
      this.options.pipe(pluck<any, number>('opacity'), distinctUntilChanged() ),
      this.options.pipe(pluck<any, MapboxLayerType>('layerType'), distinctUntilChanged() )
    ).pipe(map(([statistic, interpolator, grayscale, style, opacity, layerType]) => {
      return {
        statistic,
        statisticsStyle: style,
        statisticsOpacity: opacity,
        layerType,
        interpolator: grayscale ? 'interpolateGreys' : interpolator
      };
    }));

    const layer = new StatisticsMapLayer(this.map, source, layerOptions);
    const interaction = new StatisticsHoverLayer(layer);

    this.initHover(interaction);

    return { layer, interaction };
  }

  /**
   * In cell hover mode highlight current cell
   */
  private initHover(interaction: StatisticsHoverLayer) {
    const hoverSource = new GeojsonMapSource(
      observableCombineLatest(interaction.events.hover, this.showCellHover).pipe(
        map(([event, showHover]) => {
          if (!showHover || !event.feature) {
            return EMPTY_GEOJSON_DATA;
          } else {
            return event.feature;
          }
        }),
        distinctUntilChanged(),
        debounceTime(10))
    );

    let hoverLayerConfig = {
      type: 'fill',
      paint: {
        'fill-opacity': 1,
        'fill-color': 'rgba(255, 255, 0, 0.7)',
        'fill-outline-color': 'rgba(255, 255, 0, 1)'
      }
    };

    return new DefaultMapLayer(this.map, hoverSource, hoverLayerConfig);
  }

  setPosition(position: MapLayerPosition | PrimaryLayer<MapLayer<any>>): this {
    this.layer.setPosition(position);
    return this;
  }

  setVisible(value: boolean) {
    this._visible = value;
    this.layer.setVisible(value);

    return this;
  }

  isVisible() {
    return this._visible;
  }
}
