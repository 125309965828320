import {Directive, ElementRef, Input, OnInit} from '@angular/core'
import {Auth} from './auth.service';

/**
 * A directive that shows or hides elements based on the logged in users role
 *
 * Inputs:
 * @hasAuthority the required user role level
 */
@Directive({selector: '[hasAuthority]'})
export class HasAuthorityDirective implements OnInit {
  @Input() hasAuthority: string

  private originalDisplay: any

  constructor(private element: ElementRef, private auth: Auth) {
    this.originalDisplay = this.element.nativeElement.style.display
    this.element.nativeElement.style.display = 'none'
  }

  async ngOnInit() {
    if (await this.auth.hasRole(this.hasAuthority)) {
      this.element.nativeElement.style.display = this.originalDisplay
    }
  }
}
