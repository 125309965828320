import { MapSource } from './source';
import { EMPTY_GEOJSON_DATA } from '../constants';
import { ObservableExpression } from '../../../../types';

export interface GeojsonMapSourceOptions {
  maxzoom?: number;
  buffer?: number;
  tolerance?: number;
  cluster?: boolean;
  clusterRadius?: number;
}

/**
 * A geojson source for a mapbox layer, retuns the data given to it's constructor as the `data` part for a mapbox geojson source
 */
export class GeojsonMapSource extends MapSource<{}> {
  private options: any = {};

  constructor(
    input: ObservableExpression<{}>,
    optionsExpression: ObservableExpression<GeojsonMapSourceOptions> = {}
  ) {
    super(input);

    this.watch(optionsExpression, options => {
      this.options = options || {};
      this.update();
    });
  }

  updateValue(value?: {}) {
    if (value !== undefined) {
      this.value = value;
    }

    this.update(map => {
      (map.getSource(this.id) as any).setData(this.value || EMPTY_GEOJSON_DATA);
    });
  }

  get(data: {}) {
    let result: any = null;
    const options = this.options;

    if (data) {
      result = {
        type: 'geojson',
        data: data
      };
    } else {
      result = super.get(data);
    }

    if (options.maxzoom !== undefined) {
      result.maxzoom = options.maxzoom;
    }

    if (options.buffer !== undefined) {
      result.buffer = options.buffer;
    }

    if (options.tolerance !== undefined) {
      result.tolerance = options.tolerance;
    }

    if (options.cluster !== undefined) {
      result.cluster = options.cluster;
    }

    if (options.clusterRadius !== undefined) {
      result.clusterRadius = options.clusterRadius;
    }

    return result;
  }
}
