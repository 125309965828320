import { Injectable } from '@angular/core'
import { RESTHelper, getBasePath } from '@targomo/client'
import { VersionsEndpoint } from './types'
import { AbstractLocation } from '../model'

export interface PostcodeAnalysisReportGroup {
  title: string
  travelTime: number
  categories: { name: string; percent: number }[]
}

@Injectable()
export class MobileCatchmentsEndpoint implements VersionsEndpoint<any, any> {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/mobileCatchments')
  }

  exists(place: AbstractLocation) {
    if (!place) {
      return null
    }

    const storeId = place.storeId
    return this.http.get<boolean>(`/exists/` + storeId)
  }

  meta() {
    return this.http.get<{ min: number; max: number }>(`/meta`)
  }

  categories() {
    return this.http.get<{ category: string }[]>(`/categories`)
  }

  tiles(place: AbstractLocation, activeField: string) {
    if (!place) {
      return null
    }

    const storeId = place.storeId
    return place && getBasePath() + `api/mobileCatchments/tiles/${activeField}/${storeId}/{z}/{x}/{y}.mvt`
  }

  catchment(place: AbstractLocation) {
    if (!place) {
      return null
    }

    const storeId = place.storeId
    return this.http.get<{ statistics: Record<number, number>[] }>(`/catchment/` + storeId)
  }

  uploadMultipart(filesHex: any, filesStats: any) {
    const data = new FormData()
    data.append('filesHex', filesHex[0])
    data.append('filesStats', filesStats[0])
    return this.http.post<number>(`/upload/`, data)
  }

  async get(entity: { id: number }): Promise<any> {}

  async delete(set: { id: number }): Promise<any> {}

  async getVersions(set: { id: number }): Promise<any> {
    return this.http.get<any[]>(`/versions`)
  }

  async saveActive(version: { id: number }, value?: boolean): Promise<any> {
    return this.http.put<any[]>(`/versions/${version.id}/active/${value}`, {})
  }
  async savePreview(version: { id: number }, value?: boolean): Promise<any> {
    return this.http.put<any[]>(`/versions/${version.id}/preview/${value}`, {})
  }

  async deleteVersion(version: { id: number }): Promise<any> {
    return this.http.delete<any[]>(`/versions/${version.id}`)
  }
}
