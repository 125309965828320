import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { TravelType } from '@targomo/core';

@Component({
  selector: 'tgm-traveltype-select',
  templateUrl: './traveltype-select.component.html',
  styleUrls: ['./traveltype-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmTraveltypeSelectComponent {
  @Input() value: TravelType = 'car';
  @Output() change = new EventEmitter<TravelType>();

  changeTravelType(travelType: TravelType) {
    this.value = travelType;
    this.change.emit(travelType)
  }
}
