import { Pipe, PipeTransform } from '@angular/core';

export interface Address {
  street: string;
  number?: string;
  city: string;
  postalcode: string;
  state?: string;
  country?: string;
}

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  /**
   * Transforms an address object in to a string
   *
   * @param value This is the adress object
   * @param mode Either 'short' or 'full'
   */
  transform(value: Address, mode?: 'short' | 'full'): any {
    if (!value) {
      return undefined;
    }
    let result = '';

    result += value.street ? value.street : '';
    result += value.number ? (' ' + value.number) : '';

    if (mode !== 'short') {
      result += value.postalcode ? (' ' + value.postalcode) : '';
      result += value.city ? (' ' + value.city) : '';
    }

    if (mode === 'full') {
      result += value.state ? (' ' + value.state) : '';
      result += value.country ? (' ' + value.country) : '';
    }

    return result;
  }

}
