import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule, MatIconModule, MatProgressBarModule } from '@angular/material';

import { TgmGenericPanelComponent } from './generic-panel.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    TgmGenericPanelComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    FlexLayoutModule,
    MatProgressBarModule
    // NO browseranimations module here. this needs to go in app module in the app
  ],
  exports: [
    TgmGenericPanelComponent
  ]
})
export class TgmGenericPanelModule { }
