import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateTimePickerComponent } from './date-time-picker.component';
import { DateTimePickerDialogComponent } from './dialog/date-time-picker-dialog.component';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { TodayPipe } from './today.pipe';
import { DateTimePickerService } from './date-time-picker.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [ DatePipe, DateTimePickerService ],
  declarations: [ DateTimePickerComponent, DateTimePickerDialogComponent, TodayPipe ],
  entryComponents: [ DateTimePickerDialogComponent ],
  exports: [ DateTimePickerComponent, DateTimePickerDialogComponent ]
})
export class DateTimePickerModule { }
