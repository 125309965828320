import { Component, Input, OnInit, OnChanges, Inject, Output, EventEmitter } from '@angular/core'
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { DOCUMENT } from '@angular/common';

/**
 *
 */
@Component({
  selector: 'tgm-speeddial',
  templateUrl: './speed-dial.component.html',
  styleUrls: ['./speed-dial.component.less']
})
export class TgmSpeeddialComponent implements OnInit, OnChanges, OnDestroy {
  @Input() open = false
  @Input() icon: string
  @Input() label: string

  @Output() change = new EventEmitter()

  openState = false
  animate = false

  constructor(@Inject(DOCUMENT) private document: any) {
    this.document.addEventListener('click', this.clickOutside)
  }

  setOpen(open: boolean) {
    this.open = open
    this.updateState()
  }

  ngOnInit() {
    this.ngOnChanges()
  }

  ngOnDestroy() {
    this.document.removeEventListener('click', this.clickOutside)
  }

  ngOnChanges() {
    if (this.openState != this.open) {
      this.openState = this.open
      this.updateState()
    }
  }

  private readonly clickOutside = () => {
    this.setOpen(false)
  }

  private updateState() {
    this.openState = this.open

    this.change.emit(this.openState)

    setTimeout(() => {
      this.animate = this.openState
    }, 50)
  }

  toggle(event: any) {
    event.stopPropagation()

    this.open = !this.open
    this.updateState()
  }
}
