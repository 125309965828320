import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TgmSlideOpacityAnimatorComponent } from './slide-opacity-animator.component';

@NgModule({
    declarations: [
        TgmSlideOpacityAnimatorComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TgmSlideOpacityAnimatorComponent
    ]
})
export class TgmSlideOpacityAnimatorModule { }
