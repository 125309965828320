import { NgModule } from '@angular/core';
import { TgmLocationDetailsPanelComponent } from './location-details-panel.component';
import { TgmGenericPanelModule } from '../generic-panel/generic-panel.module';
import { MatFormFieldModule, MatIconModule, MatButtonModule, MatInputModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { TgmTwoColumnListModule } from '../../two-column-list/two-column-list.module';
import { FormsModule } from '@angular/forms';
import { LocationFilterPipe } from './location-filter-pipe';

@NgModule({
  imports: [
    TgmGenericPanelModule,
    TgmTwoColumnListModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule
  ],
  declarations: [
    TgmLocationDetailsPanelComponent,
    LocationFilterPipe
  ],
  exports: [TgmLocationDetailsPanelComponent]
})
export class TgmLocationDetailsPanelModule {}
