import { CommonModule } from '@angular/common';
import { MatMenuModule, MatButtonModule, MatIconModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { TgmMapScaleComponent } from './map-scale.component';


@NgModule({
  declarations: [
    TgmMapScaleComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    TgmMapScaleComponent
  ]
})
export class TgmMapScaleModule { }
