import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'placeholder'
})
export class PlaceholderPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {}

  transform(value: any, width: number = 100, height: number = 18): any {
    if (!value) {
      const html = `<div class="placeholder-animation" style='display:inline-block;height:${height}px;width:${width}px'></div>`;
      return this.sanitized.bypassSecurityTrustHtml(html);
    } else {
      return value;
    }
  }
}
