import { Location } from '@angular/common'
import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import {
  Auth,
  BarGraphOptions,
  files,
  GraphValue,
  Indicators,
  MapLayerPosition,
  MapLayoutStyleOptions,
  MarkerLabelsLayer,
  SubscriberComponent,
  TgmMapPopupComponent,
  TgmMapScaleComponent,
  TgmQuickDialogs,
  TRAVEL_COLORS,
} from '@targomo/client'
import { array as arrays } from '@targomo/common'
import { BoundingBox, LatLng, LatLngProperties, TravelTypeEdgeWeightOptions } from '@targomo/core'
import * as jQuery from 'jquery'
import { combineLatest, of, race } from 'rxjs'
import { Observable } from 'rxjs/Observable'
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  shareReplay,
  take,
  switchMap,
  withLatestFrom,
  delay,
  filter,
} from 'rxjs/operators'
import { DEFAULT_COPYRIGHT, DEFAULT_MAP_ATTRIBUTION, Settings } from '../../common/models/settings'
import { UserPermissions } from '../../common/models/userPermissions'
import { PlaceEndpoint } from '../api/place'
import { RoadsVolumeEndpoint } from '../api/roadsVolume'
import { SettingsEndpoint } from '../api/settings'
import { UserEndpoint } from '../api/user'
import { UserEventLogEndpoint } from '../api/userEventLog'
import { UserSettingsEndpoint } from '../api/userSettings'
import { AppModel } from '../model/appModel.service'
import { BatchStatisticsModel } from '../model/batchStatisticsModel'
import { FeatureModel } from '../model/featureModel'
import { INTERSECTION_THRESHOLD, POLYGON_SOURCES_THRESHOLD } from '../model/placesModel'
import { ProjectNumberModel } from '../model/projectNumberModel'
import { SavedSessionModel } from '../model/savedSessionModel'
import { INITIAL_MAP_STYLE, intersectionModeString, TravelDisplayMode } from '../model/settingsModel'
import { UserSettingsPersistenceService } from '../model/userSettingsPersitenceService'
import { ZoneLayersModel } from '../model/zoneLayersModel'
import { FALLBACK_GAP_REPORT_SETTINGS } from '../pages/admin/components/adminEditGapAnalysisReport/adminEditGapAnalysisReport.component'
import { APP_BASE_PATH } from '../util/basePath'
import { ensureSuffix } from '../util/fileNameUtil'
import { SearchResult } from './components/geosearch/geosearch.component.type'
import { TgmInfoDialogComponent } from './dialogs/info-dialog/info-dialog.component'
import { MapPrintDialog } from './dialogs/mapPrintDialog.component'
import { ProjectNumberDialogComponent } from './dialogs/projectNumberDialog/projectNumberDialog.component'
import { getGroupForZoom, MainMapLayer } from './map/mainMapLayer'
import { RoadsVolumeComposite } from './map/roadsVolumeComposite'
import { StreetsComposite } from './map/streetsComposite'
import { WorkforceComposite } from './map/workforceComposite'
import { MapMenuComponent } from './mapMenu/mapMenu.component'
import { MatchpointDialogComponent } from './matchpoint/matchpointDialog/matchpointDialog.component'
import { OptionsDialogService } from './optionsDialog/optionsDialog.service'
import { CareHomePdf } from './pdf/careHome/careHomePdf'
import { ExportPDFGapReport } from './pdf/exportPdfGapReport'
import { mapToPdf } from './pdf/exportPdfMap'
import { ExportPDFReport } from './pdf/exportPdfReport'
import { SimpleMapLayoutComponent } from './simpleMapLayout/simple-map-layout.component'
import { OpenDialog } from './ui/openDialog.service'
import { WaitDialog } from './ui/waitDialog/waitDialog.service'
import { PostcodeAnalysisComposite } from './map/postcodeAnaysisComposite'
import { CustomMapboxComponent } from './mapBox/mapbox.component'
import { PostcodeAnalysisModel } from '../model/postcodeAnalyssModel'
import { MobileCatchmentsEndpoint } from '../api/mobileCatchments'
;(<any>window).$ = jQuery

const ZOOM_LEVELS = [2, 5, 10, 15, 20, 30, 50, 100, 200, 500, 1000]

class SearchResultLocation {
  constructor(public lat: number, public lng: number, public name: string) {}

  toString() {
    return this.name
  }
}

function gateTime(gate$: Observable<any>, time: number) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable((subscriber) => {
      let gateTime: number = null
      const gateSubscription = gate$.subscribe(() => {
        gateTime = new Date().getTime()
      })

      const subscription = source.pipe(delay(time)).subscribe({
        next(value) {
          const now = new Date().getTime()

          if (gateTime == null || now - gateTime > time * 5) {
            subscriber.next(value)
          }
        },
        error(error) {
          subscriber.error(error)
        },
        complete() {
          subscriber.complete()
        },
      })

      return () => {
        gateSubscription.unsubscribe()
        subscription.unsubscribe()
      }
    })
  }
}

class AppMapLayoutOptions implements MapLayoutStyleOptions {
  identifyTitle = 'Thematic layer information'
  logoUrl = APP_BASE_PATH + 'api/settings/logo'
  hideRainbow = false
}

@Component({
  selector: 'home-component',
  templateUrl: './home.html',
  styleUrls: ['./home.less'],
})
export class HomeComponent extends SubscriberComponent implements AfterViewInit {
  @ViewChild('mapLayout') mapLayout: SimpleMapLayoutComponent
  @ViewChild('mapMenu') mapMenu: MapMenuComponent
  @ViewChild('mapScale') mapScale: TgmMapScaleComponent

  currentTab: number = 0 // NOTE: will be replaced as soon as I have something better to tab content state management

  mapView: CustomMapboxComponent
  mapMenuPopup: TgmMapPopupComponent
  graphModel: GraphValue[]
  graphOptions: BarGraphOptions
  private markersLabelsComposite: MarkerLabelsLayer<LatLngProperties>
  layoutOptions: Observable<AppMapLayoutOptions>

  showHover: boolean = false
  showHoverButton: boolean = false
  mainLayersVisible: boolean = false
  permissions: any

  panelView: string

  mainLayer: MainMapLayer

  options: any
  sources: any[]

  settings: Settings
  copyright: string

  mapScaleOptions: { label: string; value: number }[] = ZOOM_LEVELS.map((value, i) => {
    let extra = ''

    if (i === 0) {
      extra = ' (Max)'
    } else if (i === ZOOM_LEVELS.length - 1) {
      extra = ' (Min)'
    }

    let label = 'Zoom ' + (i + 1) + extra

    return {
      value,
      label,
    }
  })

  constructor(
    private indicators: Indicators,
    readonly appModel: AppModel,
    private dialog: MatDialog,
    private auth: Auth,
    private router: Router,
    private route: ActivatedRoute,
    private placeEndpoint: PlaceEndpoint,
    private userEndpoint: UserEndpoint,
    private location: Location,
    private featureModel: FeatureModel,
    private settingsEndpoint: SettingsEndpoint,
    private userSettingsPersitenceService: UserSettingsPersistenceService,
    private userSettingsEndpoint: UserSettingsEndpoint,
    readonly zoneLayersModel: ZoneLayersModel,
    private userEventLogEndpoint: UserEventLogEndpoint,
    private projectNumberModel: ProjectNumberModel,
    private exportPDFReport: ExportPDFReport,
    private waitDialog: WaitDialog,
    private savedSessionModel: SavedSessionModel,
    private exportPDFGapReport: ExportPDFGapReport,
    private openDialog: OpenDialog,
    private quickDialogs: TgmQuickDialogs,
    private optionsDialogService: OptionsDialogService,
    readonly batchStatisticsModel: BatchStatisticsModel,
    private roadsVolumeEndpoint: RoadsVolumeEndpoint,
    private postcodeAnalysisModel: PostcodeAnalysisModel,
    private mobileCatchmentsEndpoint: MobileCatchmentsEndpoint
  ) {
    super()

    this.userSettingsPersitenceService.init()
    appModel.init()
    appModel.settings.initAuth()

    this.options = {
      serviceKey: this.appModel.settings.client.serviceKey,
      maxEdgeWeightOptions: arrays
        .empty(6)
        .map((value, i) => ({ value: (i + 1) * 300, color: TRAVEL_COLORS[i], label: `${(i + 1) * 5}` })),
      // await this.appModel.settings.travelTimesColorsUpdates.take(1).toPromise(),

      travelTypeOptions: [
        { value: 'car', icon: 'time_to_leave' },
        { value: 'transit', icon: 'train' },
        { value: 'bike', icon: 'directions_bike' },
        { value: 'walk', icon: 'directions_walk' },
      ],

      performanceMouseMoveOutsideAngular: true,
      initialStyle: INITIAL_MAP_STYLE,
    }

    this.watch(this.appModel.settings.pointAndClickSourceUpdates, (source) => {
      this.appModel.places.sources.updatePointAndClick(source)
    })

    this.layoutOptions = this.initAppMapLayoutOptions()

    this.initPermisions()
    this.initHelp()
  }

  private async initHelp() {
    this.settings = await this.settingsEndpoint.getPublic()
    this.copyright = this.settings.copyright || DEFAULT_COPYRIGHT
  }

  private async initPermisions() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
      this.initProjectNumber()
    }
  }

  private async initProjectNumber() {
    if (this.permissions.projectNumber && !this.projectNumberModel.hasProjectNumber()) {
      const dialogRef = this.dialog.open(ProjectNumberDialogComponent, {
        disableClose: false,
        data: await this.projectNumberModel.getSuggestedProjectNumber(),
      })

      const result = await dialogRef.afterClosed().toPromise()
      if (result) {
        this.projectNumberModel.setProjectNumber(result)
      } else {
        this.projectNumberModel.dismissProjectNumber()
      }
    }
  }

  async ngAfterViewInit() {
    this.mapView = this.mapLayout.mapView
    this.mapMenuPopup = this.mapLayout.mapMenuPopup

    const zoom$ = of(this.mapView.getZoom()).pipe(
      merge(this.mapView.events.move.pipe(map((event) => event.zoom))),
      map(getGroupForZoom),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.watch(zoom$, (statisticsGroup) => {
      this.appModel.multigraph.statisticsGroupZoom$.next(statisticsGroup)
    })

    this.watch(
      Observable.from([this.mapLayout.getBounds()])
        .merge(this.mapView.events.move.map((event) => event.bounds))
        .debounceTime(300),
      async (bounds) => {
        this.appModel.places.locationsPresent.bounds$.next(await this.mapLayout.getBounds())
      }
    )

    this.watch(
      Observable.combineLatest(this.appModel.places.sources.observableCombined).debounceTime(10),
      ([sources]) => {
        this.sources = sources
      }
    )

    // if (this.appModel.isProduction) {
    //   this.mapView.jumpTo({pitch: 25, bearing: 0})
    // }

    this.mapView.setView({ lat: 51.507351, lng: 0 })
    this.mapView.setZoom(9)
    this.mapView.setStyle(INITIAL_MAP_STYLE)
    this.watch(this.appModel.settings.mapStyleUrlUpdates, (style) =>
      this.mapView.setStyle(style.url, style.referenceLayer)
    )

    this.watch(this.appModel.settings.edgeWeightsColorsUpdates, (colors) => {
      this.options = {
        ...this.options,
        maxEdgeWeightOptions: [].concat(colors),
      }
    })

    this.mainLayer = new MainMapLayer(
      this.mapView,
      this.appModel,
      this.featureModel,
      this.zoneLayersModel,
      this.indicators,
      this.auth,
      this.mobileCatchmentsEndpoint
    )

    // this.watch(this.mapView.events.click.pipe(gateTime(this.mainLayer.events.clickLocation, 200)), (ev) => {
    //   this.appModel.settings.displaySettings.nextWithCurrent((current) => {
    //     if (current.pointAndClickMode) {
    //       this.appModel.places.sources.clear()

    //       const custom = {
    //         id: 'pointAndClick' as never,
    //         lat: ev.latlng.lat,
    //         storeId: String('pointAndClick'),
    //         lng: ev.latlng.lng,
    //         properties: {
    //           name: 'Temp Location',
    //           ['marker-icon-size']: 1,
    //         },
    //         fascia: 'Temp Location',
    //         name: 'Temp Location',
    //         defaultName: 'Temp Location',
    //       } as any

    //       current.pointAndClickSource = custom
    //     } else {
    //       current.pointAndClickSource = null
    //     }

    //     return current
    //   })
    // })

    this.watch(this.mainLayer.events.clickMaybeMap, (ev) => {
      this.appModel.settings.displaySettings.nextWithCurrent((current) => {
        if (current.pointAndClickMode && !ev.location) {
          this.appModel.places.sources.clear()

          const custom = {
            id: 'pointAndClick' as never,
            lat: ev.latlng.lat,
            storeId: String('pointAndClick'),
            lng: ev.latlng.lng,
            properties: {
              name: 'Temp Location',
              ['marker-icon-size']: 1,
            },
            fascia: 'Temp Location',
            name: 'Temp Location',
            defaultName: 'Temp Location',
          } as any

          current.pointAndClickSource = custom
        } else {
          current.pointAndClickSource = null
        }

        return current
      })
    })

    // this.remember(this.appModel.mapModel.events.clickLocation.subscribe(event => {
    //   this.appModel.mapModel.sources.next([event.location])
    //   this.loadGraph()
    // }))
    this.remember(
      this.mainLayer.events.contextLocation.subscribe((event) => {
        setTimeout(() => {
          this.mapMenuPopup.show(event.position)
          this.mapMenu.show(event.location, event.latlng)
        }, 20)
      })
    )

    this.remember(
      this.mainLayer.events.contextLocationAlt.subscribe((event) => {
        setTimeout(() => {
          this.mapMenuPopup.show(event.position)
          this.mapMenu.show(event.location, event.latlng)
        }, 20)
      })
    )

    this.remember(
      this.mainLayer.events.contextPlanning.subscribe((event) => {
        setTimeout(async () => {
          this.mapMenuPopup.show(event.position)
          const location = this.appModel.places.planningApplicationAsLocation(event.location)
          this.mapMenu.show(location, event.latlng)
        }, 20)
      })
    )

    this.remember(
      this.mapView.events.context.subscribe((event) => {
        this.mapMenuPopup.show(event.position)
        this.mapMenu.show(null, event.latlng)
      })
    )

    this.remember(
      this.mainLayer.events.clickLocation.subscribe((event) => {
        const isPointAndClick = this.appModel.settings.displaySettings.getValue().pointAndClickMode

        if (isPointAndClick) {
          this.appModel.places.sources.clear()
          this.appModel.places.sources.add(event.location)
        } else if (!event.location.isTemporaryCustom()) {
          this.appModel.places.selectedPlace.next(event.location)
        }
      })
    )

    this.remember(
      this.mainLayer.events.clickLocationAlt.subscribe((event) => {
        this.appModel.places.selectedPlanningPlace.next({ type: 'location', location: event.location })
      })
    )

    this.remember(
      this.mainLayer.events.hoverPlanning.subscribe((event) => {
        this.appModel.places.hoverPlanningPlace.next(event.location)
      })
    )

    this.remember(
      this.mainLayer.events.clickPlanning.subscribe((event) => {
        this.appModel.places.selectedPlanningPlace.next({ type: 'planning', location: event.location })
      })
    )

    this.remember(
      this.mainLayer.events.hoverLocation.subscribe((event) => {
        this.appModel.places.hoverPlace.next(event.location)
      })
    )

    this.remember(
      this.appModel.places.selectedPlace.subscribe((source) => {
        if (!!source) {
          this.router.navigate(['/details'])
        }
      })
    )

    this.watch(this.appModel.places.selectedPlanningPlace, (source) => {
      if (!!source) {
        this.router.navigate(['/details'])
      }
    })
    /* BEGIN FIXENABLE
     */ // END

    // this.initZoomFilter()

    this.remember(
      this.appModel.polygonsBounds.subscribe((result) => {
        if (!result) {
          return
        }

        this.mapLayout.setBounds(result)
      })
    )

    this.remember(
      this.appModel.displaySettings.subscribe((settings) => {
        this.showHoverButton =
          settings.pedestrianLayer ||
          settings.workforceLayer ||
          settings.roadsVolumeLayer ||
          settings.travelDisplayMode != TravelDisplayMode.NoThematicPolygons
      })
    )

    this.mapView.jumpToCurrentPosition()
    // new MapboxDrawLayer(this.mapView)

    this.initCustomLayers()

    setTimeout(() => {
      if (this.route.snapshot.data.initialState) {
        this.savedSessionModel.restoreUrlSession(this.route.snapshot.data.initialState, this.mapView)
      } else if (this.route.snapshot.data.shareSesssion) {
        this.savedSessionModel.restore(this.route.snapshot.data.shareSesssion, this.mapView)
      }
    })

    this.watch(
      this.appModel.places.sources.observable.pipe(withLatestFrom(this.appModel.settings.intersectionModeUpdates)),
      async ([sources, insersectionMode]) => {
        if (sources && sources.length > INTERSECTION_THRESHOLD && insersectionMode !== 'union') {
          // TODO: decide what threshold
          this.appModel.settings.displaySettings.nextProperty('intersectionMode', 'union')

          this.openDialog.show(TgmInfoDialogComponent, {
            message: `Intersection mode '${intersectionModeString(
              insersectionMode
            )}' not currently available above ${INTERSECTION_THRESHOLD} sources. Switching to '${intersectionModeString(
              'union'
            )}'`,
            confirm: 'Ok',
          })
        }

        if (sources && sources.length > POLYGON_SOURCES_THRESHOLD) {
          this.appModel.settings.displaySettings.nextProperty(
            'travelDisplayModeForced',
            TravelDisplayMode.ThematicNoPolygons
          )
        } else {
          this.appModel.settings.displaySettings.nextProperty('travelDisplayModeForced', null)
        }
      }
    )
  }

  private async initCustomLayers() {
    const bounds$: Observable<BoundingBox> = Observable.from(this.mapLayout.getBounds())
      .pipe(merge(this.mapView.events.move.pipe(map((event) => event.bounds))))
      .pipe(debounceTime(300), shareReplay(1))

    const roadsLayer = new RoadsVolumeComposite(
      this.mapView,
      this.appModel.settings.roadsVolumeLayerUpdates,
      this.appModel.settings.cellHoverUpdates,
      this.appModel.mapModel,
      bounds$,
      this.roadsVolumeEndpoint,
      this.appModel.settings.roadsVolumeLayerViewportBreaksUpdates,
      this.appModel.places
    )

    this.watch(roadsLayer.events.context, (event) => {
      if (!!event) {
        setTimeout(async () => {
          this.mapMenuPopup.show(event.position)
          this.mapMenu.showRoad(event.latlng, event.feature)
        }, 20)
      }
    })

    const postcodesLayer = new PostcodeAnalysisComposite(
      this.mapView,
      this.appModel.settings.cellHoverUpdates,
      this.appModel,
      this.postcodeAnalysisModel
    )

    new StreetsComposite(
      this.mapView,
      this.appModel.settings.pedestrianLayerUpdates,
      this.appModel.settings.cellHoverUpdates,
      this.appModel.mapModel,
      this.appModel.settings.client
    )
    new WorkforceComposite(
      this.mapView,
      this.appModel.settings.workforceLayerUpdates,
      this.appModel.settings.cellHoverUpdates,
      this.appModel.mapModel,
      this.appModel.settings.client
    )
    this.initMarkerLabelsLayer()
  }

  private initMarkerLabelsLayer() {
    const options = this.appModel.settings.showLabelsUpdates.map((value) => {
      return {
        minzoom: 5,
        layout: {
          'symbol-placement': 'point',
          'text-field': '{name}',
          // "text-max-width": 100,
          'text-allow-overlap': !value,
          // "symbol-spacing": 150,
          // "text-line-height": 12,
          'symbol-avoid-edges': false,
          'text-size': 14,
          'text-anchor': 'left',
          'text-offset': [1, 0],
          'text-font': ['Open Sans Bold'],
          'text-justify': 'auto',
          'text-variable-anchor': [
            'left',
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
            'right',
            'top',
            'bottom',
            'center',
          ],
        },
        paint: {
          'text-color': '#444444',
          'text-halo-color': '#cccccc',
          'text-halo-width': 0.8,
        },
      }
    })

    const normalSource = combineLatest(
      this.appModel.places.labelLocations,
      this.appModel.places.hoverPlace.asObservable(),
      this.appModel.places.planningApplicationsPlaces,
      this.appModel.settings.markerStyleReportUpdates,
      this.appModel.places.sources.observable
    ).map(([places, selected, planning, reportStyle, sources]) => {
      if (reportStyle === 'hidden') {
        return sources || []
      }

      if (planning != null) {
        return planning.concat(<any>(selected ? [selected] : []))
      } else {
        return places
      }
    })

    this.markersLabelsComposite = new MarkerLabelsLayer<LatLngProperties>(
      this.mapView as any,
      <any>normalSource,
      <any>options
    )
    this.markersLabelsComposite.setPosition(MapLayerPosition.MARKERS_GLOW)
    this.markersLabelsComposite.setVisible(true)

    // this.remember(this.appModel.places.labelFascia.observable.subscribe(labels => {
    //   let visible = labels && labels.length > 0

    //   if (this.appModel.settings.displaySettings.getValue().showLabels)
    //     visible = !visible

    //   this.markersLabelsComposite.setVisible(visible)
    // }))
  }

  private initAppMapLayoutOptions() {
    const userPromise = this.userEndpoint.me()
    const options = new AppMapLayoutOptions()
    return Observable.combineLatest(
      this.featureModel.travelTimesVisibleUpdates,
      Observable.from([null]).merge(this.appModel.logoUpdated)
    ).switchMap(async ([visible, date]) => {
      options.hideRainbow = !visible

      if (date) {
        let user = await userPromise
        options.logoUrl = APP_BASE_PATH + 'api/settings/logo/' + user.id + '?ts=' + date
      }

      return options
    })
  }

  // initZoomFilter() {
  //   let zoom: any = undefined
  //   let currentZoomFilter: any = null

  //   const updateMarkerZoom = () => {
  //     const settings = this.appModel.displaySettings.getValue()
  //     const zoom = this.mapView.getZoom()

  //     const zoomFilter: Filter<Place> = (place: Place): boolean => {
  //       return place[settings.markerSizeProperty] > 20000
  //     }

  //     this.appModel.places.filters.remove(currentZoomFilter)

  //     if (zoom < 8)
  //       this.appModel.places.filters.add(currentZoomFilter = zoomFilter)
  //   }

  //   this.remember(this.appModel.displaySettings.subscribe(options => {
  //     updateMarkerZoom()
  //   }))

  //   this.remember(this.appModel.mapModel.events.moveMap.subscribe(event => {
  //     if (event.zoom !== zoom) {
  //       zoom = event.zoom
  //       updateMarkerZoom()
  //     }
  //   }))
  // }

  // private async loadGraph() {
  //   const displayOptions = this.appModel.mapModel.options.getValue()
  //   const travelOptions  = this.appModel.mapModel.travelOptions.getValue()
  //   const statistics = await this.indicators.add(api.statistics(this.appModel.mapModel.sources.getValue(), STATISTICS, STATISTICS_GROUP, travelOptions))

  //   this.graphOptions = new BarGraphOptions({width: 370})
  //   // this.graphModel = [graphModel.fromStatistics(statistics[displayOptions.statistic.name], travelOptions.maxEdgeWeight > 900 ? 300 : 60, travelOptions.maxEdgeWeight, {isMinutes: true, round: true})]
  //   this.graphModel = [graphModel.fromStatistics(statistics[displayOptions.statistic.name], travelOptions.maxEdgeWeight > 900 ? 300 : 60, travelOptions.maxEdgeWeight, {isMinutes: true, round: true})]
  // }

  travelChange(value: TravelTypeEdgeWeightOptions) {
    // this.appModel.sources.touch()
    // this.appModel.mapModel.travelOptions.touch()
    this.appModel.settings.displaySettings.nextProperty('travelOptions', {
      ...value,
      edgeWeight: 'time',
    })
  }

  selectSearch(item: LatLng) {
    if (item instanceof SearchResult) {
      this.appModel.places.temporaryLocation.next(item)
    }

    if (item) {
      this.mapView.setZoom(13)
      this.mapView.setView(item, true)
    }
  }

  showPanel(which: string) {
    // this.panelView = which
    // this.mapLayout.showOptionsView() // FIXME
    this.router.navigate([which])
  }

  downloadGeoJSON() {
    const polygon = this.appModel.polygons.getValue()
    if (polygon) {
      files.saveFile(JSON.stringify(polygon), 'application/vnd.geo+json', 'exported_reachability.geojson')
    }
  }

  searchSource = async (query: string): Promise<LatLng[]> => {
    return (await this.placeEndpoint.findByText({ query })).map(
      (place) =>
        new SearchResultLocation(place.position.lat, place.position.lng, place.name + ' - ' + place.description)
    )
  }

  private async exportMap(
    width: number,
    height: number,
    actionCallback: (
      imageData: string,
      reportBounds: BoundingBox,
      scaleWidth: number,
      scaleLabel: string,
      blobData: Blob
    ) => Promise<any>,
    blob = false
  ) {
    width = width / (window.devicePixelRatio || 1)
    height = height / (window.devicePixelRatio || 1)
    this.appModel.displaySettings.nextProperty('markerStylePrint', true)

    const mapSnapshotPanel = $(`
      <div class="map-snapshot-panel">
        <i class="material-icons">camera_alt</i>
        <div>Please Wait...</div>
      </div>`)
    mapSnapshotPanel.appendTo('.map-container')

    const map = await this.mapView.getMap()
    const bounds = await this.mapLayout.getBounds()
    const originalBounds = await this.mapView.getBounds()

    const mapWidth = $('.mapboxgl-canvas').css('width')
    const mapHeight = $('.mapboxgl-canvas').css('height')

    $('.mapboxgl-map').css('width', width).css('height', height).css('position', 'relative')
    $('.mapboxgl-canvas').css('width', width).css('height', height)

    // Begin waiting

    map.resize()
    this.mapView.setBounds(bounds, <any>{ animate: false })
    map.resize()
    await new Promise((resolve) => setTimeout(resolve, 5))
    await this.mapView.waitUntilTilesLoaded()
    await new Promise((resolve) => setTimeout(resolve, 5))

    await new Promise((resolve) => {
      const callback = () => {
        if (map.loaded()) {
          resolve({})
        } else {
          setTimeout(callback, 25)
        }
      }

      callback()
    })
    // End waiting

    const scaleWidth = this.mapScale.widthPixels
    const scaleLabel = this.mapScale.label

    let imageData: string = null
    let blobData: Blob = null

    if (blob) {
      blobData = await new Promise<Blob>((resolve) => {
        const canvas = <HTMLCanvasElement>document.querySelector('custom-mapbox canvas')
        if (canvas.toBlob) {
          canvas.toBlob((result) => {
            resolve(result)
          })
        } else {
          imageData = this.mapView.getImageData()
          const rawData = atob(imageData.slice(imageData.indexOf(',') + 1))
          var array = new Uint8Array(rawData.length)
          for (var i = 0; i < rawData.length; i++) {
            array[i] = rawData.charCodeAt(i)
          }

          resolve(new Blob([array], { type: 'image/png' }))
        }
      })
    } else {
      imageData = this.mapView.getImageData()
    }

    let reportBounds = await this.mapView.getBounds()

    this.appModel.displaySettings.nextProperty('markerStylePrint', false)

    // Restore size
    $('.mapboxgl-map').css('height', '100%').css('width', '100%').css('position', 'static')
    $('.mapboxgl-canvas').css('height', mapHeight).css('width', mapWidth)
    map.resize()

    await new Promise((resolve) => {
      setTimeout(resolve, 25)
    })
    // End waiting

    this.mapView.setBounds(originalBounds, <any>{ animate: false })

    await actionCallback(imageData, reportBounds, scaleWidth, scaleLabel, blobData)

    mapSnapshotPanel.remove()
  }

  async printMap() {
    const dialogOptions = { fileName: 'storepointgeo_map', title: '', showTitle: false }
    let { fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    const callback = async (imageData: string, reportBounds: BoundingBox, scaleWidth: number, scaleLabel: string) => {
      const result: any = {}

      if (result) {
        const zoomLevel = this.mapView.getZoom()
        const pdfResult = await mapToPdf(
          imageData,
          zoomLevel,
          this.appModel,
          this.userEndpoint,
          this.settingsEndpoint,
          reportBounds,
          result,
          { width: scaleWidth, label: scaleLabel },
          this.userSettingsEndpoint,
          this.exportPDFReport
        )
        pdfResult.download(fileName ? ensureSuffix(fileName, '.pdf') : 'storepointgeo_map.pdf')
      }
      this.userEventLogEndpoint.logMapCreated()
    }

    this.exportMap(2000, 1700, callback)
  }

  exportMapReportImage = (mode: 'hidden' | 'competition' = 'hidden') => {
    return new Promise<{ imageData: string }>(async (resolve) => {
      const callback = async (imageData: string, reportBounds: BoundingBox, scaleWidth: number, scaleLabel: string) => {
        const result: any = {}

        // if (result) {
        // const zoomLevel = this.mapView.getZoom()
        // const pdfResult = await mapToPdf(imageData, zoomLevel, this.appModel, this.userEndpoint, this.settingsEndpoint, reportBounds, result, {width: scaleWidth, label: scaleLabel})
        // pdfResult.download('storepointgeo_map.pdf')
        // }

        this.appModel.displaySettings.nextProperty('markerStylePrint', false)
        this.appModel.displaySettings.nextProperty('markerStyleReport', null)
        resolve({ imageData })
      }

      this.appModel.displaySettings.nextProperty('markerStylePrint', true)
      this.appModel.displaySettings.nextProperty('markerStyleReport', mode)

      await new Promise((resolve) => setTimeout(resolve, 100))

      this.exportMap(2000, 1700, callback)
    })
  }

  async exportMapImage() {
    const dialogOptions = { fileName: 'storepointgeo_map_image', title: '', showTitle: false }
    let { fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    const copyright = [this.settings.copyright || DEFAULT_COPYRIGHT, DEFAULT_MAP_ATTRIBUTION].join(', ')

    function convertImgToDataURLviaCanvas(url: string): Promise<any> {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = url

        img.onload = function () {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          canvas.width = 2000 /// (<any>this).width
          canvas.height = 1700 //(<any>this).height
          ctx.drawImage(img, 0, 0)

          ctx.fillStyle = 'black'
          ctx.font = '20px sans serif'
          const tm = ctx.measureText(copyright)
          ctx.fillText(copyright, canvas.width - 20 - tm.width, canvas.height - 50)

          canvas.toBlob(resolve)
        }

        img.onerror = function () {
          resolve({ width: 0, height: 0, data: '' }) // don't stop the show just for missing client/logo
        }

        img.src = url
      })
    }

    const callback = async (
      imageData: string,
      reportBounds: BoundingBox,
      scaleWidth: number,
      scaleLabel: string,
      blobData: Blob
    ) => {
      const blob = await convertImgToDataURLviaCanvas(imageData)
      files.saveFile(blob, 'image/png', fileName ? ensureSuffix(fileName, '.png') : 'storepointgeo_map_image.png')
      this.userEventLogEndpoint.logMapImageCreated()
    }

    this.exportMap(2000, 1700, callback, false)
  }

  logout() {
    this.auth.logout()
    this.projectNumberModel.clearProjectNumber()
    this.router.navigate(['/login'])
    window.location.reload()
  }

  // showMainView() {
  //   this.panelView = null
  //   // this.mapLayout.showMainView()
  //   this.appModel.places.selectedPlace.next(null)
  // }

  // hoverChange(value: boolean) {
  //   this.showHover = value
  //   this.appModel.mapModel.hoverMode.next(value)
  // }

  async toggleCellHover() {
    // this.slideStatus = false;
    const value = await this.appModel.settings.cellHoverUpdates.take(1).toPromise()
    // const benchmarks = await this.settingsStore.benchmarksVisibleUpdates.take(1).toPromise()
    if (!value) {
      //   // this.locationsStore.navigateWithSelection((benchmarks ? '/benchmarks' : '') + '/cellHover')
      this.router.navigate(['/cellHover'])
    } else if (value != undefined) {
      this.location.back()
    }
  }

  togglePointAndClick() {
    this.appModel.settings.displaySettings.nextWithCurrent((current) => {
      current.pointAndClickMode = !current.pointAndClickMode
      if (!current.pointAndClickMode) {
        current.pointAndClickSource = null
      }

      return current
    })
  }

  // NOTE: done like this instead of the normal way of a separte route
  // to preserve open state between route changes until  have time to rewrite it
  setMainLayerVisible(visible: boolean) {
    this.mainLayersVisible = visible
  }

  showTabPanel(which: number) {
    this.router.navigate(['/'])
    this.currentTab = which
    this.mapLayout.isSideBarOpened = true

    // if (which === 0) {
    //   this.appModel.settings.displaySettings.nextProperty('showSectors', false)
    // }
  }

  showOptions() {
    this.optionsDialogService.open()
    // this.showTabPanel(2)
  }

  sidenavOpen() {
    this.mapLayout.isSideBarOpened = true
  }

  isTabActive(which: number) {
    return this.mainLayersVisible && this.currentTab === which
  }

  clearSelected() {
    this.appModel.places.sources.clear()
    this.zoneLayersModel.clearSelected()
  }

  toggleLabels() {
    this.appModel.settings.displaySettings.nextPropertyWithCurrent('showLabels', (current) => {
      return !current
    })
  }

  showMatchpointDialog() {
    const dialogRef = this.dialog.open(MatchpointDialogComponent, {
      disableClose: false,
      width: '100vw',
      height: '100vh',
      minWidth: '100wv',
      maxWidth: '100wv',
      panelClass: 'maxi-dialog-container-marker-class',
      data: {
        // exportMapReportImage: this.homeComponent.exportMapReportImage
      },
    })

    this.userEventLogEndpoint.matchpointViewed()
  }

  async exportMainPdfReport() {
    const dialogOptions = { fileName: 'storepointgeo_report', title: '' }
    let { title, fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    const options = { message: 'Please Wait...' }
    this.waitDialog.wait(
      this.indicators.add(this.exportPDFReport.save(this.exportMapReportImage, { title, fileName })),
      options
    )
    this.indicators.add(this.userEventLogEndpoint.pdfDownloaded())
  }

  async exportReportData() {
    const dialogOptions = { fileName: 'storepointgeo_report', title: '', showTitle: false }
    let { fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    const options = { message: 'Please Wait...' }
    this.waitDialog.wait(this.indicators.add(this.appModel.maxiReportModel.export(false, fileName)), options)
  }

  exportIndividualData() {
    this.batchStatisticsModel.addCurrentSources('report')
  }

  exportIndividualCompetitors() {
    this.batchStatisticsModel.addCurrentSources('competitors')
  }

  help() {}

  async exportGapReport() {
    const me = await this.auth.me()
    const gapReportPermissions =
      (me && (me as any).permissions && ((me as any).permissions as UserPermissions).gapReport) ||
      FALLBACK_GAP_REPORT_SETTINGS

    const options = { message: 'Please Wait...' }

    const getDefaultTitle = async () => {
      const map = await this.mapView.getMap()
      const center = map.getCenter()

      return `Lng ${center.lng.toFixed(3)}, Lat ${center.lat.toFixed(3)}`
    }

    const dialogOptions = { fileName: 'storepointgeo_gap_report', title: '' }
    let { title, fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    const callback = async (imageData: string, reportBounds: BoundingBox, scaleWidth: number, scaleLabel: string) => {
      title = title || (await getDefaultTitle())
      const zoomLevel = this.mapView.getZoom()
      const pdfResult = await this.exportPDFGapReport.save(
        title,
        imageData,
        zoomLevel,
        reportBounds,
        {
          width: scaleWidth,
          label: scaleLabel,
        },
        gapReportPermissions
      )

      pdfResult.download(fileName ? ensureSuffix(fileName, '.pdf') : 'storepointgeo_gap_report.pdf')
      this.userEventLogEndpoint.logGapReportCreated()

      this.appModel.displaySettings.nextProperty('markerStyleReport', null)
    }

    // this.appModel.displaySettings.nextProperty('markerStylePrint', true)
    this.appModel.displaySettings.nextProperty(
      'markerStyleReport',
      gapReportPermissions.showCompetition ? 'competition' : 'hidden'
    )

    this.waitDialog.wait(this.indicators.add(this.exportMap(2000, 1700, callback)), options)
  }

  async exportCareHomeReport() {
    const careHomeReport = await this.appModel.careHomeReportModel.value$.pipe(debounceTime(400), take(1)).toPromise()

    if (!careHomeReport) {
      return
    }

    const dialogOptions = { fileName: 'storepointgeo_care_home_report', title: '' }
    let { title, fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    const options = { message: 'Please Wait...' }
    const exportPdf = new CareHomePdf(this.exportMapReportImage, this.appModel, this.exportPDFReport, careHomeReport)

    this.waitDialog.wait(this.indicators.add(exportPdf.save(title, ensureSuffix(fileName, '.pdf'))), options)
    this.indicators.add(this.userEventLogEndpoint.careReportPdfDownloaded())
  }

  async shareableLink() {
    const url = await this.savedSessionModel.snapshotUrlSession(this.mapView)

    const clipboard = (window.navigator as any).clipboard
    if (clipboard) {
      try {
        await clipboard.writeText(url)
        this.quickDialogs.snack('Link copied')
      } catch (e) {}
    }
  }
}
