import { OrderByPipe } from './order-by.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { AddressPipe } from './address.pipe';
import { PlaceholderPipe } from './placeholder.pipe';
import { SecToMinPipe} from './sec-to-min.pipe'
import { TimeToColorPipe} from './time-to-color.pipe'
import { RouteSegmentToIconPipe } from './route-segment-to-icon.pipe';
import { TransitTypeToColorPipe } from './transittype-to-color.pipe';

@NgModule({
  declarations: [
    AddressPipe,
    PlaceholderPipe,
    SecToMinPipe,
    TimeToColorPipe,
    OrderByPipe,
    RouteSegmentToIconPipe,
    TransitTypeToColorPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AddressPipe,
    PlaceholderPipe,
    SecToMinPipe,
    TimeToColorPipe,
    OrderByPipe,
    RouteSegmentToIconPipe,
    TransitTypeToColorPipe
  ]
})
export class TgmPipesModule { }
