import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteTeaserComponent } from './route-teaser.component';
import { MatIconModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { RouteSegmentToIconPipe } from '../../pipes/route-segment-to-icon.pipe';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule
  ],
  declarations: [ RouteTeaserComponent ],
  exports: [ RouteTeaserComponent ],
  providers: [ RouteSegmentToIconPipe ]
})
export class RouteTeaserModule { }
