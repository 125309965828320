import { CommonModule } from '@angular/common';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { TgmSpeeddialComponent } from './speed-dial.component';
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    TgmSpeeddialComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule
  ],
  exports: [
    TgmSpeeddialComponent
  ]
})
export class TgmSpeeddialModule { }
