import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { ExtendedStatisticsKey } from '@targomo/core';
import { MatSelectChange, MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'tgm-statistics-panel',
  templateUrl: './statistics-panel.component.html',
  styleUrls: ['./statistics-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmStatisticsPanelComponent {
  @Input() activeStatistic: ExtendedStatisticsKey;
  @Input() options: ExtendedStatisticsKey[] = [];
  @Input() rasterVisible = false;

  @Output() activeStatisticChanged = new EventEmitter<ExtendedStatisticsKey>();
  @Output() rasterVisibleChanged = new EventEmitter<boolean>();

  constructor() {
  }

  checkboxChanged(evt: MatCheckboxChange) {
    this.rasterVisible = evt.checked
    this.rasterVisibleChanged.emit(evt.checked)
  }

  selectChanged(evt: MatSelectChange) {
    this.activeStatistic = evt.value
    this.activeStatisticChanged.emit(this.options.find( option => option.id === evt.value))
  }

}
