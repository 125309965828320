import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimePickerService {

  readonly now = new Date()
  readonly today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
  readonly tomorrow = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() + 1);
  readonly dayAfterTomorrow = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() + 2);

  compareByDay(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate()
  }

  isToday(value: Date) {
    return this.compareByDay(value, this.today)
  }

  isTomorrow(value: Date) {
    return this.compareByDay(value, this.tomorrow)
  }

  isDayAfterTomorrow(value: Date) {
    return this.compareByDay(value, this.dayAfterTomorrow)
  }
}

