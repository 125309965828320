import { Component, Input } from '@angular/core'
import { Indicators } from './indicators.service'

/**
 * A component that includes the angular2 router and also hides the initial 'loading' screen once the app is loaded
 */
@Component({
  selector: 'tgm-global-indicators',
  styleUrls: ['./global-indicators.component.less'],
  templateUrl: './global-indicators.component.html'
})
export class TgmGlobalIndicatorsComponent {
  /**
   * Visual type of loading indicator. Either `bar` or `spinner`. `spinner` is fullscreen and blocks User input
   */
  @Input() type: 'bar' | 'spinner' = 'bar'

  constructor(
    // router: Router,
     public indicators: Indicators
    ) {
    // router.events.subscribe((value) => {
    //   function removeLoading(): void {
    //     const loadingElement = document.querySelector('.app-loading')
    //     if (loadingElement) {
    //       loadingElement.classList.add('app-loading-done')
    //       setTimeout(() => { loadingElement.remove() }, 1100)
    //     }
    //   }

    //   if (value instanceof NavigationEnd) {
    //     setTimeout(removeLoading, 100)
    //   }
    // })
  }
}
