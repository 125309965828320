import { Route, LatLng } from '@targomo/core'
import * as helpers from '@turf/helpers'
import { MapSource } from '../../../sources/source'
import { ObservableExpression } from '../../../../../../types/abstract-subscriber'

/**
 * Only useful internally
 */
export class RouteLinesSource extends MapSource<Route[]> {
  constructor(input: ObservableExpression<Route[]>, private walk: boolean) {
    super(input)
  }

  get(routes: Route[]) {
    if (routes) {
      const segments: helpers.Feature<any>[] = []

      routes.forEach((route, i) => {
        if (route.routeSegments) {
          route.routeSegments.forEach(segment => {
            let linePoints: LatLng[] = []
            linePoints = segment.points

            const {points, ...properties} = segment as any

            if ((this.walk === true && properties.type !== 'WALK') || (this.walk === false && properties.type === 'WALK')) {
              return
            }

            properties.routeIndex = i
            properties.combinedType = '' + (properties.transitType || properties.routeType || properties.type)

            const lineString = helpers.lineString(linePoints.map(point => [point.lng, point.lat]), properties)
            segments.push(lineString)
          })
        }
      })

      const result = helpers.featureCollection(segments)
      return {
        type: 'geojson',
        data: result
      }
    } else {
      return super.get(routes)
    }
  }

  byIndex(index: number) {
    return this.value[index]
  }
}
