import { CommonModule } from '@angular/common';
import { TgmRangesliderComponent } from './range-slider.component'
import { MatSliderModule } from '@angular/material'
import { NgModule } from '@angular/core'


@NgModule({
  declarations: [
    TgmRangesliderComponent
  ],
  imports: [
    CommonModule,
    MatSliderModule
  ],
  exports: [
    TgmRangesliderComponent
  ]
})
export class TgmRangesliderModule { }
