
import {map, filter} from 'rxjs/operators';
import { Observable } from 'rxjs'
import { Component, OnInit, Input, ChangeDetectionStrategy, Output } from '@angular/core'
import * as d3 from 'd3'




export interface ScaleStep {
  value: number;
  large: boolean;
}

export interface BoxplotDistribution {
  min: number
  q1: number
  median: number
  q3: number
  max: number
}

export class BoxplotModel {
  quantiles: number[] = [0, 0, 0]
  whiskers: number[] = [0, 0]
  min: number = 0
  max: number = 0
  iqr: number = 0
  colors: string[] = ['#2f2f2f', '#d98328', '#5a1321', '#2f2f2f']
  scaleSteps: ScaleStep[] = []
  outliers: number[] = []
  segmentValues: number[] = []
  valueDistance: number = 0

  getBarWidth(i: number) {
    return ((this.segmentValues[i + 1] - this.segmentValues[i]) / this.valueDistance) * 100;
  }

  getValueMargin(i: number) {
    if (i == 0) { return 0; }
    const margin: number = ((this.segmentValues[i] - this.segmentValues[i - 1]) / this.valueDistance) * 100 + this.getValueMargin(i - 1);
    return margin;
  }

  getOutlierWrapperMargin() {
    return (this.whiskers[0] - this.min) / this.valueDistance * 100;
  }

  getOutlierMargin(v: number) {
    return (v - this.min) / this.valueDistance * 100;
  }

  getQuantileName(i: number) {
    return ['Min', 'Q1', 'Median', 'Q3', 'Max'][i];
  }

  getBarStyle(i: number) {
    return {
      'width': this.getBarWidth(i) + '%',
      'background': i !== 0 && i !== 3 && this.colors[i],
      'border-right': i === 3 ? '2px solid ' + this.colors[i] : 'none',
      'border-left': i === 0 ? '2px solid ' + this.colors[i] : 'none'
    };
  }
}

@Component({
  selector: 'tgm-boxplot',
  templateUrl: './boxplot.component.html',
  styleUrls: ['./boxplot.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmBoxplotComponent implements OnInit {

  /**
   * Observable Array of values to be visualized
   * @Input
   */
  @Input() values: Observable<number[]>;

  /**
   * Observable object that emits the calculated distribution values
   * @Output
   */
  @Output() distribution: Observable<BoxplotDistribution>

  /**
   * @hidden
   */
  boxplotModel: Observable<BoxplotModel>

  constructor() {

  }

  ngOnInit() {
    this.boxplotModel = this.values.pipe(
    filter(values => !!values && values.length > 1),
    map((values) => {
      return this.calculateModel(values);
    }), );

  this.distribution = this.boxplotModel.pipe(map(model => {
    return {
      min: model.min,
      q1: model.quantiles[0],
      median: model.quantiles[1],
      q3: model.quantiles[2],
      max: model.max
    }
  }))
  }

  private calculateModel(values: number[]): BoxplotModel {

    const model = new BoxplotModel();

    values = values.filter((value) => value > 0); // No negative values;
    values.sort((a, b) => a - b);

    model.quantiles = [d3.quantile(values, 0.25), d3.quantile(values, 0.5), d3.quantile(values, 0.75)];
    model.min = Math.min.apply(Math, values);
    model.max = Math.max.apply(Math, values);
    model.iqr = model.quantiles[2] - model.quantiles[0];

    const innerFences = [0, 0];
    innerFences[0] = (model.quantiles[0] - (1.5 * model.iqr));
    innerFences[1] = (model.quantiles[2] + (1.5 * model.iqr));

    values.some((v) => {
      if (v > innerFences[0]) {
        model.whiskers[0] = v;
        return true;
      }
      return undefined
    });

    values.some((v, i, a) => {
      if (v > innerFences[1]) {
        model.whiskers[1] = a[i - 1];
        return true;
      }
      model.whiskers[1] = model.max;
      return undefined
    });

    let index = Math.ceil(model.min / 100) * 100;

    const step: number = Math.ceil((model.max - model.min) / 7 / 100) * 100;

    for (index; index < model.max; index += step) {
      model.scaleSteps.push({
        value: index,
        large: false
      });
    }


    model.outliers = values.filter((v) => {
      if (v < innerFences[0] || v > innerFences[1]) {
        return v;
      } else {
        return false
      }
    });

    model.segmentValues = [model.whiskers[0]].concat(model.quantiles, [model.whiskers[1]]);
    model.valueDistance = model.max - model.min;

    return model;
  }

}
