import { CompositeLayer } from './composite-layer'
import { Observable } from 'rxjs'
import * as interaction from '../util/interaction'
import { PointLike } from 'mapbox-gl';

/**
 * Parent class of interactio layers. These layers (NOTE: maybe we should pick a different name)
 * don't add anything visually, but serve to decorate exising layers with interactive functionality ex response to mouse events
 */
export abstract class InteractionLayer extends CompositeLayer {
  protected fire<U>(target: Observable<U>, event: U) {
    interaction.fire(target, event)
  }

  protected mousePoint(event: MouseEvent | TouchEvent) {
    return interaction.mousePoint(event)
  }

  protected queryMapRenderedFeatures(
    map: mapboxgl.Map,
    pointOrBox?: PointLike | PointLike[],
    parameters?: {layers?: string[], filter?: any[]}): GeoJSON.Feature<GeoJSON.GeometryObject>[] {

    const actualParameters = parameters ? {...parameters} : parameters
    if (actualParameters && actualParameters.layers) {
      actualParameters.layers = actualParameters.layers.filter(id => {
        const layer = map.getLayer(id)
        return !!layer && !!layer.paint
      })
    }

    try {
      return map.queryRenderedFeatures(pointOrBox, actualParameters)
    } catch {
      return []
    }
  }
}
