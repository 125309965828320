import { CommonModule } from '@angular/common';
import { MatDialogModule, MatSnackBarModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { TgmQuickDialogs } from './quick-dialogs.service';
import {TgmConfirmDialogModule} from '../confirm-dialog/confirm-dialog.module'

@NgModule({
  providers: [
    TgmQuickDialogs,
  ],
  imports: [
    TgmConfirmDialogModule,
    CommonModule,
    MatDialogModule,
    MatSnackBarModule
  ]
})
export class TgmQuickDialogsModule { }
