import { CookieService } from 'ngx-cookie-service';
import { Injectable, InjectionToken, Inject, Optional } from '@angular/core'
import { RESTHelper } from '../http/http.service'
import { User, DEFAULT_USER_ROLES } from './auth.service.types';
import { BehaviorSubject } from 'rxjs';

export const TGM_AUTH_API_CONF = new InjectionToken<TgmAuthApiConf>('tgmAuthApiPath');

export interface TgmAuthApiConf {
  apiUrl?: string
  usersPath?: string
  authPath?: string
  loginRedirectPath?: string
  loginRedirectFunction?: () => void
}

export const DEFAULT_API_CONF: TgmAuthApiConf = {
  apiUrl: '',
  usersPath: '/users',
  authPath: '/auth/local',
  loginRedirectPath: '/login',
  loginRedirectFunction: null
}

export interface LoginOptions {
  domain?: string
  path?: string
  expires?: number | Date
}

/**
 *
 */
@Injectable()
export class Auth {
  private currentUser: Promise<User>
  currentUserSubject = new BehaviorSubject<User>(null);

  private userRoles: string[] = DEFAULT_USER_ROLES

  private apiConf: TgmAuthApiConf

  constructor(
    private http: RESTHelper,
    private cookieService: CookieService,
    @Optional() @Inject(TGM_AUTH_API_CONF) apiConf?: TgmAuthApiConf
  ) {
    if (apiConf) {
      this.apiConf = {
        ...DEFAULT_API_CONF,
        ...apiConf
      }
    } else {
      this.apiConf = DEFAULT_API_CONF
    }
    if (this.apiConf.apiUrl && this.apiConf.apiUrl !== '') {
      this.http = http.createInstance(this.apiConf.apiUrl)
    }
  }

  /**
   * Use a custom function for authentication
   */
  async loginWithToken(token: string, options?: LoginOptions) {
    try {
      if (!token) {
        throw new Error('login failed')
      }

      this.setToken(token, options)
      this.currentUser = null
      return this.me()
    } catch (e) {
      this.logout()
      throw e
    }
  }

  /**
   * Authenticate user and save token
   *
   * @param user     - login info
   */
  async login(username: string, password: string, options?: LoginOptions): Promise<User> {
    try {
      const res = await this.http.post<any>(this.apiConf.authPath, { username, password })
      if (!res || !res.token) {
        throw new Error('login failed')
      }

      this.setToken(res.token, options)
      this.currentUser = null
      this.currentUserSubject.next(null)
      return this.me()
    } catch (e) {
      this.logout()
      throw e
    }
  }

  private setToken(token: string, options?: LoginOptions) {
    const path    = options && options.path || '/'
    const domain  = options && options.domain
    const expires = options && options.expires || null
    if (domain) {
      this.cookieService.set('token', token, expires, path, domain)
    } else {
      this.cookieService.set('token', token, expires, path)
    }
  }

  /**
   * Delete access token and user info
   */
  logout() {
    this.cookieService.delete('token', '/')
    this.currentUser = null
    this.currentUserSubject.next(null)
  }

  /**
   *
   */
  async me(): Promise<User> {
    try {
      if (!this.currentUser) {
        this.currentUser = this.http.get(this.apiConf.usersPath + '/me')
        this.currentUserSubject.next(await this.currentUser)
      }
    } catch (e) {
      if (e.status) {
        // Dont throw 401 and 403
        if (e.status === 404) {
          console.error('Error getting User. Is the database Up?', e);
        }
        if (e.status >= 500) {
          console.error('Error getting User', e);
        }
      } else {
        console.error('Error getting User', e);
      }
    }

    return this.currentUser
  }

  /**
   *
   */
  async refreshMe(): Promise<User> {
    this.currentUser = this.http.get(this.apiConf.usersPath + '/me')
    this.currentUserSubject.next(await this.currentUser)
    return this.currentUser
  }


  hasRole(role: string): Promise<boolean> {
    let hasRole = (r: string, h: string) => this.userRoles.indexOf(r) >= this.userRoles.indexOf(h)

    return this.me()
      .then((user: User) => user && hasRole(user.role, role))
  }

  /**
   * Check if a user is an admin
   *   (synchronous|asynchronous)
   */
  isAdmin(): Promise<boolean> {
    return this.hasRole('admin')
  }

  /**
   * Get auth token
   *
   * @return - a token string used for authenticating
   */
  getToken(): string {
    return this.cookieService.get('token')
  }

  /**
   * Check if a user is logged in
   *
   */
  isLoggedIn(): Promise<boolean> {
    return this.me()
      .then(user => user && user.hasOwnProperty('role'))
  }
}
