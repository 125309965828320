import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secToMin',
  pure: false
})
export class SecToMinPipe implements PipeTransform {

  transform(
    value: number,
    suffixM: string = 'min',
    suffixH: string = 'h '
  ): any {

    if (value === 0) {
      return '0 ' + suffixM;
    }

    if (!value) {
      return 'loading...';
    }

    if (value < 0) {
      return 'out of range';
    }

    const m: number = Math.floor(value / 60) % 60;
    const h: number = Math.floor(value / 3600);

    let result = '';

    if (h > 0) {
      result += (h + suffixH);
    }

    if (m > 0) {
      result += (m + suffixM);
    }

    return result;
  }
}
