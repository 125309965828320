import {Injectable} from '@angular/core'
import {MatSnackBar, MatDialog} from '@angular/material'
import {TgmConfirmDialogComponent} from '../confirm-dialog'

export interface ConfirmDialogOptions {
  title?: string
  message: string
  confirm: string,
  cancel: string
}

/**
 * Angular2 service that provides a quick way to dislay confirmation dialogs and cnackbars
 */
@Injectable()
export class TgmQuickDialogs {
  constructor(/** @hidden */ private snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  /**
   * Shows an angular snackbar
   *
   * @param message
   * @param duration
   */
  snack(message: string, duration: number = 3000) {
    const view = this.snackBar.open(message)
    setTimeout(() => {view.dismiss()}, 3000)
  }

  /**
   * Shows a confirmation dialog
   *
   * @param options
   */
  confirm(options: ConfirmDialogOptions) {
    const dialogRef = this.dialog.open(TgmConfirmDialogComponent, {
      disableClose: false,
      data: options
    })

    return dialogRef.afterClosed().toPromise()
  }
}
