import { CommonModule } from '@angular/common';
import { TgmTravelFabComponent } from './travel-fab.component';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    TgmTravelFabComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule
  ],
  exports: [
    TgmTravelFabComponent
  ]
})
export class TgmTravelFabModule { }
