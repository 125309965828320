import { Component, Input } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'tgm-slide-opacity-animator',
    templateUrl: './slide-opacity-animator.component.html',
    animations: [
        trigger(
          'slideOpacity',
          [ transition(
            ':enter', [
              style({ transform: 'translate{{direction}}({{amount}})', opacity: 0 }),
              animate('300ms', style({ transform: 'translate{{direction}}(0px)', opacity: 1 }))
            ], { params: { direction: 'Y', amount: '20px' }}
          ),
          transition(
            ':leave', [
              style({ transform: 'translate{{direction}}(0px)', opacity: 1 }),
              animate('300ms', style({ transform: 'translate{{direction}}({{amount}})', opacity: 0 }))
            ], { params: { direction: 'Y', amount: '20px' }}
          )]
        )
      ]
})
export class TgmSlideOpacityAnimatorComponent {

    @Input() animationOptions: any = {
        trigger: true,
        direction: 'Y',
        amount: '20px'
    }
}
