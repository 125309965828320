import { Statistics} from '@targomo/core'

/**
 *
 */
export class GraphValue {
  key: string
  values: {
    label: string
    xValue?: number
    value: number
  }[]
}

/**
 *
 */
export class GraphFromStatisticsOptions {
  isMinutes?: boolean = false
  combine?: 'add' | 'avg' | 'none' = 'add'
  round?: boolean = false
}

export namespace graphs {
  export function fromStatistics(
    statistics: Statistics,
    groupSize: number,
    maximum: number,
    options: GraphFromStatisticsOptions,
    key?: string
  ) {
    const result = new GraphValue()
    const localOptions = {...new GraphFromStatisticsOptions(), ...options}
    result.key = key ? key : ''

    const totalIndex = Math.floor(maximum / (groupSize || 1))
    result.values = new Array(totalIndex)
    for (let i = 0; i < result.values.length; i++) {
      const labelValue = ((localOptions.isMinutes ? ((i + 1) / 60) : (i + 1) / 1000)) * groupSize
      result.values[i] = {
        label: `${Math.round(labelValue * 10) / 10}`, // Round label to 1 decimal. (values may be stacked otherwise) TODO make configurable
        value: 0,
        xValue: (i + 1) * groupSize
      }
    }

    for (let value in statistics.values) {
      let minute = Math.max(0, Math.floor((+value - 1) / groupSize))

      if (minute >= totalIndex) {
        minute = totalIndex - 1
      }

      result.values[minute].value += statistics.values[value]
    }

    // Values should include all previous minutes/distances
    if (localOptions.combine === 'add') {
      for (let i = 1; i < result.values.length; i++) {
        result.values[i].value += result.values[i - 1].value
      }
    }

    if (localOptions.round) {
      for (let i = 1; i < result.values.length; i++) {
        result.values[i].value = Math.round(result.values[i].value)
      }
    }

    return result
  }
}
