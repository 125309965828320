// import "nvd3/build/nv.d3.css"
import 'nvd3'

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material'
import { TgmBargraphComponent } from './bargraph.component'
import { NvD3Module } from 'ng2-nvd3'
import { NgModule } from '@angular/core'

// import 'd3'
// import 'nvd3'

@NgModule({
  declarations: [
    TgmBargraphComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    NvD3Module
  ],
  exports: [
    TgmBargraphComponent
  ]
})
export class TgmBargraphModule { }


