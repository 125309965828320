import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TravelType } from '@targomo/core';

import { BarGraphOptions } from '../../bargraph/bargraph.component';
import { GraphValue } from '../../bargraph/bargraph.component.type';
import { NumberOrValueLabel } from '../../../types/value-label';

@Component({
  selector: 'tgm-reachability-panel',
  templateUrl: './reachability-panel.component.html',
  styleUrls: ['./reachability-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmReachabilityPanelComponent {

  @Input() active = true;
  @Input() expanded = false;
  @Input() isFetching = false;
  @Input() exclusive: boolean = null;  // null is important here
  @Input() graphModel: GraphValue[] = [];

  @Input() maxEdgeWeight = 900;
  @Input() travelType: TravelType = 'car';
  @Input() maxEdgeWeightOptions: NumberOrValueLabel[];

  @Output() travelTypeChanged = new EventEmitter<TravelType>();
  @Output() maxEdgeWeightChanged = new EventEmitter<number>();

  @Output() activeChanged = new EventEmitter<boolean>();
  @Output() exclusiveChanged = new EventEmitter<boolean>();
  @Output() expansionToggled = new EventEmitter<boolean>();

  @Input() graphOptions = new BarGraphOptions({
    width: 370,
    formatValue: (val: any) => d3.format('.2s')(val), // format number with SI-prefix
    xAxisLabel: 'Time in minutes',
    yAxisLabel: 'Statistic value,'

  });

  get verdict() {
    const values = (this.graphModel && this.graphModel[0]) ? this.graphModel[0].values.map((val: any) => val.value) : [0];
    return Math.max(...values)
  }
}
