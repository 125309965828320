import { NgModule } from '@angular/core';
import { TgmStatisticsPanelComponent } from './statistics-panel.component';
import { TgmGenericPanelModule } from '../generic-panel/generic-panel.module';
import { MatCheckboxModule, MatFormFieldModule, MatSelectModule, MatOptionModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [TgmStatisticsPanelComponent],
  imports: [
    TgmGenericPanelModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatOptionModule,
    CommonModule,
    MatSelectModule
  ],
  exports: [TgmStatisticsPanelComponent]
})
export class TgmStatisticsPanelModule {}
