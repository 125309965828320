import { WORKFORCE_SEGMENTS } from './model/reportSegments'
import { AppModel } from '../../model/appModel.service'
import { Input, Component } from '@angular/core'
import { Subscription } from 'rxjs/Subscription'
import { array as arrays } from '@targomo/common'
import { CensusStatistics, AllStatistics } from '../../../common/models/statistics/statistics'
import { ReportPerCapitaHousehold } from '../../model/settingsModel'
import { AbstractMiniReportComponent } from './components/abstractMiniReportComponent/abstractMiniReportComponent'

@Component({
  selector: 'workforce-mini-report',
  templateUrl: './censusMiniReport.html',
})
export class WorkforceMiniReportComponent extends AbstractMiniReportComponent {
  @Input() allColumns: boolean

  // The application wide model keeping track of selected and visible places
  loading: Promise<any>

  statistics: CensusStatistics
  allStatistics: AllStatistics
  groups = WORKFORCE_SEGMENTS
  currencySymbol = '£'
  perHouseholdCapitaTitle: string = ''

  constructor(readonly appModel: AppModel) {
    super(appModel)
    this.groups = arrays.sortBy(this.groups, 'name')
  }

  ngOnInit() {
    this.watch(this.appModel.statistics.census.promise, (promise) => (this.loading = promise))
    this.watch(this.appModel.statistics.census.value, (statistics) => {
      if (statistics) {
        this.statistics = statistics.census
        this.allStatistics = statistics
        this.currencySymbol = this.allStatistics.currencySymbol()
        this.perHouseholdCapitaTitle =
          statistics.householdsOrPopulation === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Per Household'
      } else {
        this.statistics = null
        this.allStatistics = null
      }
    })
  }

  click(node: any) {}
}
