
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TgmAuthApiConf, TGM_AUTH_API_CONF } from '../auth/auth.service';
import { isSameOrigin } from './auth-utils';


export const TGM_AUTH_INTERCEPTOR_CONF = new InjectionToken<TgmAuthInterceptorConf>('tgmAuthInterceptorConf');

export interface TgmAuthInterceptorConf {
  allowDomains?: string[]
  csrfCookieName?: string
  csrfHeaderName?: string
}

/**
 * This interceptor decorates all api requests with XSRF and JWT tokens if applicable.
 *
 * Tokens `TGM_AUTH_INTERCEPTOR_CONF` and `TGM_AUTH_API_CONF` can be provided to set options
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private csrfHeaderName: string = 'X-XSRF-TOKEN'
  private csrfCookieName: string = 'XSRF-TOKEN'

  private loginRoute: string = '/login';
  private redirectFn: (msg?: string) => void;

  jwtDecode = (<any>jwt).default || jwt // dirty hack because jwt decode does not have proper exports

  constructor(
    private cookieService: CookieService,
    private router: Router,
    @Optional() @Inject(TGM_AUTH_INTERCEPTOR_CONF) private options?: TgmAuthInterceptorConf,
    @Optional() @Inject(TGM_AUTH_API_CONF) apiConf?: TgmAuthApiConf
  ) {
    if (apiConf && apiConf.loginRedirectPath) {
      this.loginRoute = apiConf.loginRedirectPath
    }
    if (apiConf && apiConf.loginRedirectFunction) {
      this.redirectFn = apiConf.loginRedirectFunction
    }

    if (options) {
      if (options.csrfHeaderName) {
        this.csrfHeaderName = options.csrfHeaderName
      }

      if (options.csrfCookieName) {
        this.csrfCookieName = options.csrfCookieName
      }

    }
  }

  private navigateToLogin(msg?: string) {
    if (this.redirectFn) {
      this.redirectFn(msg)
    } else {
      if (this.loginRoute.includes('http')) {
        window.location.href = this.loginRoute + '?returnUrl=' + window.location.href + (msg ? `&msg=${encodeURIComponent(msg)}` : '')
      } else {
        this.router.navigate([this.loginRoute], { queryParams: { returnUrl: window.location.href, msg: msg } })
      }
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Only set auth header if jwt exists and the request url matches the origin of `options.allowDomains`
    const allowDomains = this.options && this.options.allowDomains
    const jwtEncoded = this.cookieService.get('token')
    if (jwtEncoded && isSameOrigin(request.url, allowDomains)) {

      // decode JWT to check expiry
      const jwtDecoded = <any>this.jwtDecode(jwtEncoded)
      const currentTimeInS = Date.now() / 1000;
      if (!!jwtDecoded && !!jwtDecoded.exp && jwtDecoded.exp < currentTimeInS) {
        // navigate to login if expired and add message
        this.cookieService.delete('token')
        this.navigateToLogin('session expired')
        return of(null)
      }

      // set authorization header
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwtEncoded}`
        }
      })
    }

    if (request.method !== 'GET'
      && request.method !== 'HEAD'
      && this.cookieService.get(this.csrfCookieName)
      && isSameOrigin(request.url, allowDomains)
      && !request.headers.has(this.csrfHeaderName)
    ) {
      // set XSRF header if needed
      const token = this.cookieService.get(this.csrfCookieName)
      request = request.clone({ headers: request.headers.set(this.csrfHeaderName, token) })
    }

    // handle unauthorized errors
    return next.handle(request).pipe(
      // use tap here. Using `catchError` results in the completion of the observable => app cannot handle errors correctly
      tap(null, (error) => {
        if (error && (error.status === 401)) {
          // Only handle 401 here. Other errors have to be handled by the app
          this.navigateToLogin('Not Authorized')
        }
      }))
  }
}
