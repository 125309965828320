import { MapLayer, MapLayerPosition } from '../layer';
import { StatisticsMapSource } from '../../sources/statistics-source';
import { StatisticsKey } from '@targomo/core';
import { EMPTY_GEOJSON } from '../../constants';
import { statisticsLayerStops } from '../../util/interpolation';
import { TgmMapboxComponent } from '../../mapbox.component';
import { ObservableExpression, MapboxLayerType } from '../../../../../types';
import { Layer } from 'mapbox-gl';

export interface StatisticsMapLayerOptions {
  statistic?: StatisticsKey;
  interpolator?: string;
  statisticsStyle?: any;
  statisticsOpacity?: number;
  layerType?: MapboxLayerType;
}

/**
 * A layer representing a targomo statistics groups from our statistics service as a vector tiles
 * layer on a map
 */
export class StatisticsMapLayer extends MapLayer<StatisticsMapSource> {
  private options: StatisticsMapLayerOptions = <any>{};

  constructor(
    protected map: TgmMapboxComponent,
    protected source: StatisticsMapSource,
    optionsObservable?: ObservableExpression<StatisticsMapLayerOptions>
  ) {
    super(map, source);

    if (optionsObservable) {
      this.watch(optionsObservable, options => {
        this.options = options || <any>{};
        this.update();
      });
    }

    this.watch(this.source.events, () => this.update());
    this.setPosition(MapLayerPosition.STATISTICS);
  }

  get(): Partial<Layer> {
    const attribute =
      (this.options && this.options.statistic && this.options.statistic.id) ||
      0;
    const interpolator =
      (this.options && this.options.interpolator) || 'interpolateYlOrRd';
    const statisticsStyle = this.options && this.options.statisticsStyle;

    if (this.source.metadata) {
      return {
        type: (this.options && this.options.layerType) || 'fill',
        'source-layer': '' + this.source.metadata.id,
        paint: statisticsStyle || {
          'fill-color': statisticsLayerStops(
            this.source.metadata.stats[attribute],
            'kmeans',
            9,
            interpolator
          ),
          'fill-opacity':
            this.options &&
            (this.options.statisticsOpacity != null
              ? this.options.statisticsOpacity
              : 0.5),
          'fill-antialias': false
        }
      };
    } else {
      return {
        type: (this.options && this.options.layerType) || 'fill',
        source: EMPTY_GEOJSON,
        paint: {
          'fill-opacity': 0
        }
      };
    }
  }
}
