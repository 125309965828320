
import { MapLayer } from '../layer'
import { AbstractSource } from '../../sources'
import { Observable } from 'rxjs'
import { TgmMapboxComponent } from '../../mapbox.component'
import { Layer } from 'mapbox-gl';

export interface FilterMapLayerOptions {
  sourceLayer: string
  type: string
  paint: any | Observable<any>
  layout?: any | Observable<any>
}

/**
 * A layer that is a subet of an existing layer
 */
export class FilterMapLayer extends MapLayer<AbstractSource> {
  private filter: any[] = null
  private paint: any
  private layout: any

  constructor(protected map: TgmMapboxComponent, source: AbstractSource, private options: FilterMapLayerOptions) {
    super(map, source)

    if (options) {
      this.watch(options.paint, value => {
        this.paint = value
        this.update()
      })
    }

    if (options) {
      this.watch(options.layout, value => {
        this.layout = value
        this.update()
      })
    }
  }

  setFilter(filter: any[]) {
    this.filter = filter
    this.update(map => {
      map.setFilter(this.id, filter)
    })
  }

  getFilter() {
    return this.filter
  }

  get(): Partial<Layer> {
    const result: any = {
      'type': this.options.type || 'fill',
      'source-layer': this.options.sourceLayer,
      'layout': this.layout
    }

    if (this.paint) {
      result.paint = this.paint
    }

    if (this.filter) {
      result.filter = this.filter
    }

    return result
  }
}
