
import {shareReplay} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs'


/**
 * An array of values that emits an even (via an observable) whenever it is mutated
 */
export class ObservableList<T> {
  private values: T[] = []
  private readonly subject = new BehaviorSubject<T[]>(this.values)

  /**
   * An observable that notifies when this list is updated
   */
  readonly observable: Observable<T[]> = this.subject.asObservable().pipe(shareReplay(1))

  constructor() {
  }

  /**
   * Add an item to this list and notify its observable
   */
  add(value: T): this {
    if (this.values.indexOf(value) < 0) {
      this.values.push(value)
      this.subject.next(this.values)
    }

    return this
  }

  /**
   * Add a list of items to this list and notify its observable
   */
  addAll(values: T[]): this {
    let notify = false

    values.forEach(value => {
      if (this.values.indexOf(value) < 0) {
        this.values.push(value)
        notify = true
      }
    })

    if (notify) {
      this.subject.next(this.values)
    }

    return this
  }


  /**
   * Remove an item from this list and notify its observable
   */
  remove(value: T): this {
    const index =  this.values.indexOf(value)
    if (index >= 0) {
      this.values.splice(index, 1)
      this.subject.next(this.values)
    }

    return this
  }


  /**
   * Remove a list of itema from this list and notify its observable
   */
  removeAll(values: T[]): this {
    let notify = false

    values.forEach(value => {
      const index =  this.values.indexOf(value)
      if (index >= 0) {
        this.values.splice(index, 1)
        notify = true
      }
    })

    if (notify) {
      this.subject.next(this.values)
    }


    return this
  }

  /**
   * Add or remove an item from this list and notify its observable
   * When the status parameter is true the items is added, when false it is removed
   */
  merge(value: T, status: boolean): this {
    if (status) {
      this.add(value)
    } else {
      this.remove(value)
    }

    return this
  }

  /**
   * Add an item to the list if it is not already in, otherwise remove it. Afterwards notify the observable
   */
  toggle(value: T): this {
    const status = this.values.indexOf(value) >= 0
    this.merge(value, !status)

    return this
  }

  /**
   * Replace the values in this list with the given ones and notify the observable
   */
  update(values: T[]): this {
    this.values = [].concat(values)
    this.subject.next(this.values)
    return this
  }

  /**
   * Notify the obserable with the curtent list value
   */
  touch(): this {
    this.subject.next(this.values)
    return this
  }

  /**
   * Remove all items from this list and notify the observable
   */
  clear(): this {
    this.update([])
    return this
  }

  /**
   * Return the current values in this list
   */
  getValues() {
    return this.values
  }

  /**
   */
  isEmpty() {
    return this.values.length == 0
  }

  /**
   */
  length() {
    return this.values.length
  }

  /**
   * Find an item in this list
   */
  find(value: T | ((item: T) => boolean)): T {
    if (typeof value == 'function') {
      return this.values.filter(value)[0]
    } else {
      return this.values.filter(item => item == value)[0]
    }
  }

  /**
   * Test if an item is contained in this list
   */
  contains(value: T | ((item: T) => boolean)): boolean {
    if (typeof value == 'function') {
      return this.values.some(value)
    } else {
      return this.values.indexOf(value) > -1
    }
  }
}
