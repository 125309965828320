import { RouteLinesSource } from './routeLinesSource'
import { Route, LatLng } from '@targomo/core';
import { RoutePointsSource } from './routePointsSource';
import { CompositeLayer } from '../../composite-layer';
import { DefaultMapLayer } from '../../simple/default-layer';
import { TgmMapboxComponent } from '../../../mapbox.component';
import { FeatureInteractionLayer } from '../../interaction/feature-interaction-layer';
import { MapLayerPosition, MapLayer } from '../../layer';
import { PrimaryLayer } from '../../abstract-layer';
import { ObservableExpression } from '../../../../../../types/abstract-subscriber';
import { MapCellEvent } from '../../../mapbox.component.type';
import { Observable } from 'rxjs/Observable';
import {map as rxMap} from 'rxjs/operators'

const COLORS: {[type: string]: string} = {
  'WALK' : '#00B1F9',
  'BIKE' : '#558D54',
  'CAR' : '#558D54',
  'TRANSFER' : '#558D54',
  'SBAHN' : '#006837',
  'UBAHN' : '#156ab8',
  'BAHN' : 'red',
  'BUS' : '#A3007C',
  'TRAM' : 'red',
  '109' : '#006F35',
  '100' : 'black',
  '3' : '#A3007C',
  '1' : '#156ab8',
  '4' : '#0089c8',
  '0' : 'red',
}


/**
 * Mapbox-gl expression for styling a route segment line.
 * Tries to first color by using the routeType property and if no style for that exists try to style
 * based on the more general type property
 */
function segmentStyle(options: RouteLayerOptions) {
  const colors = options && options.routeTypeColors || COLORS
  return ['to-color',
    ['case',
      ['boolean', ['has', ['get', 'combinedType'], ['literal', colors]]], ['get', ['get', 'combinedType'], ['literal', colors]],
      ['boolean', ['has', ['get', 'type'], ['literal', colors]]], ['get', ['get', 'type'], ['literal', colors]],
      options && options.routeDefaultColor || '#ff0000'
    ]
  ]
}

export class RouteLayerEvent {
  constructor(readonly latlng: LatLng,
    readonly position: {x: number, y: number},
    readonly feature: any = null,
    readonly properties: any = null,
    readonly route: Route = null) {}
}

/**
 * Options for customizing a RouteLayer
 */
export class RouteLayerOptions {
  /**
   * Default color for a route segment when no other more specific color was given
   */
  routeDefaultColor?: string

  /**
   * Color for a route segment, based on the routeType and type properties
   */
  routeTypeColors?: {[type: string]: string}
}

/**
 * A layer that can display one of more Routes as returned from the targomo routing service
 */
export class RouteLayer extends CompositeLayer {
  private linesLayer: DefaultMapLayer
  private linesLayerWalk: DefaultMapLayer
  private pointsLayer: DefaultMapLayer
  private visible = true
  private optionsObservable: Observable<RouteLayerOptions>

  readonly events: {
    clickPoint: Observable<MapCellEvent>
    hoverPoint: Observable<MapCellEvent>
    clickSegment: Observable<MapCellEvent>
    hoverSegment: Observable<MapCellEvent>
  }

  constructor(
    map: TgmMapboxComponent,
    private routes: ObservableExpression<Route[]>,
    options?: ObservableExpression<RouteLayerOptions>
  ) {
    super(map)

    this.optionsObservable = this.toObservable(options)
    this.events = this.init()
  }

  private init() {
    const lineStyle = this.optionsObservable.pipe(rxMap(options => {
      return {
        type: 'line',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': segmentStyle(options),
          'line-width': {
            base: 1,
            stops: [[12, 6], [23, 18]]
          },
          'line-opacity': 1,
        }
      }
    }))

    const lineStyleWalk = this.optionsObservable.pipe(rxMap(options => {
      return {
        type: 'line',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': segmentStyle(options),
          'line-width': {
            base: 1,
            stops: [[12, 6], [23, 18]]
          },
          'line-opacity': 1,
          'line-dasharray': [1, 2],
        }
      }
    }))

    const lineOutlineStyle = this.optionsObservable.pipe(rxMap(options => {
      return {
        type: 'line',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#ffffff',
          'line-width': {
            base: 1,
            stops: [[12, 10], [23, 28]]
          },
          'line-opacity': 1,
        }
      }
    }))

    const pointStyle = this.optionsObservable.pipe(rxMap(options => {
      return {
        type: 'circle',
        paint: {
          'circle-color': ' #ffffff',
          'circle-radius': {
            'stops': [[5, 1], [25, 22]]
          },
          'circle-opacity': 1,
          'circle-stroke-width': {
            'stops': [[1, 1], [25, 3]]
          },
          'circle-stroke-color': '#558D54',
        }
      }
    }))

    const linesSourceWalk = new RouteLinesSource(this.routes, true)
    const linesSourceNotWalk = new RouteLinesSource(this.routes, false)
    this.linesLayer = new DefaultMapLayer(this.map, linesSourceNotWalk, lineStyle)
    this.linesLayerWalk = new DefaultMapLayer(this.map, linesSourceWalk, lineStyleWalk)
    new DefaultMapLayer(this.map, linesSourceWalk, lineOutlineStyle).setPosition(this.linesLayerWalk)
    new DefaultMapLayer(this.map, linesSourceNotWalk, lineOutlineStyle).setPosition(this.linesLayer)
    this.pointsLayer = new DefaultMapLayer(this.map, new RoutePointsSource(this.routes), pointStyle)

    const interactionSegments = new FeatureInteractionLayer([this.linesLayer, this.linesLayerWalk])
    const interactionPoints = new FeatureInteractionLayer(this.pointsLayer)

    const decorateEvent = (input: MapCellEvent) => {
      return new RouteLayerEvent(
          input.latlng,
          input.position,
          input.feature,
          input.properties,
          input.properties && linesSourceWalk.byIndex(input.properties.routeIndex) || null
      )
    }

    return {
      clickPoint: interactionPoints.events.click.pipe(rxMap(decorateEvent)),
      hoverPoint: interactionPoints.events.hover.pipe(rxMap(decorateEvent)),
      clickSegment: interactionSegments.events.click.pipe(rxMap(decorateEvent)),
      hoverSegment: interactionSegments.events.hover.pipe(rxMap(decorateEvent)),
    }
  }

  setVisible(value: boolean): this {
    this.visible = value

    this.linesLayer.setVisible(this.visible)
    this.linesLayerWalk.setVisible(this.visible)
    this.pointsLayer.setVisible(this.visible)

    return this
  }

  isVisible(): boolean {
    return this.visible
  }

  setPosition(position: MapLayerPosition | PrimaryLayer<MapLayer<any>>): this {
    this.linesLayer.setPosition(position)
    this.linesLayerWalk.setPosition(position)
    this.pointsLayer.setPosition(position)

    return this
  }
}
