import { MapLayer, MapLayerPosition } from '../layer';
import { StatisticsMapSource } from '../../sources/statistics-source';
import { StatisticsKey, ReachableTile, StatisticsKeyMeta } from '@targomo/core';
import { EMPTY_GEOJSON } from '../../constants';
import { TgmMapboxComponent } from '../../mapbox.component';
import d3scales from '../../util/scales'
import { TRAVEL_COLORS, ObservableExpression } from '../../../../../types';
import { Layer } from 'mapbox-gl';

export type ExtrusionStatisticsTravelScale = {min: number, max: number} & ({interpolator: string, inverse?: boolean} | {colors: string[]})

export enum ExtrusionStatisticsTravelLayerDisplayMode {
  TRAVEL_COLOR_STATISTIC_HEIGHT = 0,
  TRAVEL_HEIGHT_STATISTIC_COLOR = 1,
  FLAT_TRAVEL_COLOR_STATISTIC_COLOR = 2,
  FLAT_TRAVEL_COLOR_STATISTIC_COLOR_GRAYSCALE = 3,
  FLAT_TRAVEL_COLOR_STATISTIC_COLOR_INVERSE = 4,
}

export interface ExtrusionStatisticsTravelLayerOptions {
  statistic?: StatisticsKey;
  interpolator?: string;
  statisticsStyle?: any;

  travelScale?: ExtrusionStatisticsTravelScale
  opacity?: number

  displayMode?: ExtrusionStatisticsTravelLayerDisplayMode
  statisticsVisible?: boolean

  classification?: string
  extrusionHeight?: number
}

const DEFAULT_TRAVEL_SCALE: ExtrusionStatisticsTravelScale = {
  min: 0,
  max: 1800,
  colors: TRAVEL_COLORS
}

/**
 * A layer representing a targomo statistics groups from our statistics service as a vector tiles
 * layer on a map
 */
export class ExtrusionStatisticsTravelLayer extends MapLayer<StatisticsMapSource> {
  private options: ExtrusionStatisticsTravelLayerOptions = <any>{};
  private travel: ReachableTile = null
  private _visibleLayer = true

  constructor(
    protected map: TgmMapboxComponent,
    protected source: StatisticsMapSource,
    private optionsObservable?: ObservableExpression<ExtrusionStatisticsTravelLayerOptions>
  ) {
    super(map, source);

    if (optionsObservable) {
      this.watch(optionsObservable, options => {
        this.options = {...options} || <any>{}
        this.update();
      });
    }


    this.watch(this.source.events, () => this.update())
    this.setPosition(MapLayerPosition.BELOW_LABELS)
  }

  private getBreakpoints(attribute: StatisticsKeyMeta) {
    let classification: string[] = null

    try {
      classification = (this.options && this.options.classification || 'kmeans.c9').split('.')
    } catch (e) {
      classification = 'kmeans.c9'.split('.')
    }

    if (classification.length < 2) {
      console.warn(`ExtrusionStatisticsTravelLayer ${this.id}: invalid classification '${this.options && this.options.classification}'`)
    }

    if (attribute.breakpoints[classification[0]]
        && attribute.breakpoints[classification[0]][classification[1]]) {
      return attribute.breakpoints[classification[0]][classification[1]]
    } else {
      return attribute.breakpoints.equal_interval.c9
    }
  }

  private calculateStatisticsHeightStops(attribute: StatisticsKeyMeta) {
    const extrusionHeight = this.options && this.options.extrusionHeight == null ? 1000 : this.options.extrusionHeight

    if (!attribute) {
      return 0
    }

    let breakpoints: any[] = this.getBreakpoints(attribute)
    let extrusionHeightStep = extrusionHeight / breakpoints.length

    const result: any[] = [
      'interpolate',
      ['exponential', 1],
      ['number', ['get', '' + attribute.statistic_id]],
    ]

    let last: any = null
    breakpoints.forEach((breakpoint: any, i: number) => {
      if (last !== breakpoint) {
        result.push(breakpoint, Math.round(i * extrusionHeightStep))
        last = breakpoint
      }
    })

    return result
  }


  private calculateStatisticsColorStops(attribute: StatisticsKeyMeta, interpolator: string) {
    if (!attribute) {
      return 'rgba(0, 0, 0, 0)'
    }

    const interpolatorFunction = (<any>d3scales)[interpolator]
    let breakpoints: any[] = this.getBreakpoints(attribute)

    const result: any[] = [
      'interpolate',
      ['exponential', 1],
      ['number', ['get', '' + attribute.statistic_id]],
    ]

    let last: any = null
    breakpoints.forEach((breakpoint: any, i: number) => {
      if (last !== breakpoint) {
        result.push(breakpoint, interpolatorFunction((i + 1) / breakpoints.length))
        last = breakpoint
      }
    })

    return result
  }

  private calculateTravelColor(travel: ReachableTile,
                                  options: {min: number, max: number,
                                  interpolator?: string, inverse?: boolean,
                                  colors?: string[]}) {
    const steps: any[] = [
      'interpolate',
      ['exponential', 1],
      ['number', ['get', ['to-string', ['get', 'id']], ['literal', travel]]],
    ]

    if (options.interpolator) {
      const valueStep = (options.max - options.min) / 5
      const interpolatorFunction = (<any>d3scales)[options.interpolator]

      for (let i = 0; i <= 5; i++) {
        steps.push(options.min + i * valueStep, interpolatorFunction((options.inverse ? (1 - (i / 5)) : (i / 5))))
      }
    } else if (options.colors && options.colors.length > 1) {
      const valueStep = (options.max - options.min) / (options.colors.length - 1)

      options.colors.forEach((color, i) => {
        steps.push(options.min + i * valueStep, color)
      })
    }

    return steps
  }

  private calculateTravelHeight(travel: ReachableTile, options: {min: number, max: number}) {
    const extrusionHeight = this.options && this.options.extrusionHeight == null ? 1000 : this.options.extrusionHeight

    const steps: any[] = [
      'interpolate',
      ['exponential', 1],
      ['number', ['get', ['to-string', ['get', 'id']], ['literal', travel]]],
      options.min, extrusionHeight,
      options.max, 0
    ]

    return ['case',
      ['has', ['to-string', ['get', 'id']], ['literal', travel]], steps,
       0
    ]
  }

  private calculateFilter() {
    let travel = this.travel || {}
    let statisticsVisible = this.options && this.options.statisticsVisible != null ? this.options.statisticsVisible : true

    if (statisticsVisible) {
      return ['all']
    } else {
      return ['has', ['to-string', ['get', 'id']], ['literal', travel]]
    }
  }

  private calculatePaint() {
    const attribute = (this.options && this.options.statistic && this.options.statistic.id) || 0
    const interpolator = (this.options && this.options.interpolator) || 'interpolateYlOrRd'
    const statisticsStyle = this.options && this.options.statisticsStyle
    let travel = this.travel
    const displayMode = this.options.displayMode != null
                          ? this.options.displayMode
                          : ExtrusionStatisticsTravelLayerDisplayMode.TRAVEL_COLOR_STATISTIC_HEIGHT
    if (statisticsStyle) {
      return statisticsStyle
    }

    const travelScale: ExtrusionStatisticsTravelScale = this.options && this.options.travelScale || DEFAULT_TRAVEL_SCALE

    const paint: any = {
      'fill-extrusion-opacity':
        this.options &&
        (this.options.opacity != null
          ? this.options.opacity
          : 0.5),
    }

    paint['fill-extrusion-color'] = this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], interpolator)

    if (displayMode === ExtrusionStatisticsTravelLayerDisplayMode.TRAVEL_COLOR_STATISTIC_HEIGHT) {
      paint['fill-extrusion-height'] = this.calculateStatisticsHeightStops(this.source.metadata.stats[attribute])

      if (travel) {
        paint['fill-extrusion-color'] = ['case',
          ['has', ['to-string', ['get', 'id']], ['literal', travel]],
          this.calculateTravelColor(travel, travelScale),
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], 'interpolateGreys')
        ]
      }

    } else if (displayMode === ExtrusionStatisticsTravelLayerDisplayMode.TRAVEL_HEIGHT_STATISTIC_COLOR) {
      if (travel) {
        paint['fill-extrusion-height'] = this.calculateTravelHeight(travel, travelScale)

        paint['fill-extrusion-color'] = ['case',
          ['has', ['to-string', ['get', 'id']], ['literal', travel]],
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], interpolator),
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], 'interpolateGreys')
        ]
      }
    } else if (displayMode === ExtrusionStatisticsTravelLayerDisplayMode.FLAT_TRAVEL_COLOR_STATISTIC_COLOR) {
      if (travel) {
        paint['fill-extrusion-color'] = ['case',
          ['has', ['to-string', ['get', 'id']], ['literal', travel]],
          this.calculateTravelColor(travel, travelScale),
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], interpolator)
        ]
      }
    } else if (displayMode === ExtrusionStatisticsTravelLayerDisplayMode.FLAT_TRAVEL_COLOR_STATISTIC_COLOR_INVERSE) {
      if (travel) {
        paint['fill-extrusion-color'] = ['case',
          ['has', ['to-string', ['get', 'id']], ['literal', travel]],
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], interpolator),
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], 'interpolateGreys')
        ]
      }
    } else if (displayMode === ExtrusionStatisticsTravelLayerDisplayMode.FLAT_TRAVEL_COLOR_STATISTIC_COLOR_GRAYSCALE) {
      if (travel) {
        paint['fill-extrusion-color'] = ['case',
          ['has', ['to-string', ['get', 'id']], ['literal', travel]],
          this.calculateTravelColor(travel, travelScale),
          this.calculateStatisticsColorStops(this.source.metadata.stats[attribute], 'interpolateGreys')
        ]
      }
    }

    return paint
  }

  get(): Partial<Layer> {
    if (this.source.metadata) {
      return {
        type: 'fill-extrusion',
        'source-layer': '' + this.source.metadata.id,
        paint: this.calculatePaint(),
        filter: this.calculateFilter(),
      };
    } else {
      return {
        type: 'fill',
        source: EMPTY_GEOJSON,
        paint: {
          'fill-opacity': 0
        }
      };
    }
  }

  async setTravel(reachable: ReachableTile) {
    this.travel = reachable
    this.setVisible(this._visibleLayer)
    this.update()
  }

  /**
   * Set the options of this layer, an alternative to using the observable passed in the constructor
   *
   * @param options
   * @param updatePartial
   */
  setOptions(options: ExtrusionStatisticsTravelLayerOptions) {
    if (this.optionsObservable) {
      console.warn('An options parameter was already passed to this layer ${this.id}, options updates may clash')
    }

    this.options = {...options}
    this.setVisible(this._visibleLayer)
    this.update()
  }


  /**
   * Set the options of this layer, an alternative to using the observable passed in the constructor
   * Only the options properties present in the passed object will be affected
   *
   * @param options
   * @param updatePartial
   */
  setOptionsPartial(options: Partial<ExtrusionStatisticsTravelLayerOptions>) {
    if (this.optionsObservable) {
      console.warn('An options parameter was already passed to this layer ${this.id}, options updates may clash')
    }

    for (let key in options) {
      (<any>this.options)[key] = (<any>options)[key]
    }

    this.setVisible(this._visibleLayer)
    this.update()
  }

  setVisible(visible: boolean) {
    this._visibleLayer = visible

    const actualVisible = visible && !(!this.options.statisticsVisible && !(this.travel && Object.keys(this.travel).length > 0))
    super.setVisible(actualVisible)

    return this
  }

  /**
   * Returns where the layer is currently visible or invisible
   */
  isVisible() {
    return this._visibleLayer
  }
}
