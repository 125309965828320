import { Component, OnInit, Input, forwardRef } from '@angular/core'
import { ComparisonReportTemplate, ComparisonReportTemplateEndpoint } from '../../../../api/comparisonReportTemplate'
import { UserSettings, Company } from '../../../../../common/models'
import {
  PlanningApplicationDataSet,
  PlanningApplicationDataSetEndpoint,
} from '../../../../api/planningApplicationDataSet'
import { ReportTemplate, ReportTemplateEndpoint } from '../../../../api/reportTemplate'
import { DataProfile, DataProfileEndpoint } from '../../../../api/dataProfile'
import { GeoRegion, GeoRegionsEndpoint } from '../../../../api/georegions'
import { MatSelectChange } from '@angular/material'
import { DataSetEndpoint, User } from '../../../../api'
import { FormBuilder, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Indicators, SubscriberComponent } from '@targomo/client'
import { UserSettingsEndpoint } from '../../../../api/userSettings'
import { SelectFasciasDefinition } from '../../../../../common/models/planningApplication'
import { SelectFasciasModel } from '../adminSelectFascias/selectFasciasModel'
import { MobileCatchmentsEndpoint } from '../../../../api/mobileCatchments'

interface SelectableMObileCatchmentCategory {
  category: string
  selected: boolean
}

interface SelectableDataProfile extends DataProfile {
  selected: boolean
}

interface SelectableGeoRegion extends GeoRegion {
  selected: boolean
}

export interface UserCompanyPermissionsData {
  userOrCompany: User | Company
  selectedFascias: SelectFasciasDefinition[]
}

const PERMISSIONS = [
  { key: 'thematicWorkforce', label: ' Workforce Thematic Map' },
  { key: 'thematicPedestrian', label: 'Pedestrian Flow Thematic Map' },
  { key: 'uploadGeoJSON', label: 'Upload GeoJSON/CSV Layers' },
  { key: 'intersectionMode', label: 'Travel Intersection Mode' },
  { key: 'locationsInView', label: 'Locations Present/Not Present' },
  { key: 'zones', label: 'Boundary Layers' },
  { key: 'populationForecast', label: 'Population Forecast Report' },
  { key: 'extendedTravel', label: 'Extended Travel Times' },
  { key: 'downloadPolygonGeojson', label: 'Export travel-time polygons as GeoJSON' },
  { key: 'projectNumber', label: 'Project Number' },
  { key: 'matchpoint', label: 'Matchpoint' },
  { key: 'householdsCapitaSwitch', label: 'Households capita switch' },
  { key: 'dateAddedFilter', label: 'Date added filter' },
  { key: 'withinTool', label: 'Within/Not Within Tool' },
  { key: 'selectFascia', label: 'Select entire Fascia' },
  { key: 'careHome', label: 'Care Home Report' },
  { key: 'roadsVolume', label: 'Road Volume Thematic Map' },
  { key: 'postcodesAnalysis', label: 'Postcodes Analysis' },
  // { key: 'mobileCatchments', label: 'Mobile catchments' },
]

export const EDIT_PERMISSIONS_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AdminEditPermissionsComponent),
  multi: true,
}

@Component({
  selector: 'admin-edit-permissions',
  templateUrl: './adminEditPermissions.component.html',
  styleUrls: ['./adminEditPermissions.component.less'],
  providers: [EDIT_PERMISSIONS_ACCESSOR],
})
export class AdminEditPermissionsComponent extends SubscriberComponent implements OnInit, ControlValueAccessor {
  loading: Promise<any>
  ready = false

  user: any = {}

  dataProfiles: SelectableDataProfile[]
  mobileCatchmentsCategories: SelectableMObileCatchmentCategory[]
  availablePermissions = PERMISSIONS
  availableRegions: SelectableGeoRegion[]
  availableTemplates: ReportTemplate[]

  dataSets: PlanningApplicationDataSet[]
  loadingDataSets: Promise<PlanningApplicationDataSet[]>

  availableComparisonTemplates: (ComparisonReportTemplate & { selected?: boolean })[]

  _onChange: (value: UserCompanyPermissionsData) => void
  _onTouched: (value: UserCompanyPermissionsData) => void

  readyPromise: Promise<any>

  constructor(
    private dataProfileEndpoint: DataProfileEndpoint,
    private dataSetsEndpoint: DataSetEndpoint,
    private planningEndpoint: PlanningApplicationDataSetEndpoint,
    private regionsEndpoint: GeoRegionsEndpoint,
    private route: ActivatedRoute,
    private router: Router,
    private indicators: Indicators,
    private userSettingsEndpoint: UserSettingsEndpoint,
    private templateEndpoint: ReportTemplateEndpoint,
    private comparisonTemplateEndpoint: ComparisonReportTemplateEndpoint,
    private mobileCatchmentsEndpoint: MobileCatchmentsEndpoint
  ) {
    super()
  }

  ngOnInit() {
    this.readyPromise = (async () => {
      this.dataSets = await this.indicators.add((this.loadingDataSets = this.planningEndpoint.findAllFull()))
      this.availableComparisonTemplates = await this.comparisonTemplateEndpoint.findAll()
      this.availableTemplates = [{ id: null, name: 'Default', data: {} }].concat(await this.templateEndpoint.findAll())
      this.dataProfiles = <SelectableDataProfile[]>await this.dataProfileEndpoint.findAll() //.then(dataProfiles => this.dataProfiles = <SelectableDataProfile[]>dataProfiles)
      this.availableRegions = <SelectableGeoRegion[]>await this.regionsEndpoint.findAll() // .then(regions => this.availableRegions = <SelectableGeoRegion[]>regions)
      this.availableRegions = this.availableRegions.filter(
        (profile) => profile && profile.name && profile.name.toLowerCase() !== 'default'
      )
      this.mobileCatchmentsCategories = (await this.mobileCatchmentsEndpoint.categories()) as any[]
    })()
  }

  emit() {
    if (this._onChange) {
      const user = this.user || {}
      const selectedPlanningFascias = new SelectFasciasModel(this.dataSets).getSelectedFascias()

      this.user.permissions = this.user.permissions || {}
      this.user.permissions.comparisonReportTemplates = this.availableComparisonTemplates
        .filter((template) => template.selected)
        .map((template) => template.id)

      this.user.permissions.mobileCatchmentsCategories = this.mobileCatchmentsCategories
        .filter((template) => template.selected)
        .map((template) => template.category)

      user.dataProfiles = this.dataProfiles.filter((profile) => profile.selected).map((profile) => profile.id)
      user.geoRegions = this.availableRegions.filter((profile) => profile.selected).map((profile) => profile.id)

      this._onChange({ userOrCompany: user, selectedFascias: selectedPlanningFascias })
    }
  }

  async writeValue(configValue: UserCompanyPermissionsData) {
    await this.readyPromise

    if (configValue) {
      this.user = configValue.userOrCompany

      this.user.permissions = this.user.permissions || {}
      this.user.permissions.customReport = this.user.permissions.customReport || {
        printShowSizeLegend: true,
        printShowFasciaLegend: true,
      }

      new SelectFasciasModel(this.dataSets, false).updateSelected(configValue.selectedFascias || [])

      this.dataProfiles.forEach((profile) => {
        profile.selected = this.user.dataProfiles && this.user.dataProfiles.indexOf(profile.id) > -1
      })

      this.availableRegions.forEach((profile) => {
        profile.selected = this.user.geoRegions && this.user.geoRegions.indexOf(profile.id) > -1
      })

      this.availableComparisonTemplates.forEach((template) => {
        template.selected =
          this.user.permissions &&
          this.user.permissions.comparisonReportTemplates &&
          this.user.permissions.comparisonReportTemplates.indexOf(template.id) > -1
      })

      const selectedMobileCAtchments = new Set(
        (this.user.permissions && this.user.permissions.mobileCatchmentsCategories) || []
      )

      this.mobileCatchmentsCategories.forEach((profile) => {
        profile.selected = selectedMobileCAtchments.has(profile.category)
      })
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {}

  toggleProfile(profile: any) {
    profile.selected = !profile.selected
    this.emit()
  }

  toggleMobileCatchment(profile: any) {
    profile.selected = !profile.selected
    this.emit()
  }

  toggleRegion(profile: any) {
    profile.selected = !profile.selected
    this.emit()
  }

  toggleGeneral(key: string) {
    this.user.permissions.customReport[key] = !this.user.permissions.customReport[key]
    this.emit()
  }

  togglePermission(key: string) {
    this.user.permissions = this.user.permissions || {}
    this.user.permissions[key] = !this.user.permissions[key]
    this.emit()
  }

  changeComparisonTemplate(value: any) {
    value.selected = !value.selected
    this.emit()
  }

  changeDefaultTemplate(event: MatSelectChange) {
    this.user.permissions.reportTemplateId = event.value
    this.emit()
  }

  planningSelectionChange(event: any) {
    this.emit()
  }

  updateGapReport(value: any) {
    this.user.permissions.gapReport = value
  }
}
