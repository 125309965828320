import { StatisticsKeyMeta } from '@targomo/core'
import { Component, Input, ChangeDetectionStrategy, ElementRef, ViewChild, OnChanges, Output } from '@angular/core'
import d3scales from '../mapbox/util/scales'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tgm-statistic-legend',
  templateUrl: './statistic-legend.component.html',
  styleUrls: ['./statistic-legend.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TgmStatisticLegendComponent implements OnChanges {

  id: number;
  /**
   * d3 interpolator
   */
  @Input() interpolator: string | ((value: number) => string) = 'interpolateYlOrRd'

  /**
   * Optional Opacity Input that takes care of opaciting the legend with the same amount as
   * the layer you are visualizing
   */
  @Input() opacity: number = 1

  /**
   * Statistics metadata model. You'll get this with a call to `TargomoClient.statistics.metadataKey()`
   */
  @Input() model: StatisticsKeyMeta

  /**
   * labels to the left and the right of the legend. defaults to min and max value
   */
  @Input() labels?: { min?: number, max?: number, center?: string }

  /**
   * Represents the correspondent statistic value of the mouse x-position as long as the user hovers on the legend.
   */
  @Output() hoverValue$: Observable<number>;

  startLabel: number = 0
  endLabel: number = 0
  centerLabel: string

  tooltipX$ = new BehaviorSubject<number>(0);
  hovered$ = new BehaviorSubject<boolean>(false);

  @ViewChild('scale') scaleEl: ElementRef

  constructor(elRef: ElementRef) {
    this.id = Math.floor(Math.random() * 1000);
    this.hoverValue$ = this.tooltipX$.pipe(
      map(x => !!x ? (((x / elRef.nativeElement.clientWidth) * (this.model.max - this.model.min)) +  this.model.min) : null )
    )
  }

  handleMouseover(mouseEvent: MouseEvent) {
    console.log(mouseEvent)
    this.tooltipX$.next(mouseEvent.offsetX)
  }

  ngOnChanges() {
    if (!this.model) {
      console.warn('Model was not available initially in TgmStatisticLegendComponent')
      return
    }

    const svg = d3.select(this.scaleEl.nativeElement)
      .html('')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '30px')
      .attr('opacity', this.opacity)
      .attr('id', 'scale-legend')

    const defs = svg.append('defs')

    const linearGradient = defs.append('linearGradient')
      .attr('id', `linear-gradient-${this.id}`)

    linearGradient
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%')

    let interpolator = typeof this.interpolator === 'function' ? this.interpolator : (<any>d3scales)[this.interpolator]

    for (let i = 0; i <= 10; i++) {
      linearGradient.append('stop')
        .attr('offset', (i * 10) + '%')
        .attr('stop-color', interpolator(i / 10))
    }

    svg.append('rect')
      .attr('class', 'legend-scale')
      .attr('width', '100%')
      .attr('height', '30px')
      // .attr('transform', 'translate(0,20)')
      .style('fill', `url(#linear-gradient-${this.id})`)

    this.startLabel = (this.labels && this.labels.min) || this.model.min;
    this.endLabel = (this.labels && this.labels.max) || this.model.max;
    this.centerLabel = (this.labels && this.labels.center) || this.model.descriptions.en
  }
}
