import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocationCollection } from '../../types/location-collection';

@Component({
  selector: 'tgm-layer-interaction',
  templateUrl: './layer-interaction.component.html',
  styleUrls: ['./layer-interaction.component.scss']
})
export class TgmLayerInteractionComponent implements OnChanges {
  layerInput: FormControl;

  @Input() inputPlaceholder = 'add to layer';
  @Input() activeLayers: LocationCollection[] = [];
  @Input() inactiveLayers: LocationCollection[] = [];
  @Input() multiSelect = true;
  @Output() activeLayersChanged: EventEmitter<LocationCollection[]> = new EventEmitter<LocationCollection[]>();

  constructor() {
    this.layerInput = new FormControl('');
  }

  ngOnChanges(): void {
    this.layerInput.reset();
  }

  addtoActiveLayers(layer: LocationCollection): void {
    // add to Active Layers
    this.activeLayers.push(layer);

    // Remove from Inactive Layers
    this.inactiveLayers = this.inactiveLayers.filter(l => l.id !== layer.id);

    // clear the autocomplete input
    this.layerInput.setValue('');

    // Broadcast Changes
    this.activeLayersChanged.emit(this.activeLayers);
  }

  removeFromActiveLayers(layer: LocationCollection): void {
    // Add to Inactive Layers
    this.inactiveLayers.push(layer);

    // Remove from Active Layers
    this.activeLayers = this.activeLayers.filter(l => l.id !== layer.id);

    // Broadcast Changes
    this.activeLayersChanged.emit(this.activeLayers);
  }

  isAutocompleteEnabled(): boolean {
    return this.multiSelect ? this.inactiveLayers.length > 0 : this.activeLayers.length < 1;
  }
}
