
import {from as observableFrom,  BehaviorSubject } from 'rxjs';
import * as turf from '@turf/turf'

import { TgmMapboxComponent } from '../../mapbox.component'
import { GeojsonMapSource } from '../../sources'
import { DefaultMapLayer } from '../simple'



export class MeasureLayer {
  readonly distance: BehaviorSubject<number>
  readonly active: BehaviorSubject<boolean>

  private geoJsonPoints: any
  private geoJsonLineString: any
  private geoJsonSource: GeojsonMapSource
  private pointsLayer: DefaultMapLayer
  private lineLayer: DefaultMapLayer
  private map: mapboxgl.Map

  constructor(private mapBoxView: TgmMapboxComponent) {

    const pointsLayerConf = observableFrom([{
      id: 'measure-points',
      type: 'circle',
      source: 'geojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#222',         // TODO make style configurable
      },
      filter: ['in', '$type', 'Point']
    }])

    const lineLayerConf = observableFrom([{
      id: 'measure-lines',
      type: 'line',
      source: 'geojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#222',         // TODO make style configurable
        'line-width': 2.5,
        'line-opacity': 0.7
      },
      filter: ['in', '$type', 'LineString']
    }])

    this.geoJsonPoints = {
      'type': 'FeatureCollection',
      'features': <any>[]
    }

    this.geoJsonLineString = {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': <any>[]
      }
    }

    this.geoJsonSource = new GeojsonMapSource(this.geoJsonPoints)

    this.pointsLayer = new DefaultMapLayer(mapBoxView, this.geoJsonSource, lineLayerConf)
    this.lineLayer = new DefaultMapLayer(mapBoxView, this.geoJsonSource, pointsLayerConf)

    // this.mapBoxView.addMapSource(this.geoJsonSource)

    this.distance = new BehaviorSubject<number>(0)
    this.active = new BehaviorSubject<boolean>(true)

    this.init()
  }

  toggleActive(active?: boolean) {
    if (typeof active !== 'undefined') {
      active ? this.activate() : this.deactivate()
    } else {
      this.active.getValue() ? this.deactivate() : this.activate()
    }
  }

  reset() {
    this.geoJsonPoints = {
      'type': 'FeatureCollection',
      'features': <any>[]
    }
    this.geoJsonLineString.geometry.coordinates = <any>[]
    this.geoJsonPoints.features = <any>[]
    this.geoJsonSource.updateValue(this.geoJsonPoints)
    this.distance.next(0)
  }

  private init() {
    // this.mapBoxView.addMapLayer(this.pointsLayer)
    // this.mapBoxView.addMapLayer(this.lineLayer)
    this.geoJsonSource.updateValue(this.geoJsonPoints)
    this.addClickListener()
    this.active.next(true)
  }

  private clickListener = (e: mapboxgl.EventData) => {

    let features = this.map.queryRenderedFeatures(e.point, { layers: [this.pointsLayer.id] });

    if (this.geoJsonPoints.features.length > 1) {
      this.geoJsonPoints.features.pop();
    }

    if (features.length) {
      let id = features[0].properties.id;
      this.geoJsonPoints.features = this.geoJsonPoints.features.filter((point: any) => {
        return point.properties.id !== id;
      });
    } else {
      let point = {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            e.lngLat.lng,
            e.lngLat.lat
          ]
        },
        'properties': {
          'id': String(new Date().getTime())
        }
      };

      this.geoJsonPoints.features.push(point);
    }

    if (this.geoJsonPoints.features.length > 1) {
      this.geoJsonLineString.geometry.coordinates = this.geoJsonPoints.features.map(function (point: any) {
        return point.geometry.coordinates;
      });

      this.geoJsonPoints.features.push(this.geoJsonLineString);
      this.distance.next(turf.lineDistance(this.geoJsonLineString))
    }

    this.geoJsonSource.updateValue(this.geoJsonPoints)
  }

  private async addClickListener() {
    this.map = await this.mapBoxView.getMap()
    this.map.on('click', this.clickListener)
  }

  private async removeClickListener() {
    this.map.off('click', this.clickListener)
  }

  private activate() {
    if (!this.active.getValue()) {
      // this.init()          // TODO re-implement when layers are removed

      this.pointsLayer.setVisible(true)  // quickfix. Should be removed instead of hidden
      this.lineLayer.setVisible(true)  // quickfix. Should be removed instead of hidden

      this.active.next(true);
    }
  }

  private deactivate() {
    if (this.active.getValue()) {

      this.removeClickListener()

      this.pointsLayer.setVisible(false)  // quickfix. Should be removed instead of hidden
      this.lineLayer.setVisible(false)  // quickfix. Should be removed instead of hidden
      this.addClickListener()

      // this.mapBoxView.removeMapLayer(this.pointsLayer)  // TODO @tomis Does not work yet
      // this.mapBoxView.removeMapLayer(this.lineLayer)  // TODO @tomis Does not work yet
      this.active.next(false);
      // this.reset()
    }
  }

}
