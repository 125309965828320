import { Injectable, NgZone } from '@angular/core'
import { MatSnackBar } from '@angular/material'
import { Observable } from 'rxjs/Observable';
import { isPromise } from '@targomo/common';

/**
 * Global progress indicator service (strip on top of screen)
 * It displays the progress indicator strip on top when a task is running and hides it when it is done.
 * When multiple tasks are running at the same time, the strip is shown when the first one starts and the last one is done.
 * when an error happens a notification is shown
 */
@Injectable()
export class Indicators {
  private _count: number = 0

  constructor(private snackBar: MatSnackBar, private ngZone: NgZone) {
  }

  private set count(val: number) {
    if (val > 0) {
      this._count = val;
    } else {
      this._count = 0;
    }
  }

  private get count() {
    return this._count;
  }

  /**
   * Show the global progress indicator until promise is resolved
   */
  add<T>(promise: Observable<T>): Observable<T>
  add<T>(promise: Promise<T>): Promise<T>
  add<T>(original: Observable<T> | Promise<T>): Observable<T> | Promise<T> {
    let promise = isPromise(original) ? original : original.toPromise()

    this.ngZone.run(() => this.count++)

    const callback = () => {
      this.ngZone.run(() => this.count--)
    }

    const callbackError = (e: Error) => {
      callback()
      this.error(e)
    }

    promise.then(callback, callbackError)
    return original
  }

  /**
   * Decrease the counter by 1.
   * If counter is 0 no loading bar will be displayed.
   * Useful if a request is not relevant anymore and no indicator should be displayed because of it.
   */
  removeOne() {
    this.ngZone.run(() => this.count--)
  }

  /**
   * Should the indicator be visible or not (used by the template that actually displays the indicator)
   */
  visible() {
    return this.count > 0
  }

  /**
   * Display an error using a snackbar widget. The snackbar goes away after 3 seconds
   */
  error(e: any) {
    this.snackBar.open((e && e.message) || e || 'There was an error', 'Ok',  {
      duration: 3000
    })
  }
}
