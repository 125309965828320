import { Component, Input } from '@angular/core';

/**
 * Use this inside `tgm-action-items`
 *
 */
@Component({
  selector: 'tgm-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss']
})

export class TgmActionItemComponent {

  @Input() disabled = false;
}
