import { graphs, GraphValue, Indicators } from '@targomo/client'
import { object as objects } from '@targomo/common'
import { Statistics, StatisticsGroup, TravelTypeEdgeWeightOptions, ExtendedStatisticsKey } from '@targomo/core'
import { Observable } from 'rxjs/Observable'
import { STATISTICS_FORECAST } from '../../common/constants/constantsForecast'
import { STATISTICS_EXTENDED } from '../../common/constants/statisticsExtended'
import { AllStatistics } from '../../common/models/statistics/statistics'
import { canonicalPositions, Place } from '../api/place'
import { ZoneLayersEndpoint } from '../api/sectors'
import { StatisticsEndpoint } from '../api/statistics'
import { AsyncObservable } from '../util/asyncObservable'
import { AppModel } from './appModel.service'
import { STATISTICS, STATISTICS_GROUP, STATISTICS_GROUP_WORKFORCE, STATISTICS_WORKFORCE } from './constants'
import { AbstractLocation } from './entities/place'
import { FeatureModel } from './featureModel'
import { INTERSECTION_THRESHOLD } from './placesModel'
import { SettingsModel } from './settingsModel'
import { ZoneLayersModel } from './zoneLayersModel'
import { CatchmentMode } from './matchpoint/combinedSources'
import { MobileCatchmentsEndpoint } from '../api/mobileCatchments'

import { StatisticsValues } from '@targomo/core'

export const POPULATION_FORECAST_KEYS = [
  'age0to4',
  'age5to9',
  'age10to14',
  'age15to19',
  'age20to24',
  'age25to29',
  'age30to34',
  'age35to39',
  'age40to44',
  'age45to49',
  'age50to54',
  'age55to59',
  'age60to64',
  'age65to69',
  'age70to74',
  'age75to79',
  'age80to84',
  'age85to89',
  'age90Plus',
]

export const POPULATION_FORECAST_LABELS = [
  '0-4',
  '5-9',
  '10-14',
  '15-19',
  '20-24',
  '25-29',
  '30-34',
  '35-39',
  '40-44',
  '45-49',
  '50-54',
  '55-59',
  '60-64',
  '65-69',
  '70-74',
  '75-79',
  '80-84',
  '85-89',
  '90+',
]

const STATISTICS_COMBINED = STATISTICS.concat(STATISTICS_FORECAST as any, STATISTICS_EXTENDED as any)

export class StatisticsModel {
  public readonly census = new AsyncObservable<AllStatistics>(<any>this.initCensus())
  public readonly populationForecast: Observable<GraphValue[]>

  constructor(
    private indicators: Indicators,
    private sources: Observable<Place[]>,
    private travelOptions: Observable<TravelTypeEdgeWeightOptions>,
    private intersectionMode: Observable<string>,
    private statisticsEndpoint: StatisticsEndpoint,
    private settingsModel: SettingsModel,
    private appModel: AppModel,
    private featuresModel: FeatureModel,
    private zoneLayersModel: ZoneLayersModel,
    private zoneLayersEndpoint: ZoneLayersEndpoint,
    private mobileCatchmentsEndpoint: MobileCatchmentsEndpoint
  ) {
    this.populationForecast = this.initPopulationForecast()
  }

  initCensus() {
    // const STATISTICS_COMBINED = STATISTICS.concat(STATISTICS_FORECAST as any, STATISTICS_EXTENDED as any)

    const statisticsUpdates = Observable.combineLatest(
      this.sources,
      this.travelOptions,
      this.intersectionMode,
      this.appModel.settings.exclusiveTravelUpdates,
      this.featuresModel.travelTimesVisibleUpdates,
      this.zoneLayersModel.selectionExistsUpdates,
      this.zoneLayersModel.visibleLayerFeaturesGeometry,
      this.appModel.places.inactiveSources,
      this.settingsModel.disableStatisticsUpdates,
      this.featuresModel.catchmentMode
    )
      .debounceTime(20)
      .map(
        async ([
          sources,
          options,
          intersectionMode,
          exclusiveMode,
          travelTimesVisible,
          zonesVisible,
          geometry,
          inactiveSources,
          disableStatistics,
          catchmentMode,
        ]) => {
          if (disableStatistics) {
            console.log('Statistics disabled')
            return { sources: sources, statistics: undefined }
          }

          if (catchmentMode === CatchmentMode.MOBILE) {
            const statisticsResult = await this.calculateMobileForSources(sources)
            return { sources, statistics: statisticsResult }
          }

          if (zonesVisible) {
            if (!geometry) {
              return { sources: sources, statistics: undefined }
            }

            // TODO::  only for testing
            // TEMPORARY
            // sources = [this.appModel.places.places.getValue()[0]]
            // const geometry = await this.zoneLayersModel.visibleLayerFeaturesGeometry.filter(item => !!item).take(1).toPromise()

            const result = await this.indicators.add(
              Promise.all([
                // this.zoneLayersEndpoint.statistics(geometry.properties.alternate, {statistics: STATISTICS, statisticsGroup: STATISTICS_GROUP}),
                // this.zoneLayersEndpoint.statistics(geometry.properties.alternate, {statistics: STATISTICS_WORKFORCE, statisticsGroup: STATISTICS_GROUP_WORKFORCE}),
                this.settingsModel.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {
                  crs: 25833,
                  statistics: STATISTICS_COMBINED,
                  statisticsGroup: STATISTICS_GROUP,
                }),
                this.settingsModel.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {
                  crs: 25833,
                  statistics: STATISTICS_WORKFORCE,
                  statisticsGroup: STATISTICS_GROUP_WORKFORCE,
                }),
              ])
            )

            const merged = objects.assignNotExists({}, result[0].values.sum, result[1].values.sum)
            for (let key in merged) {
              ;(merged as any)[key].total = (merged as any)[key].value
              ;(merged as any)[key].values = { 0: (merged as any)[key].value }
            }

            const regions = [await this.statisticsEndpoint.singleRegion(geometry.properties.centers)]
            const statisticsResult = new AllStatistics(merged, (await this.appModel.publicSettings).gbpToEuroRate)
            return { sources: regions, statistics: statisticsResult }
          } else if (!sources.length || !travelTimesVisible) {
            return { sources: sources, statistics: undefined }
          }

          if (sources.length > INTERSECTION_THRESHOLD && intersectionMode !== 'union') {
            return { sources: sources, statistics: undefined }
          }

          const statisticsResult = await this.calculateForSources(sources, options, inactiveSources, intersectionMode)
          // this.decorateWithRegion(sources)

          // const result: StatisticsGroup[] = await this.indicators.add(
          //   Promise.all([
          //     this.settingsModel.clientStatistics.statistics.combined(canonicalPositions(sources), {
          //       inactiveSources,
          //       intersectionMode,
          //       statistics: STATISTICS_COMBINED,
          //       statisticsGroup: STATISTICS_GROUP,
          //       ...options,
          //     }),
          //     this.settingsModel.clientStatistics.statistics.combined(canonicalPositions(sources), {
          //       inactiveSources,
          //       intersectionMode,
          //       statistics: STATISTICS_WORKFORCE,
          //       statisticsGroup: STATISTICS_GROUP_WORKFORCE,
          //       ...options,
          //     }),
          //   ])
          // )

          // const merged = objects.assignNotExists({}, result[0], result[1])

          // // const result: api.StatisticsGroup = await this.indicators.add(api.statistics(sources, STATISTICS, STATISTICS_GROUP, options))
          // const statisticsResult = new AllStatistics(merged, (await this.appModel.publicSettings).gbpToEuroRate)
          return { sources, statistics: statisticsResult }
        }
      )

    return Observable.combineLatest(
      statisticsUpdates,
      this.settingsModel.regonalIndicesUpdates,
      this.settingsModel.reportPerCapitaHouseholdsUpdates
    ).map(async ([statisticsPromise, regional, reportPerCapitaHouseholds]) => {
      const { sources, statistics } = await statisticsPromise

      if (statistics) {
        const { region, calculatedRegion, country } = this.singleRegion(sources)
        statistics.updateIndices(region, calculatedRegion, country, reportPerCapitaHouseholds)
      }

      return statistics
    })
  }

  async calculateMobileForSources(sources: AbstractLocation[]): Promise<any> {
    if (sources.length != 1) {
      return undefined
    }

    const data = (await this.indicators.add(this.mobileCatchmentsEndpoint.catchment(sources[0]))).statistics

    const valuesCensus = STATISTICS_COMBINED.reduce((acc, cur) => {
      const total = data[cur.id]

      acc[cur.name] = {
        total,
        values: { 0: total },
      }

      return acc
    }, {} as any)

    const valuesWorkforce = STATISTICS_WORKFORCE.reduce((acc, cur) => {
      const total = data[cur.id]

      acc[cur.name] = {
        total,
        values: { 0: total },
      }

      return acc
    }, {} as any)

    this.decorateWithRegion(sources)

    const merged = objects.assignNotExists({}, valuesCensus, valuesWorkforce)

    return new AllStatistics(merged, (await this.appModel.publicSettings).gbpToEuroRate).setCatchmentMode(
      CatchmentMode.MOBILE
    )
  }

  async calculateForSources(
    sources: AbstractLocation[],
    options: TravelTypeEdgeWeightOptions,
    inactiveSources: AbstractLocation[],
    intersectionMode: string
  ) {
    if (sources.length > INTERSECTION_THRESHOLD && intersectionMode !== 'union') {
      return undefined
    }

    const STATISTICS_COMBINED = STATISTICS.concat(STATISTICS_FORECAST as any, STATISTICS_EXTENDED as any)
    this.decorateWithRegion(sources)

    const result: StatisticsGroup[] = await this.indicators.add(
      Promise.all([
        this.settingsModel.clientStatistics.statistics.combined(canonicalPositions(sources), {
          inactiveSources,
          intersectionMode,
          statistics: STATISTICS_COMBINED,
          statisticsGroup: STATISTICS_GROUP,
          ...options,
        }),
        this.settingsModel.clientStatistics.statistics.combined(canonicalPositions(sources), {
          inactiveSources,
          intersectionMode,
          statistics: STATISTICS_WORKFORCE,
          statisticsGroup: STATISTICS_GROUP_WORKFORCE,
          ...options,
        }),
      ])
    )

    const merged = objects.assignNotExists({}, result[0], result[1])

    return new AllStatistics(merged, (await this.appModel.publicSettings).gbpToEuroRate)
  }

  async calculateForGeometry(geometry: any) {
    if (!geometry) {
      return undefined
    }

    const STATISTICS_COMBINED = STATISTICS.concat(STATISTICS_FORECAST as any, STATISTICS_EXTENDED as any)

    const result = await this.indicators.add(
      Promise.all([
        this.settingsModel.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {
          crs: 25833,
          statistics: STATISTICS_COMBINED,
          statisticsGroup: STATISTICS_GROUP,
        }),
        this.settingsModel.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {
          crs: 25833,
          statistics: STATISTICS_WORKFORCE,
          statisticsGroup: STATISTICS_GROUP_WORKFORCE,
        }),
      ])
    )

    const merged = objects.assignNotExists({}, result[0].values.sum, result[1].values.sum)
    for (let key in merged) {
      ;(merged as any)[key].total = (merged as any)[key].value
      ;(merged as any)[key].values = { 0: (merged as any)[key].value }
    }

    const regions = [await this.statisticsEndpoint.singleRegion(geometry.properties.centers)]
    return new AllStatistics(merged, (await this.appModel.publicSettings).gbpToEuroRate)
  }

  private async decorateWithRegion(sources: Place[]) {
    const regions = await this.statisticsEndpoint.locationRegion(sources)

    sources.forEach((source) => {
      source.region = regions[source.id] && regions[source.id].region
      source.country = regions[source.id] && regions[source.id].country
    })
  }

  public singleRegion(
    places: { region?: string; country?: string }[],
    useRegionalIndices = this.settingsModel.displaySettings.getValue().regonalIndices
  ) {
    let region = null
    let country = null

    for (let i = 0; i < places.length; i++) {
      if (region == null) {
        region = places[i].region
        country = places[i].country
      }

      if (region != places[i].region) {
        region = country
        // break
      }

      if (country != places[i].country) {
        region = 'GB'
        country = 'GB'
        break
      }
    }

    let calculatedRegion = region || ''
    let settingsRegion = calculatedRegion
    if (!useRegionalIndices) {
      settingsRegion = country || 'GB'
    }

    return { region: settingsRegion, calculatedRegion, country: country || 'GB' }
  }

  public async createGraph(statistics: Statistics) {
    const travelOptions = await this.travelOptions.take(1).toPromise()
    const travelMinuteGroupping = await this.settingsModel.travelMinutesGroupingUpdates.take(1).toPromise()
    const factor = Math.round(travelMinuteGroupping.minuteLabelFactor) || 1

    const model = graphs.fromStatistics(
      statistics,
      factor * (travelOptions.maxEdgeWeight > 900 ? 120 : 60),
      travelOptions.maxEdgeWeight,
      { isMinutes: true, round: true }
    )

    return [model]
  }

  private initPopulationForecast() {
    return Observable.combineLatest(this.census.value, this.settingsModel.populationForecastRangeUpdates).map(
      ([census, range]) => {
        if (!census) {
          return null
        }

        const keys = POPULATION_FORECAST_KEYS
        const labels = POPULATION_FORECAST_LABELS

        const v1 = {
          key: '' + range.min,
          values: [] as any,
        }

        const v2 = {
          key: '' + range.max,
          values: [] as any,
        }

        labels.forEach((l, i) => {
          v1.values.push({
            label: l,
            xValue: i,
            value: census.projectedPopulation.year[range.min][keys[i]].total,
          })

          v2.values.push({
            label: l,
            xValue: i,
            value: census.projectedPopulation.year[range.max][keys[i]].total,
          })
        })

        return [v1, v2] as GraphValue[]
      }
    )
  }
}
