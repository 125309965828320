import { Component, Input, NgZone } from '@angular/core';
import { TgmMapboxComponent } from '../mapbox/mapbox.component'

function isStructure(value: number[] | {label: string, value: number}[]): value is {label: string, value: number}[] {
  return (value[0] as any).label
}

@Component({
  selector: 'tgm-map-scale',
  template: `
    <div [hidden]="!mapView">
      <div class="label-container" [style.width]="width" [matMenuTriggerFor]="menu">
        {{label}}

        <div class="menu-select">
          <i class="material-icons">keyboard_arrow_down</i>
        </div>
      </div>

      <mat-menu #menu="matMenu">
        <ng-container *ngIf="!menuOptionsLabels">
          <button *ngFor="let option of menuOptions" (click)="setZoom(option)"
                mat-menu-item>1<span class="unit">px</span> : {{option}}<span class="unit">m</span></button>
        </ng-container>
        <ng-container *ngIf="!!menuOptionsLabels">
          <button *ngFor="let option of menuOptionsLabels; let i = index" (click)="setZoom(option.value)"
                mat-menu-item>{{option.label}}</button>
        </ng-container>
      </mat-menu>
    </div>
  `,
  styles: [`
    .label-container {
      padding: 5px;
      font-size: 9px;
      border: 2px solid black;
      border-top: none;
      color: black;
      position: relative;
      background: white;
    }

    .menu-select {
      position: absolute;
      right: 2px;
      top: 5px;
    }

    .menu-select i.material-icons {
      font-size 14px;
    }

    .label-container:hover {
      background: #f0f0f0;
    }

    .menu {
      background: white;
    }

    .material-icons {
      font-size: 12px !important;
    }

    :host {
      pointer-events: all;
      cursor: pointer;
      position: relative;
    }

    .unit {
      color: #555;
      margin-left: 3px;
      font-size: 13px;
    }
  `]
})
export class TgmMapScaleComponent {

  private unit: string // TODO: for now only meters,
  private borderPaddingPixels: number = 14 // padding 5px x 2 + border 2px x 2
  // @Input() private unit: string
  @Input() mapView: TgmMapboxComponent
  @Input() menuOptions: ({label: string, value: number}[]) | (number[]) = [10, 50, 100, 500, 1000, 5000, 10000]

  width: string = ''
  label: string = ''
  widthPixels: number = 0

  private viewInitialized: boolean = false
  private map: mapboxgl.Map

  menuOptionsLabels: {label: string, value: number}[] = []

  constructor(private zone: NgZone) {
  }

  async ngOnInit() {
    this.ngOnChanges()
  }

  async ngOnChanges() {
    this.updateLabels()

    if (!this.mapView) {
      return
    }

    if (this.viewInitialized) {
      return
    }

    this.viewInitialized = true

    const map = this.map = await this.mapView.getMap()
    map.on('zoom', (event: any) => {
      this.updateScale()
    })

    map.on('move', (event: any) => {
      this.updateScale()
    })

    this.updateScale()
  }

  private updateLabels() {
    if (this.menuOptions && this.menuOptions.length) {
      if (isStructure(this.menuOptions)) {
        this.menuOptionsLabels = this.menuOptions
      } else {
        this.menuOptionsLabels = null
      }
    }
  }

  ngOnDestroy() {
    this.map.off('zoom', this.updateScale)
    this.map.off('move', this.updateScale)
  }

  async setZoom(scale: number) {
    const map = await this.mapView.getMap()

    scale = scale * 2

    const y = (<any>map)._container.clientHeight / 2
    const latlng  =  map.unproject([0, y])
    const zoomPow = (156543.03392 * Math.cos(latlng.lat * Math.PI / 180)) / scale
    const zoom    = Math.log(zoomPow) / Math.log(2)

    map.setZoom(zoom)
  }

  // Taken from mapbox-gl
  updateScale = () => {
    const map = this.map
    const maxWidth = 100

    const y = (<any>map)._container.clientHeight / 2

    const maxMeters = this.getDistance(map.unproject([0, y]), map.unproject([maxWidth, y]))
    if (this.unit === 'imperial') {
      const maxFeet = 3.2808 * maxMeters
      if (maxFeet > 5280) {
          const maxMiles = maxFeet / 5280
          this.setScale(maxWidth, maxMiles, 'mi')
      } else {
        this.setScale(maxWidth, maxFeet, 'ft')
      }
    } else if (this.unit === 'nautical') {
      const maxNauticals = maxMeters / 1852
      this.setScale(maxWidth, maxNauticals, 'nm')
    } else {
      this.setScale(maxWidth, maxMeters, 'm')
    }
  }

  // Taken from mapbox-gl
  private setScale(maxWidth: number, maxDistance: number, unit: string) {
    this.zone.run(() => {
      let distance = this.getRoundNum(maxDistance)
      const ratio = distance / maxDistance

      if (unit === 'm' && distance >= 1000) {
        distance = distance / 1000
        unit = 'km'
      }

      this.widthPixels = (maxWidth * ratio) - this.borderPaddingPixels
      this.width = `${this.widthPixels}px`
      this.label = distance + unit
    })
  }

  // Taken from mapbox-gl
  private getDistance(latlng1: any, latlng2: any) {
    const R = 6371000

    const rad = Math.PI / 180,
        lat1 = latlng1.lat * rad,
        lat2 = latlng2.lat * rad,
        a = Math.sin(lat1) * Math.sin(lat2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad)

    const maxMeters = R * Math.acos(Math.min(a, 1))
    return maxMeters
  }

  // Taken from mapbox-gl
  private getRoundNum(num: number) {
    const pow10 = Math.pow(10, (`${Math.floor(num)}`).length - 1)
    let d = num / pow10

    d = d >= 10 ? 10 :
        d >= 5 ? 5 :
        d >= 3 ? 3 :
        d >= 2 ? 2 : 1

    return pow10 * d
  }
}
