

/**
 * Parse a given url with the use of an anchor element
 *
 * @param  string url - the url to parse
 * @return - the parsed url, anchor element
 */
export function urlParse(url: string): HTMLAnchorElement {
  let a = document.createElement('a')
  a.href = url

  // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
  if (a.host === '') {
    a.href = a.href
  }

  return a
}

/**
 * Test whether or not a given url is same origin
 *
 * @param url  - url to test
 * @param origins - additional origins to test against
 * @return  - true if url is same origin
 */
export function isSameOrigin(urlOriginal: string, origins?: any[]): boolean {
  const url = urlParse(urlOriginal)
  origins = origins && [].concat(origins) || []
  origins = origins.map(urlParse)
  origins.push(window.location)

  origins = origins.filter(function(o) {
    let hostnameCheck = url.hostname === o.hostname
    let protocolCheck = url.protocol === o.protocol
    // 2nd part of the special treatment for IE fix (see above):
    // This part is when using well-known ports 80 or 443 with IE,
    // when $window.location.port==='' instead of the real port number.
    // Probably the same cause as this IE bug: https://goo.gl/J9hRta
    let portCheck = url.port === o.port || o.port === '' && (url.port === '80' || url.port === '443')

    return hostnameCheck && protocolCheck && portCheck
  })

  return origins.length >= 1
}
