import {Component, Input, ElementRef, EventEmitter, Output} from '@angular/core'

function cancel(event: any) {
  event.stopPropagation()
  event.preventDefault()
  return false
}

@Component({
  selector: 'tgm-file-uploader',
  styleUrls: ['./file-uploader.component.less'],
  templateUrl: './file-uploader.component.html'
})
export class TgmFileUploaderComponent {
  @Input() filter: string = '.csv,.json,.geojson'
  @Input() placeholder: string
  @Input() background: string

  @Output() selected = new EventEmitter<any>()

  error: string
  fileName: string
  selectionExists: boolean = false

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {
    const element: Element = this.element.nativeElement
    const self = this

    const fileElement = element.querySelector('[name=file]')
    fileElement.addEventListener('change', function(this: any) {
      self.selectFiles(this.files)
    })

    const fileSelectElement = element.querySelector('.file-select')
    fileSelectElement.addEventListener('click', () => {
      this.selectFile()
    })

    function dragEnter(event: any) {
      cancel(event)
      element.classList.add('drag-hover')
    }

    function dragLeave(event: any) {
      cancel(event)
      element.classList.remove('drag-hover')
    }

    const dropFile = (event: any) => {
      cancel(event)
      element.classList.remove('drag-hover')
      self.selectFiles(event.dataTransfer.files)
    }

    element.addEventListener('dragover',  cancel, false)
    element.addEventListener('dragenter', dragEnter, false)
    element.addEventListener('dragleave', dragLeave, false)
    element.addEventListener('drop',      dropFile, false)

    const bodyElement = document.querySelector('body')
    // Prevent accidental drops outside of box from causing unwanted navigation
    bodyElement.addEventListener('drop',     cancel, false)
    bodyElement.addEventListener('dragover', cancel, false)
  }

  selectFiles(fileList: FileList) {
    this.error = null

    const files: File[] = []
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i])
    }

    this.fileName = files && files.map(file => file.name).join(', ')
    this.selectionExists = true
    this.selected.emit(files)
  }

  ngOnDestroy() {
    const bodyElement = document.querySelector('body')

    bodyElement.removeEventListener('drop',     cancel, false)
    bodyElement.removeEventListener('dragover', cancel, false)
  }

  selectFile() {
    const element: Element = this.element.nativeElement
    const inputElement: any = element.querySelector('[name=file]')
    inputElement.click()
  }

  clearSelection(event?: any) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.background = null
    this.fileName = null
    this.selectionExists = false
    this.selected.emit(null)
  }
}
