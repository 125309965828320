import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimePickerService } from './date-time-picker.service';

@Pipe({
  name: 'todayPipe'
})
export class TodayPipe implements PipeTransform {

  constructor(private datePipe: DatePipe, private dateService: DateTimePickerService) {}

  transform(value: Date, args?: any): any {

    if (this.dateService.isToday(value)) {
      return 'Heute';
    }

    if (this.dateService.isTomorrow(value)) {
      return 'Morgen';
    }

    if (this.dateService.isDayAfterTomorrow(value)) {
      return 'Übermorgen';
    }
    return this.datePipe.transform(value, 'dd.MM.yyyy')
  }
}
