import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tgm-useravatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmUserAvatarComponent implements OnInit {

  /**
   * Name to be visualized. Eg. "John Doe"
   */
  @Input() private name: string;

  constructor() { }

  ngOnInit() {
  }

  getInitials() {
    if (!this.name) {
      return '-';
    }
    const initials = this.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

}
