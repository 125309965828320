import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core'
import { TRAVEL_COLORS } from '../../../types/colors'
import { NumberOrValueLabel, ValueLabelFunctions } from '../../../types/value-label'

/**
 * The Targomo rainbow/legend component ported to angular2
 */
@Component({
  selector: 'tgm-rainbow',
  styleUrls: ['./rainbow.component.less'],
  templateUrl: './rainbow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmRainbowComponent implements OnChanges {

  /**
   * Array of values that will be rendered as the rainbow sections.
   */
  @Input() options: NumberOrValueLabel[] = []


  /**
   * @deprecated
   * Use maxValue
   */
  @Input() model: number = 999999999;

  /**
   * Maximum Value. Values in `options` dont get displayed if they are below this.
   */
  @Input() maxValue: number = this.model;

  /**
   * Color array that defines the colors of the sections
   * @deprecated
   * Provide colors in options
   */
  @Input() colors: string[]

  /**
   * Global Label that is appended after numbers
   * @deprecated Provide label in options
   */
  @Input() unitLabel: string

  /**
   * @hidden
   */
  items: any[] = []

  /**
   * @hidden
   */
  ngOnChanges(changes: SimpleChanges) {
    const colors = this.colors || TRAVEL_COLORS
    if (this.options) {
      this.items = this.options
        .filter(option => ValueLabelFunctions.getValue(option) <= this.maxValue)
        .map((option, i) => ({
          label: ValueLabelFunctions.getLabel(option),
          value: (ValueLabelFunctions.getValue(option) / 60),
          color: ValueLabelFunctions.getColor(option) || colors[i]
        })
        )
    }
  }
}
