import { ModuleWithProviders } from '@angular/core'
import { Routes, RouterModule, UrlSegment } from '@angular/router'

import { HomeComponent } from './main/home.component'
import { LoginComponent } from './pages/account/login.component'
import { AdminUserComponent } from './pages/admin/adminUser.component'
import { AdminUsersComponent } from './pages/admin/adminUsers.component'
import { AdminComponent } from './pages/admin/admin.component'
import { PasswordResetComponent } from './pages/account/passwordReset'
import { RequestPasswordResetComponent } from './pages/account/requestPasswordReset'
import { AdminAuthGuard } from '@targomo/client'
import { AuthGuard } from '@targomo/client'
import { AdminSettingsComponent } from './pages/admin/adminSettings.component'
import { AdminHeaderComponent } from './pages/admin/adminHeader.component'
import { AdminDataSetsListComponent } from './pages/admin/adminDataSetsList.component'
import { AdminDataProfilesListComponent } from './pages/admin/adminDataProfilesList.component'
import { AdminDataProfileCategoriesComponent } from './pages/admin/adminDataProfileCategories.component'
import { AdminDataProfileDetailsComponent } from './pages/admin/adminDataProfileDetails.component'
import { AdminCategoryEditComponent } from './pages/admin/adminCategoryEdit.component'
import { AdminUploadDataSetComponent } from './pages/admin/uploadDataSet.component'
import { PlaceDetailsComponent } from './main/sidenav/placeDetails.component'
import { HoverInfoDisplayComponent } from './main/sidenav/hoverInfoDisplay.component'
import { UploadLayerComponent } from './main/uploadLayer/uploadLayer.component'
import { ViewLayersComponent } from './main/viewLayers/viewLayers.component'
import { SaveSessionComponent } from './pages/home/saveSession/saveSession.component'
import { LoadSessionComponent } from './pages/home/loadSession/loadSession.component'
import { AdminActivityLogComponent } from './pages/admin/activityLog/activityLog.component'
import { ZoneLayerPanelComponent } from './pages/home/sectorsPanel/zoneLayerPanel.component'
import { AdminZoneLayersListComponent } from './pages/admin/zones/adminZoneLayersList.component'
import { AdminUploadZoneLayerComponent } from './pages/admin/zones/uploadZoneLayer.component'
import { AdminZoneLayerComponent } from './pages/admin/zones/adminZoneLayer.component'
import { AdminGeoRegionsListComponent } from './pages/admin/georegions/adminGeoRegionsList.component'
import { AdminUploadGeoRegionComponent } from './pages/admin/georegions/uploadGeoRegion.component'
import { AdminGeoRegionComponent } from './pages/admin/georegions/adminGeoRegions.component'
import { AdminActivityLogReportComponent } from './pages/admin/activityLogReport/activityLogReport.component'
import { TrafficLightPanelComponent } from './pages/home/trafficLightPanel/trafficLightPanel.component'
import { AdminPlanningApplicationsListComponent } from './pages/admin/planningApplications/planningApplicationsList.component'
import { AdminPlanningApplicationVersionsComponent } from './pages/admin/planningApplications/planningApplicationVersions.component'
import { AdminPlanningApplicationUploadComponent } from './pages/admin/planningApplications/planningApplicationUpload.component'
import { AdminReportTemplatesListComponent } from './pages/admin/adminReportTemplatesList/adminReportTemplatesList.component'
import { AdminReportTemplateComponent } from './pages/admin/adminReportTemplate/adminReportTemplate.component'
import { AdminComparisonReportTemplatesListComponent } from './pages/admin/adminComparisonReportTemplatesList/adminComparisonTemplatesList.component'
import { AdminComparisonReportTemplateComponent } from './pages/admin/adminComparisonReportTemplate/adminComparisonReportTemplate.component'
import { AdminMessageCenterEditComponent } from './pages/admin/adminMessageCenter/adminMessageCenterEdit.component'
import { AdminMessageCenterComponent } from './pages/admin/adminMessageCenter/adminMessageCenter.component'
import { AdminCompanyListComponent } from './pages/admin/adminCompanyList/adminCompanyList.component'
import { AdminCompanyEditComponent } from './pages/admin/adminCompanyEdit/adminCompanyEdit.component'
import { AdminMatchpointPanelComponent } from './pages/admin/adminMatchpointPanel/adminMatchpointPanel.component'
import { ShareSessionResolver } from './resolvers/shareSessionResolver'
import { AdminCareHomeComponent } from './pages/admin/adminCareHome/adminCareHome.component'
import { UrlInitialStateResolver } from './resolvers/urlInitialStateResolver'
import { ExportAllPanelComponent } from './pages/home/exportAllPanel/exportAllPanel.component'
import { AdminUploadPostcodesComponent } from './pages/admin/postcodes/uploadPostcodes.component'
import { AdminUploadMobileCatchmentsComponent } from './pages/admin/mobilecatchments/uploadMobileCatchments.component'

export function homeMatcher(segments: UrlSegment[]) {
  let posParams: { [key: string]: UrlSegment } = {}
  let consumed: UrlSegment[] = []

  if (segments.length >= 2 && segments[0].path == 'location') {
    consumed = [segments[0], segments[1]]
    posParams['locationId'] = segments[1]
  }

  return { consumed, posParams }
}

const children = [
  { path: 'uploadLayer', component: UploadLayerComponent },
  { path: 'saveSession', component: SaveSessionComponent },
  { path: 'loadSession', component: LoadSessionComponent },
  { path: 'exportData', component: ExportAllPanelComponent },
  { path: 'zones', component: ZoneLayerPanelComponent },
  { path: 'riskAssessment', component: TrafficLightPanelComponent },

  // { path: 'layers', component: ViewLayersComponent },
  // { path: 'layers/upload', component: UploadLayerComponent },
  // { path: 'settings', component: ViewSettingsComponent },
  // { path: 'newLocation/:lng/:lat', component: NewLocationComponent },
  { path: 'details', component: PlaceDetailsComponent },
  { path: 'cellHover', component: HoverInfoDisplayComponent },
  { path: '', component: ViewLayersComponent },
]

// const appRoutes: Routes = [
//   { matcher: homeMatcher, component: HomeComponent, children }
// ]

// order of routes definition is significant
// appRoutes.push({ path: '**', component: HomeComponent, children })

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children,
    canActivate: [AuthGuard],
    resolve: { shareSesssion: ShareSessionResolver, initialState: UrlInitialStateResolver },
  },
  { path: 'request-reset', component: RequestPasswordResetComponent },
  { path: 'reset/:code', component: PasswordResetComponent },
  // { path: 'about', component: AboutComponent }
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminAuthGuard],
    resolve: { shareSesssion: ShareSessionResolver, initialState: UrlInitialStateResolver },
    children: [
      { path: 'users', component: AdminUsersComponent },
      { path: 'user/:id', component: AdminUserComponent },

      { path: 'companies', component: AdminCompanyListComponent },
      { path: 'company/:id', component: AdminCompanyEditComponent },

      { path: 'category/:id', component: AdminCategoryEditComponent },
      { path: 'dataset/upload', component: AdminUploadDataSetComponent },
      { path: 'dataset/upload/:id', component: AdminUploadDataSetComponent },
      { path: 'dataset/:setId', component: AdminDataProfileCategoriesComponent },
      { path: 'profile/:id/categories/:setId', component: AdminDataProfileCategoriesComponent },
      { path: 'profile/:id', component: AdminDataProfileDetailsComponent },
      { path: 'profiles', component: AdminDataProfilesListComponent },
      { path: 'datasets', component: AdminDataSetsListComponent },

      { path: 'zones', component: AdminZoneLayersListComponent },
      { path: 'zone/upload', component: AdminUploadZoneLayerComponent },
      { path: 'zone/upload/:id', component: AdminUploadZoneLayerComponent },
      { path: 'zone/:id', component: AdminZoneLayerComponent },

      { path: 'postcodes', component: AdminUploadPostcodesComponent },
      { path: 'mobilecatchments', component: AdminUploadMobileCatchmentsComponent },

      { path: 'georegions', component: AdminGeoRegionsListComponent },
      { path: 'georegion/upload', component: AdminUploadGeoRegionComponent },
      { path: 'georegion/upload/:id', component: AdminUploadGeoRegionComponent },
      { path: 'georegion/:id', component: AdminGeoRegionComponent },

      { path: 'messages', component: AdminMessageCenterComponent },
      { path: 'messages/:id', component: AdminMessageCenterEditComponent },

      { path: 'planningApplications', component: AdminPlanningApplicationsListComponent },
      { path: 'planningApplications/upload', component: AdminPlanningApplicationUploadComponent },
      { path: 'planningApplications/upload/:id', component: AdminPlanningApplicationUploadComponent },
      { path: 'planningApplications/:id', component: AdminPlanningApplicationVersionsComponent },

      { path: 'settings', component: AdminSettingsComponent },
      { path: 'activitylog', component: AdminActivityLogComponent, data: { wide: true } },
      { path: 'activitylogreport', component: AdminActivityLogReportComponent, data: { wide: true } },

      { path: 'reportTemplates', component: AdminReportTemplatesListComponent },
      { path: 'reportTemplate/:id', component: AdminReportTemplateComponent },

      { path: 'comparisonReportTemplates', component: AdminComparisonReportTemplatesListComponent },
      { path: 'comparisonReportTemplate/:id', component: AdminComparisonReportTemplateComponent, data: { wide: true } },

      { path: 'matchpoint', component: AdminMatchpointPanelComponent, data: { wide: true } },

      { path: 'carehome', component: AdminCareHomeComponent, data: { wide: false } },

      // {path: '', component: AdminUserComponent, canActivate: [AdminAuthGuard]},
      // {path: '', component: AdminUsersComponent, canActivate: [AdminAuthGuard]},
    ],
  },
  { path: 'login', component: LoginComponent },
  {
    path: '**',
    redirectTo: '',
    canActivate: [AuthGuard],
    resolve: { shareSesssion: ShareSessionResolver, initialState: UrlInitialStateResolver },
  },

  // appRoutes.push({path: '**', component: HomeComponent, children, canActivate: [AuthGuard]})
]

// order of routes definition is significant

// export const appRoutingProviders: any[] = []
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {useHash: true})
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes)

export const appRoutingProviders: any[] = []
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true })
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {useHash: false})
