import { Injectable, Optional, Inject } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { TGM_AUTH_API_CONF, TgmAuthApiConf, Auth } from './auth.service';

/**
 * Guard a route so that only a logged in user can access it
 * ```
 *   {path: '', component: HomeComponent, canActivate: [AuthGuard]}
 * ```
 */
@Injectable()
export class AuthGuard implements CanActivate {

  private loginRoute: string = '/login'
  private redirectFn: () => void

  constructor(
    private router: Router,
    private auth: Auth,
    @Optional() @Inject(TGM_AUTH_API_CONF) apiConf?: TgmAuthApiConf
  ) {
    if (apiConf && apiConf.loginRedirectPath) {
      this.loginRoute = apiConf.loginRedirectPath
    }
    if (apiConf && apiConf.loginRedirectFunction) {
      this.redirectFn = apiConf.loginRedirectFunction
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try  {
      const loggedIn = await this.auth.isLoggedIn()
      if (loggedIn) {
        // logged in so return true
        return true
      } else {
        throw new Error('Not Logged in')
      }
    } catch (e) {
          // not logged in so redirect to login page with the return url
          if (this.redirectFn) {
            this.redirectFn()
          } else {
            if (this.loginRoute.includes('http')) {
              window.location.href = this.loginRoute + '?returnUrl=' + window.location.href
            } else {
              this.router.navigate([this.loginRoute], { queryParams: { returnUrl: state.url } })
            }
          }

          return false
    }

  }
}
