import { Component, Input, HostBinding } from '@angular/core';

/**
 * A collection of max. 5 action items.
 * Use `tgmStretch` attibute to stretch buttons full width. Otherwise buttons will be 20% width
 *
 * Example:
 * ```
 * <tgm-action-items tgmStretch="true">
 *   <tgm-action-item (click)="openChangeCityDialog()">
 *     <mat-icon tgm-icon>refresh</mat-icon>
 *     <span tgm-label>Change Cities</span>
 *   </tgm-action-item>
 *   <tgm-action-item (click)="openImmoDialog()">
 *     <mat-icon tgm-icon>home</mat-icon>
 *     <span tgm-label>Immo Search</span>
 *   </tgm-action-item>
 *   <tgm-action-item (click)="openFilterDialog()">
 *     <mat-icon tgm-icon>filter_list</mat-icon>
 *     <span tgm-label>Filter</span>
 *   </tgm-action-item>
 * </tgm-action-items>
 * ```
 *
 */
@Component({
  selector: 'tgm-action-items',
  templateUrl: './action-items.component.html',
  styleUrls: ['./action-items.component.scss']
})

export class TgmActionItemsComponent {
  @Input() @HostBinding('class.stretch') tgmStretch = false;
}
