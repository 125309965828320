import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DateTimePickerDialogComponent } from './dialog/date-time-picker-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tgm-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnDestroy {

  private subscription: Subscription;

  @Input() date = new Date();
  @Output() dateChanged = new EventEmitter<Date>()

  constructor(
    private matDialog: MatDialog
  ) { }

  openDialog(): void {
    const dialogRef = this.matDialog.open(DateTimePickerDialogComponent, {
      width: '400px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { date: this.date }
    });

    this.subscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.date = result;
        this.dateChanged.emit(this.date);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
