import { Component, HostBinding, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'tgm-generic-panel',
  templateUrl: './generic-panel.component.html',
  styleUrls: ['./generic-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('true', style({
        overflow: 'hidden',
        height: '*',
      })),
      state('*', style({
        opacity: '0',
        display: 'none',
        overflow: 'hidden',
        height: '0px',
      })),
      transition('* <=> *', animate('.3s ease-in-out')),
    ]),
    trigger('fadeInOut', [
      state('true', style({
        opacity: 1,
      })),
      state('*', style({
        opacity: '0',
        display: 'none',
        overflow: 'hidden',
      })),
      transition('* <=> *', animate('.3s ease-in-out')),
    ]),
    trigger('shrinkInOut', [
      state('true', style({
        opacity: 1
      })),
      state('*', style({
        opacity: '0',
        flex: '0.00001',
        'min-width': 0,
        'margin-left': 0,
        display: 'none',
        overflow: 'hidden',
      })),
      transition('* <=> *', animate('.3s ease-in-out')),
    ])
  ]
})
export class TgmGenericPanelComponent {

  @Input() activeLabel = 'Active';
  @Input() showActiveToggle = false;
  @Input() expanded = false;
  @Input() fetching = false;

  @HostBinding('class.active') @Input() active = true;
  @HostBinding('class.settings-active') showSettings = false;

  @Output() activeChanged = new EventEmitter<boolean>();
  @Output() expansionToggled = new EventEmitter<boolean>();

  toggleExpansion() {
    this.expansionToggled.emit(!this.expanded);
  }

  toggleSettings() {
    this.showSettings = !this.showSettings
  }

  toggleActive(evt: MatCheckboxChange) {
    this.active = evt.checked;
    if (!evt.checked) {
      this.showSettings = false;
    }
    this.activeChanged.emit(evt.checked)
  }
}
