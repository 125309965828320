import { NgModule, Optional, SkipSelf, InjectionToken } from '@angular/core'
import { RESTHelper } from './http.service';
import { Router, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor, TgmAuthInterceptorConf, TGM_AUTH_INTERCEPTOR_CONF } from './auth-interceptor';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'

export function factory(http: HttpClient, router: Router, cookieService: CookieService) {
  return new RESTHelper(http, router, cookieService)
}


@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: RESTHelper,
      useFactory: factory,
      deps: [HttpClient, Router, CookieService] as any[]
    },

    {provide: <InjectionToken<TgmAuthInterceptorConf>>TGM_AUTH_INTERCEPTOR_CONF, useValue: null}
  ]
})
export class TgmHttpModule {
  static forRoot(
    options: TgmAuthInterceptorConf,
  ) {
    return {
      ngModule: TgmHttpModule,
      providers: [
        { provide: <InjectionToken<TgmAuthInterceptorConf>>TGM_AUTH_INTERCEPTOR_CONF, useValue: options },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: TgmHttpModule) {
    if (parentModule) {
      throw new Error('TgmHttpModule has already been imported.');
    }
  }
}
