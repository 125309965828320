import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelPlanComponent } from './travel-plan.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule, MatIconModule } from '@angular/material';
import { FilterTransferSegmentsPipe } from './filter-segments.pipe';
import { TgmPipesModule } from '../../pipes/pipes.module';

const COMPONENTS = [
  TravelPlanComponent,
  FilterTransferSegmentsPipe
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    TgmPipesModule,
    MatProgressSpinnerModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class TravelPlanModule { }
