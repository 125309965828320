import {Injectable} from '@angular/core'
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router'
import {Auth} from './auth.service'

/**
 * A somewhat specific user case, but added it to the lib in case we have this elsewhere too.
 * If a user is not logged in it tries to login as 'guest' with no password
 */
@Injectable()
export class GuestAuthGuard implements CanActivate {
  constructor(private auth: Auth) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loggedIn = await this.auth.isLoggedIn()

    if (loggedIn) {
      return true
    }

    await this.auth.login('guest', 'guest')
    return true
  }
}
