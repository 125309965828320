import { CirclePaint, Layer, LinePaint } from 'mapbox-gl';
import { ObservableExpression } from '../../../../../types';
import { TgmMapboxComponent } from '../../mapbox.component';
import { TileMapSource } from '../../sources/tile-source';
import { MapLayer, MapLayerPosition } from '../layer';

export interface MultigraphMapLayerOptions {
  /**
   * Colors and stops for the lines, based on the `w` property. Can either be an array of strings
   * in which case the stops are based on the maxEdgeWeight divided by the number of colors
   * or it can be an array of object with `color` and `value` properties in which case the stops are provided by the `value`
   * Don't forget to provide a stop and color for the `0` value
   */
  colors: string[] | { color: string, value: number }[]

  opacity?: number | number[][]

  /**
   * minimum value in this mg layer
   */
  min?: number

  /**
   * maximum value in this mg layer
   */
  max?: number

  /**
   * For debugging. Defaults to aggregation
   */
  sourceLayer?: string

  /**
   * The line width, either a simple number or an array representing a zoom based width (see mapbox styles)
   * only applies to `visualizationType = edge`
   */
  lineWidth?: number | number[][]

  /**
   * The circle width, either a simple number or an array representing a zoom based width (see mapbox styles)
   * only applies to `visualizationType = node`
   */
  circleRadius?: number | number[][]

  /**
   * type of the visualization. determines the layer type
   */
  visualizationType?: 'edge' | 'node' | 'tile' | 'tile_node' | 'hexagon' | 'hexagon_node'
}

function isStrings(values: string[] | { color: string, value: number }[]): values is string[] {
  return values && values.length && (typeof values[0] === 'string' || values[0] instanceof String)
}

/**
 * A MVT-multigraph layer
 */
export class MultigraphMapLayer extends MapLayer<TileMapSource> {
  private options: MultigraphMapLayerOptions

  constructor(
    protected map: TgmMapboxComponent,
    source: TileMapSource,
    optionsObservable: ObservableExpression<MultigraphMapLayerOptions>
  ) {
    super(map, source)

    this.setPosition(MapLayerPosition.BELOW_MARKERS)

    if (optionsObservable) {
      this.watch(optionsObservable, options => {
        this.options = options
        this.update()
      })

    }
  }

  /**
   * Get the computed color steps with their respective value. i.e. to display a legend
   */
  get colorStops() {
    if (isStrings(this.options.colors)) {
      return this.options.colors.map((color, i) => {
        if (!this.options.max) {
          console.error('Max value needs to be defined when not explicitly provided with colors')
        }
        const min = this.options.min ? this.options.min : 0
        return [
          min + Math.ceil(i * ((this.options.max - min) / (this.options.colors.length - 1))),
          color
        ]
      })
    } else {
      return this.options.colors.map((item, i) => {
        return [item.value, item.color]
      })
    }
  }

  private get lineWidths() {
    if (this.options.lineWidth == undefined || this.options.lineWidth instanceof Array) {
      return {
        stops: this.options.lineWidth || [[13, 1], [24, 24]]
      }
    } else {
      return this.options.lineWidth
    }
  }

  private get circleRadii() {
    if (this.options.circleRadius == undefined || this.options.circleRadius instanceof Array) {
      return {
        stops: this.options.circleRadius || [[13, 3], [24, 5]]
      }
    } else {
      return this.options.circleRadius
    }
  }

  private get opacities() {
    if (this.options.opacity == undefined) {
      return 1
    }
    if (this.options.opacity instanceof Array) {
      return {
        stops: this.options.opacity
      }
    } else {
      return this.options.opacity
    }
  }

  get(): Partial<Layer> {
    if (this.options) {
      switch (this.options.visualizationType) {
        case 'edge':
          return {
            'filter': this.options.max ? ['<=', 'w', this.options.max] : null,
            'type': 'line',
            'source-layer': this.options.sourceLayer ? this.options.sourceLayer : 'aggregation',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': <LinePaint>{
              'line-width': this.lineWidths,
              'line-color': {
                'property': 'w',
                'stops': this.colorStops,
              },
              'line-opacity': this.opacities
            }
          }
        case 'node':
          return {
            'filter': this.options.max ? ['<=', 'w', this.options.max] : null,
            'type': 'circle',
            'source-layer': this.options.sourceLayer ? this.options.sourceLayer : 'aggregation',
            'paint': <CirclePaint>{
              'circle-radius': this.circleRadii,
              'circle-color': {
                'property': 'w',
                'stops': this.colorStops,
              },
              'circle-opacity': this.opacities
            }
          }
        case 'hexagon':
        case 'hexagon_node':
        case 'tile':
        case 'tile_node':
        return {
          'filter': this.options.max ? ['<=', 'w', this.options.max] : null,
          'type': 'fill',
          'source-layer': this.options.sourceLayer ? this.options.sourceLayer : 'aggregation',
          'paint': <CirclePaint>{
            'fill-color': {
              'property': 'w',
              'stops': this.colorStops,
            },
            'fill-outline-color': 'rgba(0,0,0,0)',
            'fill-opacity': this.opacities
          }
        }
        default:
          return super.get()
      }
    }
  }
}
