import { Component, ElementRef, AfterViewInit, Input } from '@angular/core';

/**
 * Because at the moment angular2 didn't have a way (note to self, check if they have now) to popup a menu
 * at an arbritrary position (on a map in our case), this component serves that purpose
 *
 * The show() and hide() methods trigger visibility, and the transcluded content determines what is shown in the popup
 */
@Component({
  selector: 'tgm-map-popup',
  styleUrls: ['./map-popup.component.less'],
  templateUrl: './map-popup.component.html'
})
export class TgmMapPopupComponent implements AfterViewInit {
  @Input() hideOnClick: boolean = true
  /** * @hidden */
  // private visible = false

  /** * @hidden */
  constructor(private elementRef: ElementRef) {
  }

  /** * @hidden */
  ngAfterViewInit() {
    window.addEventListener('click', () => this.hide())
    window.addEventListener('keypress', (event) => (event.which == 27) && this.hide())
  }

  /** * @hidden */
  ngOnChanges() {
  }

  /**
   * Hide the popup
   */
  hide() {
    if (this.hideOnClick) {
      this.elementRef.nativeElement.style.display = 'none'
    }
  }

  /**
   * Show te popup the the specified position
   */
  show(position: {x: number, y: number}) {
    this.elementRef.nativeElement.style.position = 'absolute'
    this.elementRef.nativeElement.style.top = position.y + 'px'
    this.elementRef.nativeElement.style.left = position.x + 'px'
    this.elementRef.nativeElement.style.display = 'block'
  }
}
