import { MapSource } from './source'

/**
 * A vector tile source, the argument given to it's contrcutoris used as the `tiles` value for a mapbox vector tiles layer
 */
export class TileMapSource extends MapSource<string> {
  get(data: string) {
    if (data) {
      return {
        'type': 'vector',
        'tiles': [data]
      }
    } else {
      return super.get(data)
    }
  }
}
