<section>
  <h3 style="text-transform: uppercase" class="mat-title">Enabled features</h3>
  <section>
    <mat-list>
      <mat-list-item *ngFor="let profile of availablePermissions" (click)="togglePermission(profile.key)">
        <mat-checkbox
          style="pointer-events: none"
          [checked]="user.permissions && user.permissions[profile.key]"
          aria-label=""
        ></mat-checkbox>
        {{ profile.label }}
      </mat-list-item>
    </mat-list>
  </section>
</section>

<section>
  <h3 style="text-transform: uppercase" class="mat-title">Enabled regions</h3>
  <section>
    <mat-list>
      <mat-list-item *ngFor="let profile of availableRegions" (click)="toggleRegion(profile)">
        <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
        {{ profile.name }}
      </mat-list-item>
    </mat-list>
  </section>
</section>

<section>
  <h3 style="text-transform: uppercase" class="mat-title">Data profiles</h3>
  <section>
    <mat-list>
      <mat-list-item *ngFor="let profile of dataProfiles" (click)="toggleProfile(profile)">
        <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
        {{ profile.name }}
      </mat-list-item>
    </mat-list>
  </section>
</section>

<section>
  <h3 style="text-transform: uppercase" class="mat-title">Map Print</h3>
  <mat-list>
    <mat-list-item (click)="toggleGeneral('printShowSizeLegend')">
      <mat-checkbox
        style="pointer-events: none"
        [checked]="user?.permissions?.customReport?.printShowSizeLegend"
        aria-label=""
      ></mat-checkbox>
      Show Marker Size Legend
    </mat-list-item>

    <mat-list-item (click)="toggleGeneral('printShowFasciaLegend')">
      <mat-checkbox
        style="pointer-events: none"
        [checked]="user?.permissions?.customReport?.printShowFasciaLegend"
        aria-label=""
      ></mat-checkbox>
      Show Fascia Legend
    </mat-list-item>
  </mat-list>
</section>

<section>
  <h3 style="text-transform: uppercase" class="mat-title">Pdf Report Template</h3>
  <mat-select
    placeholder="Default"
    [value]="user?.permissions?.reportTemplateId"
    (selectionChange)="changeDefaultTemplate($event)"
  >
    <mat-option *ngFor="let template of availableTemplates" [value]="template.id">
      {{ template.name }}
    </mat-option>
  </mat-select>
</section>

<section>
  <h3 style="text-transform: uppercase" class="mat-title">Comparison Report Template</h3>
  <mat-list>
    <mat-list-item *ngFor="let profile of availableComparisonTemplates" (click)="changeComparisonTemplate(profile)">
      <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
      {{ profile.name }}
    </mat-list-item>
  </mat-list>
</section>

<section>
  <h3 style="text-transform: uppercase" class="mat-title">Planning Applications</h3>
  <tgm-loadable [model]="loadingDataSets">
    <admin-select-fascias
      (selectionChange)="planningSelectionChange($event)"
      [allowGroupings]="false"
      [dataSets]="dataSets"
    ></admin-select-fascias>
  </tgm-loadable>
</section>

<section>
  <!-- <tgm-loadable [model]="loadingDataSets"> -->
  <admin-edit-gap-analysis-report
    (modelChange)="updateGapReport($event)"
    [model]="user?.permissions?.gapReport"
  ></admin-edit-gap-analysis-report>
  <!-- </tgm-loadable> -->
</section>

<!-- <section>
  <h3 style="text-transform: uppercase" class="mat-title">Mobile catchments</h3>
  <section>
    <mat-list>
      <mat-list-item *ngFor="let profile of mobileCatchmentsCategories" (click)="toggleMobileCatchment(profile)">
        <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
        {{ profile.category }}
      </mat-list-item>
    </mat-list>
  </section>
</section> -->
