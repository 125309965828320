import { TgmMapboxComponent } from '../mapbox.component'
import { AbstractSubscriber } from '../../../../types';

/**
 * For composite layers return one layer to represent the composite, so that for example it can be used for
 * receiving events, ex clicks. Abstracts away the need for classes to know if this layer is a simple map layer or a composite layer
 */
export interface PrimaryLayer<T> {
  getPrimaryLayer(): T
}

/**
 * Parent of both map layers and composite layers. A SimpleLayer is one that corresponds 1 to 1 with a mapbox layer,
 * while a CompositeLayer is not represented directly by one mapbox layer but instead is a collection/composite of several other layers
 * providing more complex functionality
 */
export abstract class AbstractLayer extends AbstractSubscriber {
  constructor(protected map: TgmMapboxComponent) {
    super()

    if (!map) {
      throw new Error('Map parameter is null or undefined')
    }

    this.watch(map.onLayersDestroy, () => this.onRemove())
  }

  abstract setVisible(value: boolean): this
  abstract isVisible(): boolean

  onRemove() {
    this.unsubscribe()
  }
}
