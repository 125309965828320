import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { TgmMaxedgeweightSelectComponent } from './maxedgeweight-select.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [TgmMaxedgeweightSelectComponent],
  imports: [MatIconModule, CommonModule, FlexLayoutModule],
exports: [TgmMaxedgeweightSelectComponent]
})
export class TgmMaxEdgeWeightSelectModule {}
