import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TgmBoxplotComponent } from './boxplot.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { TgmPipesModule } from '../pipes/index';

@NgModule({
  declarations: [
    TgmBoxplotComponent,
  ],
  imports: [
    CommonModule,
    TgmPipesModule,
    FlexLayoutModule
  ],
  exports: [
    TgmBoxplotComponent
  ]
})
export class TgmBoxplotModule { }
