export const EMPTY_GEOJSON: any = {
  'type': 'geojson',
  'data': {
    'type': 'FeatureCollection',
    'features': []
  }
}

export const EMPTY_GEOJSON_DATA: any = {
  'type': 'FeatureCollection',
  'features': []
}

export const MAP_STYLES = [
  {name: 'Light', key: 'positron', referenceLayer: 'tunnel_motorway_casing'},
  {name: 'Dark', key: 'darkmatter', referenceLayer: 'highway_path'},
  {name: 'Basic', key: 'basic', referenceLayer: 'road_path'},
  {name: 'Satellite', key: 'hybrid', referenceLayer: 'water'},
  {name: 'Bright', key: 'bright', referenceLayer: 'tunnel-service-track-casing'},
]

export const MAP_DEFAULT_STYLE = MAP_STYLES[0].key
export const MAP_DEFAULT_REFERENCE_LAYER = MAP_STYLES[0].referenceLayer

export const BASE_MAP_TILER_URL = 'https://api.maptiler.com'
export const DEFAULT_MAP_TILER_KEY = '9vcJFUMVLLsN7QrhnPL2'
