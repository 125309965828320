import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core'
import { NumberOrValueLabel, ValueLabelFunctions, TRAVEL_COLORS } from '../../../types'

@Component({
  selector: 'tgm-maxedgeweight-select',
  templateUrl: './maxedgeweight-select.component.html',
  styleUrls: ['./maxedgeweight-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TgmMaxedgeweightSelectComponent implements OnChanges {

  items: { label: string; icon: string; value: number; color: any; }[]

  /**
   * Set of possible select options. Type of `NumberOrValueLabel`
   */
  @Input() options: NumberOrValueLabel[] = [
    { value: 300, label: '5min', color: TRAVEL_COLORS[0] },
    { value: 600, label: '10min', color: TRAVEL_COLORS[1] },
    { value: 900, label: '15min', color: TRAVEL_COLORS[2] },
    { value: 1200, label: '20min', color: TRAVEL_COLORS[3] },
    { value: 1500, label: '25min', color: TRAVEL_COLORS[4] },
    { value: 1800, label: '30min', color: TRAVEL_COLORS[5] }
  ];

  /**
   * Color array if not provided in options
   */
  @Input() colors: string[] = []

  /**
   * initial value of maxEdgeWeight
   */
  @Input() value = 900

  /**
   * Not sure if we need to use this
   */
  @Input() unitLabel: string;

  @Output() change = new EventEmitter<number>()

  constructor() {
    this.buildItems()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildItems()
  }

  select(value: number) {
    this.value = value
    this.change.emit(value)
  }

  private buildItems(): void {
    if (this.options && this.options.length) {
      this.items = this.options.map((option, i) => ({
        label: ValueLabelFunctions.getLabel(option),
        icon: ValueLabelFunctions.getIcon(option),
        value: ValueLabelFunctions.getValue(option),
        color: (ValueLabelFunctions.getColor(option) || this.colors[i] || undefined)
      }
      ))
    }
  }
}
