import { TgmMapboxComponent } from '../mapbox.component'
import { EMPTY_GEOJSON } from '../constants'
import { AbstractSubscriber } from '../../../../types';

let counterId = 0

export class AbstractSource extends AbstractSubscriber {
  private _id: string
  protected map: TgmMapboxComponent
  private _removed: boolean = false

  // It is actully used (via casting, since typescript doesn't have a friend access modifier)
  // tslint:disable-next-line
  private addToMap(map: TgmMapboxComponent) {
    if (this.map) {
      return
    }

    this.map = map

    this.watch(map.onLayersReload, () => this.update())
    this.watch(map.onLayersDestroy, () => this.onRemove())

    this.update()
  }

  /**
   * Returns the mapbox id of this source, the id is autogenerated
   */
  get id() {
    if (!this._id) {
      this._id = `GeneratedSourceId_${counterId++}`
    }

    return this._id
  }

  onRemove() {
    this.unsubscribe()
  }

  // remove() {
  //   throw new Error('not implemented')
  // }

  getMapBoxObject(): any {
    return EMPTY_GEOJSON
  }

  waitReady() {
    return this.update(() => {})
  }


  /**
   * Refreshes the source. This methid is called both internally in response to data changes, but can also be called manually.
   * Concretely what this method does is the following:
   *
   * If the source does not exist yet in the mapbox component if will add the source to mapbox (based on the definition return by
   * the `getMapBoxObject()` method)
   *
   * If the source exists in the mapbox component then if not `callback` parameter is given the source is removend and re-added,
   * otherwise if a `callback` parameter was supplied it is called and expected to handle any concrete steps to update the source
   *
   * Basically the callback parameter allows for fine-grained control of actual updates to the source for performance optimization
   *
   * @param callback
   */
  update(callback?: (map: mapboxgl.Map, sourceObject?: any) => void) {
    return new Promise(resolve => {
      if (!this.map) {
        resolve()
        return
      }

      this.map.styleReady.add(async () => {
        if (this._removed) {
          return
        }

        const map = await this.map.getMap()
        const mapSource = map.getSource(this.id)
        const sourceObject = this.getMapBoxObject()

        let layers: any[] = null

        if (mapSource && callback) {
          callback(map, sourceObject)
          resolve()
          return
        } else if (!callback && mapSource) {
          layers = this.findAndRemoveLayers(map)
          map.removeSource(this.id)
        }

        map.addSource(this.id, sourceObject)


        if (layers) {
          layers.forEach(layer => {
            const temporaryErrorHandler = () => console.warn(`Layer ${layer.id} not updated`)
            map.on('error', temporaryErrorHandler)
            map.addLayer(layer, layer.before)
            // NOTE: can happen that I updated source type and layer needs to be updated before being readded
            // ex, needs 'source-layer' or something...we assume the layer will notice and take care of itself later
            map.off('error', temporaryErrorHandler)
          })
        }

        resolve()
      })
    })
  }

  // Gets all layers that have this source as their source
  // (together with the last layer to keep the position when we re-add them) and then removed them
  // They readded to the map in update(). This exercise is because of the new restrictions in mapbox-gl regarding removal or used sources
  private findAndRemoveLayers(map: mapboxgl.Map) {
    let {layers = []} = map.getStyle()

    layers = layers
      .map((layer, id) => {
        const {id: before} = layers[id + 1] || {id: undefined}
        return {...layer, before}
      })
      .filter(layer => layer.source === this.id)

    layers.forEach(layer => map.removeLayer(layer.id))
    return layers.reverse()
  }

  /**
   * Remove a source from the map
   */
  async remove() {
    if (this._removed) {
      return
    }

    this._removed = true
    this.map.styleReady.add(async () => {
      const map = await this.map.getMap()
      this.findAndRemoveLayers(map)

      if (map.getSource(this.id)) {
        map.removeSource(this.id)
      }

      this.onRemove()
    })
  }

  async removeIfUnused() {
    this.map.styleReady.add(async () => {
      const map = await this.map.getMap()
      let {layers = []} = map.getStyle()

      if (layers.filter(layer => layer.source === this.id).length === 0) {
        this.remove()
      }
    })
  }
}
