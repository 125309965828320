import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import {
  MatIconModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatListModule,
  MatInputModule,
  MatButtonModule
} from '@angular/material';
import { NgModule } from '@angular/core';
import { TgmLayerInteractionComponent } from './layer-interaction.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TgmLayerInteractionComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatListModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  exports: [TgmLayerInteractionComponent]
})
export class TgmLayerInteractionModule {}
