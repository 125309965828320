
import {combineLatest as observableCombineLatest } from 'rxjs';

import {map, pluck} from 'rxjs/operators';
// FIXME: thinking out loud here
import { ReachableTile, StatisticsKey } from '@targomo/core'
import { MapLayerPosition, MapLayer } from '../layer'
import { CompositeLayer } from '../composite-layer';
import { FilterMapLayerOptions, FilterMapLayer } from './filter-layer';
import { PrimaryLayer } from '../abstract-layer';
import { statisticsLayerStops } from '../../util/interpolation';

import { StatisticsMapLayer } from './statistics-layer';
import { TRAVEL_COLORS, ObservableExpression } from '../../../../../types';

// FIXME: temporary
export interface StatisticsTravelLayerOptions {
  edgeWeights: {value: number, color: string}[]
  opacity?: number
  inverseStyle?: any
  statistic?: StatisticsKey
  interpolator?: string
}

/**
 * A layer representing travel times on top of a statistics layer by coloring cells with colors based on tavel times
 * as return from the travel times statistics endpointt. The layer can also be displayed in an inverse mode
 */
export class StatisticsTravelLayer extends CompositeLayer {
  private _visible: boolean = true
  private _inverse: boolean = false

  private layers: FilterMapLayer[] = []
  private inverseLayer: FilterMapLayer

  private travelValues: any[][] = []
  private inverseValues: any[]

  private position: MapLayerPosition | PrimaryLayer<MapLayer<any>> = MapLayerPosition.TRAVEL

  private options = {
    type: 'fill',
    position: MapLayerPosition.BELOW_MARKERS,
    layersPaints: TRAVEL_COLORS.map(color => {
      return {
        'fill-color': color,
        'fill-opacity': 0.5,
        'fill-antialias': false
      }
    }),

    inversePaint: {
      'fill-color': '#ff0000',
      'fill-opacity': 0.5,
      'fill-antialias': false
    } as any,

    // timeLayers: [] as number[] // 300, 600, 900, 1200, 1500, 1800]
    timeLayers: [300, 600, 900, 1200, 1500, 1800]
  }

  private statisticsLayer: StatisticsMapLayer

  // TODO: options format will change...just checking that updates work for now
  constructor(statisticsLayer: PrimaryLayer<StatisticsMapLayer>,
              travelOptionsObservable?: ObservableExpression<StatisticsTravelLayerOptions>) {
    super((statisticsLayer.getPrimaryLayer() as any).map) // Typrscript doesn't have `friend` modifier

    this.statisticsLayer = statisticsLayer.getPrimaryLayer()

    if (travelOptionsObservable) {
      this.watch(travelOptionsObservable, options => {
        this.options.timeLayers = options.edgeWeights.map(item => item.value)
        this.options.layersPaints = options.edgeWeights.map(item => {
          return {
            'fill-color': item.color,
            'fill-opacity': options.opacity != null ? options.opacity : 0.5,
            'fill-antialias': false
          }
        })

        const inversePaint = observableCombineLatest(
           this.toObservable(travelOptionsObservable).pipe(pluck<any, any>('inversePaint')),
           this.toObservable(travelOptionsObservable).pipe(pluck<any, StatisticsKey>('statistic')),
           this.toObservable(travelOptionsObservable).pipe(pluck<any, string>('interpolator')),
           this.toObservable(travelOptionsObservable).pipe(pluck<any, number>('opacity')),
          ).pipe(map(([paint, statistic, interpolator, opacity]) => {
          if (!paint && statistic && interpolator) {
            const metadata = this.statisticsLayer.getSource().metadata
            paint = {
              'fill-color': statisticsLayerStops(metadata.stats[statistic.id], 'kmeans', 9, interpolator),
              'fill-opacity': opacity != null ? opacity : 0.5,
              'fill-antialias': false
            }
          }

          const result = paint || {
            'fill-color': '#0000ff',
            'fill-opacity': 0.5,
            'fill-antialias': false
          }

          return result
        }))

        this.options.inversePaint = inversePaint
        // this.init()
      })
    }

    this.watch(this.toObservable(travelOptionsObservable).pipe(pluck<any, StatisticsKey>('edgeWeights')), () => {
      this.init()
    })


    this.watch(this.statisticsLayer.getSource().events, () => {
      this.init()
    })
  }

  private async init() {
    const source = this.statisticsLayer.getSource()
    await source.waitReady()

    this.createSourcesLayers()
  }


  private createSourcesLayers() {
    const source = this.statisticsLayer.getSource()
    const timeLayers = this.options.timeLayers

    this.layers.forEach(layer => {
      layer.remove()
    })
    this.layers = []

    if (this.inverseLayer) {
      this.inverseLayer.remove()
    }
    this.inverseLayer = null

    if (!source.metadata) {
      return
    }

    this.layers = timeLayers.map((time, i) => {
      const options: FilterMapLayerOptions = {
        sourceLayer: '' + source.metadata.id, // this.options.sourceLayer, // string
        type: this.options.type,
        paint: this.options.layersPaints[i]
      }
      const layer = new FilterMapLayer(this.map, source, options).setPosition(this.options.position || MapLayerPosition.BELOW_MARKERS)
      layer.setVisible(this._visible && !this._inverse)
      layer.setFilter(this.travelValues[i] || ['in', 'id'])
      layer.setPosition(this.position)
      return layer
    })

    const createInverseLayer = () => {
      const options: FilterMapLayerOptions = {
        sourceLayer: '' + source.metadata.id,
        type: this.options.type,
        paint: this.options.inversePaint
      }
      const layer = new FilterMapLayer(this.map, source, options).setPosition(this.options.position || MapLayerPosition.BELOW_MARKERS)
      layer.setFilter(this.inverseValues || ['in', 'id'])
      layer.setVisible(this._visible && !!this._inverse)
      layer.setPosition(this.position)
      return layer
    }

    this.inverseLayer = createInverseLayer()
  }

  //   this.remember(statisticsLayer.getSource().events.subscribe(() => {
  //     this.update()
  //   }))
  // }

  async setTravel(reachable: ReachableTile) {
    const source = this.statisticsLayer.getSource()
    await source.waitReady()
    const timeLayers = this.options.timeLayers

    // Most groups have the id as number but at least one has the id as string...compensate for this
    const processList = (list: any[]) => {
      // if (source.metadata.id == 15) {
      //   return list
      // }

      for (let i = 2; i < list.length; i++) {
        list[i] = +list[i]
      }

      return list
    }

    timeLayers.forEach((time, i) => {
      const nextTime = timeLayers[i - 1] || 0
      const list: (string | number)[] = ['in', 'id']

      for (let key in reachable) {
        let value = +reachable[key]
        if (value > nextTime && value <= time) {
          list.push(key)
        }
      }

      this.travelValues[i] = processList(list)
      if (this.layers[i]) {
        this.layers[i].setFilter(this.travelValues[i])
      }

    })

    let calculateInverse = () => {
      const time = timeLayers[timeLayers.length - 1]
      const list: (string | number)[] = ['in', 'id']
      for (let key in reachable) {
        let value = +reachable[key]
        if (value <= time) {
          list.push(key)
        }
      }

      this.inverseValues = processList(list)
      if (this.inverseLayer) {
        this.inverseLayer.setFilter(this.inverseValues)
      }
    }

    calculateInverse()
  }


  setPosition(position: MapLayerPosition | PrimaryLayer<MapLayer<any>>): this {
    this.position = position

    this.layers.forEach(layer => {
      layer.setPosition(position)
    })

    if (this.inverseLayer) {
      this.inverseLayer.setPosition(position)
    }

    return this
  }

  setVisible(value: boolean) {
    this._visible = value

    this.layers.forEach(layer => {
      layer.setVisible(value && !this._inverse)
    })

    if (this.inverseLayer) {
      this.inverseLayer.setVisible(value && !!this._inverse)
    }

    return this
  }

  isVisible() {
    return this._visible
  }

  isInverse() {
    return this._inverse
  }

  setInverse(value: boolean) {
    this._inverse = value
    this.setVisible(this._visible)
    return this
  }
}
