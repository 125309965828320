import { CommonModule } from '@angular/common';
import { TgmRainbowComponent } from './rainbow.component'
import { NgModule } from '@angular/core'


@NgModule({
  declarations: [
    TgmRainbowComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TgmRainbowComponent
  ]
})
export class TgmRainbowModule { }
