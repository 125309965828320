import { TgmClient, TgmClientConfig, TGM_MAIN_CONFIG, TGM_DEV_CONFIG } from './tgm-client.service';
import { NgModule, Optional, SkipSelf, InjectionToken } from '@angular/core';


/**
 * Module to import `TgmClient` service.
 * Use `forRoot()` so supply your client configuration.
 *
 * Client config looks like this:
 * ```
 * {
 *  region: '<your region / custom sevrice url>',
 *  key: '<your key>'
 *  options: ClientOptions
 * }
 * ```
 *
 * Example:
 * ``` ts
 * @NgModule ({
 *  imports: [TgmClientModule.forRoot({region: 'germany', key: 'ABCDE12345'})]
 * })
 * ```
 *
 * There is a possibility to add a second client (i.e. for testing dev endpoints). Simply add add a second config parameter to `forRoot()`
*/
@NgModule({
  providers: [ TgmClient ]
})
export class TgmClientModule {
  static forRoot(
    mainClientConf: TgmClientConfig,
    devClientConf?: TgmClientConfig
  ) {
    return {
      ngModule: TgmClientModule,
      providers: [
        { provide: <InjectionToken<string>>TGM_MAIN_CONFIG, useValue: mainClientConf },
        { provide: <InjectionToken<string>>TGM_DEV_CONFIG, useValue: devClientConf }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: TgmClientModule) {
    if (parentModule) {
      throw new Error('TgmClientModule has already been imported.');
    }
  }
}
