import { Input, Component } from '@angular/core';

@Component({
  selector: 'tgm-two-column-list',
  templateUrl: './two-column-list.component.html',
  styleUrls: ['./two-column-list.component.scss']
})
export class TgmTwoColumnListComponent {
  @Input() items: { label: string; value: string }[];
}
