import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TgmTraveltypeSelectComponent } from './traveltype-select.component';
import { MatIconModule } from '@angular/material';

@NgModule({
  declarations: [TgmTraveltypeSelectComponent],
  imports: [FlexLayoutModule, MatIconModule],
  exports: [TgmTraveltypeSelectComponent]
})
export class TgmTravelTypeSelectModule { }
