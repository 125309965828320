
import {combineLatest as observableCombineLatest,  Observable ,  BehaviorSubject } from 'rxjs';

import {map, debounceTime} from 'rxjs/operators';
import {
  StatisticsSet,
  ReachableTile,
  SimpleLRU,
  LatLngId,
  StatisticsKey,
  TargomoClient,
  TravelRequestOptions
} from '@targomo/core';





import { CompositeLayer } from '../composite-layer';
import { TgmMapboxComponent } from '../../mapbox.component';
import { StatisticsMapLayer } from '../simple/statistics-layer';
import {
  StatisticsTravelLayer,
  StatisticsTravelLayerOptions
} from '../simple/statistics-travel-layer';
import { MaxEdgeWeightOption, ObservableExpression } from '../../../../../types';
import { Indicators } from '../../../../../lib';

const STATISTICS_CACHE = new SimpleLRU<ReachableTile>(10);

export interface DefaultStatisticsTravelLayerOptions {
  // travelTypeEdgeWeight: TravelTypeEdgeWeightOptions,
  travelOptions: TravelRequestOptions;
  edgeWeights: MaxEdgeWeightOption[];
  interpolator: string;
  statistic: StatisticsKey;
  sources: LatLngId[];
  inactiveSources?: LatLngId[];
  inverseTravel?: boolean;
  opacity?: number;
  closestSources?: boolean;
  intersectionMode?: string;
}

/**
 * An extended StatisticsTravelLayer that populates itself with travel times based on the sources and other
 * data given via the options parameter
 */
export class DefaultStatisticsTravelLayer extends CompositeLayer {
  private layer: StatisticsTravelLayer;

  private options: Observable<DefaultStatisticsTravelLayerOptions>;
  private visibleSubject = new BehaviorSubject<boolean>(true);

  protected map: TgmMapboxComponent;

  constructor(
    mapboxComponent: TgmMapboxComponent,
    private statisticsLayer: StatisticsMapLayer,
    private client: TargomoClient,
    options: ObservableExpression<DefaultStatisticsTravelLayerOptions>,
    private indicators?: Indicators
  ) {
    super(mapboxComponent);
    this.map = mapboxComponent;

    this.options = this.toObservable(options);
    this.init();
  }

  private init() {
    const layerOptions = this.pluckDistinctProperties<
      [MaxEdgeWeightOption[], string, StatisticsKey, number]
    >(this.options, ['edgeWeights', 'interpolator', 'statistic', 'opacity']).pipe(
      debounceTime(10),
      map(([edgeWeights, interpolator, statistic, opacity]) => {
        return {
          edgeWeights: edgeWeights.map(item => ({
            color: item.color,
            value: item.value
          })),
          interpolator: interpolator || 'interpolateYlOrRd',
          opacity: opacity != null ? opacity : 0.5,
          statistic
        } as StatisticsTravelLayerOptions;
      }), );

    const travelLayer = (this.layer = new StatisticsTravelLayer(
      this.statisticsLayer,
      layerOptions
    ));
    travelLayer.setVisible(true);

    this.watchProperties<[boolean]>(
      this.options,
      'inverseTravel',
      ([value]) => {
        travelLayer.setInverse(!!value);
      }
    );

    this.watch(
      observableCombineLatest(
        this.pluckDistinctProperties<
          [LatLngId[], LatLngId[], TravelRequestOptions, string]
        >(this.options, [
          'sources',
          'inactiveSources',
          'travelOptions',
          'intersectionMode'
        ]),
        this.statisticsLayer.getSource().events,
        this.visibleSubject
      ).pipe(debounceTime(10)),
      async ([
        [sources, inactiveSources, travelOptions, intersectionMode],
        statisticsSourceEvent,
        visible
      ]) => {
        const hasSources = sources && sources.length;
        if (!hasSources || !visible) {
          travelLayer.setTravel({});
          return;
        }

        const loadTravel = async (group: StatisticsSet) => {
          const result = await this.withIndicators(
            STATISTICS_CACHE.get(
              this.getTravelCacheKey(
                sources,
                travelOptions,
                inactiveSources,
                intersectionMode,
                group
              ),
              () =>
                this.client.statistics.travelTimes(sources, {
                  ...travelOptions,
                  closestSources: travelOptions.closestSources,
                  statisticsGroup: group,
                  inactiveSources,
                  intersectionMode
                })
            )
          );

          travelLayer.setTravel(result);
        };

        loadTravel(statisticsSourceEvent.group);
      }
    );

    return travelLayer;
  }

  setVisible(value: boolean): this {
    this.visibleSubject.next(value);
    this.layer.setVisible(value);
    return this;
  }

  isVisible(): boolean {
    return this.layer.isVisible();
  }

  private withIndicators<T>(action: Promise<T>) {
    if (this.indicators) {
      return this.indicators.add(action);
    } else {
      return action;
    }
  }

  private getTravelCacheKey(
    sources: LatLngId[],
    travelOptions: TravelRequestOptions,
    inactiveSources: LatLngId[] = null,
    intersectionMode: string,
    group: StatisticsSet = null
  ) {
    return JSON.stringify({
      ...travelOptions,
      inactiveSources: (inactiveSources || []).map(source => ({
        lat: source.lat,
        lng: source.lng
      })),
      sources: (sources || []).map(source => ({
        lat: source.lat,
        lng: source.lng
      })),
      travelOptions,
      intersectionMode,
      group
    });
  }
}
