import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TgmStatisticLegendComponent } from './statistic-legend.component'


@NgModule({
  declarations: [
    TgmStatisticLegendComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TgmStatisticLegendComponent
  ]
})
export class TgmStatisticLegendModule { }
