import { NgModule } from '@angular/core'
import {
  MatFormFieldModule, MatInputModule, MatSidenavModule,
  MatIconModule,
  MatButtonModule,
  MatTabsModule
} from '@angular/material'
import { TgmSimpleMapLayoutComponent } from './simple-map-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TgmMapboxModule } from '../mapbox/mapbox.module';
import { TgmMapPopupModule } from '../map-popup/map-popup.module';
import { TgmRainbowModule } from '../rainbow/rainbow.module';
import { TgmTravelFabModule } from '../travel-fab/travel-fab.module';
import { TgmSlideOpacityAnimatorModule } from '../../slide-opacity-animator/slide-opacity-animator.module';

@NgModule({
  declarations: [
    TgmSimpleMapLayoutComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    FlexLayoutModule,
    TgmMapboxModule,
    TgmMapPopupModule,
    TgmRainbowModule,
    TgmTravelFabModule,
    TgmSlideOpacityAnimatorModule
  ],
  exports: [
    TgmSimpleMapLayoutComponent
  ]
})
export class TgmSimpleMapLayoutModule { }
