import { TgmMainRouterComponent } from './main-router.component';
import { MatProgressBarModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout'
import { RouterModule } from '@angular/router'
import { TgmGlobalIndicatorsModule } from '../global-indicators/index';

@NgModule({
  declarations: [
    TgmMainRouterComponent
  ],
  imports: [
    TgmGlobalIndicatorsModule,
    FormsModule,
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports: [
    TgmMainRouterComponent
  ]
})
export class TgmMainRouterModule { }
