import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core'
import { Category, DataSet } from '../../../api'
import { Auth, Indicators } from '@targomo/client'
import { DataSetEndpoint } from '../../../api/dataSet'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GeoRegionsEndpoint } from '../../../api/georegions'
import { UserSettingsEndpoint } from '../../../api/userSettings'
import { PdfReportSettings } from '../../../model/settingsModel'
import { MatDialog } from '@angular/material'
import { ColorSelectDialog } from '../../../main/dialogs/colorSelectDialog/colorSelectDialog.component'
import {
  LIFESTYLE_SEGMENTS,
  CENSUS_SEGMENTS_RAW,
  WORKFORCE_SEGMENTS,
  ReportSegment,
  HOUSE_PRICES_SEGMENTS,
} from '../../../main/mini/model/reportSegments'
import { array as arrays } from '@targomo/common'

const AVAILABLE_LOCATION_COLUMS: { key: string; label: string; selected?: boolean; type?: string }[] = [
  { key: 'holdingCompany', label: 'Company' },
  { key: 'fascia', label: 'Fascia' },
  { key: 'sitename', label: 'Site Name' },
  { key: 'primaryCategory', label: 'Primary Category' },
  { key: 'secondaryCategory', label: 'Secondary Category' },
  { key: 'town', label: 'Town' },
  { key: 'street', label: 'Street' },
  { key: 'postcode', label: 'Postcode' },
  { key: 'grossInternalArea', label: 'GIA (sq ft)', type: 'integer' },
  { key: 'netSalesArea', label: 'NSA (sq ft)', type: 'integer' },
  { key: '!spaceEfficiency', label: 'Space Efficiency' },
].map((row) => {
  row.key = 'property@' + row.key
  return row
})

const AVAILABLE_COMPETITION_COLUMS: { key: string; label: string; selected?: boolean }[] = [
  { label: 'Count of Competitors', key: 'count' },
  { label: 'Total Competitor NSA', key: 'netSalesArea' },
  { label: 'Total Competitor GIA', key: 'grossInternalArea' },
].map((row) => {
  row.key = 'competition@' + row.key
  return row
})

const AVAILABLE_DEMOGRAPHICS_COLUMNS: { key: string; label: string; selected?: boolean; children?: any }[] =
  CENSUS_SEGMENTS_RAW[0].children
    .map((row) => {
      return {
        label: row.name,
        key: 'demographics@population2011@' + row.id,
        children: (row.children || []).map((item) => {
          return {
            label: item.name,
            key: item.id,
          }
        }),
      }
    })
    .concat(
      CENSUS_SEGMENTS_RAW.slice(1).map((row) => {
        return {
          label: row.name,
          key: 'demographics@' + row.id,
          children: (row.children || []).map((item) => {
            return {
              label: item.name,
              key: item.id,
            }
          }),
        }
      })
    )

const AVAILABLE_LIFESTYLE_COLUMNS: { key: string; label: string; children?: any; selected?: boolean }[] =
  LIFESTYLE_SEGMENTS[0].children
    .slice(0, LIFESTYLE_SEGMENTS[0].children.length - 1)
    .map((row) => {
      return {
        label: row.name,
        key: 'spendSummary@' + row.id,
        children: (row.children || []).map((item) => {
          return {
            label: item.name,
            key: item.id,
          }
        }),
      }
    })
    .concat(
      LIFESTYLE_SEGMENTS[1].children.map((row) => {
        return {
          label: row.name,
          key: 'spend@' + row.id,
          children: (row.children || []).map((item) => {
            return {
              label: item.name,
              key: item.id,
            }
          }),
        }
      })
    )

const AVAILABLE_WORKSPACE_COLUMNS: { key: string; label: string; selected?: boolean; children?: any }[] =
  WORKFORCE_SEGMENTS.map((row) => {
    return {
      label: row.id === 'workerAges' ? row.name + ' (by ages)' : row.name,
      key: 'workforce@' + row.id,
      children: (row.children || []).map((item) => {
        return {
          label: item.name,
          key: item.id,
        }
      }),
    }
  })

const AVAILABLE_WORKFORCE_OTHER = [{ label: 'All Workforce', key: 'allWorkforce' }]

const AVAILABLE_LIMITED_DOMINANT_OTHER = [
  { label: 'Lifestyle: Dominant Group A1:D5', key: 'lifestyle#A1D5' },
  { label: 'Lifestyle: Dominant Group A1:C5', key: 'lifestyle#A1C5' },
  {
    label: 'Demographics: Tenure: Dominant Owned, Private Rented, Social Rented',
    key: 'demographics@tenure#ownedrented',
  },
]

const HOUSE_PRICES_COLUMNS = [
  {
    key: 'house@dwellingTypesAveragePrice@all',
    label: 'Average House Price',
  },

  // {
  //   key: 'dwellingTypesAveragePricePerSqFt',
  //   label: 'House Price per Sq M',
  // },

  {
    key: 'house@householsIncome@totalGross',
    label: 'Average Household Income',
  },
]

function prefixMatchpoint(prefix: string, values: { children?: any; label: string }[]) {
  return values.map((item) => {
    return { ...item, label: prefix + ': ' + item.label, children: item.children }
  })
}

export const MATCHPOINT_COLUMNS = JSON.parse(
  JSON.stringify(
    [].concat(
      prefixMatchpoint('Spend', AVAILABLE_LIFESTYLE_COLUMNS),
      prefixMatchpoint('Workforce', AVAILABLE_WORKFORCE_OTHER),
      prefixMatchpoint('Demographics', AVAILABLE_DEMOGRAPHICS_COLUMNS),
      prefixMatchpoint('Workforce', AVAILABLE_WORKSPACE_COLUMNS),
      prefixMatchpoint('House Proces & Household Income', HOUSE_PRICES_COLUMNS),
      AVAILABLE_LIMITED_DOMINANT_OTHER
    )
  )
)

export const ALL_COMPARISON_REPORT_COLUMNS_LIST = [].concat(
  // AVAILABLE_DEMOGRAPHICS_COLUMNS,
  AVAILABLE_LIFESTYLE_COLUMNS,
  // AVAILABLE_WORKSPACE_COLUMNS,
  AVAILABLE_LOCATION_COLUMS,
  AVAILABLE_COMPETITION_COLUMS,

  AVAILABLE_WORKFORCE_OTHER,

  expandGroup(AVAILABLE_DEMOGRAPHICS_COLUMNS, CENSUS_SEGMENTS_RAW),
  expandGroup(AVAILABLE_WORKSPACE_COLUMNS, WORKFORCE_SEGMENTS)
)

export const ALL_COMPARISON_REPORT_COLUMNS_MAP: { [id: string]: { key: string; label: string; type?: string } } =
  ALL_COMPARISON_REPORT_COLUMNS_LIST.reduce((acc, cur) => {
    acc[cur.key] = cur
    return acc
  }, {})

function decorateNames(list: { label: string; key: string }[], prefix: string) {
  return list.map((item) => {
    return {
      label: prefix + ': ' + item.label,
      key: item.key,
    }
  })
}

function expandGroup(list: { label: string; key: string }[], segments: ReportSegment[]) {
  const result: { label: string; key: string }[] = []

  list.forEach((item) => {
    const parts = item.key.split('@')
    if (parts.length > 2) {
      result.push({
        label: item.label,
        key: item.key,
      })
    } else {
      const [_, key] = parts
      const segment = segments.filter((candidate) => candidate.id === key)[0]

      if (segment) {
        segment.children.forEach((child) => {
          result.push({
            label: segment.name + ': ' + child.name,
            key: item.key + '@' + child.id,
          })
        })
      }
    }
  })

  return result
}

@Component({
  selector: 'admin-comparison-report-options',
  templateUrl: './adminComparisonReportOptions.component.html',
  styleUrls: ['./adminComparisonReportOptions.component.less'],
})
export class AdminComparisonReportOptionsComponent implements OnInit, OnChanges {
  availableDemographics = AVAILABLE_DEMOGRAPHICS_COLUMNS
  availableLifestyle = AVAILABLE_LIFESTYLE_COLUMNS
  availableWorkforce = AVAILABLE_WORKSPACE_COLUMNS
  availableLocationColumns = AVAILABLE_LOCATION_COLUMS
  availableCompetitionColumns = AVAILABLE_COMPETITION_COLUMS

  availableForRatios: { label: string; key: string }[] = this.initiAvailableForRatios()

  @Input() model: any
  @Output() modelChange = new EventEmitter<any>()

  private extraProperties: string[] = []

  constructor(private dialog: MatDialog, private dataSetEndpoint: DataSetEndpoint, private indicators: Indicators) {}

  async ngOnInit() {
    this.ngOnChanges()

    this.extraProperties = await this.indicators.add(this.dataSetEndpoint.getAllAvailableProperties())
    this.availableForRatios = this.initiAvailableForRatios()
  }

  private initiAvailableForRatios() {
    const locationExtraProperties = (this.extraProperties || []).map((key) => {
      return {
        key: '*' + key,
        label: 'Location: ' + key,
      }
    })

    return arrays.sortBy(
      [].concat(
        decorateNames(expandGroup(AVAILABLE_DEMOGRAPHICS_COLUMNS, CENSUS_SEGMENTS_RAW), 'Demographics'),
        decorateNames(AVAILABLE_LIFESTYLE_COLUMNS, 'Spend'),
        decorateNames(expandGroup(AVAILABLE_WORKSPACE_COLUMNS, WORKFORCE_SEGMENTS), 'Workforce'),
        decorateNames(AVAILABLE_LOCATION_COLUMS, 'Location'),
        locationExtraProperties,
        decorateNames(AVAILABLE_WORKFORCE_OTHER, 'Workforce'),
        decorateNames(AVAILABLE_COMPETITION_COLUMS, 'Competition')
      ),
      'label'
    )
  }

  ngOnChanges() {
    if (!this.model) {
      this.model = <any>{}
    }

    // this.availableSections = this.mergeList(AVAILABLE_SECTIONS, this.model.sections)
    this.availableCompetitionColumns = this.mergeList(AVAILABLE_COMPETITION_COLUMS, this.model.competitionColumns)

    this.availableCompetitionColumns = this.mergeList(AVAILABLE_COMPETITION_COLUMS, this.model.competitionColumns)
    this.availableDemographics = this.mergeList(AVAILABLE_DEMOGRAPHICS_COLUMNS, this.model.demographicsColumns)
    this.availableLifestyle = this.mergeList(AVAILABLE_LIFESTYLE_COLUMNS, this.model.lifestyleColumns)
    this.availableWorkforce = this.mergeList(AVAILABLE_WORKSPACE_COLUMNS, this.model.workforceColumns)
    this.availableLocationColumns = this.mergeList(AVAILABLE_LOCATION_COLUMS, this.model.locationColumnsColumns)
  }

  private mergeList(avaliable: { key: string; label: string; selected?: boolean }[], selected: string[]) {
    const selectedMap: any = (selected || []).reduce((acc, cur) => {
      acc[cur] = true
      return acc
    }, {} as any)

    return avaliable.map((item) => {
      return {
        ...item,
        selected: selectedMap[item.key],
      }
    })
  }

  toggleCompetitionColumn(value: any) {
    value.selected = !value.selected
    this.fireUpdate()
  }

  toggleReportSection(value: any) {
    value.selected = !value.selected
    this.fireUpdate()
  }

  toggleGeneral(key: string) {
    ;(<any>this.model)[key] = !(<any>this.model)[key]
    this.fireUpdate()
  }

  async editColor($event: any) {
    $event.preventDefault()

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: { value: { color: this.model.borderColor } },
    })

    const result = await dialogRef.afterClosed().toPromise()
    this.model.borderColor = result ? result.color : this.model.borderColor
    this.fireUpdate()
  }

  clearColor() {
    this.model.borderColor = null
    this.fireUpdate()
  }

  private fireUpdate() {
    // this.model.sections = this.availableSections.filter(item => item.selected).map(item => item.key)
    this.model.competitionColumns = this.availableCompetitionColumns
      .filter((item) => item.selected)
      .map((item) => item.key)
    this.model.demographicsColumns = this.availableDemographics.filter((item) => item.selected).map((item) => item.key)
    this.model.lifestyleColumns = this.availableLifestyle.filter((item) => item.selected).map((item) => item.key)
    this.model.workforceColumns = this.availableWorkforce.filter((item) => item.selected).map((item) => item.key)
    this.model.locationColumnsColumns = this.availableLocationColumns
      .filter((item) => item.selected)
      .map((item) => item.key)
    // this.model.competitionColumnsColumns = this.availableCompetitionColumns.filter(item => item.selected).map(item => item.key)

    this.modelChange.next(this.model)
  }

  updateRatios(value: any, key: string) {
    this.model[key] = value
  }
}
