export interface ValueLabel {
  label: string
  value: number
  color?: string
}

export interface ValueIcon {
  icon: string
  value: number
}

export type NumberOrValueLabel = number | ValueLabel

export type NumberOrValueLabelIcon = NumberOrValueLabel | ValueIcon

export namespace ValueLabelFunctions {


  export function isValueLabel(item: NumberOrValueLabelIcon): item is ValueLabel {
    return (typeof item == 'object') && ('label' in <any>item)
  }

  export function isColorLabel(item: NumberOrValueLabelIcon): item is ValueLabel {
    return (typeof item == 'object') && ('color' in <any>item)
  }

  export function isValueIcon(item: NumberOrValueLabelIcon): item is ValueIcon {
    return (typeof item == 'object') && ('icon' in <any>item)
  }

  export function isNumber(item: any): item is number {
    return (typeof item == 'number')
  }

  export function getValue(item: NumberOrValueLabelIcon) {
    return isNumber(item) ? item : item.value
  }

  export function getColor(item: NumberOrValueLabelIcon) {
    if (isValueIcon(item)) {
      return null
    } else {
      return '' + (isColorLabel(item) ? item.color : null)
    }
  }
  export function getLabel(item: NumberOrValueLabelIcon) {
    if (isValueIcon(item)) {
      return null
    } else {
      return '' + (isValueLabel(item) ? (item.label || item.value) : item)
    }
  }

  export function getIcon(item: NumberOrValueLabelIcon) {
    return isValueIcon(item) ? item.icon : null
  }
}
