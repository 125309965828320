import { NgModule } from '@angular/core'
import { TgmTwoColumnListComponent } from './two-column-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    TgmTwoColumnListComponent,
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    MatDividerModule,
  ],
  exports: [
    TgmTwoColumnListComponent
  ]
})
export class TgmTwoColumnListModule { }
