import { Component, Output, Input, ViewChild, ViewContainerRef, EventEmitter } from '@angular/core'
import { UndoQueue } from '../../../util/index'
import { TgmMapboxComponent } from '../mapbox';
import { MapLayoutStyleOptions, MapLayoutTravelTimesOptions, MapLayoutOptions } from './simple-map-layout.component.type'
import { TRAVEL_COLORS } from '../../../types/colors';
import { MaxEdgeWeightOption } from '../../../types/index';
import { TgmMapPopupComponent } from '../map-popup/index';

/**
 * Standard map, sidenav, fabs layout for the apps we build. It can be customized using transclusion
 *
 * The following transclution slots are defined:
 *
 * `.map-layout-sidenav-main`  The main contents of the sidenav
 * `.map-layout-sidenav-details` The contents for a "details" panel in sidenav
 * `.map-layout-sidenav-options` The contents for a "options" panel in sidenav
 * `.map-layout-sidenav-reports` The contents for a "report" panel in sidenav
 * `.map-layout-hover-info` A sidenav panel showing info for hover over map cells
 * `.map-layout-extra-fabs`  Additional fabs in the right fab column
 */
@Component({
  selector: 'tgm-simple-map-layout',
  templateUrl: './simple-map-layout.component.html',
  styleUrls: ['./simple-map-layout.component.less']
})
export class TgmSimpleMapLayoutComponent {

  @Input() undoQueue: UndoQueue<{}, {}>
  @Input() styleOptions: MapLayoutStyleOptions
  @Input() options: MapLayoutOptions = {}
  @Input() travelOptions: any = { travelType: 'car', maxEdgeWeight: 900 }
  @Input() isSideBarOpened: boolean = true

  @Output() travelOptionsChange = new EventEmitter<MapLayoutTravelTimesOptions>()
  @Output() hoverInfoChange = new EventEmitter<boolean>()

  @ViewChild('sidenav', {read: ViewContainerRef}) sidenavRef: ViewContainerRef
  @ViewChild('map') readonly mapView: TgmMapboxComponent
  @ViewChild('mapMenuPopup') readonly mapMenuPopup: TgmMapPopupComponent

  colors: string[] = TRAVEL_COLORS
  private lastMaxEdgeWeightOptions: MaxEdgeWeightOption[]

  ngDoCheck() {
    if (this.lastMaxEdgeWeightOptions != this.options.maxEdgeWeightOptions) {
      this.lastMaxEdgeWeightOptions = this.options.maxEdgeWeightOptions

      if (this.lastMaxEdgeWeightOptions) {
        this.colors = this.lastMaxEdgeWeightOptions.map(option => option.color)
      }
    }
  }

  async sidenavStateChange(state: boolean) {
    this.isSideBarOpened = state
    const map = await this.mapView.getMap()
    map.resize()
  }

  travelOptionsChanged() {
    this.travelOptionsChange.emit(this.travelOptions)
  }
}
