import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterTransfers'
})
export class FilterTransferSegmentsPipe implements PipeTransform {
    transform(segments: any[]): any {
        if (!segments) {
            return segments;
        }

        return segments.filter((s: any) => s.type !== 'TRANSFER');
    }
}
