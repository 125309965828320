import {Component, Input, EventEmitter, Output} from '@angular/core';
import { MatSliderChange } from '@angular/material';

/*
 * Other angular2 components were unavailable/didn't work at this point
 */
@Component({
  selector: 'tgm-rangeslider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.less']
})
export class TgmRangesliderComponent {
  @Input() min: number = 0
  @Input() max: number = 200000
  @Input() margin: number = 100
  @Input() step: number = 1

  @Output() minValueChange = new EventEmitter<number>()
  @Output() maxValueChange = new EventEmitter<number>()

  @Input() minValue = 0
  @Input() maxValue = 200000

  constructor() {
  }

  changeMax(value: MatSliderChange) {
    this.maxValue = value.value
    this.maxValueChange.emit(this.maxValue)

    if (this.minValue > this.maxValue - this.margin) {
      this.minValue = Math.max(this.min, this.maxValue - this.margin)
      this.minValueChange.emit(this.minValue)
    }
  }

  changeMin(value: MatSliderChange) {
    this.minValue = value.value
    this.minValueChange.emit(this.minValue)

    if (this.maxValue < this.minValue + this.margin) {
      this.maxValue = Math.min(this.max, this.minValue + this.margin)
      this.maxValueChange.emit(this.maxValue)
    }
  }
}
