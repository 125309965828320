import { Component } from '@angular/core'
import { AppModel } from '../../model/appModel.service'
import { MAP_STYLES, MaxEdgeWeightOption, SubscriberComponent } from '@targomo/client'
import * as colors from '@targomo/client'
import * as d3scales from '../util/d3scales'
import { array as arrays } from '@targomo/common'
import { Auth } from '@targomo/client'
import {
  TravelDisplayMode,
  TRAVEL_TIME_RANGE_OPTIONS,
  NEW_MAP_STYLES,
  INTERSECTION_MODES,
  GapReportLocations,
} from '../../model/settingsModel'
import { TRAVEL_COLORS } from '@targomo/client'
import { TravelTypeEdgeWeightOptions } from '@targomo/core'
import { LabelsConfig } from '../../model/labelsConfig'
import { MatDialog } from '@angular/material'
import { CustomTravelColorDialogComponent } from '../dialogs/customTravelColorDialog/customTravelColorDialog.component'
import { INTERSECTION_THRESHOLD, POLYGON_SOURCES_THRESHOLD } from '../../model/placesModel'

export const MAP_COLOR_RANGES: { name: string; value: string[]; key: string }[] = [
  { key: 'greenToRed', name: 'Green to Red', value: TRAVEL_COLORS },
  { key: 'colorblind', name: 'Colorblind', value: ['#142b66', '#4525ab', '#9527bc', '#ce29a8', '#df2a5c', '#f0572c'] },
  {
    key: 'classic',
    name: 'Classic',
    value: [
      'rgb(179,255,64)',
      'rgb(255,152,48)',
      'rgb(0, 104, 55)',
      'rgb(234,56,193)',
      'rgb(14,137,255)',
      'rgb(237,4,31)',
    ],
  },
  // {name: 'Classic', value: ['rgb(179,255,64)', 'rgb(255,152,48)', 'rgb(255,255,176)', 'rgb(234,56,193)', 'rgb(14,137,255)', 'rgb(237,4,31)']},
]

const TRAVEL_DISPLAY_MODE_OPTIONS = [
  { name: 'No thematic, Isochrone Travel-Times', value: TravelDisplayMode.NoThematicPolygons },
  { name: 'Thematic Background, Inverted Isochrones', value: TravelDisplayMode.ThematicPolygonsInverted },
  { name: 'Thematic Background, Hex Travel-Time', value: TravelDisplayMode.ThematicNoPolygons },
]

const GAP_REPORT_LOCATIONS_OPTIONS = [
  { name: 'Map Window', value: GapReportLocations.WITHIN_MAP_WINDOW },
  { name: 'Reachable Area', value: GapReportLocations.WITHIN_REACHABLE_AREA },
]

function initMapColors() {
  const colorRamps = [
    { name: 'Yellow-Orange-Red', key: 'interpolateYlOrRd' },
    { name: 'Red-Yellow-Blue', key: 'interpolateRdYlBu' },
    { name: 'Red-Yellow-Green', key: 'interpolateRdYlGn' },
    { name: 'Spectral', key: 'interpolateSpectral' },
    { name: 'Yellow-Green-Blue', key: 'interpolateYlGnBu' },
    { name: 'Red-Purple', key: 'interpolateRdPu' },
    { name: 'Blues', key: 'interpolateBlues' },
    { name: 'Greens', key: 'interpolateGreens' },
    { name: 'Purples', key: 'interpolatePurples' },
    { name: 'Warm', key: 'interpolateWarm' },
    { name: 'Cool', key: 'interpolateCool' },
    { name: 'Plasma', key: 'interpolatePlasma' },
    { name: 'GrayScale', key: 'interpolateGreys' },
  ]

  function processRamp(interpolator: string) {
    function getColor(idx: number, stops: number, interpolator: string) {
      const opacity = 1
      const colorScale = (<any>d3scales)[interpolator]((idx + 1) / stops)
      const rgb = d3.rgb(colorScale)

      return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity + ')'
    }

    return arrays.empty(6).map((value, i) => getColor(i, 6, interpolator))
  }

  colorRamps.forEach((ramp) => {
    MAP_COLOR_RANGES.push({ key: ramp.key, name: ramp.name, value: processRamp(ramp.key) })
  })
}

initMapColors()

@Component({
  selector: 'options-panel',
  templateUrl: './optionsPanel.html',
  styleUrls: ['./optionsPanel.component.less'],
})
export class OptionsPanelComponent extends SubscriberComponent {
  mapStyleOptions = NEW_MAP_STYLES
  travelColors = MAP_COLOR_RANGES.concat({ key: 'custom', name: 'User Defined', value: null })
  selectedTravelColors = MAP_COLOR_RANGES[0]
  permissions: any = {}
  travelDisplayModeOptions = TRAVEL_DISPLAY_MODE_OPTIONS
  travelRanges = TRAVEL_TIME_RANGE_OPTIONS
  gapReportOptions = GAP_REPORT_LOCATIONS_OPTIONS

  intersectionModelValues: any[] = INTERSECTION_MODES

  selectedTravelRange = TRAVEL_TIME_RANGE_OPTIONS[0]

  disabledIntersection = false
  disabledTravelDisplay = false

  constructor(readonly appModel: AppModel, private auth: Auth, private dialog: MatDialog) {
    super()
    this.initPermisions()

    this.watch(appModel.places.sources.observable, (sources) => {
      this.disabledIntersection = sources && sources.length > INTERSECTION_THRESHOLD
      this.disabledTravelDisplay = sources && sources.length > POLYGON_SOURCES_THRESHOLD
    })

    this.watch(appModel.settings.travelRangeIdUpdates, (rangeId) => {
      let travelRange = TRAVEL_TIME_RANGE_OPTIONS[0].options
      if (rangeId != null) {
        TRAVEL_TIME_RANGE_OPTIONS.forEach((option) => {
          if (option.id === rangeId) {
            this.selectedTravelRange = option
          }
        })
      }
    })

    this.watch(appModel.settings.travelColorsRangeNameUpdates, (key) => {
      // Initialize
      this.selectedTravelColors = this.travelColors.filter((option) => option.key === key)[0] || MAP_COLOR_RANGES[0]
    })
  }

  private async initPermisions() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }

  changeIndices(value: boolean) {
    this.appModel.settings.displaySettings.nextProperty('regonalIndices', value)
  }

  changeMapStyle(value: string) {
    this.appModel.settings.displaySettings.nextProperty('mapStyle', value)
  }

  async changeMapColorRange(option: any) {
    this.selectedTravelColors = option

    const customColors = await this.appModel.settings.customTravelColorsUpdates.take(1).toPromise()
    this.appModel.settings.displaySettings.nextWithCurrent((current) => {
      const optionColors: string[] = option.value

      if (option.key === 'custom') {
        current.travelColors = [].concat(customColors)
      } else {
        current.travelColors = option.value
      }

      current.travelColorsRangeName = option.key

      return current
    })
  }

  changePedestrianLayer(value: string) {
    this.appModel.settings.displaySettings.nextProperty('pedestrianLayer', value)
  }

  changeWorkforceLayer(value: string) {
    this.appModel.settings.displaySettings.nextProperty('workforceLayer', value)
  }

  changeIsochrones(value: boolean) {
    this.appModel.settings.displaySettings.nextProperty('isochrones', value)
  }

  changeShowLabels(value: boolean) {
    this.appModel.settings.displaySettings.nextProperty('showLabels', value)
    this.appModel.places.clearLabels()
  }

  changeTravelDisplayMode(value: any) {
    this.appModel.settings.displaySettings.nextProperty('travelDisplayMode', value)
  }

  changeIntersectionMode(value: any) {
    this.appModel.settings.displaySettings.nextProperty('intersectionMode', value)
  }

  changeShowSectors(value: any) {
    this.appModel.settings.displaySettings.nextProperty('showSectors', value)
  }

  changeTravelRange(value: any) {
    this.appModel.settings.displaySettings.nextProperty('travelRange', value.id)
  }

  changeLabelsConfig(value: string[]) {
    this.appModel.settings.displaySettings.nextProperty('labelsConfig', value)
  }

  changeShowCustomCategoryColors(value: boolean) {
    this.appModel.settings.displaySettings.nextProperty('showCustomCategoryColors', value)
  }

  changeGapReportLocationsUpdates(value: any) {
    this.appModel.settings.displaySettings.nextProperty('gapReportLocations', value)
  }

  async editCustomTravelColors() {
    const currentColors = await this.appModel.settings.customTravelColorsUpdates.take(1).toPromise()
    const dialogRef = this.dialog.open(CustomTravelColorDialogComponent, {
      disableClose: false,
      data: { colors: currentColors },
    })

    const result: any = await dialogRef.afterClosed().toPromise()
    if (result) {
      this.appModel.settings.displaySettings.nextProperty('customTravelColors', [].concat(result))
    }
  }
}
