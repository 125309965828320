
import {MapLayer} from '../layer'
import { LatLngProperties } from '@targomo/core'
import { TgmMapboxComponent } from '../../mapbox.component'
import { MarkerMapSource } from '../../sources';
import { ObservableExpression } from '../../../../../types';
import { Layer, CirclePaint } from 'mapbox-gl';

/**
 * A layer representing markers on a map, implemented by a mapbox `circle` layer
 */
export class MarkerMapLayer<T extends LatLngProperties> extends MapLayer<MarkerMapSource<T>> {
  private style: CirclePaint

  /**
   * @param map The mapbox component this layer will be added to
   * @param source The source of markers data to be shown
   * @param styleObservable A mapbox-gl style object definition that can be used to style this layer
   */
  constructor(
    protected map: TgmMapboxComponent,
    source: ObservableExpression<T[]> | MarkerMapSource<T>,
    styleObservable?: ObservableExpression<any>
  ) {
    super(map, source instanceof MarkerMapSource ? source : new MarkerMapSource(source))

    if (styleObservable) {
      this.watch(styleObservable, style => {
        this.style = style
        this.update()
      })
    }
  }

  get(): Partial<Layer> {
    // const style = options.markerStyle || {
    const style = this.style || {
      'circle-radius': {
        // 'base': 1.75,
        'stops': [[5, 1], [25, 28]]
      },
      'circle-color': '#3887be',
      'circle-stroke-width': 1,
      'circle-stroke-color': '#24577b'
    }

    return {
      'interactive': true,
      'type': 'circle',
      'paint': style
    }
  }
}
